const features = {
  type: 'Iot', // or "Dealer", "Subtenant", "Department"
  routes: [
    {
      name: 'Dashboard',
      showInSidebar: true,
      sidebarName: 'Dashboard',
      sidebarGroup: 'Admin',
      features: {
        summaries: [
          // existing: ACTIVATED SERVICES/PREACTIVE SIMS/PAYG CONSUMED/UPCOMING RENEWALS
          {
            name: 'ActivatedServicesCard',
            clickable: 'true',
            // "navigateTo": "costCenter" // we don't have this page yet
            navigateTo: 'ServiceList'
          },
          {
            name: 'PreActiveSimsCard',
            clickable: 'true',
            navigateTo: 'Inventory'
          },
          {
            name: 'CurrentSpendCard',
            clickable: 'true',
            navigateTo: 'ServiceList'
          },
          {
            name: 'RetailSpendCard',
            clickable: 'true',
            navigateTo: 'ServiceList'
          }
          //...
        ],
        charts: [
          // existing: SERVICE HISTORY, ACTIVATION HISTORY, PAYG STATUS (%), USAGE OVERVIEW (%)
          {
            name: 'ServiceHistoryLineChart',
            clickable: 'ture',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'ActivationHistoryLineChart',
            clickable: 'ture',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'CostCtrServiceCountBarChart',
            clickable: 'ture',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'TerminationHistoryLineChart',
            clickable: 'ture',
            navigateTo: 'ServiceList'
          },
          {
            name: 'NoRechargeChart',
            clickable: 'ture',
            navigateTo: 'ServiceList'
          },
          {
            name: 'SpendChart',
            clickable: 'ture',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'PreActiveSimsChart',
            clickable: 'ture',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'PAYG Status',
            clickable: 'ture',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'Usage Overview',
            clickable: 'ture',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'ActivationsByCostCtrChart',
            clickable: 'true',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'ActivationsByChannelChart',
            clickable: 'true',
            navigateTo: 'ServiceList' // or CostCenter Summary Table
          },
          {
            name: 'CostCtrCreditBalanceChart',
            clickable: 'true',
            navigateTo: 'GroupDetails' // or CostCenter Summary Table
          },

          //...
        ]
      }
    },
    {
      name: 'ServiceList',
      showInSidebar: true,
      sidebarName: 'My Services',
      sidebarGroup: 'Admin',
      features: {
        columns: [
          // need to be ordered, need to be corelated to the /services/list api response fileds
          // use api fields
          'costCtrName',
          'preferredName',
          'data',
          'PAYG',
          'planStartDate',
          'msisdn',
          'plan',
          'daysLeft',
          'status',
          'ATUStatus'
        ]
      }
    },
    {
      name: 'Inventory',
      showInSidebar: true,
      sidebarName: 'Inventory',
      sidebarGroup: 'Admin',
      features: {
        visibleStatus: ['Used', 'Available', 'Terminated']
      }
    },
    {
      name: 'Groups',
      showInSidebar: true,
      sidebarName: 'My Groups', // "My Sub-Tenants"/"My Dealers"/"My Departments"...
      sidebarGroup: 'Admin',
      features: {
        canAddGroup: true
      }
    },
    {
      name: 'Users',
      showInSidebar: true,
      sidebarName: 'My Users', // Dealer's/Subtenant's/Department's Users
      sidebarGroup: 'Admin',
      features: {
        canAddUsers: true
      }
    },
    {
      name: 'SearchActivate',
      showInSidebar: true,
      sidebarName: 'Activate SIM',
      sidebarGroup: 'Admin'
    },
    {
      name: 'Profile',
      showInSidebar: true,
      sidebarName: 'Profile',
      sidebarGroup: 'Account'
    },
    {
      name: 'Service', // View/Manage Service Page
      showInSidebar: false,
      features: {
        canManageATU: true,
        canUpdateNickName: true,
        canBarService: true,
        canChangeNextPlan: true,
        canPurchaseNewPlan: true,
        canSeeDataUsage: true,
        canSeePAYGUsage: true
      }
    },
    {
      name: 'ActivateSim', // Fill in details to activate sim page
      showInSidebar: false
    },
    {
      name: 'AddGroup',
      showInSidebar: false,
      features: {
        addGroupDetails: ['groupName', 'address', 'email', 'phone']
      }
    },
    {
      name: 'AddUser',
      showInSidebar: false,
      features: {
        addUserDetails: ['groupName', 'address', 'email', 'phone']
      }
    },
    {
      name: 'UserDetails',
      showInSidebar: false,
      features: {
        canEditUser: true,
        userDetails: ['groupName', 'address', 'email', 'phone']
      }
    },
    {
      name: 'GroupDetails',
      showInSidebar: false,
      features: {
        groupDetails: ['groupName', 'address', 'email', 'phone']
      }
    },
    {
      name: 'ChangePlan',
      showInSidebar: false
    },
    {
      name: 'PurchasePlan',
      showInSidebar: false
    },
    {
      name: 'Summary', //Summary Page after Activation
      showInSidebar: false
    },
    {
      name: 'Balances',
      showInSidebar: false
    },
    {
      name: 'Review',
      showInSidebar: false
    },
    {
      name: 'Recharge',
      showInSidebar: true,
      sidebarName: "Recharge",
      sidebarGroup: "Admin"
    },
    {
      name: 'RechargeReview',
      showInSidebar: false
    },
  ]
}

export default features
