import { useAuthContext } from 'contexts/AuthContext'
import PropTypes from 'prop-types'
import React, { FunctionComponent, useRef } from 'react'
import { IActivationHandler, IHttpClient } from 'services/sdk/interfaces'
import HttpClient from 'services/sdk/HttpClient'
import ActivationHandler from 'services/sdk/handlers/ActivationHandler'
import {
  CreateAccountRequest,
  dealerActivateRequest,
  AccountLookupRequest
} from 'services/sdk/models/requests'
import {
  VerificationID,
  CreateAccountResponse
} from 'services/sdk/models/responses'
import { createCtx } from './helpers'

interface ActivationContextInterface {
  // Http Clients
  activationHandler: IActivationHandler

  // Functions
  getIDList: () => Promise<VerificationID[]>
  createAccount: (
    request: CreateAccountRequest
  ) => Promise<CreateAccountResponse>
  dealerActivate: (request: dealerActivateRequest) => Promise<any>
  isSimKitted: boolean
  setIsSimKitted: (isSimKitted: boolean) => void
  accountLookup: (request: AccountLookupRequest) => Promise<any>
}

type ActivationContextProps = {
  children: React.ReactNode
}

const [ctx, useContext, Provider] = createCtx<ActivationContextInterface>()

export const ActivationContextProvider: FunctionComponent<
  ActivationContextProps
> = ({ children }) => {
  const { tokenProvider } = useAuthContext()
  const standardHttpClient = useRef<IHttpClient>(
    HttpClient(process.env.REACT_APP_HOST_AUTH, tokenProvider)
  ).current
  const activationHandler = useRef(
    ActivationHandler(standardHttpClient)
  ).current
  const [isSimKitted, setIsSimKitted] = React.useState<boolean>(false)

  const getIDList = async () => {
    return await activationHandler
      .getIDList()
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get id list error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const createAccount = async (request: CreateAccountRequest) => {
    return await activationHandler
      .createAccount(request)
      .then((response) => {
        if (response.ok && response.response) {
          console.log('create account success:', response.response)
          return response.response
        } else {
          console.log('create account error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const dealerActivate = async (request: dealerActivateRequest) => {
    return await activationHandler
      .dealerActivate(request)
      .then((response) => {
        console.log('dealer activate response:', response)
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('dealer activate error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const accountLookup = async (request: AccountLookupRequest) => {
    return await activationHandler
      .accountLookup(request)
      .then((response) => {
        console.log('account lookup response:', response)
        if (response.ok && response.response) {
          return response
        } else {
          console.log('account lookup error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  return (
    <Provider
      value={{
        activationHandler,
        getIDList,
        createAccount,
        dealerActivate,
        accountLookup,
        isSimKitted,
        setIsSimKitted,
      }}
    >
      {children}
    </Provider>
  )
}

ActivationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useActivationContext = useContext

export default ctx
