import React, { useEffect, useState, useCallback, useMemo } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useHistory } from 'react-router-dom'
// import Strings from 'localization'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { usePsmaContext } from 'contexts/PsmaContext'
import Address from 'services/sdk/models/address'
import useDebounce from 'hooks/useDebounce'
import { dateTimeFormatter } from '../../utils/Utils'
import 'assets/css/argon-dashboard-pro-react.css'

function UserDetails({ location }) {
  const { authUserId, authRoleId, authRole, groupName, status, emailVerified } = location.state
  const { getUserDetails, getRoleList, updateUser, updateUserStatus, sendEmailVerification } = useDashboardContext()
  const [costCenterType, setCostCenterType] = useState('')
  const [userDetails, setUserDetails] = useState(null)
  //const { username, address, contact, lastLogin } = userDetails
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [psmaError, setPsmaError] = useState(false)
  const [psmaErrorMessage, setPsmaErrorMessage] = useState('')
  const [suggestedAddresses, setSuggestedAddresses] = useState(null)
  const [addressQuery, setAddressQuery] = useState('')
  const [showAddressList, setShowAddressList] = useState(false)
  const { getAddressSuggestions, getAddress } = usePsmaContext()
  const [roleList, setRoleList] = useState([])
  const [showRoleList, setShowRoleList] = useState(false)
  const [roleId, setRole] = useState(authRoleId)
  const [address, setAddress] = useState(null)
  const history = useHistory()
  // const [manualAddress, setManualAddress] = useState({
  //   unitNumber: '', 
  //   street: '', 
  //   streetNumber: '', 
  //   streetName: '', 
  //   streetType: '', 
  //   suburb: '', 
  //   state: '',
  //   postcode: ''
  // });
  // const [contact, setContact] = useState({email: '', contactMobile: ''});
  const [editable, setEditable] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [originalUserDetails, setOriginalUserDetails] = useState(null)
  const [terminate, setTerminate] = useState(false)
  const [terminated, setTerminated] = useState(false)

  const [showVerifiedPopup, setShowVerifiedPopup] = useState(false)
  const [showUnverifiedPopup, setShowUnverifiedPopup] = useState(false)
  const [showEmailSentSuccessPopup, setShowEmailSentSuccessPopup] = useState(false)
  const [showEmailSentErrorPopup, setShowEmailSentErrorPopup] = useState(false)
  const [emailSentErrorMessage, setEmailSentErrorMessage] = useState('')

  const MIN_LENGTH = 4 // PSMA wants at least 4 chars before returning results

  const loadUser = useCallback(async () => {
    setLoading(true)
    const userInfo = await getUserDetails({ authUserId })
    console.log('userInfo:', userInfo)
    setUserDetails(userInfo)
    setOriginalUserDetails(userInfo)
    setCostCenterType(userInfo.costCtrType)
    setLoading(false)
  }, [getUserDetails, authUserId])

  useEffect(() => {
      loadUser()
  }, [getUserDetails, loadUser, authUserId])

  const statusFlag = () => {
      if (status === 'active' && terminated === false) {
        return (
          <Button color="success" disabled="true">
            Active
          </Button>
        ) 
      } else if (status === 'suspended' && terminated === false) {
        return (
          <Button color="warning" disabled="true">
            Suspended
          </Button>
        )
      } else {
        return (
          <Button color="dark" disabled="true">
            Terminated
          </Button>
        )
      }
  }

  const editToggle = () => {
    setEditable((prevState) => !prevState)
  }

  const handleUserDetailsChange = (key, value) => {
    // hack to deep copy object and avoid mutating state
    let updatedDetails = JSON.parse(JSON.stringify(userDetails));
    if (key.match(/\./)) {
      const path = key.split(/\./);
      const lastKey = path.pop();
      const nestedObj = path.reduce((a, prop) => a[prop], updatedDetails);
      nestedObj[lastKey] = value;
    } else {
      updatedDetails[key] = value;
    }
  
    setUserDetails({
      ...updatedDetails,
    });
  }

  
  const addressToggle = () => {
    setShowAddressList((prevState) => !prevState)
  }
  
  const selectAddress = useCallback(async (item) => {
    setError(false)
    setLoading(true)
    
    await getAddress(item.id)
    .then((res) => {
      setLoading(false)
      setAddress(Address.ConstructFromPSMAAddress(res.address))
      handleUserDetailsChange('userDetails.address', {
        unitType: res.address.properties.complex_unit_type ? res.address.properties.complex_unit_type  : '' 
        ,unitNumber: res.address.properties.complex_unit_identifier ? res.address.properties.complex_unit_identifier : ''
        ,levelType: res.address.properties.complex_level_type ? res.address.properties.complex_level_type  : '' 
        ,levelNumber: res.address.properties.complex_level_number ? res.address.properties.complex_level_number : ''
        ,streetNumber: res.address.properties.street_number_1
        ,streetNumber2: res.address.properties.street_number_2 ? res.address.properties.street_number_2 : ''
        ,streetName: res.address.properties.street_name
        ,streetType: res.address.properties.street_type
        ,suburb: res.address.properties.locality_name
        ,state: res.address.properties.state_territory
        ,postcode: res.address.properties.postcode
      })
    }) 
    .catch((err) => {
      console.log(err)
      setLoading(false)
      setPsmaError(true)
      setPsmaErrorMessage(
        'Something went wrong with getting the address details, please try again later'
      )
    })
  }, [handleUserDetailsChange])
    
  const debouncedAddressQuery = useDebounce(addressQuery, 300)
  
  useEffect(() => {
    const getAddressList = async () => {
      if (
        debouncedAddressQuery === null ||
        debouncedAddressQuery.length < MIN_LENGTH
        ) {
          setSuggestedAddresses(null)
          setAddress(null)
          return
        }
        
        if (debouncedAddressQuery === address?.formattedAddress) {
          // if the address query is the same as the selected address, don't make a request again
          return
        }
        
        setLoading(true)
        
      await getAddressSuggestions(debouncedAddressQuery)
      .then((res) => {
        setLoading(false)
        setSuggestedAddresses(res.suggest)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setPsmaError(true)
        setPsmaErrorMessage(
          'Something went wrong with getting the address suggestions list, please try again later'
        )
        setSuggestedAddresses(null)
      })
    }
    getAddressList()
  }, [debouncedAddressQuery])
      
  useEffect(() => {
    if (address) {
      console.log('selected address to mrtm:', address)
      // set the address query shown in Input field to the PSMA suggested address
      setAddressQuery(address.formattedAddress)
    }
  }, [address])
      
      //const history = useHistory()
      
  useEffect(() => {
    setLoading(true)

    // get the role list based on the cost center type
    // console.log('costCtrType', userDetails.costCtrType)
    let interfaceName;
    switch (costCenterType.toLowerCase()) {
      case 'company':
        interfaceName = 'MGT-PORTAL';
        break;
      case 'dealer':
        interfaceName = 'DEALER-PORTAL';
        break;
      case 'subtenant':
        interfaceName = 'MGT-PORTAL';
        break;
      default:
        interfaceName = 'MGT-PORTAL';
        break;
    }

    const getRoles = async () => {
      await getRoleList({interface: interfaceName})
      .then((role) => {
        setLoading(false)
        setRoleList(role)
        console.log('role: ', role)
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
        setRoleList([])
      })
    }
    getRoles()
  }, [getRoleList, costCenterType])

  
  const updateDetails = async () => {
    const updatedUserDetails = {
      firstName: userDetails?.userDetails.firstName,
      lastName: userDetails?.userDetails.lastName,
      address: userDetails?.userDetails.address,
      contact: userDetails?.userDetails.contact
    }
    setLoading(true)
    await updateUser({
      authUserId: authUserId,
      authRoleId: roleId,
      username: userDetails.username,
      userDetails: updatedUserDetails
    })
    .then((res) => {
      setLoading(false)
      setSuccess(true)
      setSuccessMessage('User details updated successfully')
      getUserDetails({ authUserId })
      setOriginalUserDetails(userDetails)
    })
    .catch((err) => {
      setLoading(false)
      setError(true)
      setErrorMessage(err.message)
    })
  }
    
  const terminateToggle = () => {
    setTerminate((prevState) => !prevState)
  }

  const isUsernameValid = useMemo(() => {
    const rgx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}$/
    return rgx.test(userDetails?.username)
  }, [userDetails?.username])

  const isPhoneValid = useMemo(() => {
    const rgx = /^(?:\d{8}|\d{10})$/
    return rgx.test(userDetails?.userDetails?.contact?.contactMobile)
  }, [userDetails?.userDetails?.contact?.contactMobile])
    
      
  const handleConfirmTerminate = async () => {
    setLoading(true)
    await updateUserStatus({
      authUserId: authUserId,
      status: 'terminated'
    })
      .then((res) => {
        setLoading(false)
        setSuccess(true)
        setSuccessMessage('User terminated successfully')
        console.log('terminate success')
        terminateToggle()
        getUserDetails({ authUserId })
        setTerminated(true)
      })
     .catch((err) => {
       setLoading(false)
       setError(true)
       setErrorMessage(err.message)
     })
  }

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleChangePassword = () => {
    console.log("change password")
    history.push({
      pathname: '/admin/changePassword',
      state: {
        authUserId: authUserId,
        authRoleId: roleId,
        username: userDetails.username
      }
    })
  }

  const sendEmail = async () => {
    console.log("sending email verification...")
    await sendEmailVerification({
      category: 'EMAIL_VERIFICATION',
      deliveryMethod: 'EMAIL',
      authUserId: authUserId,
    })
      .then((res) => {
        console.log('actions after sending email verification')
        setShowEmailSentSuccessPopup(true)
      })
      .catch((err) => {
        console.log('error:', err)
        setShowEmailSentErrorPopup(true)
        setEmailSentErrorMessage(err)
      })
  }

  return (
    <>
      {userDetails && (
    <>
      <SimpleHeader name="View / Manage"/>
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  User Details
                </h3>
              </Col>
              <Col className="text-right" xs="4">
                {statusFlag()}
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form>
              <div className="pl-lg-4">
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>{Capitalize(costCenterType.toLowerCase())} Name</h>
                </Col>
                <Col md="9">
                  <h>{groupName}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Last Login</h>
                </Col>
                <Col md="9">
                  <h>{userDetails.lastLogin === null ? '' : dateTimeFormatter(userDetails.lastLogin)}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Role</h>
                </Col>
                {editable ? (
                  <Col 
                    xs="12 d-flex align-items-center"
                    md="10 d-flex align-items-center" 
                    sm="6 d-flex align-items-center" 
                  >
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showRoleList}
                      toggle={() => setShowRoleList(!showRoleList)}
                      className="ml-1 py-1"                   
                    >
                      <DropdownToggle color="light" caret>
                        {roleId ? roleList.find(i => i.authRoleId === roleId)?.authRole : "Select a Role"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {roleList.map((role) => {
                          return (
                            <DropdownItem
                              key={role.authRoleId}
                              onClick={() => {
                                setRole(role.authRoleId)
                              }}
                            >
                              {role.authRole}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                ) : (
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{userDetails.authRole}</h>
                  </Col>
                )}
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Username</h>               
                </Col>
                {editable ? (
                  <Col md="6">
                    <Input
                      placeholder="Email"
                      value={userDetails.username}
                      onKeyPress={(event) => {
                        if (/^[a-zA-Z0-9.`*+/=?^_]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gi.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('username', e.target.value)
                      }}
                      invalid={!isUsernameValid && !!userDetails.username}
                    >
                    </Input>
                    <div className="invalid-feedback">
                      Please enter a valid username
                    </div>
                    {error && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                  </Col> 
                ) : (
                  <Col className="d-flex align-items-center" md={10}>
                    <h style={{marginRight: 10}}>{userDetails.username}</h>
                    {emailVerified === 'Y' ? (
                        <i
                          className="ni ni-badge text-success"
                          onClick={() => setShowVerifiedPopup(!showVerifiedPopup)}
                        />
                      ) : (
                        <i
                          className="ni ni-badge text-danger"
                          style={{}}
                          onClick={() => setShowUnverifiedPopup(!showUnverifiedPopup)}
                        />
                      )
                    }
                  </Col>
                )}
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>First Name</h>
                </Col>
                {editable ? (
                  <Col md='6'>
                    <Input
                      placeholder='First Name'
                      value={userDetails?.userDetails.firstName}
                      onChange={(e) => {
                        handleUserDetailsChange('userDetails.firstName', e.target.value)
                      }}
                    >
                    </Input>
                  </Col>
                ) : (
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{userDetails?.userDetails?.firstName}</h>
                  </Col>
                )}
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Last Name</h>
                </Col>
                {editable ? (
                  <Col md='6'>
                    <Input
                      placeholder='Last Name'
                      value={userDetails?.userDetails.lastName}
                      onChange={(e) => {
                        handleUserDetailsChange('userDetails.lastName', e.target.value)
                      }}
                    >
                    </Input>
                  </Col>
                ) : (
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{userDetails?.userDetails?.lastName}</h>
                  </Col>
                )}
              </Row>
              {editable ? (
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Address Search
                  </Label>
                  <Col md={6}>
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showAddressList}
                      toggle={addressToggle}
                    >
                      <DropdownToggle tag="a">
                        <Input
                          id="input-address"
                          placeholder="Address Search"
                          type="input"
                          required={true}
                          autoComplete='off'
                          value={addressQuery}
                          invalid={!!addressQuery && !address && !psmaError}
                          onChange={(e) => {
                            console.log('address change', e.target.value)
                            setPsmaError(false)
                            setPsmaErrorMessage('')
                            setAddressQuery(e.target.value)
                          }}
                        />
                        <div className="invalid-feedback">
                          Please choose an address from the list
                        </div>
                        {psmaError && psmaErrorMessage && (
                          <div className="error-message">
                            {psmaErrorMessage}
                          </div>
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        {suggestedAddresses ? (
                          suggestedAddresses.length > 0 ? (
                            suggestedAddresses.map((item) => (
                              <DropdownItem
                                key={item.id}
                                onClick={(e) => {
                                  setError(false)
                                  setErrorMessage('')
                                  selectAddress(item)
                                }}
                              >
                                {item.address}
                              </DropdownItem>
                            ))
                          ) : (
                            <></>
                          )
                        ) : (
                          <DropdownItem>
                            Start by typing your address
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
              ) : (
                <>
                </>
              )}
              {!editable ? (
                <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Address</h>
                </Col>
                  <Col className="d-flex align-items-center" md={10}>
                    <h>
                    {userDetails.userDetails.address.unitNumber}{' '}
                    {userDetails.userDetails.address.streetNumber}{' '}
                    {userDetails.userDetails.address.streetName}{' '}
                    {userDetails.userDetails.address.streetType}{' '}
                    {userDetails.userDetails.address.suburb}{' '}
                    {userDetails.userDetails.address.state}{' '}
                    {userDetails.userDetails.address.postcode}
                    </h>
                  </Col>
                </Row>
              ) : (
                <>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Shop/Unit/Level
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Shop/Unit/Level"
                      value={userDetails.userDetails.address.unitNumber ? userDetails.userDetails.address.unitNumber : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.unitNumber', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Number
                  </Label>
                  <Col md="6">
                    <Input
                      id='input-street-number'
                      placeholder="Street Number"
                      value={userDetails.userDetails.address.streetNumber ? userDetails.userDetails.address.streetNumber : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.streetNumber', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Name
                  </Label>
                  <Col md="6">
                    <Input
                      id='input-street-name'
                      placeholder="Street Name"
                      value={userDetails.userDetails.address.streetName ? userDetails.userDetails.address.streetName : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.streetName', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Type
                  </Label>
                  <Col md="6">
                    <Input
                      id='input-street-type'
                      placeholder="Street Type"
                      value={userDetails.userDetails.address.streetType ? userDetails.userDetails.address.streetType : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.streetType', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    Suburb
                  </Label>
                  <Col md="6">
                    <Input
                      id='input-suburb'
                      placeholder="Suburb"
                      value={userDetails.userDetails.address.suburb ? userDetails.userDetails.address.suburb : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.suburb', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    State/Postcode
                  </Label>
                  <Col md="3">
                    <Input
                      id='input-state'
                      placeholder="State"
                      value={userDetails.userDetails.address.state ? userDetails.userDetails.address.state : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.state', e.target.value)
                      }}
                    ></Input>
                  </Col>
                  <Col md="3">
                    <Input
                      id='input-postcode'
                      placeholder="Postcode"
                      value={userDetails.userDetails.address.postcode ? userDetails.userDetails.address.postcode : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.postcode', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                </>
              )}
                <Row className="mb-4 align-items-center">
                  <Col md="2">
                    <h>Phone Number</h>
                  </Col>
                  {editable ? (
                    <Col md="6">
                      <Input
                        id='input-phone-number'
                        placeholder="Phone Number"
                        value={userDetails.userDetails.contact.contactMobile ? userDetails.userDetails.contact.contactMobile : ''}
                        invalid={!isPhoneValid && !!userDetails?.userDetails?.contact?.contactMobile}
                        // onKeyDown={(e) => {
                        //   if (!isPhoneValid) {
                        //     e.preventDefault()
                        //   }
                        // }}
                        onChange={(e) => {
                          setError(false)
                          setErrorMessage('')
                          handleUserDetailsChange('userDetails.contact.contactMobile', e.target.value)
                        }}
                        minLength={8}
                        maxLength={10}
                      >
                      </Input>
                      <div className="invalid-feedback">
                        Please enter a valid phone number
                      </div>
                    </Col>
                  ) : (
                    <Col className="d-flex align-items-center" md={10}>
                      <h>{userDetails.userDetails.contact.contactMobile}</h>
                    </Col>
                  )}
                </Row>
                <FormGroup row>
                  {!editable ? (
                    <Col className="d-flex justify-content-start">
                      <Button
                        className="btn-icon btn-3"
                        type="button"
                        disabled={(status === 'terminated' || terminated)}
                        style={(status === 'terminated' || terminated) ? {backgroundColor: 'lightgray'} : null}
                        onClick={editToggle}
                      >
                        <span className="btn-inner--icon"></span>
                        <span className="btn-inner-center-text">Edit</span>
                      </Button>
                      <Button
                        className="delete-btn btn-3"
                        type="button"
                        disabled={(status === 'terminated' || terminated)}
                        style={(status === 'terminated' || terminated) ? {backgroundColor: 'lightgray'} : null}
                        onClick={terminateToggle}
                      >
                        <span className="btn-inner--icon"></span>
                        <span className="btn-inner-center-text">Terminate</span>
                      </Button>
                      <Button
                        className="delete-btn btn-3"
                        type="button"
                        disabled={(status === 'terminated' || terminated)}
                        style={(status === 'terminated' || terminated) ? {backgroundColor: 'lightgray'} : null}
                        onClick={handleChangePassword}
                      >
                        <span className="btn-inner--icon"></span>
                        <span className="btn-inner-center-text">Change password</span>
                      </Button>
                      {terminateToggle ? (
                        <Modal
                          isOpen={terminate}
                          toggle={terminateToggle}
                          className="modal-dialog-centered"
                        >
                          <ModalBody>
                            <p>
                              Are you sure you want to terminate {userDetails.username}?
                            </p>
                          </ModalBody>
                          <ModalFooter>
                            <Button color="secondary" onClick={handleConfirmTerminate}>
                              Confirm
                            </Button>
                            <Button color="primary" onClick={terminateToggle}>
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                        ) : null
                      }
                    </Col>
                  ) : (
                    <Col className="d-flex justify-content-start">
                      <Button
                        className="btn-icon btn-3"
                        color="light"
                        type="button"
                        style={{border: '1px solid'}}
                        onClick={(e) => {
                          e.preventDefault()
                          setUserDetails(originalUserDetails)
                          editToggle()
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-times" />
                        </span>
                        <span className="btn-inner--text">Cancel</span>
                      </Button>
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="button"
                        onClick={(e) => {
                          updateDetails()
                          e.preventDefault()
                          editToggle()
                          setAddressQuery('')
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-check" />
                        </span>
                        <span className="btn-inner--text">Save</span>
                      </Button>
                      {error && <div className="error-message">{errorMessage}</div>}
                    </Col>
                  )}
                </FormGroup>
              </div>
            </Form>
            {showVerifiedPopup ? (
              <Modal
                className="modal-dialog-centered"
                isOpen={showVerifiedPopup}
              >
                <ModalBody>
                  <p>This email has already been verified</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      console.log('click OK')
                      setShowVerifiedPopup(false)
                    }}
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
            {showUnverifiedPopup ? (
              <Modal
                className="modal-dialog-centered"
                isOpen={showUnverifiedPopup}
              >
                <ModalBody>
                  <p>Resend verification email to {userDetails?.username}?</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      console.log('click cancel')
                      setShowUnverifiedPopup(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      console.log('click send')
                      setShowUnverifiedPopup(false)
                      sendEmail()
                    }}
                  >
                    Send
                  </Button>
                </ModalFooter>

              </Modal>
            ) : null}
            {showEmailSentSuccessPopup ? (
              <Modal
                className="modal-dialog-centered"
                isOpen={showEmailSentSuccessPopup}
              >
                <ModalBody>
                  <p>Email sent successfully</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      console.log('click OK in success popup')
                      setShowEmailSentSuccessPopup(false)
                    }}
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
            {showEmailSentErrorPopup ? (
              <Modal
                className="modal-dialog-centered"
                isOpen={showEmailSentSuccessPopup}
              >
                <ModalBody>
                  <p>Error occurs when sending email...</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      console.log('click OK in error popup')
                      setShowEmailSentErrorPopup(false)
                    }}
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
          </CardBody>
        </Card>
      </Container>
    </>
  )}
    </>
    )
}

export default UserDetails