import {
  Container,
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button
} from 'reactstrap'
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useEffect, useCallback, useState } from 'react'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

function Invoice({}) {
  const { getInvoice } = useDashboardContext()
  const { authCostCtrId } = useAccessControlContext()
  const [invoiceDetails, setInvoiceDetails] = useState(null)
  const [showSummary, setShowSummary] = useState(true)
  const loadInvoice = useCallback(async () => {
    const res = await getInvoice({
      costCtrId: authCostCtrId,
      detailed: showSummary ? 'False' : 'True'
    })
    console.log('invoice Details: ', res)
    setInvoiceDetails(res)
  }, [getInvoice, showSummary])
  useEffect(() => {
    loadInvoice()
  }, [loadInvoice])
  return (
    <>
      <SimpleHeader name="Invoice" description="View invoice details" />
      <Container fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Row className="d-flex align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0 text-primary">
                        {showSummary
                          ? 'Your total invoice summary'
                          : 'Your cost center invoice details'}
                      </h3>
                    </Col>
                    <Col xs="6" className="text-right">
                      <ButtonGroup className="btn-group border rounded-sm">
                        <Button
                          color="secondary"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault()
                            setShowSummary(true)
                          }}
                          active={showSummary}
                        >
                          Summary
                        </Button>
                        <Button
                          color="secondary"
                          type="button"
                          onClick={async (e) => {
                            e.preventDefault()
                            setShowSummary(false)
                          }}
                          active={!showSummary}
                        >
                          Details
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                {showSummary ? (
                  <CardBody>
                    <Form className="">
                      <Row>
                        <Col className="h3 text-primary">Plans</Col>
                        <Col className="h3 text-primary">Price</Col>
                        <Col className="h3 text-primary">Count</Col>
                        <Col className="h3 text-primary">Cost</Col>
                      </Row>
                      {invoiceDetails?.plans?.map((item, index) => {
                        return (
                          <Row key={index}>
                            <Col>
                              <h5>{item.planName}</h5>
                            </Col>
                            <Col>
                              <h5>{item.price}</h5>
                            </Col>
                            <Col>
                              <h5>{item.count}</h5>
                            </Col>
                            <Col>
                              <h5>{item.cost}</h5>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr className="my-3" />
                      <Row>
                        <Col className="h3 text-primary">Add-Ons</Col>
                        <Col className="h3 text-primary">Price</Col>
                        <Col className="h3 text-primary">Count</Col>
                        <Col className="h3 text-primary">Cost</Col>
                      </Row>
                      {invoiceDetails ? (
                        invoiceDetails['add-ons']?.map((item, index) => {
                          return (
                            <Row key={index}>
                              <Col>
                                <h5>{item.addOnName}</h5>
                              </Col>
                              <Col>
                                <h5>{item.price}</h5>
                              </Col>
                              <Col>
                                <h5>{item.count}</h5>
                              </Col>
                              <Col>
                                <h5>{item.cost}</h5>
                              </Col>
                            </Row>
                          )
                        })
                      ) : (
                        <></>
                      )}
                      <hr className="my-3" />
                      <Row className="d-flex justify-content-between">
                        <Col xs={3}>
                          <div>Total</div>
                        </Col>
                        <Col xs={3}>
                          <div>{invoiceDetails?.total[0].totalCost}</div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Form className="">
                      <Row>
                        <Col xs={3} className="h3 text-primary">
                          Cost Center
                        </Col>
                        <Col xs={3} className="h3 text-primary">
                          Plans
                        </Col>
                        <Col xs={2} className="h3 text-primary">
                          Price
                        </Col>
                        <Col xs={2} className="h3 text-primary">
                          Count
                        </Col>
                        <Col xs={2} className="h3 text-primary">
                          Cost
                        </Col>
                      </Row>
                      {invoiceDetails?.plans?.map((item, index) => {
                        return (
                          <Row key={index}>
                            <Col xs={3}>
                              <h5>{item.costCtrDescr}</h5>
                            </Col>
                            <Col xs={3}>
                              <h5>{item.planName}</h5>
                            </Col>
                            <Col xs={2}>
                              <h5>{item.price}</h5>
                            </Col>
                            <Col xs={2}>
                              <h5>{item.count}</h5>
                            </Col>
                            <Col xs={2}>
                              <h5>{item.cost}</h5>
                            </Col>
                          </Row>
                        )
                      })}
                      <hr className="my-3" />
                      <Row>
                        <Col xs={3} className="h3 text-primary">
                          Cost Center
                        </Col>
                        <Col xs={3} className="h3 text-primary">
                          Add-Ons
                        </Col>
                        <Col xs={2} className="h3 text-primary">
                          Price
                        </Col>
                        <Col xs={2} className="h3 text-primary">
                          Count
                        </Col>
                        <Col xs={2} className="h3 text-primary">
                          Cost
                        </Col>
                      </Row>
                      {invoiceDetails ? (
                        invoiceDetails['add-ons']?.map((item, index) => {
                          return (
                            <Row key={index}>
                              <Col xs={3}>
                                <h5>{item.costCtrDescr}</h5>
                              </Col>
                              <Col xs={3}>
                                <h5>{item.addOnName}</h5>
                              </Col>
                              <Col xs={2}>
                                <h5>{item.price}</h5>
                              </Col>
                              <Col xs={2}>
                                <h5>{item.count}</h5>
                              </Col>
                              <Col xs={2}>
                                <h5>{item.cost}</h5>
                              </Col>
                            </Row>
                          )
                        })
                      ) : (
                        <></>
                      )}
                      <hr className="my-3" />
                      <Row className="d-flex justify-content-between">
                        <Col xs={3}>
                          <div>Total</div>
                        </Col>
                        <Col xs={2}>
                          <div>{invoiceDetails?.total[0].totalCost}</div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Invoice
