/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Progress,
  Row
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from '../../contexts/DashboardContext'

import { dateTimeFormatter, kBToGB } from '../../utils/Utils'
import { useAuthContext } from '../../contexts/AuthContext'
import Strings from 'localization'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

function Service({ location }) {
  const { msisdn, svcId } = location.state
  const {
    getServiceDetail,
    setServiceATU,
    setServiceBar,
    updateServiceDetail
  } = useDashboardContext()
  const { getServiceFeatures } = useAccessControlContext()
  const { costCenterType } = useAuthContext()
  const history = useHistory()
  const [nickName, setNickname] = useState('')
  const [serviceDetail, setServiceDetail] = useState(null)
  const [loading, setLoading] = useState(false)
  const [atu, setAtu] = useState(false)
  const [bar, setBar] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [isSuspended, setIsSuspended] = useState(false)
  const [dataUsage, setDataUsage] = useState(null)
  const [isStandardPlan, setIsStandardPlan] = useState(false)
  const [serviceFeatures, setServiceFeatures] = useState(getServiceFeatures())

  const loadService = useCallback(async () => {
    setLoading(true)
    const serviceInfo = await getServiceDetail({ svcId })
    console.log('service info: ', serviceInfo)
    setServiceDetail(serviceInfo)
    setLoading(false)
    setIsSuspended(
      serviceInfo.state === 'Grace' ||
        serviceInfo.state === 'Dormant' ||
        serviceInfo.state === 'Suspended'
    )
    setIsStandardPlan(
      !serviceInfo.planDetails.planExpiry ||
        !serviceInfo.planDetails.planStartDate
    )
  }, [getServiceDetail, svcId])

  useEffect(() => {
    if (serviceDetail && serviceDetail.planDetails) {
      const dataUsage = serviceDetail.planDetails.balances.find((balance) => {
        return balance.type === 'kb'
      })
      setDataUsage(dataUsage)
    }
  }, [serviceDetail])

  useEffect(() => {
    loadService()
  }, [getServiceDetail, loadService, svcId])

  useEffect(() => {
    if (serviceDetail) {
      setNickname(serviceDetail.preferredName)
      setAtu(serviceDetail.ATUStatus === 'Enabled')
      setBar(serviceDetail.billingBars.data !== 'E')
      setGroupName(serviceDetail.costCtrName)
    }
  }, [serviceDetail, setNickname])

  useEffect(() => {
    console.log('loading: ', loading)
    console.log('msisdn:', msisdn)
    console.log('groupName:', groupName)
    console.log('nickName:', nickName)
  }, [loading, groupName, nickName, msisdn])

  const updateATU = async () => {
    const res = await setServiceATU({
      svcId,
      planId: serviceDetail.ATUPlanId,
      ATUStatus: atu ? 'Disabled' : 'Enabled'
    })
    console.log('set atu res: ', res)
    if (res === 200) {
      console.log('success set atu to :', !atu)
      setAtu(!atu)
    } else {
      console.log('error update atu')
    }
  }

  const handleAtuToggle = () => {
    if (!bar) {
      if (serviceDetail.ATUPlanId) {
        updateATU()
      } else {
        console.log(
          'no next plan choose, does not make sense to toggle on and off'
        )
        setAtu(!atu)
      }
    }
  }

  const handleBarToggle = async () => {
    const res = await setServiceBar({
      svcId,
      bar: !bar ? 'True' : 'False'
    })
    if (res === 200) {
      console.log('success set bar to :', !bar)
      if (!bar) {
        setBar(true)
        setAtu(false)
      } else {
        setBar(false)
        loadService()
      }
    } else {
      console.log('error update bar')
    }
  }

  const statusFlag = () => {
    if (isSuspended) {
      return (
        <Button color="danger" disabled="true">
          Suspended
        </Button>
      )
    } else if (serviceDetail.state === 'Terminated') {
      return (
        <Button color="dark" disabled="true">
          Terminated
        </Button>
      )
    } else if (bar) {
      return (
        <Button color="warning" disabled="true">
          Barred
        </Button>
      )
    } else {
      return (
        <Button color="success" disabled="true">
          Active
        </Button>
      )
    }
  }

  const updatePreferredName = async () => {
    const res = await updateServiceDetail({ svcId, preferredName: nickName })
    if (res === 200) {
      loadService()
      console.log('success update nickname to ', nickName)
    } else {
      console.log('error update nickname')
    }
  }

  return (
    <>
      {serviceDetail && (
        <>
          <SimpleHeader name="View / Manage" />
          <Container fluid>
            <Row>
              <div className="col">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0 text-primary">
                            {'Manage ' + serviceDetail.preferredName}
                          </h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          {statusFlag()}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="text-primary">
                      <Form>
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>{Strings[costCenterType].MS_Group}</h>
                          </Col>
                          <Col md="9">
                            <h>{groupName}</h>
                          </Col>
                        </Row>
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>Nickname</h>
                          </Col>
                          {JSON.parse(serviceFeatures?.canUpdateNickName) ? (
                            <>
                              <Col md="7">
                                <Input
                                  value={nickName}
                                  onChange={(e) =>
                                    setNickname(
                                      e.target.value.replace(
                                        /[^a-zA-Z0-9_-\s]/gi,
                                        ''
                                      )
                                    )
                                  }
                                ></Input>
                              </Col>
                              <Col md="2">
                                <Button
                                  color="light"
                                  className="text-primary"
                                  onClick={updatePreferredName}
                                >
                                  Update
                                </Button>
                              </Col>
                            </>
                          ) : (
                            <Col md="9">
                              <h>{nickName}</h>
                            </Col>
                          )}
                        </Row>
                        {serviceDetail.state !== 'Terminated' && (
                          <Row className="mb-4 align-items-center">
                            <Col md="2">
                              <h>Current plan</h>
                            </Col>
                            <Col md="7">
                              {serviceDetail &&
                                (!isSuspended && !isStandardPlan ? (
                                  <h>{serviceDetail.planDetails.planName}</h>
                                ) : (
                                  JSON.parse(
                                    serviceFeatures?.canPurchaseNewPlan
                                  ) && (
                                    <Button
                                      color="light"
                                      className="ml-5 text-primary"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        history.push({
                                          pathname: '/admin/new/plan',
                                          state: {
                                            msisdn,
                                            nickName,
                                            groupName,
                                            svcId
                                          }
                                        })
                                      }}
                                    >
                                      Purchase Plan
                                    </Button>
                                  )
                                ))}
                            </Col>
                          </Row>
                        )}

                        {atu && serviceDetail.state !== 'Terminated' && (
                          <Row className="mb-4 align-items-center">
                            <Col md="2">
                              <h>Next plan</h>
                            </Col>
                            <Col md="7">
                              {serviceDetail && (
                                <h>{serviceDetail.ATUPlanName}</h>
                              )}
                              {JSON.parse(
                                serviceFeatures?.canChangeNextPlan
                              ) && (
                                <Button
                                  color="light"
                                  className="ml-5 text-primary"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    history.push({
                                      pathname: '/admin/change/plan',
                                      state: {
                                        msisdn,
                                        nickName,
                                        groupName,
                                        svcId
                                      }
                                    })
                                  }}
                                >
                                  Change
                                </Button>
                              )}
                            </Col>
                          </Row>
                        )}
                        {!isSuspended &&
                          serviceDetail.state !== 'Terminated' && (
                            <Row className="mb-4 align-items-center">
                              <Col md="2">
                                <h>Auto renew</h>
                              </Col>
                              <Col md="7 d-flex align-items-center">
                                <label
                                  className={classnames(
                                    'custom-toggle',
                                    {
                                      'custom-toggle-success':
                                        serviceDetail.ATUPlanName !== null
                                    },
                                    {
                                      'custom-toggle-warning':
                                        serviceDetail.ATUPlanName === null
                                    },
                                    'mr-1'
                                  )}
                                >
                                  <input
                                    disabled={
                                      bar ||
                                      !JSON.parse(serviceFeatures?.canManageATU)
                                    }
                                    checked={atu}
                                    onChange={handleAtuToggle}
                                    type="checkbox"
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="Off"
                                    data-label-on="On"
                                  />
                                </label>
                                {atu && !serviceDetail.ATUPlanName && (
                                  <h className="ml-4 text-warning">
                                    Please choose next plan
                                  </h>
                                )}
                              </Col>
                            </Row>
                          )}

                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>MSISDN</h>
                          </Col>
                          <Col md="9">
                            <h>{msisdn}</h>
                          </Col>
                        </Row>
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>Activation date</h>
                          </Col>
                          <Col md="9">
                            <h>
                              {dateTimeFormatter(serviceDetail.activationDate)}
                            </h>
                          </Col>
                        </Row>
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>Expiry date</h>
                          </Col>
                          <Col md="9">
                            <h>
                              {dateTimeFormatter(
                                serviceDetail.planDetails.planExpiry
                                  ? serviceDetail.planDetails.planExpiry
                                  : serviceDetail.accountExpiry
                              )}
                            </h>
                          </Col>
                        </Row>
                        {JSON.parse(serviceFeatures?.canSeeDataUsage) && (
                          <Row className="mb-4 align-items-center">
                            <Col md="2">
                              <h>Data used / limit</h>
                            </Col>
                            <Col md="3">
                              {dataUsage &&
                                dataUsage.used &&
                                dataUsage.limit && (
                                  <>
                                    <h>
                                      {kBToGB(dataUsage.used) +
                                        'GB /  ' +
                                        kBToGB(dataUsage.limit) +
                                        'GB'}
                                    </h>
                                    <Progress
                                      color={
                                        dataUsage.used / dataUsage.limit < 0.5
                                          ? 'success'
                                          : 'warning'
                                      }
                                      className="progress-xs mb-0"
                                      max={dataUsage.limit}
                                      value={dataUsage.used}
                                    />
                                  </>
                                )}
                            </Col>
                          </Row>
                        )}
                        {JSON.parse(serviceFeatures?.canSeePAYGUsage) && (
                          <Row className="mb-4 align-items-center">
                            <Col md="2">
                              <h>PAYG used / limit</h>
                            </Col>
                            <Col md="3">
                              {serviceDetail.PAYGUsed && (
                                // serviceDetail.PAYGLimit &&
                                <>
                                  <h>
                                    {
                                      '$' + serviceDetail.PAYGUsed + ' / $100'
                                      // serviceDetail.PAYGLimit
                                    }
                                  </h>
                                  <Progress
                                    color={'warning'}
                                    className="progress-xs mb-0"
                                    max={100}
                                    value={serviceDetail.PAYGUsed}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        )}
                        {JSON.parse(serviceFeatures?.canBarService) && (
                          <Row className="mb-4 align-items-center">
                            <Col md="2">
                              <h>Bar service</h>
                            </Col>
                            <Col md="9 d-flex align-items-center">
                              <label className="custom-toggle custom-toggle-warning mr-1">
                                <input
                                  defaultChecked={false}
                                  checked={bar}
                                  onChange={handleBarToggle}
                                  type="checkbox"
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="Off"
                                  data-label-on="On"
                                />
                              </label>
                              <h className="ml-4">
                                {bar
                                  ? '(Toggle off to unbar this service)'
                                  : '(Toggle on to bar this service)'}
                              </h>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Row>
          </Container>
        </>
      )}
      {/* </Card>
            </div>
          </div>
        </Row>
      </Container> */}
    </>
  )
}

export default Service
