/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'
import { Replay } from '@sentry/replay'
import { Integrations } from '@sentry/tracing'

import Compose from 'contexts/Compose'
import { AuthContextProvider } from './contexts/AuthContext'
import { DashboardContextProvider } from './contexts/DashboardContext'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './assets/css/neue-haas-grotesk-display-pro/NeueHaasDisplayBold.ttf'
import './assets/css/neue-haas-grotesk-display-pro/NeueHaasDisplayMediu.ttf'
import './assets/css/neue-haas-grotesk-display-pro/NeueHaasDisplayRoman.ttf'
import './assets/css/neue-haas-grotesk-display-pro/NeueHaasDisplayRoman.ttf'
import './assets/css/neue-haas-grotesk-display-pro/NeueHaasDisplayThin.ttf'
import { AccessControlContextProvider } from './contexts/AccessControlContext'
import { PsmaContextProvider } from './contexts/PsmaContext'
import { ActivationContextProvider } from './contexts/ActivationContext'

// Set the global title here
document.title = process.env.REACT_APP_MVNO_NAME + " " + process.env.REACT_APP_PORTAL_TYPE
const root = ReactDOM.createRoot(document.getElementById('root'))

Sentry.init({
  dsn: 'https://85dcdc3aeda24e5cb0af629f2c0b1434@o656400.ingest.sentry.io/4504493890732032',
  integrations: [
    new Integrations.BrowserTracing(),
    new Replay({
      maskAllText: true,
      maskAllMedia: true
    })
  ],

  environment: process.env.REACT_APP_ENVIRONMENT ?? 'development',

  sampleRate: process.env.REACT_APP_ENVIRONMENT !== 'production' ? 0.5 : 1
})

root.render(
  <Compose
    components={[
      AuthContextProvider,
      AccessControlContextProvider,
      DashboardContextProvider,
      PsmaContextProvider,
      ActivationContextProvider
    ]}
  >
    <App />
  </Compose>
)
