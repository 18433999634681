import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from 'contexts/DashboardContext'
import { useActivationContext } from 'contexts/ActivationContext'
import { usePsmaContext } from 'contexts/PsmaContext'
import { Calendar } from 'react-date-range'
// react plugin used to create DropdownMenu for selecting items
//import Select2 from 'react-select2-wrapper'
import useDebounce from 'hooks/useDebounce'
import Address from 'services/sdk/models/address'

const MIN_LENGTH = 4 // PSMA wants at least 4 chars before returning results

function PersonalDetails({ location }) {
  const {
    msisdn,
    sim,
    portInNumber,
    activationType,
    accountNumber,
    acctCostCtrId,
    acctEmail,
    createAccount
  } =
    location.state
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [psmaError, setPsmaError] = useState(false)
  const [psmaErrorMessage, setPsmaErrorMessage] = useState('')
  // const [iccid, setICCID] = useState('')
  const [title, setTitle] = useState('Mr')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [mobileType, setMobileType] = useState('Mobile')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [dob, setDob] = useState('')
  const [address, setAddress] = useState(null)
  const [email, setEmail] = useState(createAccount ? acctEmail : '')
  const [availableEmail, setAvailableEmail] = useState(true)
  const [userInterface, setUserInterface] = useState('EUI')

  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showAddressList, setShowAddressList] = useState(false)
  const [year, setYear] = useState(null)
  const [month, setMonth] = useState(null)
  const [day, setDay] = useState(null)


  const [suggestedAddresses, setSuggestedAddresses] = useState(null)
  const [addressQuery, setAddressQuery] = useState('')
  const { getAddressSuggestions, getAddress } = usePsmaContext()

  const { usernameCheck } = useDashboardContext()

  const numberValid = useMemo(() => {
    const mobileRgx = /^(04)([0-9]{8})$/
    const phoneRgx = /^(0)([0-9]{9})$/
    if (phoneNumber.length !== 10) return false
    if (mobileType === 'Mobile') {
      if (!mobileRgx.test(phoneNumber)) return false
    }
    if (mobileType === 'Phone') {
      if (!phoneRgx.test(phoneNumber)) return false
    }
    return true
  }, [phoneNumber, mobileType])

  const isEmailValid = useMemo(() => {
    const rgx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}$/
    return rgx.test(email)
  }, [email])

  const handleSubmit = async () => {
    if (
      (!numberValid && phoneNumber !== '')||
      !address ||
      !dob ||
      !year ||
      month === null ||
      !day ||
      (!createAccount ? !isEmailValid : false)
      // !availableEmail
    ) {
      setError(true)
      return
    }

    const billingAddress = {
      unitType: address.unitType,
      unitNumber: address.unitNumber,
      levelType: address.levelType,
      levelNumber: address.levelNumber,     
      streetNumber: address.streetNumber,
      streetNumber2: address.streetNumber2,
      streetName: address.streetName,
      streetType: address.streetType,
      postcode: address.postcode,
      suburb: address.suburb,
      state: address.state
    }

    // const postalAddress = {
    //   streetNumber: address.streetNumber,
    //   streetName: address.streetName,
    //   streetType: address.streetType,
    //   postcode: address.postcode,
    //   suburb: address.suburb,
    //   state: address.state
    // }

    const subscriberDetails = {
      title,
      firstName,
      middleName,
      lastName,
      dob: new Date(year, month, day).toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }),
      contactMobile: mobileType === 'Mobile' ? phoneNumber : undefined,
      contactPhone: mobileType === 'Phone' ? phoneNumber : undefined,
      billingAddress: billingAddress
      //postalAddress: postalAddress,
    }

    // await createAccount({
    //   username: email,
    //   password: '123456',
    //   subscriberDetails: subscriberDetails
    // })
    //   .then((res) => {
    //     console.log('create account res:', res)
    history.push({
      pathname: '/admin/id/verification',
      state: {
        msisdn,
        sim,
        email,
        password: '123456',
        subscriberDetails: subscriberDetails,
        mobileType,
        phoneNumber,
        address,
        portInNumber,
        activationType,
        accountNumber,
        acctCostCtrId,
        acctEmail,
        createAccount
      }
    })
      // })
      // .catch((err) => {
      //   setError(true)
      //   setErrorMessage(
      //     err.message
      //       ? err.message
      //       : 'Something went wrong, please try again later'
      //   )
      // })
  }

  /* const toggle = () => {
      setShowDatePicker((prevState) => !prevState)
  } */
  const toggle = useCallback(() => {
    let state = !showDatePicker;
    console.log(state)
    setShowDatePicker(!showDatePicker);
  },[showDatePicker, setShowDatePicker]);

  const toggleOutside = useCallback(() => {
    setShowDatePicker(false);
  }, [setShowDatePicker]);

  const addressToggle = () => {
    setShowAddressList((prevState) => !prevState)
  }

  const selectAddress = useCallback(async (item) => {
    setError(false)
    setLoading(true)

    await getAddress(item.id)
      .then((res) => {
        setLoading(false)
        setAddress(Address.ConstructFromPSMAAddress(res.address))
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setPsmaError(true)
        setPsmaErrorMessage(
          'Something went wrong with getting the address details, please try again later'
        )
      })
  }, [getAddress])

  const debouncedAddressQuery = useDebounce(addressQuery, 300)

  useEffect(() => {
    const getAddressList = async () => {
      if (
        debouncedAddressQuery === null ||
        debouncedAddressQuery.length < MIN_LENGTH
      ) {
        setSuggestedAddresses(null)
        setAddress(null)
        return
      }

      if (debouncedAddressQuery === address?.formattedAddress) {
        // if the address query is the same as the selected address, don't make a request again
        return
      }

      setLoading(true)

      await getAddressSuggestions(debouncedAddressQuery)
        .then((res) => {
          setLoading(false)
          setSuggestedAddresses(res.suggest)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setPsmaError(true)
          setPsmaErrorMessage(
            'Something went wrong with getting the address suggestions list, please try again later'
          )
          setSuggestedAddresses(null)
        })
    }

    getAddressList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddressQuery])

  useEffect(() => {
    if (dob && dob.length === 10) {
      const [d, m, y] = dob.split('-')
      setDay(+d)
      setMonth(+m - 1)
      setYear(+y)
    } else {
      setDay(null)
      setMonth(null)
      setYear(null)
    }
  }, [dob])

  useEffect(() => {
    if (address) {
      console.log('selected address to mrtm:', address)
      console.log('address unit:', address.streetName)

      // set the address query shown in Input field to the PSMA suggested address
      setAddressQuery(address.formattedAddress)
    }
  }, [address]);

  const useOutsideClick = (callback) => {
    const ref = React.useRef();
  
    React.useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
  
      document.addEventListener('click', handleClick);
  
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);
  
    return ref;
  };

  const outsideDatePickerRef = useOutsideClick(toggleOutside);
  

  // if (createAccount) {
  //   const debouncedUsernameQuery = useDebounce(email, 300)
  //   useEffect( () => {
  //     const checkUsername = async () => {
  //       if (isEmailValid) {
  //         await usernameCheck({
  //           email: debouncedUsernameQuery,
  //           interface: userInterface
  //         })
  //         .then((res) => {
  //           console.log('res', res)
  //           if (res === 200) {
  //             setAvailableEmail(true)
  //             setError(false)
  //             setErrorMessage('')
  //           } else {
  //             setAvailableEmail(false)
  //             setError(true)
  //             setErrorMessage('Invalid email address')
  //           }
  //         })
  //         .catch((err) => {
  //           console.log('err', err)
  //           setAvailableEmail(false)
  //           setError(true)
  //           setErrorMessage('Invalid email address')
  //         })
  //       }
  //     }
  //     if (debouncedUsernameQuery !== '') {
  //       checkUsername()
  //     }
  //   },[usernameCheck, debouncedUsernameQuery, isEmailValid, userInterface])
  // }

  return (
    <>
      <SimpleHeader name="Activate SIM" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Enter Personal Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              <div className="pl-lg-4">
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Title
                  </Label>
                  <Col md={3}>
                    <Input
                      id="select-title"
                      type="select"
                      value={title}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setTitle(e.target.value)
                      }}
                    >
                      <option>Mr</option>
                      <option>Mrs</option>
                      <option>Miss</option>
                      <option>Ms</option>
                      <option>Dr</option>
                      <option>Prof</option>
                      <option>Master</option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    First Name
                  </Label>
                  <Col md={6}>
                    <Input
                      id="input-nickname"
                      placeholder="First Name"
                      type="text"
                      autoComplete='off'
                      value={firstName}
                      required={true}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setFirstName(
                          e.target.value.replace(/[^a-zA-Z\s]/gi, '')
                        )
                      }}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Middle Names
                  </Label>
                  <Col md={6}>
                    <Input
                      id="input-nickname"
                      placeholder="Middle Names"
                      type="text"
                      autoComplete='off'
                      value={middleName}
                      required={false}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setMiddleName(
                          e.target.value.replace(/[^a-zA-Z \s]/gi, '')
                        )
                      }}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Last Name
                  </Label>
                  <Col md={6}>
                    <Input
                      id="input-nickname"
                      placeholder="Last Name"
                      type="text"
                      autoComplete='off'
                      value={lastName}
                      required={true}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setLastName(e.target.value.replace(/[^a-zA-Z\s]/gi, ''))
                      }}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Date of Birth
                  </Label>
                  <Col md={6}>
                    <div ref={outsideDatePickerRef} style={{
                      position: 'relative'
                    }}>
                        <Input
                          id="select-dob"
                          placeholder="DD-MM-YYYY"
                          type="text"
                          required={true}
                          value={dob}
                          autoComplete='off'
                          invalid={!!dob && (!year || month === null || !day)}
                          onChange={(e) => {
                            setError(false)
                            setErrorMessage('')
                            setDob(e.target.value.replace(/[^0-9-]/gi, ''))
                          }}
                          maxLength={10}
                          onClick={toggle}
                        ></Input>
                        <div className="invalid-feedback">
                          Please enter/choose a valid date of birth
                        </div>
                        {showDatePicker && (
                          <div style={{
                            position: 'absolute',
                            zIndex: 1,
                            border: '0 solid rgba(0, 0, 0, 0.15)',
                            borderRadius: '0.4375rem',
                            boxShadow: '0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1)',
                          }}>
                          <Calendar
                            date={
                              year && month !== null && day
                                ? new Date(year, month, day)
                                : new Date()
                            }
                            onChange={(item) => {
                              setDob(
                                item.toLocaleDateString('es-CL', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                                })
                              )
                              toggle()
                            }}
                          ></Calendar>
                          </div>
                          )}
                      </div>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Contact Number
                  </Label>
                  <Col md={3}>
                    <Input
                      id="input-mobile-type"
                      type="select"
                      //required={true}
                      value={mobileType}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setMobileType(e.target.value)
                      }}
                    >
                      <option>Mobile</option>
                      <option>Phone</option>
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      id="input-nickname"
                      placeholder="Contact Number"
                      type="text"
                      value={phoneNumber}
                      invalid={!numberValid && !!phoneNumber}
                      autoComplete='off'
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setPhoneNumber(e.target.value.replace(/[^0-9\s]/gi, ''))
                      }}
                      maxLength={10}
                    />
                    <div className="invalid-feedback">
                      Please enter a valid phone number
                    </div>
                  </Col>
                </FormGroup>

                  <FormGroup row>
                    <Label className="letter-4" htmlFor="input-email" md={2}>
                      Email
                    </Label>
                    <Col md={6}>
                      {createAccount && (
                        <div className="label" style={{fontSize: '12.5px'}}>
                          This field will be used as the subscriber's login username.
                        </div>
                      )}
                      <Input
                        id="input-email"
                        placeholder="example@example.com"
                        type="text"
                        autoComplete='off'
                        required={true}
                        value={createAccount ? acctEmail : email}
                        disabled={createAccount}
                        invalid={(!isEmailValid && email !== '')}
                        // label="This field will be used as the subscriber's username."
                        onChange={(e) => {
                          setError(false)
                          setErrorMessage('')
                          setEmail(e.target.value)
                        }}
                      />
                      <div className="invalid-feedback">
                        Please enter a valid/ unique email address
                      </div>
                    </Col>
                  </FormGroup>
               
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Address
                  </Label>
                  <Col md={6}>
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showAddressList}
                      toggle={addressToggle}
                    >
                      <DropdownToggle tag="a">
                        <Input
                          id="input-address"
                          placeholder="Address"
                          type="input"
                          required={true}
                          autoComplete='off'
                          value={addressQuery}
                          invalid={!!addressQuery && !address && !psmaError}
                          onChange={(e) => {
                            console.log('address change', e.target.value)
                            setPsmaError(false)
                            setPsmaErrorMessage('')
                            setAddressQuery(e.target.value)
                          }}
                        />
                        <div className="invalid-feedback">
                          Please choose an address from the list
                        </div>
                        {psmaError && psmaErrorMessage && (
                          <div className="error-message">
                            {psmaErrorMessage}
                          </div>
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        {suggestedAddresses ? (
                          suggestedAddresses.length > 0 ? (
                            suggestedAddresses.map((item) => (
                              <DropdownItem
                                key={item.id}
                                onClick={(e) => {
                                  selectAddress(item)
                                }}
                              >
                                {item.address}
                              </DropdownItem>
                            ))
                          ) : (
                            <></>
                          )
                        ) : (
                          <DropdownItem>
                            Start by typing your address
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>

                {error && errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}

                <Button color="secondary" type="submit">
                  Submit
                </Button>

                {/* </Row> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default PersonalDetails
