import * as React from 'react'

export function createCtx<A extends unknown | null>(): [
  React.Context<A | undefined>,
  () => A,
  React.Provider<A | undefined>,
  React.Consumer<A | undefined>
] {
  const ctx = React.createContext<A | undefined>(undefined)
  function useCtx() {
    const c = React.useContext(ctx)
    if (c === undefined)
      throw new Error('useCtx must be inside a Provider with a value')
    return c
  }
  return [ctx, useCtx, ctx.Provider, ctx.Consumer]
}

export default {
  createCtx
}
