import SimpleHeader from 'components/Headers/SimpleHeader.js'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
//import  Strings from 'localization'
import {
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Spinner
  //DropdownProps
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useHistory, useLocation } from 'react-router-dom'
//import { useAuthContext } from '../../contexts/AuthContext'
//import RecordsDropdown from '../../components/Table/RecordsDropdown'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import useDebounce from 'hooks/useDebounce'
import { usePsmaContext } from 'contexts/PsmaContext'
import Address from 'services/sdk/models/address'
import { isEmailValid, isPhoneNumberValid } from '../../utils/validation'

const AddUser = () => {
  const [groupName, setGroupName] = useState()
  const [username, setUsername] = useState()
  const { addUser, getCostCtrListSimple, getRoleList, getGroupDetails, usernameCheck } = useDashboardContext()
  //const { authCostCtrId} = useAccessControlContext()
  const { getAddUserFeatures } = useAccessControlContext()
  const history = useHistory()
  const [costCtrId, setCostCtrId] = useState(null)

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [usernameErrorMessage, setUsernameErrorMessage] = useState(null)
  const [submitError, setSubmitError] = useState(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')
  const [manualAddress, setManualAddress] = useState({unitType: '', unitNumber: '', levelType: '', levelNumber: '', street: '', streetNumber: '', streetNumber2: '', streetName: '', streetType: '', suburb: '', state: '', postcode: ''});
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState(null)
  const [contact, setContact] = useState({email: '', contactMobile: ''});
  const [showCostCtrList, setShowCostCtrList] = useState(false)
  const [showAddressList, setShowAddressList] = useState(false)
  const [costCtrList, setCostCtrList] = useState([])
  const [psmaError, setPsmaError] = useState(false)
  const [psmaErrorMessage, setPsmaErrorMessage] = useState('')
  const [sortDesc, setSortDesc] = useState(true)
  const [isLoading, setIsLoading] = useState(null)
  const [suggestedAddresses, setSuggestedAddresses] = useState(null)
  const [addressQuery, setAddressQuery] = useState('')
  const { getAddressSuggestions, getAddress } = usePsmaContext()
  const [roleList, setRoleList] = useState([])
  const [showRoleList, setShowRoleList] = useState(false)
  const [roleId, setRole] = useState(null)
  const [showUserTypeList, setShowUserTypeList] = useState(false);
  const [currentUserType, setCurrentUserType] = useState('');
  const [requireDealer, setRequireDealer] = useState(true);
  const [requireSubTenant, setRequireSubTenant] = useState(false);
  const [currentSubtenant, setCurrentSubtenant] = useState('');
  const [typeAndGroupMutable, setTypeAndGroupMutable] = useState(true);
  const [availableEmail, setAvailableEmail] = useState(true)
  const [userInterface, setUserInterface] = useState('')
  const [isValid, setIsValid] = useState(false)

  // read add user features from GUI Feature data config
  const [addUserFeatures, setAddUserFeatures] = useState(getAddUserFeatures());
  
  const location = useLocation();

  const MIN_LENGTH = 4 // PSMA wants at least 4 chars before returning results

  // all possible user types
  // available user types are driving by GUI Feature data config
  const types = {
    All: 'All',
    Company: 'Company',
    Dealer: 'Dealer',
    Subtenant: 'Subtenant',
    Department: 'Department'
  }

  // Get the dealer name and costCtrId from useLocation()
  // This is to remember where you came from
  // 1. if you came from the dealer page, you can only add a user to that dealer
  // 2. if you came from the user page, you can add a user to any dealer
  useEffect(() => {
    // If you came from the dealer page, you can only add a user to that dealer
    if (location.state.costCenterType === 'DEALER' && location.state.groupName !== undefined) {
      setCurrentUserType('Dealer');
      setGroupName(location.state.groupName);
      setCostCtrId(location.state.costCtrId);
      setTypeAndGroupMutable(false);
    }
  }, [location])

  useEffect(() => {
    const getGroupName = async () => {
      if (addUserFeatures?.getDealerNameFromApi) {
        setCostCtrId(location.state.costCtrId);
        await getGroupDetails(location.state.costCtrId)
        .then((res) => {
          console.log('res:', res)
          if (res?.costCtrType === 'DEALER') {
            setCurrentUserType('Dealer')
            setGroupName(res?.costCtrDescr)
            setTypeAndGroupMutable(false);
          }
        })
        .catch((err) => {
          console.log('err:', err)
        });
      }
    }
    getGroupName();
  }, [getGroupDetails, addUserFeatures?.getDealerNameFromApi, location.state.costCtrId])

  const isStateValid = useMemo(() => {
    const rgx = /^[A-Z]{2,3}$/
    return rgx.test(manualAddress.state)
  }, [manualAddress.state])

  const isPostcodeValid = useMemo(() => {
    const rgx = /^[0-9]{4}$/
    return rgx.test(manualAddress.postcode)
  }, [manualAddress.postcode])

  useEffect(() => {
    setError(false)
    if (currentUserType !== 'Company') {
      if (currentUserType === '' || groupName === '' || costCtrId === undefined || !isEmailValid(username) || username === '' || username === undefined || !availableEmail || roleId === null || firstName === '' || lastName === '') {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
    } else {
      if (currentUserType === '' || !isEmailValid(username) || username === '' || username === undefined || !availableEmail || roleId === null || firstName === '' || lastName === '') {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
    }
    isEmailValid()
  }, [setIsValid, currentUserType, groupName, costCtrId, username, availableEmail, roleId, firstName, lastName])
  
  const onSubmit = async (ev) => {
    ev.preventDefault()
    console.log('onSubmit')
    setIsLoading(true)
    if (!isValid) {
      setIsLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please fill in all required fields')
      return
    } else if (manualAddress.state !== '' && !isStateValid) {
      setIsLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please enter a valid state')
      return
    } else if (manualAddress.postcode !== '' && !isPostcodeValid) {
      setIsLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please enter a valid postcode')
      return
    } else if (contact?.contactMobile !== '' && !isPhoneNumberValid(contact?.contactMobile)) {
      setIsLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please enter a valid phone number')
      return
    } else {
      setSubmitError(false)
      setSubmitErrorMessage('')
    }

    const Address = {
      unitType: manualAddress.unitType,
      unitNumber: manualAddress.unitNumber,
      levelType: manualAddress.levelType,
      levelNumber: manualAddress.levelNumber,
      streetNumber: manualAddress.streetNumber,
      streetNumber2: manualAddress.streetNumber2,
      streetName: manualAddress.streetName,
      streetType: manualAddress.streetType,
      suburb: manualAddress.suburb,
      state: manualAddress.state,
      postcode: manualAddress.postcode
    }

    const Contact = {
      email: username,
      contactMobile: contact.contactMobile
    }

    const userDetails = {
      firstName: firstName,
      lastName: lastName,
      address: Address,
      contact: Contact
    }

    const res = await addUser({ 
      username: username,
      authRoleId: roleId,
      costCtrId: costCtrId,
      costCtrType: currentUserType,
      userDetails: userDetails 
    })
    console.log('groupName:', groupName)
    // TODO: what action will be in the backend, if currentUserType is subtenant
    console.log('currentUserType:', currentUserType)
    if (res === 200) {
      setIsLoading(false)
      console.log('add user success')
      alert('User added successfully')
      history.goBack()
    } else {
      setIsLoading(false)
      setErrorMessage('Something went wrong')
      setError(true)
      alert('Something went wrong')
      console.log('error adding user')
    }
  }

  function handleAddressChange(key, value) {
    setManualAddress({
      ...manualAddress,
      [key]: value
    });
  }

  function handleContactChange(key, value) {
    setContact({
      ...contact,
      [key]: value
    });
  }

  const costCtrListToggle = () => {
    setShowCostCtrList((prevState) => !prevState)
  }


  const debouncedCostCtrList = useDebounce(groupName, 300)
  useEffect(() => {
    const loadCostCtrList = async () => {
      if (groupName !== '') {
        await getCostCtrListSimple({
          costCtrId,
          sortOrder: sortDesc ? 'asc' : 'desc',
          costCtrDescrFilter: debouncedCostCtrList,
          costCtrType: currentUserType ? currentUserType : 'All'
        })
        .then((load) => {
          setIsLoading(false)
          // console.log('load: ', load)
          setCostCtrList(load)
        })
        .catch((err) => {
          setIsLoading(false)
          setError(true)
          setErrorMessage(err.message)
          setCostCtrList([])
        })
      }
    }
    if (debouncedCostCtrList !== '') {
      loadCostCtrList()
    }
  }, [debouncedCostCtrList, getCostCtrListSimple, costCtrId, sortDesc, currentUserType, groupName])

  const addressToggle = () => {
    setShowAddressList((prevState) => !prevState)
  }

  const selectAddress = useCallback(async (item) => {
    setError(false)
    setIsLoading(true)
    await getAddress(item.id)
      .then((res) => {
        setIsLoading(false)
        setAddress(Address.ConstructFromPSMAAddress(res.address))
        setManualAddress({
          unitType: res.address.properties.complex_unit_type ? res.address.properties.complex_unit_type  : '' 
          ,unitNumber: res.address.properties.complex_unit_identifier ? res.address.properties.complex_unit_identifier : ''
          ,levelType: res.address.properties.complex_level_type ? res.address.properties.complex_level_type  : '' 
          ,levelNumber: res.address.properties.complex_level_number ? res.address.properties.complex_level_number : ''
          ,streetNumber: res.address.properties.street_number_1
          ,streetNumber2: res.address.properties.street_number_2 ? res.address.properties.street_number_2 : ''
          ,streetName: res.address.properties.street_name
          ,streetType: res.address.properties.street_type
          ,suburb: res.address.properties.locality_name
          ,state: res.address.properties.state_territory
          ,postcode: res.address.properties.postcode
        })
     }) 
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
        setPsmaError(true)
        setPsmaErrorMessage(
          'Something went wrong with getting the address details, please try again later'
        )
      })
  }, [getAddress])

    const debouncedAddressQuery = useDebounce(addressQuery, 300)

  useEffect(() => {
    const getAddressList = async () => {
      if (
        debouncedAddressQuery === null ||
        debouncedAddressQuery.length < MIN_LENGTH
      ) {
        setSuggestedAddresses(null)
        setAddress(null)
        return
      }

      if (debouncedAddressQuery === address?.formattedAddress) {
        // if the address query is the same as the selected address, don't make a request again
        return
      }

      setIsLoading(true)

      await getAddressSuggestions(debouncedAddressQuery)
        .then((res) => {
          setIsLoading(false)
          setSuggestedAddresses(res.suggest)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          setPsmaError(true)
          setPsmaErrorMessage(
            'Something went wrong with getting the address suggestions list, please try again later'
          )
          setSuggestedAddresses(null)
        })
    }

    getAddressList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddressQuery])

  useEffect(() => {
    if (address) {
      console.log('selected address to mrtm:', address)
      // set the address query shown in Input field to the PSMA suggested address
      setAddressQuery(address.formattedAddress)
    }
  }, [address])

  useEffect(() => {
    setIsLoading(true)
    const getRoles = async () => {
      let interfaceName;
      switch (currentUserType.toLowerCase()) {
        case 'company':
          interfaceName = 'MGT-PORTAL';
          break;
        case 'dealer':
          interfaceName = 'DEALER-PORTAL';
          break;
        case 'subtenant':
          interfaceName = 'MGT-PORTAL';
          break;
        default:
          interfaceName = 'MGT-PORTAL';
          break;
      }

      // Get the role list based on the current user type
      // The default url param is MGT-PORTAL
      await getRoleList({interface: interfaceName})
        .then((role) => {
          setIsLoading(false)
          setRoleList(role)
          console.log('role: ', role)
        })
        .catch((err) => {
          setIsLoading(false)
          setError(true)
          setErrorMessage(err.message)
          setRoleList([])
        })
    }
    if (currentUserType !== '') {
      getRoles()
    }
  }, [getRoleList, currentUserType])

  useEffect(() => {
    console.log("currentUserType: ", currentUserType)
    switch (currentUserType.toLowerCase()) {
      case 'company':
        console.log("dealer not required");
        setRequireDealer(false);
        setRequireSubTenant(false);
        setUserInterface('MGT-PORTAL')
        break;
      case 'dealer':
        console.log("dealer required");
        setRequireDealer(true);
        setRequireSubTenant(false);
        setUserInterface('DEALER-PORTAL')
        break;
      case 'subtenant':
        console.log("subtenant required");
        setRequireDealer(false);
        setRequireSubTenant(true);
        break;
      default:
        console.log("default userType, do nothing");
        break;
    }
  }, [currentUserType])

  const debouncedUsernameQuery = useDebounce(username, 300)
  useEffect( () => {
    const checkUsername = async () => {
      if (isEmailValid(username) && username !== '') {
        await usernameCheck({
          email: debouncedUsernameQuery,
          interface: userInterface ? userInterface : 'MGT-PORTAL'
        })
        .then((res) => {
          console.log('res', res)
          if (res === 200) {
            setAvailableEmail(true)
            setError(false)
            setUsernameErrorMessage('')
            setErrorMessage('')
          } else {
            setAvailableEmail(false)
            setError(true)
            setUsernameErrorMessage('Email needs to be unique')
          }
        })
        .catch((err) => {
          console.log('err', err)
          setAvailableEmail(false)
          setError(true)
          setUsernameErrorMessage('Something went wrong. Please try again later')
        })
      }
    }
    if (debouncedUsernameQuery !== '') {
      checkUsername()
    }
  },[usernameCheck, debouncedUsernameQuery, userInterface, username])

  return (
    <>
      <SimpleHeader 
        name="New User"
        description="Create a new user"
      ></SimpleHeader>
      <Container fluid>
        <Card>
          <CardBody>
            <Form>
              <div className="pl-lg-4 text-primary">
                <FormGroup row>
                  <Label 
                    className="letter-4" 
                    htmlFor="select-plan" 
                    md={2}
                  >
                    Type of the user
                    <span style={{color: "red"}}> *</span>
                  </Label>
                  <Col
                    xs="12 d-flex align-items-center justify-content-between"
                    sm="6 justify-content-sm-start"
                    md="4"
                  >
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showUserTypeList}
                      toggle={() => setShowUserTypeList(!showUserTypeList)}
                      disabled={!typeAndGroupMutable}
                      className="ml-1 py-1"
                    >
                      <DropdownToggle color="light" caret>
                        {currentUserType === '' ? "Select a user type" : currentUserType}
                      </DropdownToggle>
                      <DropdownMenu>
                        {addUserFeatures?.visibleType.map((type, index) => {
                          return (
                            <DropdownItem
                              required={true}
                              key={index}
                              onClick={() => {
                                setCurrentUserType(types[type])
                                setCostCtrList([])
                                setRole(null)
                                setGroupName('')
                                setUsername('')
                                setAvailableEmail(true)
                                setSubmitError(false)
                              }}
                            >
                              {types[type]}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                {requireDealer ?
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Dealer
                    <span style={{color: "red"}}> *</span>
                  </Label>
                  <Col md={6}>
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showCostCtrList}
                      toggle={costCtrListToggle}
                    >
                      <DropdownToggle tag="a">
                        <Input
                          id='input-cost-center'
                          placeholder='Dealer'
                          type='input'
                          autoComplete='off'
                          required={true}
                          value={groupName}
                          invalid={!costCtrId && !!groupName}
                          disabled={!typeAndGroupMutable}
                          onChange={(e) => {
                            setError(true)
                            setErrorMessage('')
                            setSubmitError(false)
                            //loadCostCtrList(e.target.value)
                            setCostCtrId(null)
                            setGroupName(e.target.value)
                          }}
                          />
                        <div className="invalid-feedback">
                          Please select a valid dealer
                        </div>
                      </DropdownToggle>
                      {typeAndGroupMutable &&
                      <DropdownMenu>
                        {costCtrList ? (
                          costCtrList.length > 0 ? (      
                            costCtrList.map((cost) => (
                              <DropdownItem
                                key={cost.costCtrId}
                                onClick={(e) => {
                                  setError(false)
                                  setErrorMessage('')
                                  setSubmitError(false)
                                  setGroupName(cost.costCtrDescription)
                                  setCostCtrId(cost.costCtrId)
                                }}
                              >
                                {cost.costCtrDescription}
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem>
                              Start by typing a dealer name
                            </DropdownItem>
                          )
                        ) : (
                          <DropdownItem>
                            No dealer found
                          </DropdownItem>
                        )}
                      </DropdownMenu>}
                    </Dropdown>
                  </Col>
                </FormGroup> : null}
                {requireSubTenant ?
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>Subtenant</Label>
                  <Col md={6}>
                    <Input
                      id='input-subtenant'
                      placeholder='Subtenant Name'
                      type='input'
                      autoComplete='off'
                      required={false}
                      value={currentSubtenant}
                      onChange={(e) => {
                        console.log('subtenant: ', e.target.value);
                        setCurrentSubtenant(e.target.value);
                      }}
                    />
                  </Col>
                </FormGroup> : null}
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Role
                    <span style={{color: "red"}}> *</span>
                  </Label>
                  <Col 
                    xs="12 d-flex align-items-center justify-content-between"
                    sm="6 justify-content-sm-start"
                    md="4"
                  >
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showRoleList}
                      toggle={() => setShowRoleList(!showRoleList)}
                      className="ml-1 py-1"
                    >
                      <DropdownToggle color="light" caret>
                        {roleId ? roleList.find(i => i.authRoleId === roleId)?.authRole : "Select a Role"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {roleList.length !== 0 ? (roleList.map((role) => {
                          return (
                            <DropdownItem
                              key={role.authRoleId}
                              onClick={() => {
                                setRole(role.authRoleId)
                              }}
                            >
                              {role.authRole}
                            </DropdownItem>
                          )})
                        ) : (
                          <DropdownItem>
                            {"Select the user type first"}
                          </DropdownItem>
                        )
                      }
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4 text-primary" htmlFor="input-email" md={2}>
                    Email
                    <span style={{color: "red"}}> *</span>
                  </Label>
                  <Col md={6}>
                    <div className="label" style={{fontSize: '12.5px'}}>
                      This field will be used as the user's login username.
                    </div>
                    <Input
                      placeholder="Email"
                      type="text"                
                      value={username}
                      required={true}
                      invalid={(!isEmailValid(username) && username !== '' && username !== undefined) || !availableEmail}
                      label="username"
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setUsernameErrorMessage('')
                        setSubmitError(false)
                        setUsername(e.target.value)
                      }}
                    ></Input>
                    <div className="invalid-feedback">
                      {usernameErrorMessage}
                      {username !== '' && username !== undefined && !isEmailValid(username) ? "Please enter a valid email" : null}
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4 text-primary" md={2}>
                    First Name
                    <span style={{color: "red"}}> *</span>
                  </Label>
                  <Col md={6}>
                    <Input
                      placeholder='First Name'
                      value={firstName}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setFirstName(e.target.value)
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4 text-primary" md={2}>
                    Last Name
                    <span style={{color: "red"}}> *</span>
                  </Label>
                  <Col md={6}>
                    <Input
                      placeholder='Last Name'
                      value={lastName}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setLastName(e.target.value)
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Address
                  </Label>
                  <Col md={6}>
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showAddressList}
                      toggle={addressToggle}
                    >
                      <DropdownToggle tag="a">
                        <Input
                          id="input-address"
                          placeholder="Address Search"
                          type="input"
                          //required={true}
                          autoComplete='off'
                          value={addressQuery}
                          invalid={!!addressQuery && !address && !psmaError}
                          onChange={(e) => {
                            console.log('address change', e.target.value)
                            setPsmaError(false)
                            setPsmaErrorMessage('')
                            setSubmitError(false)
                            setAddressQuery(e.target.value)
                          }}
                        />
                        <div className="invalid-feedback">
                          Please choose an address from the list
                        </div>
                        {psmaError && psmaErrorMessage && (
                          <div className="error-message">
                            {psmaErrorMessage}
                          </div>
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        {suggestedAddresses ? (
                          suggestedAddresses.length > 0 ? (
                            suggestedAddresses.map((item) => (
                              <DropdownItem
                                key={item.id}
                                onClick={(e) => {
                                  setError(false)
                                  setErrorMessage('')
                                  setSubmitError(false)
                                  selectAddress(item)
                                }}
                              >
                                {item.address}
                              </DropdownItem>
                            ))
                          ) : (
                            <></>
                          )
                        ) : (
                          <DropdownItem>
                            Start by typing your address
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Shop/Unit/Level
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Shop/Unit/Level"
                      value={manualAddress.unitNumber}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('unitNumber', e.target.value)
                      }}
                      ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Number
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Number"
                      value={manualAddress.streetNumber}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('streetNumber', e.target.value)
                      }}
                      ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Name
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Name"
                      value={manualAddress.streetName}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('streetName', e.target.value)
                      }}
                      ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Type
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Type"
                      value={manualAddress.streetType}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('streetType', e.target.value)
                      }}
                      ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    Suburb
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Suburb"
                      value={manualAddress.suburb}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('suburb', e.target.value)
                      }}
                      ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    State/Postcode
                  </Label>
                  <Col md="3">
                    <Input
                      placeholder="State"
                      value={manualAddress.state}
                      invalid={(!!manualAddress.state && !isStateValid)}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('state', e.target.value)
                      }}
                      >
                        <div className="invalid-feedback">
                          Please enter a valid state
                        </div>
                      </Input>
                  </Col>
                  <Col md="3">
                    <Input
                      placeholder="Postcode"
                      value={manualAddress.postcode}
                      invalid={(!!manualAddress.postcode && !isPostcodeValid)}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('postcode', e.target.value)
                      }}
                      >
                        <div className="invalid-feedback">
                          Please enter a valid postcode
                        </div>
                      </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Phone Number
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Phone Number"
                      value={contact.contactMobile}
                      invalid={!isPhoneNumberValid(contact.contactMobile) && !!contact.contactMobile}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleContactChange('contactMobile', e.target.value)
                      }}
                      maxLength={10}
                      ></Input>
                      <div className="invalid-feedback">
                        Please enter a valid phone number
                      </div>
                  </Col>
                </FormGroup>
                {submitError && submitErrorMessage && (
                  <div className="error-message">{submitErrorMessage}</div>
                )}
                {isLoading ? (
                  <Spinner></Spinner>
                ) : (
                  <Button color="secondary" type="submit" disabled={!isValid} onClick={onSubmit}>
                  Add
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default AddUser