import { useEffect, useState, useCallback } from 'react'
import { useDashboardContext } from '../../contexts/DashboardContext'
import SummartCard from './SummaryCard'
import { useHistory } from 'react-router-dom'

function ActivatedServicesCard({ costCtrId }) {
  const [activatedServices, setActivatedServices] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { getTotalActivatedServices } = useDashboardContext()
  const history = useHistory()

  const loadNumberOfActivatedServices = useCallback(async () => {
    setIsLoading(true)
    await getTotalActivatedServices(costCtrId)
      .then((response) => {
        setActivatedServices(response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [getTotalActivatedServices, costCtrId])

  const handleClick = useCallback(() => {
    console.log('activated services card clicked')
    history.push({
      pathname: '/admin/services',
      state: {
        costCtrId: costCtrId
      }
    })
  }, [history, costCtrId])

  useEffect(() => {
    loadNumberOfActivatedServices()
  }, [loadNumberOfActivatedServices])
  return (
    <SummartCard
      title="Activated Services"
      value={activatedServices}
      isLoading={isLoading}
      handleClick={handleClick}
    />
  )
}

export default ActivatedServicesCard
