import React from 'react'
import PropTypes from 'prop-types'

const Compose = ({ components, children }) => {
  return (
    <>
      {components.reduceRight(
        (acc, Comp) => (
          <Comp>{acc}</Comp>
        ),
        children
      )}
    </>
  )
}

Compose.propTypes = {
  components: PropTypes.arrayOf(PropTypes.elementType).isRequired,
  children: PropTypes.node.isRequired
}

export default Compose
