import { Bar } from 'react-chartjs-2'
import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { colors } from 'variables/charts.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'
function CostCtrServiceCountBarChart({ costCtrId }) {
  const [serviceCountList, setServiceCountList] = useState([])
  const [serviceCountPerPlanList, setServiceCountPerPlanList] = useState([])
  const { getCostCtrServiceCount, getSubtenantServiceCountPerPlan } =
    useDashboardContext()

  const history = useHistory()

  const loadCostCtrServiceCount = useCallback(async () => {
    await getCostCtrServiceCount()
      .then((response) => {
        setServiceCountList(response.results)
        //console.log('set costctr service count: ', response)
      })
      .catch((err) => {
        console.log('error at loadCostCtrServiceCount: ', err)
      })
  }, [getCostCtrServiceCount])

  const loadSubtenantServiceCountPerPlan = useCallback(async () => {
    await getSubtenantServiceCountPerPlan(costCtrId)
      .then((response) => {
        setServiceCountPerPlanList(response.results)
        //console.log('set subtenant service count per plan: ', response)
      })
      .catch((err) => {
        console.log('error at loadSubtenantServiceCountPerPlan: ', err)
      })
  }, [costCtrId, getSubtenantServiceCountPerPlan])

  const getCostCtrLable = () => {
    const list = serviceCountList.map((data) => data.costCtrDescr)

    return list
  }

  const getPlanLabel = () => {
    const list = serviceCountPerPlanList.map((data) => data.planName)

    return list
  }

  const getServiceCountData = () => {
    const list = serviceCountList.map((data) => data.count)

    return list
  }

  const getServiceCountDataPerPlan = () => {
    const list = serviceCountPerPlanList.map((data) => data.count)

    return list
  }

  useEffect(() => {
    if (costCtrId === null) {
      loadCostCtrServiceCount()
    }
    if (costCtrId) {
      loadSubtenantServiceCountPerPlan()
    }
  }, [loadCostCtrServiceCount, costCtrId, loadSubtenantServiceCountPerPlan])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Service Count</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {costCtrId === null && (
              <Bar
                data={{
                  labels: getCostCtrLable(),
                  datasets: [
                    {
                      label: 'Service Count',
                      data: getServiceCountData()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {
                          // beginAtZero: true
                        }
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element[0],
                      element[0]._index,
                      serviceCountList[element[0]._index],
                      'costCtrId: ' +
                        serviceCountList[element[0]._index].costCtrId
                    )
                    history.push({
                      pathname: '/admin/services',
                      state: {
                        costCtrId: serviceCountList[element[0]._index].costCtrId
                      }
                    })
                  }
                }}
                id="chart-service-count"
                className="chart-canvas"
              />
            )}
            {costCtrId != null && (
              <Bar
                data={{
                  labels: getPlanLabel(),
                  datasets: [
                    {
                      label: 'Service Count',
                      data: getServiceCountDataPerPlan()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {
                          // beginAtZero: true
                        }
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element[0],
                      element[0]._index,
                      serviceCountPerPlanList[element[0]._index],
                      'planId: ' +
                        serviceCountPerPlanList[element[0]._index].planId,
                      'planName: ' +
                        serviceCountPerPlanList[element[0]._index].planName
                    )
                    history.push({
                      pathname: '/admin/services',
                      state: {
                        preFilter: true,
                        costCtrId: costCtrId,
                        planId:
                          serviceCountPerPlanList[element[0]._index].planId
                      }
                    })
                  }
                }}
                id="chart-service-count-per-plan"
                className="chart-canvas"
              />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default CostCtrServiceCountBarChart
