import React, { useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardText,
  List,
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import { useActivationContext } from '../../contexts/ActivationContext'
import AccountLookup from './AccountLookup'


function SearchActivate({ location }) {
  const { getServiceFeatures, getActivationFlow } = useAccessControlContext()
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState('')

  const [getSimError, setGetSimError] = useState('')
  const [iccid, setICCID] = useState(location.state?.iccid ?? '')
  const [isPortIn, setIsPortIn] = useState(false)
  const [portInNumber, setPortInNumber] = useState('')
  const [activationType, setActivationType] = useState('portfromprepaid')
  const [accountNumber, setAccountNumber] = useState('')
  const [costCtrBalances, setCostCtrBalances] = useState({ credit: '' })
  const [activationFlow, setActivationFlow] = useState(getActivationFlow())
  
  const [account, setAccount] = useState({costCtrId: '', email: '', createAccount: false})
  const [confirmedAccount, setConfirmedAccount] = useState(false)

  const history = useHistory()
  const [isValid, setIsValid] = useState(false)

  const mvnoName = process.env.REACT_APP_MVNO_NAME

  const { getCostCtrBalances, checkSimSerial, usernameCheck } = useDashboardContext()
  const { authRole, authCostCtrId } = useAccessControlContext()
  const { setIsSimKitted } = useActivationContext()

  useEffect(() => {
    setLoading(true)
    const loadCostCtrBalances = async () => {
      await getCostCtrBalances(authCostCtrId)
        .then((res) => {
          console.log('res: ', res)
          setCostCtrBalances({
            credit: res.filter((item) => item.balanceType === 'CREDIT')
          })
        })
        .catch((err) => {
          console.log('err: ', err)
        })
    }
    loadCostCtrBalances()
  }, [getCostCtrBalances, authCostCtrId])

  const creditBalanceWarning = useMemo(() => {
    if (costCtrBalances.credit[0]?.useCreditBalance === 'Y') {
      let creditBalance = parseInt(costCtrBalances.credit[0]?.balance)
      let lowBalanceThreshold = parseInt(
        costCtrBalances.credit[0]?.lowBalanceThreshold
      )

      if (creditBalance >= lowBalanceThreshold) {
        return true
      }
      return false
    }
    return true
  }, [costCtrBalances])

  const numberValid = useMemo(() => {
    const mobileRgx = /^(04)([0-9]{8})$/
    if (portInNumber.length !== 10) return false

    if (!mobileRgx.test(portInNumber)) return false

    return true
  }, [portInNumber])

  const handleSubmit = async () => {
    setSubmitError('')
    if (iccid.length !== 10) {
      setGetSimError('Invalid SIM')
      return
    }

    await checkSimSerial({ iccid })
      .then((res) => {
        //console.log('res: ', res)
        setGetSimError('')
        setIsSimKitted(res.data.isKitted)
        if (activationFlow === 'dealer') {
          history.push({
            pathname: '/admin/personal/details',
            state: {
              msisdn: res.data.msisdn,
              sim: '8961030000' + iccid,
              isKitted: res.data.isKitted ? true : false,
              portInNumber: isPortIn ? portInNumber : undefined,
              activationType: isPortIn ? activationType : 'normal',
              accountNumber: !!accountNumber ? accountNumber : undefined,
              acctCostCtrId: confirmedAccount ? account.costCtrId : null,
              acctEmail: confirmedAccount ? account.email : null,
              createAccount: confirmedAccount ? account.createAccount : false
            }
          })
        }
        if (activationFlow === 'iot') {
          history.push({
            pathname: '/admin/activate',
            state: {
              msisdn: res.data.msisdn,
              sim: '8961030000' + iccid
            }
          })
        }
      })
      .catch((err) => {
        setSubmitError(err.message ? err.message : 'Something went wrong')
      })
  }

  useEffect(() => {
    if (iccid.length !== 10) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [iccid])

  const confirmAccount = (accountDetails) => {
    console.log('accountDetails', accountDetails)
    if (accountDetails?.costCtrId !== '') {
      setAccount(accountDetails)
      setConfirmedAccount(true)
    } else {
      setConfirmedAccount(false)
    }
  }

  return (
    <>
      <SimpleHeader name="Activate SIM" />
      <Container fluid>
        <AccountLookup 
          confirmedAccount={confirmAccount}
        />
        {confirmedAccount && (
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0 text-primary neue-bold letter-2">
                    SIM
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="text-primary">
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}
              >
                <div className="pl-lg-4">
                  <FormGroup row>
                    <Label className="letter-4" htmlFor="input-nickname" md={2}>
                      ICCID last 10 digits
                    </Label>
                    <Col md={6}>
                      <Input
                        id="input-nickname"
                        type="text"
                        value={'X'.repeat(10) + iccid}
                        onChange={(e) => {
                          setGetSimError('')
                          setICCID(e.target.value.replace(/[^0-9]/gi, ''))
                        }}
                        maxLength={20}
                        invalid={!!getSimError}
                      />
                      <div className="invalid-feedback">{getSimError}</div>
                    </Col>
                  </FormGroup>

                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        console.log('portin checkbox', e.target.checked)
                        setIsPortIn(e.target.checked)
                      }}
                    />
                    <Label check>Is this a Port In Service?</Label>
                  </FormGroup>

                  {isPortIn && (
                    <>
                      <h1 className="text-primary mt-3">Portin Details</h1>
                      <FormGroup row>
                        <Label
                          className="letter-4"
                          htmlFor="input-portInNumber"
                          md={2}
                        >
                          Port In Number
                        </Label>
                        <Col md={6}>
                          <Input
                            id="input-portInNumber"
                            type="text"
                            value={portInNumber}
                            required={true}
                            invalid={!numberValid && !!portInNumber}
                            onChange={(e) => {
                              setSubmitError('')
                              setPortInNumber(
                                e.target.value.replace(/[^0-9]/gi, '')
                              )
                            }}
                            placeholder="Port In Number"
                            maxLength={10}
                          />
                          <div className="invalid-feedback">
                            Please enter a valid mobile number
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          className="letter-4"
                          htmlFor="input-activation-type"
                          md={2}
                        >
                          Activation Type
                        </Label>
                        <Col md={6}>
                          <Input
                            id="input-activation-type"
                            type="select"
                            required={true}
                            onChange={(e) => {
                              setActivationType(e.target.value)
                            }}
                          >
                            <option value={'portfromprepaid'}>Pre-Paid</option>
                            <option value={'portfrompostpaid'}>Post-Paid</option>
                          </Input>
                        </Col>
                      </FormGroup>
                      {activationType === 'portfrompostpaid' && (
                        <FormGroup row>
                          <Label
                            className="letter-4"
                            htmlFor="input-account-number"
                            md={2}
                          >
                            Account Number
                          </Label>
                          <Col md={6}>
                            <Input
                              id="input-account-number"
                              type="text"
                              value={accountNumber}
                              placeholder="Account Number"
                              required={true}
                              onChange={(e) => {
                                setAccountNumber(
                                  e.target.value.replace(/[^0-9]/gi, '')
                                )
                              }}
                            />
                          </Col>
                        </FormGroup>
                      )}
                      <CardText className="text-dark">
                        You must provide your existing account number if you are
                        keeping a postpaid (on contract) number from another
                        network. You do not need to provide this if your existing
                        number is a prepaid service.
                      </CardText>
                      <CardText className="text-dark">
                        You authorise the mobile phone number above, the identity
                        of the gaining service provider to be disclosed to other
                        network providers and porting service providers and
                        financial institutions otherwise not involved in the
                        porting process for the purpose of routing calls,
                        complaint handling, fraud prevention and to assist in
                        fraud investigations, customer network fault management
                        and routing of SMS messages to that mobile phone number
                        after porting activity. You acknowledge and agree that:
                      </CardText>
                      <CardText className="text-dark mb-0 neue-medium">
                        I acknowledge that I had been advised by {mvnoName} of the
                        below and I acknowledge and agree with the following:
                      </CardText>
                      <List className="text-dark ">
                        <li>
                          Although I may have the right to Port the Mobile Service
                          Number, there may be costs and obligations associated
                          with the existing mobile service and Porting the Mobile
                          Service Number;
                        </li>
                        <li>
                          I may or may not be in an existing contract with the
                          current Service Provider; and
                        </li>
                        <li>
                          The contract may or may not include an obligation to
                          make early termination payments to the current Service
                          Provider. I declare that I have a contractual right to
                          the Mobile Service Number and am authorised to request
                          porting of the Mobile Service Number.
                        </li>
                        <li>
                          I acknowledge that I have been advised that by Porting
                          the Mobile Service Number, the service and/or Related
                          Services associated with that Number may or may not be
                          disconnected from the Losing Service Provider and may
                          result in finalisation of the account for that service.
                        </li>
                        <li>
                          I authorise the Mobile Service Number, the Gaining
                          Service Provider {mvnoName} and the Network Type (GSM,
                          UMTS) and any other porting information to be disclosed
                          to other carriers, network providers, portability
                          service suppliers for the purpose of porting, routing of
                          calls and SMS messages to the Mobile Service Number
                          after porting activity, complaint handling, customer and
                          network fault management, fraud prevention and to assist
                          in fraud investigations.
                        </li>
                        <li>
                          I also authorise porting information to be disclosed to
                          financial institutions for the purpose of fraud
                          prevention and to assist in fraud investigation.
                        </li>
                        <li>
                          I acknowledge that I have been advised by {mvnoName}{' '}
                          that if I continue to use the existing handset, it may
                          need to be unlocked and/or reprogrammed prior to
                          porting.
                        </li>
                        <li>
                          By proceeding with the Port-in, you agree that you are
                          authorised to request the porting of this mobile number
                          and you acknowledge that you understand and agree to the
                          terms of this authorisation as displayed on this page.
                        </li>
                      </List>
                      <FormGroup check inline className="align-items-start">
                        <Input
                          type="checkbox"
                          style={{ marginTop: '0.3rem' }}
                          required={true}
                        />
                        <Label check className="text-dark neue-medium">
                          By proceeding with the Port-in, you understand and agree
                          to the acknowledgements above.
                        </Label>
                      </FormGroup>
                    </>
                  )}

                  {!!submitError && (
                    <div className="error-message">{submitError}</div>
                  )}
                  {!creditBalanceWarning && (
                    <div className="error-message">
                      You are running low on credit and may not have enough to
                      complete the activation. Please contact your manager.
                    </div>
                  )}
                  <Row className="mt-3">
                    <Col>
                      <Button
                        style={isValid && confirmedAccount ? {} : { backgroundColor: 'lightgray' }}
                        type="submit"
                        disabled={!isValid}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        )}
      </Container>
    </>
  )
}

export default SearchActivate
