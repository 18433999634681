import {
  Row,
  Col,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

import React, { useState, useEffect } from 'react'

function CostCtrPicker({ handleOnChange }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { authCostCtrId } = useAccessControlContext()
  const [groupName, setGroupName] = useState('All')
  const { getCostCtrListSimple } = useDashboardContext()
  const [costCtrList, setCostCtrList] = useState([])

  useEffect(() => {
    const loadCostCtrList = async () => {
      await getCostCtrListSimple({ costCtrId: authCostCtrId }).then(
        (response) => {
          setCostCtrList(response)
        }
      )
    }
    loadCostCtrList()
  }, [getCostCtrListSimple])

  const toggle = () => setDropdownOpen(!dropdownOpen)
  return (
    <>
      <Label className="neue-light col-form-label text-light pr-1">
        Subtenant:
      </Label>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="btn-sm mx-0" color="light" caret>
          {groupName}
        </DropdownToggle>
        <DropdownMenu className="text-primary">
          <DropdownItem
            key={0}
            onClick={() => {
              handleOnChange(authCostCtrId)
              setGroupName('All')
            }}
          >
            All
          </DropdownItem>
          {costCtrList.map((costCtr) => {
            return (
              <DropdownItem
                key={costCtr.costCtrId}
                onClick={() => {
                  handleOnChange(costCtr.costCtrId)
                  setGroupName(costCtr.costCtrDescription)
                }}
              >
                {costCtr.costCtrDescription}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default CostCtrPicker
