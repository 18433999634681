import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Container,
  Spinner
} from 'reactstrap'
import { kBToGB } from '../../utils/Utils'
import { useDashboardContext } from '../../contexts/DashboardContext'

function VoucherRechargeReview({ location }) {
  const { voucherDetails, voucherPin, msisdn } = location.state
  const { applyVoucher, getServiceDetail } = useDashboardContext()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [svcId, setSvcId] = useState(null)
  const [serviceDetail, setServiceDetail] = useState(null)
  const history = useHistory()

  const loadService = useCallback(async () => {
    const serviceInfo = await getServiceDetail({ svcId: voucherDetails.svcId })
    console.log('service info: ', serviceInfo)
    setServiceDetail(serviceInfo)
  }, [getServiceDetail, voucherDetails.svcId])

  useEffect(() => {
    loadService()
  }, [loadService])

  const renderDiscounts = () => {
    return voucherDetails.discounts?.map((discount, index) => {
      return (
        <FormGroup row key={index}>
          <Label className="letter-4" htmlFor="input-msisdn" md={3}>
            {discount.discountDescr}
          </Label>
          <Col className="d-flex align-items-center" md={5}>
            {discount.discountDescr === 'Data' && discount.unlimited === 'N' ? (
              <h className="neue-bold">{kBToGB(discount.allowance) + ' GB'}</h>
            ) : (
              <h className="neue-bold">{discount.allowance}</h>
            )}
          </Col>
        </FormGroup>
      )
    })
  }

  const renderAccountDetails = useCallback(() => {
    if (serviceDetail && serviceDetail.balance) {
      return (
        <FormGroup row>
          <Label className="letter-4" htmlFor="input-msisdn" md={5}>
            Main Balance
          </Label>
          <Col className="d-flex align-items-center" md={5}>
            <h className="neue-bold">{'$ ' + serviceDetail.balance}</h>
          </Col>
        </FormGroup>
      )
    }
  }, [serviceDetail])

  const recharge = async () => {
    await applyVoucher({ msisdn, voucherPin })
      .then((response) => {
        console.log('apply voucher response: ', response)
        history.push({
          pathname: '/admin/voucher/recharge/receipt',
          state: {
            voucherName: voucherDetails.name,
            price: voucherDetails.price,
            svcId: voucherDetails.svcId
          }
        })
      })
      .catch((error) => {
        setError(true)
        setErrorMessage(error.message ? error.message : 'Something went wrong')
        console.log('apply voucher error: ', error)
      })
    setIsLoading(false)
  }

  return (
    <>
      <SimpleHeader name="Voucher Review" />
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="6">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Account Details
                </h3>
              </Col>
              <Col xs="6">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Voucher Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Row className="align-items-start">
              <Col xs="6">
                {/* <FormGroup row>
                  <Label className="letter-4" htmlFor="input-msisdn" md={5}>
                    Main Balance
                  </Label>
                  <Col className="d-flex align-items-center" md={5}>
                    <h className="neue-bold">$0.0</h>
                  </Col>
                </FormGroup> */}
                {renderAccountDetails()}
              </Col>
              <Col xs="6">
                <FormGroup row>
                  <Label className="letter-4" md={3}>
                    NAME
                  </Label>
                  <Col className="d-flex align-items-center" md={5}>
                    <h className="neue-bold">{voucherDetails.name}</h>
                  </Col>
                </FormGroup>
                {voucherDetails.price && (
                  <FormGroup row>
                    <Label className="letter-4" md={3}>
                      COST
                    </Label>
                    <Col className="d-flex align-items-center" md={5}>
                      <h className="neue-bold">{voucherDetails.price}</h>
                    </Col>
                  </FormGroup>
                )}
                {voucherDetails.codDays && (
                  <FormGroup row>
                    <Label className="letter-4" md={3}>
                      EXPIRY
                    </Label>
                    <Col className="d-flex align-items-center" md={5}>
                      <h className="neue-bold">{voucherDetails.codDays}</h>
                    </Col>
                  </FormGroup>
                )}
                {renderDiscounts()}
              </Col>
            </Row>
            {isLoading ? (
              <Spinner></Spinner>
            ) : (
              <Button onClick={recharge}>Recharge</Button>
            )}
            {error && <div className="error-message">{errorMessage}</div>}
          </CardBody>
          {/* <Button>Recharge</Button> */}
        </Card>
      </Container>
    </>
  )
}

export default VoucherRechargeReview
