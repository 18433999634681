import { IActivationHandler, IHttpClient, IHttpResponse } from '../interfaces'
import { VerificationID, CreateAccountResponse } from '../models/responses'
import {
  CreateAccountRequest,
  dealerActivateRequest,
  AccountLookupRequest
} from '../models/requests'
const ActivationHandler = (client: IHttpClient): IActivationHandler => {
  const getIDList = async (): Promise<IHttpResponse<VerificationID[]>> => {
    return client.GET<VerificationID[]>('/api/v1/dealer/ID/list')
  }

  const createAccount = async (
    request: CreateAccountRequest
  ): Promise<IHttpResponse<CreateAccountResponse>> => {
    console.log('create account request', request)
    return client.POST<CreateAccountResponse>('/api/v1/dealer/account/create', request)
  }

  const dealerActivate = async (
    request: dealerActivateRequest
  ): Promise<IHttpResponse<any>> => {
    return client.POST<any>('/api/v1/dealer/activate2', request)
  }

  const accountLookup = async (
    request: AccountLookupRequest
  ): Promise<IHttpResponse<any>> => {
    return client.POST<any>('/api/v1/dealer/account/lookup', request)
  }

  return {
    getIDList,
    createAccount,
    dealerActivate,
    accountLookup
  }
}
export default ActivationHandler
