export const dateFormatter = (dateString) => {
  const date = new Date(dateString)
  // console.log('dateString: ', dateString)
  // console.log('date toLocaleDateString: ', date.toLocaleDateString('en-US'))
  return date.toLocaleDateString('es-CL')
}


export const dateTimeFormatter = (dateString) => {
  const date = new Date(dateString)
  return date.toLocaleString('es-CL')
}

export const roundToOneDecimal = (number) => {
  return Math.round(number * 10) / 10
}

export const kBToGB = (number) => {
  return roundToOneDecimal(number / (1024 * 1024))
}

export const dollarAmountFormat = new Intl.NumberFormat('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 , roundingMode: 'trunc', useGrouping: false })
