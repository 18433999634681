import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Card,
  CardBody,
  Button,
  Container,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useHistory } from 'react-router-dom'
import { usePsmaContext } from 'contexts/PsmaContext'
import Address from 'services/sdk/models/address'
import useDebounce from 'hooks/useDebounce'
import { isPhoneNumberValid, isEmailValid } from '../../utils/validation'

const AddGroup = () => {
  const [groupName, setGroupName] = useState('')
  const { addGroup, checkGroupName } = useDashboardContext()
  const [costCenterType, setCostCtrType] = useState('Dealer')
  const history = useHistory()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [submitError, setSubmitError] = useState('')
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')
  const [groupNameError, setGroupNameError] = useState(false)
  const [groupNameErrorMessage, setGroupNameErrorMessage] = useState('')
  const [availableGroupName, setAvailableGroupName] = useState(false)
  const [address, setAddress] = useState(null);
  const [contact, setContact] = useState({email: '', contactMobile: ''});
  const [showAddressList, setShowAddressList] = useState(false)
  const [psmaError, setPsmaError] = useState(false)
  const [psmaErrorMessage, setPsmaErrorMessage] = useState('')
  const [suggestedAddresses, setSuggestedAddresses] = useState(null)
  const [addressQuery, setAddressQuery] = useState('')
  const { getAddressSuggestions, getAddress } = usePsmaContext()
  const [loading, setLoading] = useState(false)
  const [manualAddress, setManualAddress] = useState({unitType: '', unitNumber: '', levelType: '', levelNumber: '', street: '', streetNumber: '', streetNumber2: '', streetName: '', streetType: '', suburb: '', state: '', postcode: ''});
  const [isValid, setIsValid] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    if (!EmailValid && contact?.email !== '') {
      setLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please enter a valid email address')
      return
    } else if (!isPhoneValid && contact?.contactMobile !== '') {
      setLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please enter a valid phone number')
      return
    } else if (!isStateValid && manualAddress?.state !== '') {
      setLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please enter a valid state')
      return
    } else if (!isPostcodeValid && manualAddress?.postcode !== '') {
      setLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please enter a valid postcode')
      return
    } else if (groupName === '') {
      setLoading(false)
      setSubmitError(true)
      setSubmitErrorMessage('Please fill in all required fields')
      setGroupNameError(true)
      setGroupNameErrorMessage('Please fill in all required fields')
      return
    } else {
      setSubmitError(false)
      setSubmitErrorMessage('')
    }

    const Address = {
      unitType: manualAddress.unitType,
      unitNumber: manualAddress.unitNumber,
      levelType: manualAddress.levelType,
      levelNumber: manualAddress.levelNumber,
      streetNumber: manualAddress.streetNumber,
      streetNumber2: manualAddress.streetNumber2,
      streetName: manualAddress.streetName,
      streetType: manualAddress.streetType,
      suburb: manualAddress.suburb,
      state: manualAddress.state,
      postcode: manualAddress.postcode
    }

    const Contact = {
      email: contact.email,
      contactMobile: contact.contactMobile
    }

    const costCtrDetails = {
      address: Address,
      contact: Contact
    }

    const res = await addGroup({ costCtrDescr: groupName, costCtrType: costCenterType, costCtrDetails: costCtrDetails })
    console.log('groupName:', groupName)
    console.log('costCtrType:', costCenterType)
    if (res === 200) {
      setLoading(false)
      console.log('add group success')
      alert('Group added successfully')
      history.goBack()
    } else {
      setLoading(false)
      setSubmitErrorMessage('Something went wrong. Please try again later.')
      setSubmitError(true)
      console.log('error adding group')
    }
  }

  function handleAddressChange(key, value) {
    setManualAddress({
      ...manualAddress,
      [key]: value
    });
  }

  function handleContactChange(key, value) {
    setContact({
      ...contact,
      [key]: value
    });
  }

  // const costCtrListToggle = () => {
  //   setShowCostCtrList((prevState) => !prevState)
  // }

  const addressToggle = () => {
    setShowAddressList((prevState) => !prevState)
  }

  const MIN_LENGTH = 4 // PSMA wants at least 4 chars before returning results

  const selectAddress = useCallback(async (item) => {
    setError(false)
    setLoading(true)
  
    await getAddress(item.id)
      .then((res) => {
        setLoading(false)
        setAddress(Address.ConstructFromPSMAAddress(res.address))
        setManualAddress({
          unitType: res.address.properties.complex_unit_type ? res.address.properties.complex_unit_type  : '' 
          ,unitNumber: res.address.properties.complex_unit_identifier ? res.address.properties.complex_unit_identifier : ''
          ,levelType: res.address.properties.complex_level_type ? res.address.properties.complex_level_type  : '' 
          ,levelNumber: res.address.properties.complex_level_number ? res.address.properties.complex_level_number : ''
          ,streetNumber: res.address.properties.street_number_1
          ,streetNumber2: res.address.properties.street_number_2 ? res.address.properties.street_number_2 : ''
          ,streetName: res.address.properties.street_name
          ,streetType: res.address.properties.street_type
          ,suburb: res.address.properties.locality_name
          ,state: res.address.properties.state_territory
          ,postcode: res.address.properties.postcode
        })
     }) 
      .catch((err) => {
        console.error(err)
        setLoading(false)
        setPsmaError(true)
        setPsmaErrorMessage(
          'Something went wrong with getting the address details, please try again later'
        )
      })
  }, [setManualAddress, getAddress])
  
  const debouncedAddressQuery = useDebounce(addressQuery, 300)
  
  useEffect(() => {
    const getAddressList = async () => {
      if (
        debouncedAddressQuery === null ||
        debouncedAddressQuery.length < MIN_LENGTH
      ) {
        setSuggestedAddresses(null)
        setAddress(null)
        return
      }
  
      if (debouncedAddressQuery === address?.formattedAddress) {
        // if the address query is the same as the selected address, don't make a request again
        return
      }
  
      setLoading(true)
  
      await getAddressSuggestions(debouncedAddressQuery)
        .then((res) => {
          setLoading(false)
          setSuggestedAddresses(res.suggest)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setPsmaError(true)
          setPsmaErrorMessage(
            'Something went wrong with getting the address suggestions list, please try again later'
          )
          setSuggestedAddresses(null)
        })
    }
    getAddressList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddressQuery])
  
  useEffect(() => {
    if (address) {
      console.log('selected address to mrtm:', address)
      // set the address query shown in Input field to the PSMA suggested address
      setAddressQuery(address.formattedAddress)
    }
  }, [address])

  const EmailValid = isEmailValid(contact?.email)

  const isPhoneValid = isPhoneNumberValid(contact?.contactMobile)

  const isStateValid = useMemo(() => {
    const rgx = /^[A-Z]{2,3}$/
    return rgx.test(manualAddress.state)
  }, [manualAddress.state])

  const isPostcodeValid = useMemo(() => {
    const rgx = /^[0-9]{4}$/
    return rgx.test(manualAddress.postcode)
  }, [manualAddress.postcode])

  const debouncedGroupNameQuery = useDebounce(groupName, 300)
  useEffect( () => {
    const groupNameCheck = async () => {
      if (groupName && groupName !== '') {
        await checkGroupName({
          costCtrDescr: debouncedGroupNameQuery
        })
        .then((res) => {
          console.log('res', res)
          if (res === 200) {
            setAvailableGroupName(true)
            setError(false)
            setGroupNameError(false)
            setGroupNameErrorMessage('')
            setErrorMessage('')
            setIsValid(true)
          } else {
            setAvailableGroupName(false)
            setError(true)
            setGroupNameError(true)
            setGroupNameErrorMessage('Name needs to be unique')
            setIsValid(false)
          }
        })
        .catch((err) => {
          console.log('err', err)
          setAvailableGroupName(false)
          setError(true)
          setIsValid(false)
          setGroupNameErrorMessage('Something went wrong. Please try again later')
        })
      }
    }
    if (debouncedGroupNameQuery !== '') {
      groupNameCheck()
    }
  },[checkGroupName, debouncedGroupNameQuery])


  useEffect(() => {
    setError(false)
      if (groupName === '' || groupName === null || groupName === undefined || !availableGroupName || groupName.length <= 1) {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
  }, [groupName])

  return (
    <>
      <SimpleHeader name="New Dealer"></SimpleHeader>
      <Container fluid>
        <Card>
          <CardBody>
            <Form>
              <div className="pl-lg-4 text-primary">
              <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Dealer name
                    <span style={{color: "red"}}> *</span>
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder='Dealer name'
                      invalid={(groupName === undefined && !!groupName) || (!availableGroupName && groupName !== '') || groupNameError}
                      value={groupName}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setGroupNameError(false)
                        setGroupNameErrorMessage('')
                        setSubmitError(false)
                        setSubmitErrorMessage('')
                        setGroupName(e.target.value)
                      }}
                    ></Input>
                    {groupNameError && (
                      <div className="error-message">{groupNameErrorMessage}</div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Address Search
                  </Label>
                  <Col md={6}>
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showAddressList}
                      toggle={addressToggle}
                    >
                      <DropdownToggle tag="a">
                        <Input
                          id="input-address"
                          placeholder="Address Search"
                          type="input"
                          required={true}
                          autoComplete='off'
                          value={addressQuery}
                          invalid={!!addressQuery && !address && !psmaError}
                          onChange={(e) => {
                            console.log('address change', e.target.value)
                            setPsmaError(false)
                            setPsmaErrorMessage('')
                            setAddressQuery(e.target.value)
                          }}
                        />
                        <div className="invalid-feedback">
                          Please choose an address from the list
                        </div>
                        {psmaError && psmaErrorMessage && (
                          <div className="error-message">
                            {psmaErrorMessage}
                          </div>
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        {suggestedAddresses ? (
                          suggestedAddresses.length > 0 ? (
                            suggestedAddresses.map((item) => (
                              <DropdownItem
                                key={item.id}
                                onClick={(e) => {
                                  setError(false)
                                  setErrorMessage('')
                                  selectAddress(item)
                                }}
                              >
                                {item.address}
                              </DropdownItem>
                            ))
                          ) : (
                            <></>
                          )
                        ) : (
                          <DropdownItem>
                            Start by typing your address
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Shop/Unit/Level
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Shop/Unit/Level"
                      value={manualAddress.unitNumber ? manualAddress.unitNumber : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('unitNumber', e.target.value)
                      } }
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Number
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Number"
                      value={manualAddress.streetNumber ? manualAddress.streetNumber : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('streetNumber', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Name
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Name"
                      value={manualAddress.streetName ? manualAddress.streetName : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('streetName', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Type
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Type"
                      value={manualAddress.streetType ? manualAddress.streetType : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('streetType', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    Suburb
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Suburb"
                      value={manualAddress.suburb ? manualAddress.suburb : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('suburb', e.target.value)
                      } }
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    State/Postcode
                  </Label>
                  <Col md="3">
                    <Input
                      placeholder="State"
                      input='text'
                      invalid={manualAddress?.state !== '' && !isStateValid}
                      value={manualAddress.state ? manualAddress.state : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('state', e.target.value)
                      } }
                    ></Input>
                  </Col>
                  <Col md="3">
                    <Input
                      placeholder="Postcode"
                      input='text'
                      invalid={manualAddress?.postcode !== '' && !isPostcodeValid}
                      value={manualAddress.postcode ? manualAddress.postcode : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleAddressChange('postcode', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Email
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder='Email'
                      value={contact.email}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleContactChange('email', e.target.value)
                      }}
                      invalid={(!EmailValid && contact?.email !== '')}
                    ></Input>
                    <div className="invalid-feedback">
                      Please enter a valid email address
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Phone Number
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder='Phone Number'
                      value={contact.contactMobile}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        setSubmitError(false)
                        handleContactChange('contactMobile', e.target.value)
                      }}
                      invalid={!isPhoneValid && !!contact?.contactMobile}
                      maxLength={10}
                    ></Input>
                    <div className="invalid-feedback">
                      Please enter a valid phone number
                    </div>
                  </Col>
                </FormGroup>
                {submitError && submitErrorMessage && (
                  <div className="error-message">{submitErrorMessage}</div>
                )}
                {loading ? (
                  <Spinner></Spinner>
                ) : (
                  <Button color="secondary" disabled={!isValid} onClick={onSubmit}>
                    Add
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default AddGroup
