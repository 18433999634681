import { Bar, Line } from 'react-chartjs-2'
import React, { useCallback } from 'react'
import { colors } from 'variables/charts.js'

import { useState, useEffect } from 'react'

import { Card, CardHeader, CardBody } from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'

function SpendChart(props) {
  const { startDate, endDate, timePeriod, costCtrId } = props
  const [spendList, setSpendList] = useState([])
  const [spendHistoryList, setSpendHistoryList] = useState([])
  const { getSpendByCostCtr, getSubtenantSpendHistory } = useDashboardContext()

  const loadSpendList = useCallback(async () => {
    await getSpendByCostCtr()
      .then((response) => {
        setSpendList(response.results)
        //console.log('set preActive sims list: ', response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
  }, [getSpendByCostCtr])

  const loadSubtenantSpendHistory = useCallback(async () => {
    await getSubtenantSpendHistory({
      startDate,
      endDate,
      timePeriod,
      costCtrId
    })
      .then((response) => {
        setSpendHistoryList(response)
        //console.log('set spend history list: ', response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
  }, [costCtrId, endDate, getSubtenantSpendHistory, startDate, timePeriod])

  const getCostCtrLable = () => {
    const list = spendList.map((data) => data.costCtrDescr)

    return list
  }

  const getCostCtrWholealeList = () => {
    const list = spendList.map((data) => data.wholesale)

    return list
  }

  const getCostCtrRetailList = () => {
    const list = spendList.map((data) => data.retail)

    return list
  }

  const getDateLabel = () => {
    if (timePeriod === 'Day' || timePeriod === 'Week') {
      const list = spendHistoryList.map((data) =>
        new Date(data.date).toLocaleDateString('es-AU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      )
      return list
    }
    if (timePeriod === 'Month') {
      const list = spendHistoryList.map((data) =>
        new Date(data.date).toLocaleDateString('en-AU', {
          year: '2-digit',
          month: 'short'
        })
      )
      return list
    }
    if (timePeriod === 'Year') {
      const list = spendHistoryList.map((data) =>
        new Date(data.date).toLocaleDateString('en-AU', {
          year: 'numeric'
        })
      )
      return list
    }
  }

  const getSubtenantWholesaleList = () => {
    const list = spendHistoryList.map((data) => data.wholesale)
    return list
  }

  const getSubtenantRetailList = () => {
    const list = spendHistoryList.map((data) => data.retail)
    return list
  }

  useEffect(() => {
    if (costCtrId) {
      loadSubtenantSpendHistory()
    } else {
      loadSpendList()
    }
  }, [
    loadSpendList,
    loadSubtenantSpendHistory,
    startDate,
    endDate,
    timePeriod,
    costCtrId
  ])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Spend</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {costCtrId ? (
              <Line
                data={{
                  labels: getDateLabel(),
                  datasets: [
                    {
                      label: 'wholesale spend',
                      data: getSubtenantWholesaleList(),
                      borderColor: colors.theme.primary
                    },
                    {
                      label: 'retail spend',
                      data: getSubtenantRetailList(),
                      borderColor: colors.theme.secondary
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        }
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element,
                      spendList[element[0]._index].Count
                    )
                  }
                }}
                className="chart-canvas"
                id="chart-bars"
              />
            ) : (
              <Bar
                data={{
                  labels: getCostCtrLable(),
                  datasets: [
                    {
                      label: 'wholesale spend',
                      data: getCostCtrWholealeList(),
                      backgroundColor: colors.theme.primary
                    },
                    {
                      label: 'retail spend',
                      data: getCostCtrRetailList(),
                      backgroundColor: colors.theme.secondary
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {}
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element,
                      spendList[element[0]._index].Count
                    )
                  }
                }}
                className="chart-canvas"
                id="chart-bars"
              />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default SpendChart
