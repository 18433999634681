import {
  IHttpClientConstructor,
  IHttpClient,
  IHttpResponse,
  Interceptor,
  ITokenProvider
} from './interfaces'

export const HttpResponse = <T>(
  ok: boolean,
  status: number,
  response?: T,
  error?: string,
  headers?: Headers
): IHttpResponse<T> => {
  return {
    ok,
    status,
    response,
    error,
    headers
  }
}

const HttpClient: IHttpClientConstructor = (
  host?: string,
  tokenProvider?: ITokenProvider
): IHttpClient => {
  const interceptors: Interceptor[] = []

  if (host === undefined) {
    throw new Error('HttpClient host is undefined')
  }

  const jsonRequest = async <T>(
    uri: string,
    type: string,
    params?: Record<string, string>,
    data?: Record<string, unknown>,
    options?: Record<string, unknown>,
    secondAttempt?: boolean
  ): Promise<IHttpResponse<T>> => {
    try {
      const searchParams = (params && `?${new URLSearchParams(params)}`) || ''

      const body = (data && JSON.stringify(data || {})) || null

      const headers = options?.headers
        ? (options?.headers as Record<string, string>)
        : {}

      const request: RequestInfo = new Request(`${host}${uri}${searchParams}`, {
        method: type,
        body,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer',
          ...headers
        }
      })

      if (tokenProvider) {
        const token: string | null = tokenProvider.getToken()
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`)
        } else {
          tokenProvider.setToken(null)
          return HttpResponse<T>(false, 401, undefined, 'Unauthorized')
        }
      }

      interceptors.forEach((interceptor) => {
        interceptor(request)
      })

      const response: Response = await fetch(request)

      const contentType = response.headers.get('content-type')

      if (response.ok) {
        if (contentType && contentType.indexOf('application/json') !== -1) {
          const json = await response.json()
          return HttpResponse(
            true,
            response.status,
            json,
            undefined,
            response.headers
          )
        } else if (
          contentType &&
          contentType.indexOf(
            'application/vnd.openxmlformats-officedocument.spreadsheet.sheet'
          ) !== -1
        ) {
          const blob = await response.blob()
          return HttpResponse(
            true,
            response.status,
            blob as T,
            undefined,
            response.headers
          )
        }
        return HttpResponse<T>(
          true,
          response.status,
          undefined,
          undefined,
          response.headers
        )
      }

      if (
        response.status === 401 &&
        tokenProvider &&
        !secondAttempt &&
        (!options || !options.allow401)
      ) {
        tokenProvider.setToken(null)

        return HttpResponse<T>(false, 401, undefined, 'Unauthorized')
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        const responseJson = await response.json()

        return HttpResponse<T>(
          false,
          response.status,
          responseJson.message ? undefined : responseJson,
          responseJson.message
        )
      }

      if (contentType && contentType.indexOf('text/html') !== -1) {
        const responseText = await response.text()

        return HttpResponse<T>(false, response.status, undefined, responseText)
      }

      return HttpResponse<T>(
        false,
        response.status,
        undefined,
        response.status.toString()
      )
    } catch (err: any) {
      return HttpResponse<T>(false, 500, undefined, err.message)
    }
  }

  const GET = async <T>(
    uri: string,
    params?: Record<string, string>,
    options?: Record<string, unknown>
  ): Promise<IHttpResponse<T>> => {
    return jsonRequest(uri, 'get', params, undefined, options)
  }

  const POST = async <T>(
    uri: string,
    data?: Record<string, unknown>,
    params?: Record<string, string>,
    options?: Record<string, unknown>
  ): Promise<IHttpResponse<T>> => {
    return jsonRequest(uri, 'post', params, data, options)
  }

  const PUT = async <T>(
    uri: string,
    data?: Record<string, unknown>,
    params?: Record<string, string>,
    options?: Record<string, unknown>
  ): Promise<IHttpResponse<T>> => {
    return jsonRequest(uri, 'put', params, data, options)
  }

  const DELETE = async <T>(
    uri: string,
    params?: Record<string, string>,
    data?: Record<string, unknown>,
    options?: Record<string, unknown>
  ): Promise<IHttpResponse<T>> => {
    return jsonRequest(uri, 'delete', params, data, options)
  }

  const addInterceptor = (interceptor: Interceptor) => {
    interceptors.push(interceptor)
  }

  const removeInterceptor = (interceptor: Interceptor) => {
    const idx = interceptors.indexOf(interceptor)

    if (idx > -1) {
      interceptors.splice(idx, 1)
    }
  }

  return {
    GET,
    POST,
    PUT,
    DELETE,
    addInterceptor,
    removeInterceptor
  }
}

export default HttpClient
