/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from 'views/pages/Dashboard.js'
import Login from 'views/pages/Login'
import Profile from 'views/pages/Profile.js'
import Inventory from 'views/pages/Inventory'
import TransactionHistory from 'views/pages/TransactionHistory'
import Service from 'views/pages/Service'
import ServiceList from 'views/pages/ServiceList'
import Summary from 'views/pages/Summary.js'
import ActivateSIM from './views/pages/ActivateSIM'
import ChangePlan from './views/pages/ChangePlan'
import SearchActivate from './views/pages/SearchActivate'
import Group from './views/pages/Groups'
import AddGroup from './views/pages/AddGroup'
import AddUser from './views/pages/AddUser'
import Balances from './views/pages/Balances'
import User from './views/pages/UserList'
import UserDetails from './views/pages/UserDetails'
import GroupDetails from './views/pages/GroupDetails'
import PurchasePlan from './views/pages/PurchasePlan'
import Invoice from './views/pages/Invoice'
import VoucherRecharge from './views/pages/VoucherRecharge'
import VoucherRechargeReview from './views/pages/VoucherRechargeReview'
import VoucherRechargeReceipt from './views/pages/VoucherRechargeReceipt'
import PersonalDetails from './views/pages/Activation/PersonalDetails'
import Review from './views/pages/Activation/Review'
import SimDetails from './views/pages/SimDetails';
import Success from './views/pages/Activation/Success'
import IDVerification from './views/pages/Activation/IDVerification'
import PlanSelection from './views/pages/Activation/PlanSelection'
import Recharge from './views/pages/Recharge'
import RechargeReceipt from './views/pages/RechargeReceipt'
import ChangePassword from './views/pages/ChangePassword'
import ForgotPassword from './views/pages/ForgotPassword'
import ResetPassword from './views/pages/ResetPassword'
import EmailVerification from './views/pages/EmailVerification'
import AuditLogList from './views/pages/AuditLogList'
// import RechargeReview from './views/pages/Recharge'


export const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-chart-bar-32 text-primary',
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/transactionHistory',
    name: 'TransactionHistory',
    icon: 'ni ni-money-coins text-primary',
    component: TransactionHistory,
    layout: '/admin'
  },
  {
    path: '/services',
    name: 'ServiceList',
    icon: 'ni ni-single-copy-04 text-primary',
    component: ServiceList,
    layout: '/admin'
  },
  {
    path: '/inventory',
    name: 'Inventory',
    icon: 'ni ni-archive-2 text-primary',
    component: Inventory,
    layout: '/admin'
  },
  {
    path: '/groups',
    name: 'Groups',
    icon: 'ni ni-building text-primary',
    component: Group,
    layout: '/admin'
  },
  {
    path: '/users/list',
    name: 'UsersList',
    icon: 'ni ni-badge text-primary',
    component: User,
    layout: '/admin'
  },
  {
    path: '/invoice',
    name: 'Invoice',
    icon: 'ni ni-money-coins text-primary',
    component: Invoice,
    layout: '/admin'
  },
  {
    path: '/activate',
    redirect: true,
    name: 'ActivateSim',
    component: ActivateSIM,
    // component: VoucherRecharge,
    layout: '/admin'
  },
  {
    path: '/new/plan',
    redirect: true,
    name: 'PurchasePlan',
    component: PurchasePlan,
    layout: '/admin'
  },
  {
    path: '/search/activate',
    name: 'SearchActivate',
    icon: 'ni ni-check-bold text-primary',
    component: SearchActivate,
    layout: '/admin'
  },
  {
    path: '/simDetails',
    name: 'SimDetails',
    component: SimDetails,
    layout: '/admin'
  },
  {
    // path: '/recharge/review',
    path: '/voucher/recharge/review',
    name: 'VoucherRechargeReview',
    redirect: true,
    component: VoucherRechargeReview,
    layout: '/admin'
  },
  {
    // path: '/receipt/voucher',
    path: '/voucher/recharge/receipt',
    name: 'VoucherRechargeReceipt',
    redirect: true,
    component: VoucherRechargeReceipt,
    layout: '/admin'
  },
  {
    path: '/voucher/recharge',
    name: 'VoucherRecharge',
    icon: 'ni ni-credit-card text-primary',
    component: VoucherRecharge,
    layout: '/admin'
  },
  {
    path: '/personal/details',
    redirect: true,
    name: 'PersonalDetails',
    component: PersonalDetails,
    layout: '/admin'
  },
  {
    path: '/review',
    redirect: true,
    name: 'Review',
    component: Review,
    layout: '/admin'
  },
  {
    path: '/success',
    redirect: true,
    name: 'Success',
    component: Success,
    layout: '/admin'
  },
  {
    path: '/list/service',
    redirect: true,
    name: 'Service',
    component: Service,
    layout: '/admin'
  },
  {
    path: '/group/add',
    redirect: true,
    name: 'AddGroup',
    component: AddGroup,
    layout: '/admin'
  },
  {
    path: '/user/add',
    redirect: true,
    name: 'AddUser',
    component: AddUser,
    layout: '/admin'
  },
  {
    path: '/user/details',
    redirect: true,
    name: 'UserDetails',
    component: UserDetails,
    layout: '/admin'
  },
  {
    path: '/group/details',
    redirect: true,
    name: 'GroupDetails',
    component: GroupDetails,
    layout: '/admin'
  },
  {
    path: '/change/plan',
    redirect: true,
    name: 'ChangePlan',
    component: ChangePlan,
    layout: '/admin'
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: 'ni ni-circle-08 text-primary',
    component: Profile,
    layout: '/admin'
  },
  {
    path: '/group/balances',
    name: 'Balances',
    component: Balances,
    layout: '/admin'
  },
  {
    path: '/id/verification',
    name: 'IDVerification',
    component: IDVerification,
    layout: '/admin'
  },
  {
    path: '/plan/selection',
    name: 'PlanSelection',
    component: PlanSelection,
    layout: '/admin'
  },
  {
    path: '/recharge',
    name: 'Recharge',
    icon: 'fas fa-wallet text-primary',
    // icon: 'ni ni-credit-card text-primary',
    component: Recharge,
    layout: '/admin'
  },
  {
    // path: '/receipt/voucher',
    path: '/recharge/receipt',
    name: 'RechargeReceipt',
    redirect: true,
    component: RechargeReceipt,
    layout: '/admin'
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    layout: '/admin'
  },
  {
    path: '/auditLogs',
    name: 'AuditLogList',
    icon: 'ni ni-folder-17 text-primary',
    component: AuditLogList,
    layout: '/admin'
  }
  // {
    //   path: '/recharge/review',
    //   name: 'RechargeReview',
    //   component: RechargeReview,
    //   layout: '/admin'
    // }
  ]
  
  export const authRoutes = [
  {
    path: '/login',
    redirect: true,
    name: 'Login',
    miniName: 'L',
    component: Login,
    layout: '/auth'
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    miniName: 'FP',
    redirect: false,
    component: ForgotPassword,
    layout: '/auth'
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    miniName: 'RP',
    redirect: false,
    component: ResetPassword,
    layout: '/auth'
  },
  {
    path: '/email-verification',
    redirect: true,
    name: 'EmailVerification',
    component: EmailVerification,
    layout: '/auth'
  }
]
  