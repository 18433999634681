import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Card,
  Container,
  CardHeader,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner
} from 'reactstrap'

import { useDashboardContext } from '../../contexts/DashboardContext'

function VoucherRecharge() {
  const [msisdn, setMsisdn] = useState('')
  const [voucher, setVoucher] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [isValid, setIsValid] = useState(false)

  const { getVoucherDetails } = useDashboardContext()

  const history = useHistory()

  useEffect(() => {
    setError(false)
    if (msisdn && msisdn.length === 10 && voucher) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [msisdn, voucher])

  const handleSubmit = async () => {
    setIsLoading(true)
    console.log('submit')
    await getVoucherDetails({ voucherPin: voucher, msisdn })
      .then((res) => {
        console.log('get voucher details success:', res)
        history.push({
          // pathname: '/admin/voucher/recharge/review',
          pathname: '/admin/voucher/recharge/review',
          state: {
            voucherDetails: res,
            msisdn,
            voucherPin: voucher
          }
        })
      })
      .catch((err) => {
        setError(true)
        setErrorMessage(err.message ? err.message : 'Something went wrong')
        console.log('get voucher details error:', err)
      })
    setIsLoading(false)
  }

  return (
    <>
      <SimpleHeader name="Voucher Recharge" />
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Voucher Recharge
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              <div className="pl-lg-4">
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-msisdn" md={2}>
                    MSISDN
                  </Label>
                  <Col md={6}>
                    <Input
                      id="input-msisdn"
                      placeholder="MSISDN"
                      type="text"
                      onChange={(e) => {
                        setMsisdn(e.target.value)
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-voucher" md={2}>
                    Voucher Pin
                  </Label>
                  <Col md={6}>
                    <Input
                      id="input-msisdn"
                      placeholder="Voucher PIN"
                      type="text"
                      onChange={(e) => {
                        setVoucher(e.target.value)
                      }}
                    />
                  </Col>
                </FormGroup>
                {/* <Row className="align-items-center"> */}
                {isLoading ? (
                  <Spinner></Spinner>
                ) : (
                  <Button color="secondary" type="submit" disabled={!isValid}>
                    Next
                  </Button>
                )}
                {error && <div className="error-message">{errorMessage}</div>}
                {/* </Row> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default VoucherRecharge
