/* eslint-disable camelcase */
import { map } from 'ramda'
import { IAddressConstructorParams } from 'services/sdk/models/models'

export const coerceToNull = <T>(val?: T): T | null => {
  if (val === undefined) {
    return null
  }

  return val
}

// @ts-ignore
export const capitalize = (phrase) => {
  if (!phrase) return phrase
  return (
    phrase
      .toLowerCase()
      .split(' ')
      // @ts-ignore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  )
}

interface IPSMAAddress {
  contributor: Array<string>
  dataset: string
  geometry: {
    coordinates: Array<number | null>
    type: string
  }
  id: string
  properties: {
    address_identifier: string
    formatted_address: string
    alias_principal: string | null
    cadastral_identifier: string
    complex_level_number: number | null
    complex_level_type: string | null
    complex_level_type_description: string | null
    complex_unit_identifier: string | null
    complex_unit_type: string | null
    complex_unit_type_description: string | null
    geo_feature: string | null
    gnaf_locality_pid: string | null
    gnaf_street_locality_pid: string | null
    locality_aliases: string | null
    locality_name: string | null
    locality_neighbours: string | null
    location_descriptor: string | null
    lot_identifier: string | null
    postal_delivery_number: string | null
    postal_delivery_type: string | null
    postcode: string | null
    primary_secondary: string | null
    site_name: string | null
    state_territory: string | null
    street_aliases: string | null
    street_name: string | null
    street_number_1: string | null
    street_number_2: string | null
    street_suffix: string | null
    street_type: string | null
    street_type_description: string | null
  }
  type: string
}

interface IMRTMAddress extends IAddressConstructorParams {}

class Address {
  public static EmptyConstructorParams: IAddressConstructorParams = {
    formattedAddress: '',
    localityName: null,
    postcode: null,
    stateTerritory: null,
    streetName: null,
    streetNumber: null,
    streetNumber2: null,
    unitNumber: null,
    unitType: null,
    siteName: null,
    streetType: null,
    lotIdentifier: null,
    streetSuffix: null,
    streetPrefix: null,
    levelType: null,
    levelNumber: null,
    suburb: null,
    state: null
  }

  public static ConstructFromPSMAAddress(psmaAddress: IPSMAAddress) {
    const {
      properties: {
        cadastral_identifier,
        formatted_address,
        postcode,
        state_territory,
        street_name,
        street_number_1,
        street_number_2,
        street_suffix,
        street_type,
        locality_name,
        complex_unit_identifier,
        complex_level_type,
        complex_level_number,
        complex_unit_type,
        lot_identifier,
        site_name
      }
    } = psmaAddress

    return Address.Construct({
      //   cadastralIdentifier: cadastral_identifier,
      //   complexLevelIdentifier: null, // PSMA doesn't return this
      unitType: complex_unit_type,
      unitNumber: complex_unit_identifier,
      levelType: complex_level_type,
      levelNumber: complex_level_number,
      formattedAddress: formatted_address,
      //   localityName: capitalize(locality_name),
      suburb: capitalize(locality_name),
      //   lotIdentifier: lot_identifier,
      postcode,
      //   siteName: site_name,
      //   stateTerritory: state_territory,
      state: state_territory,
      streetName: capitalize(street_name),
      streetNumber: street_number_1,
      streetNumber2: street_number_2,
      //   streetPrefix: null, // PSMA doesn't return this
      //   streetSuffix: capitalize(street_suffix),
      streetType: street_type
    })
  }

  public static ConstructList(
    arrParams: Array<IAddressConstructorParams>
  ): Array<Address> {
    return map(Address.Construct)(arrParams)
  }

  public static ConstructEmpty(): Address {
    return new Address(Address.EmptyConstructorParams)
  }

  public static Construct(params: IAddressConstructorParams): Address {
    return new Address(params)
  }

  public static MapToMRTMAddress(address: Address): IMRTMAddress {
    const data = address.responseData

    // Set streetType to empty string if set to "None"
    if (data.streetType && data.streetType === 'None') {
      data.streetType = ''
    }

    return data
  }

  responseData: IAddressConstructorParams

  constructor(params: IAddressConstructorParams) {
    this.responseData = params
  }

  get cadastralIdentifier(): string | null {
    return coerceToNull(this.responseData.cadastralIdentifier)
  }

  get unitNumber(): string | null {
    return coerceToNull(this.responseData.unitNumber)
  }

  get unitType(): string | null {
    return coerceToNull(this.responseData.unitType)
  }

  get levelNumber(): number | null {
    return coerceToNull(this.responseData.levelNumber)
  }

  get levelType(): string | null {
    return coerceToNull(this.responseData.levelType)
  }

  get postcode(): string | null {
    return coerceToNull(this.responseData.postcode)
  }

  get state(): string | null {
    return coerceToNull(this.responseData.state)
  }

  get streetName(): string | null {
    return coerceToNull(this.responseData.streetName)
  }

  get streetNumber(): string | null {
    return coerceToNull(this.responseData.streetNumber)
  }

  get streetNumber2(): string | null {
    return coerceToNull(this.responseData.streetNumber2)
  }

  get streetType(): string | null {
    return coerceToNull(this.responseData.streetType)
  }

  get streetPrefix(): string | null {
    return coerceToNull(this.responseData.streetPrefix)
  }

  get streetSuffix(): string | null {
    return coerceToNull(this.responseData.streetSuffix)
  }

  // this is the suburb in most cases
  get suburb(): string | null {
    return coerceToNull(this.responseData.suburb)
  }

  get siteName(): string | null {
    return coerceToNull(this.responseData.siteName)
  }

  get formattedAddress(): string {
    // Fix APP-2031
    if (!this.responseData) return ''
    if (this.responseData.formattedAddress === undefined) return ''
    if (!this.responseData.formattedAddress) return ''

    return this.responseData.formattedAddress
  }

  get isSet(): boolean {
    return this.formattedAddress !== ''
  }
}

export default Address
