import React from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// reactstrap components
import { Navbar, Nav, NavItem, Container } from 'reactstrap'

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand d-xl-none pb-0',
          { 'navbar-dark bg-primary': theme === 'dark' },
          { 'navbar-light bg-secondary': theme === 'light' }
        )}
        // className={'navbar-top navbar-expand d-xl-none bg-primary navbar-dark'}
      >
        <Container fluid>
          <Nav className="align-items-center" navbar>
            <NavItem className="d-xl-none">
              <div
                className={classnames(
                  'pr-3 sidenav-toggler d-flex align-items-center',
                  {
                    active: sidenavOpen
                  }
                )}
                onClick={toggleSidenav}
              >
                <i
                  className={classnames(
                    'ni ni-bold-right',
                    { 'text-white': theme === 'dark' },
                    { 'text-primary': theme === 'light' }
                  )}
                />
              </div>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false
}
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool
}

export default AdminNavbar
