import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  Spinner,
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import PaginationService from '../../components/Table/PaginationService'
import RecordsDropdown from '../../components/Table/RecordsDropdown'
import useDebounce from 'hooks/useDebounce'
import { dateTimeFormatter } from '../../utils/Utils'

const AuditLogList = ({ location }) => {
  const types = {
    All: 'All',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_SUSPENDED: 'LOGIN_SUSPENDED',
    USER_ADD: 'USER_ADD',
    USER_UPDATE: 'USER_UPDATE',
    USER_TERMINATE: 'USER_TERMINATE',
    PASSWORD_CHANGE: 'PASSWORD_CHANGE',
    PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    GROUP_ADD: 'GROUP_ADD',
    GROUP_UPDATE: 'GROUP_UPDATE',
    GROUP_TERMINATE: 'GROUP_TERMINATE',
    ACCOUNT_ADD: 'ACCOUNT_ADD',
    SIM_ACTIVATION: 'SIM_ACTIVATION',
    SIM_KIT: 'SIM_KIT',
    SIM_UPDATE_STATUS: 'SIM_UPDATE_STATUS',
    RECHARGE: 'RECHARGE',
    SERVICE_TRANSFER: 'SERVICE_TRANSFER'
  }
  const interfaces = {
    MGT_PORTAL: 'Management Portal',
    DEALER_PORTAL: 'Dealer Portal'
  }

  const [auditLogList, setAuditLogList] = useState([])
  const { getAuditLogList } = useDashboardContext()
  const { getGroupFeatures, getAuditLogListFeatures } = useAccessControlContext()
  // const [costCenterType, setCostCenterType] = useState('Company')
  // const { costCenterType } = useAuthContext()
  // const { authCostCtrId } = useAccessControlContext()
  const [pageOffset, setPageOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState('auditId')
  const [sortDesc, setSortDesc] = useState(true)
  // const [statusFilter, setStatusFilter] = useState('All')
  const [lastPage, setLastPage] = useState(0)
  const [maxResults, setMaxResults] = useState(0)
  const [recordsDropdownOpen, setRecordsDropdownOpen] = useState(false)
  // const [groupDropdownOpen, setGroupDropdownOpen] = useState(false)
  // const [statusDropdownOpen, setStatusDropdownOpen] = useState(false)
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [typeFilter, setTypeFilter] = useState('All')
  const [costCtrId, setCostCtrId] = useState(null)

  // const history = useHistory()
  // const groupFeatures = getGroupFeatures()
  const auditLogListFeatures = getAuditLogListFeatures()
  const eventTypeList = auditLogListFeatures?.eventTypeList
  const columns = auditLogListFeatures?.columns

  const columnNames = {
    tstampCreated: 'DATE',
    costCtrType: 'GRP TYPE',
    costCtrDescr: 'GRP NAME',
    interface: 'CHANNEL',
    username: 'USERNAME',
    eventType: 'EVENT TYPE',
    note: 'NOTE',
    updatedCostCtrDescr: 'UPDATED GRP NAME',
    updatedUsername: 'UPDATED USERNAME'
  }

  const sortKeys = {
    tstampCreated: 'tstampCreated',
    costCtrType: 'GRP TYPE',
    costCtrDescr: 'GRP NAME',
    interface: 'CHANNEL',
    username: 'USERNAME',
    eventType: 'eventType',
    note: 'NOTE',
    updatedCostCtrDescr: 'UPDATED GRP NAME',
    updatedUsername: 'UPDATED USERNAME'
  }

  const sortable = {
    tstampCreated: true,
    costCtrType: false,
    costCtrDescr: false,
    interface: false,
    username: false,
    eventType: true,
    note: false,
    updatedCostCtrDescr: false,
    updatedUsername: false
  }

  // useEffect(() => {
  //   console.log('auditLogListFeatures: ', auditLogListFeatures)
  // }, [groupFeatures, auditLogListFeatures])

  const recordFooter = () => {
    const first = maxResults === 0 ? 0 : pageOffset * rowsPerPage + 1
    const last = Math.min(pageOffset * rowsPerPage + rowsPerPage, maxResults)
    return (
      <span>
        showing {first} to {last} of {maxResults} entries
      </span>
    )
  }

  const changeRowsPerPage = (number) => {
    setPageOffset(0)
    setRowsPerPage(number)
  }

  const setSort = (sort) => {
    setPageOffset(0)
    if (sort === sortBy) {
      setSortDesc(!sortDesc)
    } else {
      setSortBy(sort)
      setSortDesc(true)
    }
  }

  const debouncedSearchFilter = useDebounce(searchFilter, 300)
  useEffect(() => {
    setIsLoading(true)
    const loadAuditLogList = async () => {
      await getAuditLogList({
        pageOffset,
        rowsPerPage,
        sortBy,
        sortOrder: sortDesc ? 'desc' : 'asc',
        searchFilter: debouncedSearchFilter,
        typeFilter: typeFilter,
        costCtrId: costCtrId,
      })
        .then((load) => {
          setIsLoading(false)
          setMaxResults(load.data.maxResults)
          setLastPage(Math.ceil(load.data.maxResults / rowsPerPage))
          setAuditLogList(load.data.results)
        })
        .catch((err) => {
          setIsLoading(false)
          setError(true)
          setErrorMessage(err.message)
        })
    }
    loadAuditLogList()
  }, [
    getAuditLogList,
    pageOffset,
    rowsPerPage,
    sortBy,
    sortDesc,
    debouncedSearchFilter,
    costCtrId,
    typeFilter
  ])

  const toggle = () => {
    setRecordsDropdownOpen(!recordsDropdownOpen)
  }

  const captalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const testStringForHyphen = (string) => {
    return string.includes('-')
  }

  const replaceHyphenWithUnderscore = (string) => {
    return string.replace(/-/g, '_')
  }

  return (
    <>
      <SimpleHeader
        // name={Strings[costCenterType].MG_Title}
        // description={Strings[costCenterType].MG_SubTitle}
        name="Audit Log"
        description="Audit Log History"
      ></SimpleHeader>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col md="3">
                    {/* <span */}
                    <RecordsDropdown
                      dropdownOpen={recordsDropdownOpen}
                      toggle={toggle}
                      setRowsPerPage={changeRowsPerPage}
                      rowsPerPage={rowsPerPage}
                    ></RecordsDropdown>
                  </Col>
                  <Col sm="auto">
                    <span className="text-primary">Filter by:</span>
                    <Dropdown
                      isOpen={typeDropdownOpen}
                      toggle={() => setTypeDropdownOpen(!typeDropdownOpen)}
                      className="ml-1"
                    >
                      <DropdownToggle color="light" caret>
                        {typeFilter === 'All' ? 'Event Type' : typeFilter}
                      </DropdownToggle>
                      <DropdownMenu className="text-primary">
                        {eventTypeList.map((type, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => {
                                setTypeFilter(types[type])
                              }}
                            >
                              {types[type]}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col lg="3 ml-auto">
                  {/* <Col md="3" className="text-right"> */}
                    <Input
                      className="py-1"
                      placeHolder="Search by username"
                      type="search"
                      onKeyPress={(event) => {
                        if (/[^a-zA-Z0-9\s@-_.]/gi.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        setPageOffset(0)
                        setSearchFilter(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table
                  hover
                  className="align-items-center table-flush text-primary"
                >
                  <thead className="thead-light">
                    <tr>
                      {columns.map((column, index) => (
                        <th
                          scope="col"
                          className="sort"
                          data-sort={sortable[column] ? sortKeys[column] : null}
                          onClick={() =>
                            sortable[column] && setSort(sortKeys[column])
                          }
                          key={index}
                        >
                          {columnNames[column]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {auditLogList &&
                      auditLogList.map((item) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            key={item.auditId}
                            onClick={(e) => {
                              e.preventDefault()
                              // handleClick(item)
                            }}
                          >
                            {columns.includes('tstampCreated') && (
                              <td className="tstampCreated">
                                {dateTimeFormatter(item.tstampCreated)}
                              </td>
                            )}
                            {columns.includes('costCtrType') && (
                              <td
                                className="costCtrType">
                                {captalizeFirstLetter(item.costCtrType)}
                              </td>
                            )}
                            {columns.includes('costCtrDescr') && (
                              <td className="costCtrDescr">
                                {item.costCtrDescr}
                              </td>
                            )}
                            {columns.includes('interface') && (
                              <td className="interface">
                                {/* {
                                  testStringForHyphen(item.interface) ?
                                  interfaces[replaceHyphenWithUnderscore(item.interface)] :
                                  captalizeFirstLetter(item.interface)
                                } */}
                                {item.interface}
                              </td>
                            )}
                            {columns.includes('username') && (
                              <td className="username">
                                {item.username}
                              </td>
                            )}
                            {columns.includes('eventType') && (
                              <td className="eventType">
                                {types[item.eventType]}
                              </td>
                            )}
                            {columns.includes('note') && (
                              <td className="note">
                                {item.note}
                              </td>
                            )}
                            {columns.includes('updatedCostCtrDescr') && (
                              <td className="updatedCostCtrDescr">
                                {item.updatedCostCtrDescr}
                              </td>
                            )}
                            {columns.includes('updatedUsername') && (
                              <td className="updatedUsername">
                                {item.updatedUsername}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <Spinner>Loading...</Spinner>
                  </div>
                )}
                {error && (
                  <div className="d-flex justify-content-center">
                    <span className="error-message">{errorMessage}</span>
                  </div>
                )}
              </div>
              {!error && !isLoading && (
                <CardFooter className="py-4">
                  <Row className="d-flex align-items-center">
                    <Col xs="12" md="4" xl="3" xxl="8">
                      {recordFooter()}
                    </Col>
                    <Col sm="6" md="4" xl="9" xxl="2">
                      <nav aria-label="...">
                        <PaginationService
                          setPageOffset={setPageOffset}
                          pageOffset={pageOffset}
                          lastPage={lastPage}
                        ></PaginationService>
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default AuditLogList