/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  CardFooter,
  Col,
  Navbar,
  NavbarBrand,
  Row,
  RequiredItem
} from 'reactstrap'
// core components
import { useAuthContext } from '../../contexts/AuthContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import AuthFooter from '../../components/Footers/AuthFooter';
import 'assets/css/argon-dashboard-pro-react.css'
import PasswordRequirements from '../../components/PasswordRequirements'
import { validatePassword } from '../../utils/validation'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 95% center;
  background-size: auto 90%;
  background-origin: content-box;
`

const ResetPassword = () => {

  const location = useLocation();

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordValid, setPasswordValid] = useState(false)
  const [focusedPassword, setfocusedPassword] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const { 
    resetPassword,
    //rememberMe
  } = useAuthContext()
  //const [rem, setRem] = useState(rememberMe)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [mvnoName, setMvnoName] = useState(process.env.REACT_APP_MVNO_NAME)
  const [portalType, setPortalType] = useState(process.env.REACT_APP_PORTAL_TYPE)
  const [OTPToken, setOTPToken] = useState('')

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    if (password !== passwordConfirm) {
      setIsLoading(false)
      setError(true)
      setErrorMessage('Passwords do not match.')
      return
    }

    // check again before sending reset password request
    if (!validatePassword(password).all) {
      setIsLoading(false)
      setError(true)
      setErrorMessage('Password does not meet requirements.')
      return
    }

    const result = await resetPassword({
      otpCode: OTPToken,
      password: password
    })
    if (result === 200) {
      alert('Password Reset Successfully')
      setIsLoading(false)
      history.push('/auth/login')
    } else if (result === 401 || result === 400) {
      setIsLoading(false)
      setError(true)
      setErrorMessage('Session Expired. Please try again.')
    } else {
      setIsLoading(false)
      setError(true)
      setErrorMessage('Something went wrong. Please try again later.')
    }
  }

  useEffect(() => {
    // parse query params and extract verification token
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    if (token !== null && token !== '') {
        setError(false);
        setOTPToken(token);
    } else {
        setError(true);

    }
  }, []);

  const updatePassword = (val) => {
    setPassword(val)
    const isValid = validatePassword(val).all
    if (isValid) {
      setPasswordValid(true)
      setError(false)
      setErrorMessage('')
    } else {
      setPasswordValid(false)
      setError(true)
      setErrorMessage('Password does not meet requirements.')
    }
  }

  const checkConfirmedPassword = (val) => {
    if (val !== password) {
      setError(true)
      setErrorMessage('Passwords do not match.')
    } else {
      setError(false)
      setErrorMessage('')
    }
  }

  const toggle = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      <Background className="bg-imzi-dark" />
      <Navbar light expand="md">
        <NavbarBrand href="/" style={{paddingLeft: '2vh', paddingTop: '2vh'}}>
          <img src={process.env.REACT_APP_HOST_AUTH + `/assets/mvno.png`} alt="Logo" height="30"/>
        </NavbarBrand>
      </Navbar>
      <Container style={{ paddingTop: '8vh' }}>
        <Row className="justify-content-center">
        <Col lg="5" md="7">
          <h1
            className="text-center text-white my-0 neue-bold letter-2"
            style={{ fontSize: '26px' }}
          >
            {mvnoName + ' ' + portalType + ' '}Portal
          </h1>
          {/* <p
            className="text-center text-white mb-4 neue-thin letter-4"
            style={{ fontSize: '16px' }}
          >
            Manage your SIM services and inventory.
          </p> */}
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <CardTitle
                className="text-center text-primary neue-bold letter-2"
                style={{
                  fontSize: '20px',
                  lineHeight: '24px'
                }}
              >
                Reset Password
              </CardTitle>
              <Form 
                className="mt-4" 
                role="form" 
                onSubmit={handleSubmit}
              >
                <FormGroup
                  className={classnames({
                    focused: focusedPassword
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="neue-roman letter-4"
                      style={{ fontSize: '14px' }}
                      placeholder="New Password"
                      type="password"
                      id="password"
                      autoComplete="off"
                      value={password}
                      onChange={(e) => {
                        updatePassword(e.target.value)
                      }}
                      onBlur={() => setfocusedPassword(true)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: focusedPassword
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="neue-roman letter-4"
                      style={{ fontSize: '14px' }}
                      placeholder="Confirm New Password"
                      type="password"
                      id="confirm-password"
                      autoComplete="off"
                      value={passwordConfirm}
                      // invalid={password !== passwordConfirm && passwordConfirm !== ''}
                      onChange={(e) => {
                        setPasswordConfirm(e.target.value)
                        checkConfirmedPassword(e.target.value)
                      }}
                      onBlur={() => setfocusedPassword(true)}
                    />
                  </InputGroup>
                </FormGroup>
                <PasswordRequirements password={password} visible />
                {error && <div className="error-message">{errorMessage}</div>}
                <div className="text-center">
                  <Button
                    className="my-3 letter-4 neue-thin"
                    color="primary"
                    type="submit"
                    style={{ fontSize: '16px' }}
                    disabled={error}
                  >
                    Reset Password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        </Row>
      </Container>
      <AuthFooter />
    </>
  )
}

export default ResetPassword
