import { Card, CardHeader, CardBody } from 'reactstrap'
import { Bar, Line } from 'react-chartjs-2'
import React, { useCallback, useEffect, useState } from 'react'
import { colors } from 'variables/charts.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useHistory } from 'react-router-dom'

function NoRechargeChart({ costCtrId, startDate, endDate, timePeriod }) {
  const [noRechargeCountList, setNoRechargeCountList] = useState([])
  const [subtenantNoRechargeList, setSubtenantNoRechargeList] = useState([])
  const { getNoRechargeServiceCount, getSubtenantNoRecharge } =
    useDashboardContext()

  const history = useHistory()

  const loadNoRechargeServiceCount = useCallback(async () => {
    await getNoRechargeServiceCount()
      .then((response) => {
        setNoRechargeCountList(response.results)
        //console.log('set no recharge service count: ', response)
      })
      .catch((err) => {
        console.log('error at loadNoRechargeServiceCount: ', err)
      })
  }, [getNoRechargeServiceCount])

  const loadSubtenantNoRecharge = useCallback(async () => {
    await getSubtenantNoRecharge({
      costCtrId,
      startDate,
      endDate,
      timePeriod
    })
      .then((response) => {
        setSubtenantNoRechargeList(response)
        //console.log('set subtenant no recharge: ', response)
      })
      .catch((err) => {
        console.log('error at loadSubtenantNoRecharge: ', err)
      })
  }, [costCtrId, endDate, getSubtenantNoRecharge, startDate, timePeriod])

  const getCostCtrLable = () => {
    const list = noRechargeCountList.map((data) => data.costCtrDescr)
    return list
  }

  const getDateLable = () => {
    if (timePeriod === 'Day' || timePeriod === 'Week') {
      const list = subtenantNoRechargeList.map((data) =>
        new Date(data.date).toLocaleDateString('es-AU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      )
      return list
    }
    if (timePeriod === 'Month') {
      const list = subtenantNoRechargeList.map((data) =>
        new Date(data.date).toLocaleDateString('en-AU', {
          year: '2-digit',
          month: 'short'
        })
      )
      return list
    }
    if (timePeriod === 'Year') {
      const list = subtenantNoRechargeList.map((data) =>
        new Date(data.date).toLocaleDateString('en-AU', {
          year: 'numeric'
        })
      )
      return list
    }
  }

  const getSubtenantNoRechargeData = () => {
    const list = subtenantNoRechargeList.map((data) => data.count)

    return list
  }

  const getServiceCountData = () => {
    const list = noRechargeCountList.map((data) => data.count)

    return list
  }

  useEffect(() => {
    if (costCtrId) {
      loadSubtenantNoRecharge()
    } else {
      loadNoRechargeServiceCount()
    }
  }, [
    loadNoRechargeServiceCount,
    costCtrId,
    startDate,
    endDate,
    timePeriod,
    loadSubtenantNoRecharge
  ])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">No Recharge</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {costCtrId === null && (
              <Bar
                data={{
                  labels: getCostCtrLable(),
                  datasets: [
                    {
                      label: 'Service Count',
                      data: getServiceCountData()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {
                          // beginAtZero: true
                        }
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element[0],
                      element[0]._index,
                      noRechargeCountList[element[0]._index],
                      'costCtrId: ' +
                        noRechargeCountList[element[0]._index].costCtrId
                    )
                    history.push({
                      pathname: '/admin/services',
                      state: {
                        costCtrId:
                          noRechargeCountList[element[0]._index].costCtrId,
                        statusFilter: 'withoutPlan'
                      }
                    })
                  }
                }}
                id="chart-no-charge"
                className="chart-canvas"
              />
            )}
            {costCtrId && (
              <Line
                data={{
                  labels: getDateLable(),
                  datasets: [
                    {
                      label: 'number of service',
                      data: getSubtenantNoRechargeData()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {}
                      }
                    ]
                  },
                  onClick: () => {
                    history.push({
                      pathname: '/admin/services',
                      state: {
                        costCtrId: costCtrId,
                        statusFilter: 'withoutPlan'
                      }
                    })
                  }
                }}
                id="chart-no-charge-subtenant"
                className="chart-canvas"
              />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default NoRechargeChart
