// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@fullcalendar/common/main.min.css'
import '@fullcalendar/daygrid/main.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'select2/dist/css/select2.min.css'
import 'quill/dist/quill.core.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
// plugins styles downloaded
import 'assets/vendor/nucleo/css/nucleo.css'
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.2.1'

import AdminLayout from 'layouts/Admin.js'

export const AuthenticatedApp = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Redirect from="*" to="/admin" />
      </Switch>
    </BrowserRouter>
  )
}
