import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Card,
  Container,
  CardHeader,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Table,
} from 'reactstrap'

import { useDashboardContext } from '../../contexts/DashboardContext'
import { isMobileNumberValid } from '../../utils/validation'
import { dollarAmountFormat } from '../../utils/Utils'

function Recharge() {
  const [msisdn, setMsisdn] = useState('')
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [msisdnError, setMsisdnError] = useState(false)
  const [msisdnErrorMessage, setMsisdnErrorMessage] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const [isValid, setIsValid] = useState(false)
  const [rechargeType, setRechargeType] = useState('')

  const [planId, setPlanId] = useState(null)
  const [planName, setPlanName] = useState(null)
  const [planDescr, setPlanDescr] = useState(null)
  const [planValue, setPlanValue] = useState('')
  const [productList, setProductList] = useState([])
  const [costCtrBalances, setCostCtrBalances] = useState({credit: ''})

  const { getProductList, applyProduct, getCostCtrBalances } = useDashboardContext()
  const { authCostCtrId } = useDashboardContext()

  const history = useHistory()

  const mobileNumberValid = isMobileNumberValid(msisdn)

  useEffect(() => {
    setIsLoading(true)
    const loadCostCtrBalances = async () => {
      await getCostCtrBalances(authCostCtrId)
        .then((res) => {
          console.log('res: ', res)
          setIsLoading(false)
          setCostCtrBalances({
            credit: res
            .filter((item) => item.balanceType === 'CREDIT')
          })
        })
        .catch((err) => {
          setIsLoading(false)
          console.log('err: ', err)
        })
    }
    loadCostCtrBalances()
  }, [getCostCtrBalances, authCostCtrId])

  const creditBalanceWarning = useMemo(() => {
    console.log('useCreditBalance: ', costCtrBalances.credit[0]?.useCreditBalance)

    if (costCtrBalances.credit[0]?.useCreditBalance === 'Y') {
      let creditBalance = parseInt(costCtrBalances.credit[0]?.balance);
      let lowBalanceThreshold = parseInt(costCtrBalances.credit[0]?.lowBalanceThreshold);

      if (creditBalance >= lowBalanceThreshold) {
        return true
      }
      return false
    }
    return true
  }, [costCtrBalances])

  useEffect(() => {
    setError(false)
    if (msisdn && msisdn.length === 10 && !!planId && mobileNumberValid) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [msisdn, planId])

  const handleSubmit = async () => {
    setIsLoading(true)
    if (!mobileNumberValid) {
      setMsisdnError(true)
      setMsisdnErrorMessage('Please enter a valid MSISDN')
      return
    }
    await applyProduct({
      msisdn: msisdn,
      planId: planId
    })
      .then((res) => {
        console.log('apply product res:', res)
        if (res === 200) {
          setError(true)
          setErrorMessage('')
          setIsLoading(false)
          history.push({
            pathname: '/admin/recharge/receipt',
            state: {
              msisdn: msisdn,
              planId: planId,
              rechargeType: rechargeType,
              price: planValue,
              name: planName,
              descr: planDescr,
            }
          })
        } else if (res.status >= 500) {
          setError(true)
          setErrorMessage('Something went wrong. Please try again later.')
          setIsLoading(false)
          return
        } else {
          setError(true)
          setErrorMessage(res.error)
          setIsLoading(false)
          return
        }
      })
      .catch((err) => {
        setError(true)
        setErrorMessage(err.message ? err.message : 'Something went wrong. Please try again later.')
        console.log('get sim details error:', err)
      })
    setIsLoading(false)
  }
    
  // useEffect(() => {
    const loadProductList = async (type) => {
      console.log('rechargeType', type)
      setIsLoading(true)
      await getProductList({
        productType: type
      })
      .then((res) => {
        setIsLoading(false)
        console.log('loadProductList: ', res)
        setProductList(res)
      })
      .catch((err) => {
        setIsLoading(false)
        setError(true)
        setErrorMessage(err.message)
      })
    }
  //   loadProductList()
  // }, [getProductList])

  return (
    <>
      <SimpleHeader name="Recharge" />
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Recharge
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              <div className="pl-lg-4">
                <FormGroup row>
                  <Label className="text-primary letter-4" htmlFor="input-msisdn" md={2}>
                    MSISDN
                  </Label>
                  <Col md={6}>
                    <Input
                      id="input-msisdn"
                      placeholder="MSISDN"
                      type="text"
                      required={true}
                      autoComplete='off'
                      invalid={!mobileNumberValid && msisdn !== ''}
                      onChange={(e) => {
                        setMsisdn(e.target.value.replace(/[^0-9\s]/gi, ''))
                        setError(false)
                        setErrorMessage('')
                        setMsisdnError(false)
                        setMsisdnErrorMessage('')
                      }}
                      maxLength={10}
                    />
                    {msisdnError && (
                      <div className="error-message">{msisdnErrorMessage}</div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="text-primary letter-4" htmlFor="input-recharge-type" md={2}>
                    Recharge Type
                  </Label>
                  <Col md={6}>
                    <Input
                      id="select-adjustment-type"
                      type="select"
                      value={rechargeType}
                      onChange={(e) => {
                        console.log('e: ', e.target.value)
                        setError(false)
                        setErrorMessage('')
                        setPlanId(null)
                        setPlanName(null)
                        setPlanDescr(null)
                        setPlanValue(null)
                        setRechargeType(e.target.value)
                        loadProductList(e.target.value)
                      }}
                    >
                      <option value="" disabled selected hidden>Select Recharge Type</option>
                      <option>Plan</option>
                      <option>Add-on</option>
                      {/* <option>Main Balance</option> */}
                    </Input>
                  </Col>
                </FormGroup>
                {rechargeType === 'Plan' && (
                  <FormGroup row>
                    <Label className="text-primary letter-4" htmlFor="select-plan" md={2}>
                      Select Plan
                    </Label>
                    <Col md={6}>
                      <Input
                        id="select-plan"
                        type="select"
                        value={planId}
                        onChange={(e) => {
                          console.log('e: ', e.target.value)
                          setError(false)
                          setErrorMessage('')
                          setPlanId(productList.find(i => i.planId === e.target.value)?.planId)
                          setPlanName(productList.find(i => i.planId === e.target.value)?.planName)
                          setPlanDescr(productList.find(i => i.planId === e.target.value)?.planDescr)
                          setPlanValue(productList.find(i => i.planId === e.target.value)?.value)
                        }}
                      >
                        <option value="" disabled selected hidden>Select A Plan</option>
                        {productList.map((list) => {
                          return (
                            <option value={list.planId}>{list.planName}</option>
                          )
                        })}
                      </Input>
                    </Col>
                  </FormGroup>
                )}
                {rechargeType === 'Add-on' && (
                  <FormGroup row>
                    <Label className="text-primary letter-4" htmlFor="select-addon" md={2}>
                      Select Add-on
                    </Label>
                    <Col md={6}>
                      <Input
                        id="select-addon"
                        type="select"
                        value={planId}
                        onChange={(e) => {
                          console.log('e: ', e.target.value)
                          setError(false)
                          setErrorMessage('')
                          setPlanId(productList.find(i => i.planId === e.target.value)?.planId)
                          setPlanName(productList.find(i => i.planId === e.target.value)?.planName)
                          setPlanDescr(productList.find(i => i.planId === e.target.value)?.planDescr)
                          setPlanValue(productList.find(i => i.planId === e.target.value)?.value)
                        }}
                      >
                        <option value="" disabled selected hidden>Select An Add-on</option>
                        {productList.map((list) => {
                          return (
                            <option value={list.planId}>{list.planName}</option>
                          )
                        })}
                      </Input>
                    </Col>
                  </FormGroup>
                  )}
                  {planId && (
                    <FormGroup row>
                      <Label className="text-primary letter-4" htmlFor="select-plan" md={2}>
                      {/* <br /> */}
                        {rechargeType} Details
                      </Label>
                      <Col className='align-items-left' md={6}>
                      {/* <br /> */}
                        <Table className='text-primary letter-4 align-items-left'>
                          <thead>
                            <tr>
                              <th>{rechargeType} Name</th>
                              <th>{rechargeType} Descr</th>
                              <th>{rechargeType} Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{productList.find(i => i.planId === planId)?.planName}</td>
                              <td>{productList.find(i => i.planId === planId)?.planDescr}</td>
                              <td>$ {dollarAmountFormat.format(productList.find(i => i.planId === planId)?.value)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </FormGroup>
                  )}
                {!creditBalanceWarning && (
                  <div className="error-message">
                    You are running low on credit and may not have enough to complete the recharge. Please contact your manager.
                  </div>
                )}
                {error && <div className="error-message">{errorMessage}</div>}
                {isLoading ? (
                  <Spinner></Spinner>
                  ) : (
                    <Button color="secondary" type="submit" disabled={!isValid}>
                    Next
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Recharge