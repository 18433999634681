import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import classNames from 'classnames'

const PaginationService = ({ setPageOffset, pageOffset, lastPage }) => {
  const renderItem = (page) => {
    return (
      <PaginationItem
        className={classNames(page === pageOffset + 1 && 'active')}
      >
        <PaginationLink
          onClick={(e) => {
            e.preventDefault()
            setPageOffset(page - 1)
          }}
        >
          {page}
        </PaginationLink>
      </PaginationItem>
    )
  }

  const renderPagination = () => {
    let pages = []
    console.log('last page', lastPage)
    if (lastPage <= 3) {
      console.log('p1')
      pages = Array.from({ length: lastPage }, (_, i) => i + 1)
    } else if (pageOffset === 0) {
      pages = [1, 2, 3]
    } else if (pageOffset === lastPage - 1) {
      pages = [lastPage - 2, lastPage - 1, lastPage]
      console.log('p2')
    } else {
      pages = [pageOffset, pageOffset + 1, pageOffset + 2]
      console.log('p3')
    }
    return pages.map((page) => {
      return renderItem(page)
    })
  }

  return (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem className={classNames(pageOffset <= 0 && 'disabled')}>
        <PaginationLink
          onClick={(e) => {
            e.preventDefault()
            setPageOffset(pageOffset - 1)
          }}
          tabIndex="-1"
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
      {renderPagination(pageOffset + 1)}
      <PaginationItem
        className={classNames(pageOffset === lastPage - 1 && 'disabled')}
      >
        <PaginationLink
          onClick={(e) => {
            e.preventDefault()
            setPageOffset(pageOffset + 1)
          }}
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  )
}

export default PaginationService
