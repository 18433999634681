import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'

const RecordsDropdown = ({
  dropdownOpen,
  toggle,
  rowsPerPage,
  setRowsPerPage
}) => {
  return (
    <div className="flexbox-container text-primary">
      <span>Show{'   '}</span>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="ml-1">
        <DropdownToggle color="light" caret>
          {rowsPerPage}
        </DropdownToggle>
        <DropdownMenu className="text-primary">
          <DropdownItem onClick={() => setRowsPerPage(10)}>10</DropdownItem>
          <DropdownItem onClick={() => setRowsPerPage(15)}>15</DropdownItem>
          <DropdownItem onClick={() => setRowsPerPage(25)}>25</DropdownItem>
          <DropdownItem onClick={() => setRowsPerPage(50)}>50</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <span> entries</span>
    </div>
  )
}

export default RecordsDropdown
