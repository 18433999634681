import React, { FunctionComponent, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import HttpClient from 'services/sdk/HttpClient'
import RoutesHandler from 'services/sdk/handlers/RoutesHandler'
import { IRoutesHandler, IHttpClient, IRoutes } from 'services/sdk/interfaces'
import { useAuthContext } from './AuthContext'
import { createCtx } from './helpers'

// @ts-ignore
import { routes } from 'routes.js'
import { IFeature, IGraph } from 'services/sdk/models/models'
import FeatureConfig from 'services/sdk/models/FeatureConfig'

type AccessControlContextProviderProps = {
  children: React.ReactNode
}

interface AccessControlContextInterface {
  // Http Clients
  routesHandler: IRoutesHandler

  // Functions
  getRoutes: () => Promise<void>
  getDashboardSummary: () => IGraph[]
  getServiceListFeatures: () => IFeature | undefined
  getServiceListColumns: () => String[]
  getActivationFlow: () => String | undefined
  getServiceFeatures: () => IFeature | undefined
  getGroupFeatures: () => IFeature | undefined
  getInventoryFeatures: () => IFeature | undefined
  getDashboardCharts: () => IGraph[]
  getShowCostCtrPicker: () => boolean
  resetState: () => void
  getSimDetailsFeatures: () => IFeature | undefined
  getUserListFeatures: () => IFeature | undefined
  getAuditLogListFeatures: () => IFeature | undefined
  getAddUserFeatures: () => IFeature | undefined
  getTransactionHistoryFeatures: () => IFeature | undefined
  // setRoutes: (routes: IRoutes | null) => void

  // State
  accessRoutes: IRoutes

  authCostCtrId: string | null

  features: FeatureConfig

  authRoleId: string | null

  authRole: string | null
}

const [ctx, useContext, Provider] = createCtx<AccessControlContextInterface>()

export const AccessControlContextProvider: FunctionComponent<
  AccessControlContextProviderProps
> = ({ children }) => {
  const { tokenProvider, isLogged } = useAuthContext()
  const standardHttpClient = useRef<IHttpClient>(
    HttpClient(process.env.REACT_APP_HOST_AUTH, tokenProvider)
  ).current

  const routesHandler = useRef<IRoutesHandler>(
    RoutesHandler(standardHttpClient)
  ).current

  const localRoutes = sessionStorage.getItem('routes')

  const [accessRoutes, setAccessRoutes] = useState<IRoutes>(
    localRoutes
      ? JSON.parse(localRoutes)
      : {
          serviceRoutes: [],
          accountRoutes: []
        }
  )

  useEffect(() => {
    if (isLogged) {
      getRoutes()
    }
  }, [])

  useEffect(() => {
    console.log('current accessible routes', accessRoutes)
  }, [accessRoutes])

  const [features, setFeatures] = useState<FeatureConfig>(
    FeatureConfig.ConstructEmpty()
  )

  const [authCostCtrId, setAuthCostCtrId] = useState<string | null>(null)

  const [authRoleId, setAuthRoleId] = useState<string | null>(null)

  const [authRole, setAuthRole] = useState<string | null>(null)

  const getRoutes = async () => {
    console.log('in getRoutes function')
    return await routesHandler
      .getPermissions()
      .then((response) => {
        if (response.ok && response.response) {
          const responseRoutes = response.response.guiFeatureData.routes
          setFeatures(FeatureConfig.Construct(response.response.guiFeatureData))
          setAuthCostCtrId(response.response.costCtrId)
          setAuthRoleId(response.response.authRoleId)
          setAuthRole(response.response.authRole)
          console.log('setFeatures:', response.response)
          console.log('response routes and features:', responseRoutes)

          let mapRoutes: any = {
            serviceRoutes: [],
            accountRoutes: []
          }

          responseRoutes.map((route: any) => {
            const curr = routes.find((r: any) => {
              return r.name === route.name
            })

            if (curr) {
              if (!route.sidebarGroup || route.sidebarGroup === 'Admin') {
                mapRoutes.serviceRoutes.push({
                  name: curr.name,
                  path: curr.path,
                  icon: curr.icon ?? undefined,
                  component: curr.component,
                  layout: curr.layout,
                  sidebarName: route.sidebarName ?? undefined,
                  redirect: !JSON.parse(route.showInSidebar)
                })
              } else if (route.sidebarGroup === 'Account') {
                mapRoutes.accountRoutes.push({
                  name: curr.name,
                  path: curr.path,
                  icon: curr.icon ?? undefined,
                  component: curr.component,
                  layout: curr.layout,
                  sidebarName: route.sidebarName ?? undefined,
                  redirect: !JSON.parse(route.showInSidebar)
                })
              }
            }
          })
          console.log('Routes after map:', mapRoutes)
          // sessionStorage.setItem('routes', JSON.stringify(mapRoutes))
          setAccessRoutes(mapRoutes)
        }
      })
      .catch((error) => {
        console.log('error:', error)
      })
  }

  const getDashboardSummary = () => {
    return features.getDashboardSummary()
  }

  const getServiceListFeatures = () => {
    return features.getFeatureByRouteName('ServiceList')?.features
  }

  const getServiceListColumns = () => {
    return features.getServiceListColumns()
  }

  const getActivationFlow = () => {
    return features.getActivationFlow()
  }

  const getServiceFeatures = () => {
    return features.getFeatureByRouteName('Service')?.features
  }

  const getGroupFeatures = () => {
    return features.getFeatureByRouteName('Groups')?.features
  }

  const getDashboardCharts = () => {
    return features.getDashboardCharts()
  }

  const getShowCostCtrPicker = () => {
    return JSON.parse(features.getShowCostCtrPicker())
  }

  const getSimDetailsFeatures = () => {
    return features.getFeatureByRouteName('SimDetails')?.features
  }

  const getUserListFeatures = () => {
    return features.getFeatureByRouteName('UsersList')?.features
  }

  const getAuditLogListFeatures = () => {
    return features.getFeatureByRouteName('AuditLogList')?.features
  }

  const getAddUserFeatures = () => {
    return features.getFeatureByRouteName('AddUser')?.features
  }

  const getInventoryFeatures = () => {
    return features.getFeatureByRouteName('Inventory')?.features
  }

  const getTransactionHistoryFeatures = () => {
    return features.getFeatureByRouteName('TransactionHistory')?.features
  }

  // const setRoutes = (routes: IRoutes | null) => {
  //   if (routes) {
  //     sessionStorage.setItem('routes', JSON.stringify(routes))
  //     setAccessRoutes(routes)
  //   } else {
  //     sessionStorage.removeItem('routes')
  //     setAccessRoutes({
  //       serviceRoutes: [],
  //       accountRoutes: []
  //     })
  //   }
  // }

  // const retrieveRoutes = () => {
  //   const localRoutes = sessionStorage.getItem('routes')
  //   if (localRoutes) {
  //     const parsedRoutes = JSON.parse(localRoutes)
  //     let mapRoutes: any = {
  //       serviceRoutes: [],
  //       accountRoutes: []
  //     }
  //     const serviceRoutes = parsedRoutes.serviceRoutes
  //     const accountRoutes = parsedRoutes.accountRoutes
  //     serviceRoutes?.map((route: any) => {
  //       const curr = routes.find((r: any) => {
  //         return r.name === route.name
  //       })
  //       mapRoutes.serviceRoutes.push({
  //         ...route,
  //         component: curr.component
  //       })
  //     })
  //     accountRoutes?.map((route: any) => {
  //       const curr = routes.find((r: any) => {
  //         return r.name === route.name
  //       })
  //       mapRoutes.accountRoutes.push({
  //         ...route,
  //         component: curr.component
  //       })
  //     })
  //     setAccessRoutes(mapRoutes)
  //   }
  // }

  const resetState = () => {
    setAccessRoutes({
      serviceRoutes: [],
      accountRoutes: []
    })
    setFeatures(FeatureConfig.ConstructEmpty())
    setAuthCostCtrId(null)
    setAuthRoleId(null)
    setAuthRole(null)
  }

  return (
    <Provider
      value={{
        accessRoutes,
        getRoutes,
        getDashboardSummary,
        getServiceListFeatures,
        getServiceListColumns,
        getActivationFlow,
        getServiceFeatures,
        getGroupFeatures,
        getInventoryFeatures,
        getDashboardCharts,
        getShowCostCtrPicker,
        resetState,
        getSimDetailsFeatures,
        getUserListFeatures,
        getAuditLogListFeatures,
        getAddUserFeatures,
        getTransactionHistoryFeatures,
        routesHandler,
        features,
        authCostCtrId,
        authRoleId,
        authRole
        // setRoutes
      }}
    >
      {children}
    </Provider>
  )
}

AccessControlContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useAccessControlContext = useContext

export default ctx
