import React, { useEffect, useState } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  Table,
  Spinner
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useHistory } from 'react-router-dom'
import { useDashboardContext } from '../../../contexts/DashboardContext'
import { useActivationContext } from '../../../contexts/ActivationContext'
import { dollarAmountFormat } from '../../../utils/Utils'

function Review({ location }) {
  const {
    msisdn,
    sim,
    email,
    password,
    subscriberDetails,
    mobileType,
    phoneNumber,
    address,
    portInNumber,
    activationType,
    accountNumber,
    acctCostCtrId,
    acctEmail,
    createAccount,
    idDetails,
    planDetails
  } = location.state

  const history = useHistory()

  const { dealerActivate } = useActivationContext()
  const [submitError, setSubmitError] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  // console.log('idDetails:', idDetails.idRequest)  

  const onSubmit = async () => {
    setLoading(true)
    console.log('able to submit')

    await dealerActivate({
      username: createAccount ? acctEmail : email,
      msisdn: msisdn,
      iccid: sim,
      activationType: activationType,
      planId: planDetails.planId,
      acctCostCtrId: acctCostCtrId,
      portDetails: {
        portMsisdn: portInNumber ? portInNumber : undefined,
        accountNumber: accountNumber ? accountNumber : undefined,
      },
      subscriberDetails: subscriberDetails,
      idDetails: idDetails
    })
      .then((res) => {
        setLoading(false)
        setError(false)
        setSubmitError('')
        console.log('activate service - dealer:', res)
        history.push({
          pathname: '/admin/success',
          state: {
            msisdn,
            sim,
            subscriberDetails,
            email,
            // password,
            phoneNumber,
            portInNumber,
            activationType,
            accountNumber,
            acctCostCtrId,
            acctEmail,
            planDetails,
          }
        })
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
        setSubmitError(err.message ? err.message : 'Something went wrong')
        console.log('err:', err)
      })
  }

  return (
    <>
      <SimpleHeader name="Review activation details" />
      <Container
        fluid
        className="d-flex align-items-center justify-content-start flex-column"
      >
        <Card style={{ width: '100%' }}>
        {acctEmail !== null ? (
          <>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0 text-primary neue-bold letter-2">
                    Account Details
                  </h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="text-primary" style={{ paddingBottom: '0px'}}>
              <Form>
                <div className="pl-lg-4">
                  <Row className="d-flex align-items-center">
                    <Col>
                      <Row>
                        <Label
                          className="text-primary letter-40"
                          htmlFor="re-input-password"
                          md={3}
                          style={{ padding: '5px 15px'}}
                        >
                          Account Details
                        </Label>
                        <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                          {acctEmail}
                        </CardText>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </>
        ) : (
          <></>
        )}
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Service Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary" style={{ paddingBottom: '0px'}}>
            <Form>
              <div className="pl-lg-4">
                <Row className="d-flex align-items-center">
                  <Col>
                    <Row>
                      <Label
                        className="text-primary letter-4"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        MSISDN
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {msisdn}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-40"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Serial Number
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {sim}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-40"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Activation Type
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {activationType}
                      </CardText>
                    </Row>
                    {activationType === 'portfrompostpaid' ? (
                      <Row>
                        <Label
                          className="text-primary letter-40"
                          htmlFor="re-input-password"
                          md={3}
                          style={{ padding: '5px 15px'}}
                        >
                          Account Number
                        </Label>
                        <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                          {accountNumber}
                        </CardText>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>

          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Personal Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary" style={{ paddingBottom: '0px'}}>
            <Form>
              <div className="pl-lg-4">
                <Row className="d-flex align-items-center">
                  <Col>
                    {createAccount && (
                      <>
                        <Row>
                          <Label
                            className="text-primary letter-4"
                            htmlFor="re-input-password"
                            md={3}
                            style={{ padding: '5px 15px'}}
                          >
                            Username
                          </Label>
                          <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                            {acctEmail}
                          </CardText>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Label
                        className="text-primary letter-4"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Name
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {subscriberDetails.title}{' '}
                        {subscriberDetails.firstName}{' '}
                        {subscriberDetails.middleName}{' '}
                        {subscriberDetails.lastName}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-4"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Address
                      </Label>
                      <CardText className="text-primary" md={6} style={{ padding: '5px 15px'}}>
                        {address.formattedAddress}
                      </CardText>
                    </Row>
                    {!createAccount && (
                      <>
                        <Row>
                          <Label
                            className="text-primary letter-4"
                            htmlFor="re-input-password"
                            md={3}
                            style={{ padding: '5px 15px'}}
                          >
                            Email
                          </Label>
                          <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                            {email}
                          </CardText>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Label
                        className="text-primary letter-4"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Date of Birth
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {subscriberDetails.dob}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-4"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Phone Type
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {mobileType}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-4"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Phone Number
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {phoneNumber}
                      </CardText>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>

          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  ID Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary" style={{ paddingBottom: '0px'}}>
            <Form>
              <div className="pl-lg-4">
                <Row className="d-flex align-items-center">
                  <Col>
                    <Row>
                      <Label
                        className="text-primary letter-3"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        ID Type
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {idDetails.idRequest.selectedIDs[0] ? idDetails.idRequest.selectedIDs[0] : ''}
                        {idDetails.idRequest.selectedIDs[1] ? ', ' + idDetails.idRequest.selectedIDs[1] : ''}
                        {idDetails.idRequest.selectedIDs[2] ? ', ' + idDetails.idRequest.selectedIDs[2] : ''}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-3"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        ID Status
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        Verified
                      </CardText>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>

          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Plan Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form>
              <div className="pl-lg-4">
                <Row className="d-flex align-items-center">
                  <Col>
                    <Row>
                      <Label
                        className="text-primary letter-3"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Plan Name
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {planDetails.planName}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-3"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Plan Description
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        {planDetails.planDescr}
                      </CardText>
                    </Row>
                    <Row>
                      <Label
                        className="text-primary letter-3"
                        htmlFor="re-input-password"
                        md={3}
                        style={{ padding: '5px 15px'}}
                      >
                        Plan Value
                      </Label>
                      <CardText className="text-primary" style={{ padding: '5px 15px'}}>
                        $ {dollarAmountFormat.format(planDetails.planValue)}
                      </CardText>
                    </Row>
                    <br />
                    {!!submitError && (
                      <div className="error-message">{submitError}</div>
                    )}
                    {loading ? (
                      <Spinner></Spinner>
                    ) : (
                      <Button color="secondary" onClick={(e) => {
                        e.preventDefault()
                        onSubmit()}}
                      >
                        Submit
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Review
