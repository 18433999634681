import PropTypes from 'prop-types'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useState, useEffect } from 'react'
import React, { useCallback } from 'react'
import { Row, Col } from 'reactstrap'
import { renderFeatureComponent } from '../../utils/ComponentHelper'

function SummaryCards({ cardList, costCtrId }) {
  return (
    <>
      {cardList && (
        <Row>
          {cardList.map((card, index) => (
            <Col sm="6" lg="3" xl="3">
              {renderFeatureComponent(card.name, { costCtrId: costCtrId })}
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

SummaryCards.propTypes = {
  cardList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      clickable: PropTypes.bool,
      navigateTo: PropTypes.string
    })
  )
}

export default SummaryCards
