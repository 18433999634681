import React, { useEffect, useState, useCallback, useMemo } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useHistory } from 'react-router-dom'
import Strings from 'localization'
import classnames from 'classnames'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { usePsmaContext } from 'contexts/PsmaContext'
import Address from 'services/sdk/models/address'
import useDebounce from 'hooks/useDebounce'
import PaginationService from '../../components/Table/PaginationService'
import RecordsDropdown from '../../components/Table/RecordsDropdown'
import { isPhoneNumberValid, isEmailValid } from '../../utils/validation'
import DealerTransHistory from './TransactionHistoryTable'

function GroupDetails({ location }) {
  const { costCtrId, groupName, status, costCenterType } = location.state
  const history = useHistory()
  const {
    getGroupDetails,
    getUserList,
    updateGroup,
    updateGroupStatus,
    getCostCtrBalances
  } = useDashboardContext()
  const { getGroupFeatures, authCostCtrId } = useAccessControlContext()
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [groupDetails, setGroupDetails] = useState(null)
  const [pageOffset, setPageOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState('costCtrDescr')
  const [sortDesc, setSortDesc] = useState(true)
  const [statusFilter, setStatusFilter] = useState('All')
  const [searchFilter, setSearchFilter] = useState('')
  const [lastPage, setLastPage] = useState(0)
  const [maxResults, setMaxResults] = useState(0)
  const [recordsDropdownOpen, setRecordsDropdownOpen] = useState(false)
  //const [groupDropdownOpen, setGroupDropdownOpen] = useState(false)
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false)
  const [showAddressList, setShowAddressList] = useState(false)
  const [psmaError, setPsmaError] = useState(false)
  const [psmaErrorMessage, setPsmaErrorMessage] = useState('')
  const [suggestedAddresses, setSuggestedAddresses] = useState(null)
  const [addressQuery, setAddressQuery] = useState('')
  const { getAddressSuggestions, getAddress } = usePsmaContext()
  const [address, setAddress] = useState(null)
  // const [manualAddress, setManualAddress] = useState({unitNumber: '', street: '', streetNumber: '', streetName: '', streetType: '', suburb: '', state: '', postcode: ''});
  // const [contact, setContact] = useState({email: '', contactMobile: ''});
  const [editable, setEditable] = useState(false)
  // const [costCtrList, setCostCtrList] = useState([])
  const [showCostCtrList, setShowCostCtrList] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [originalGroupDetails, setOriginalGroupDetails] = useState(null)
  const groupFeatures = getGroupFeatures()
  const [terminate, setTerminate] = useState(false)
  const [terminated, setTerminated] = useState(false)
  const [activeTab, setActiveTab] = useState('DealerDetails')

  const recordFooter = () => {
    const first = maxResults === 0 ? 0 : pageOffset * rowsPerPage + 1
    const last = Math.min(pageOffset * rowsPerPage + rowsPerPage, maxResults)
    return (
      <span>
        showing {first} to {last} of {maxResults} entries
      </span>
    )
  }
  //console.log('costCtrId: ', costCtrId)

  const changeRowsPerPage = (number) => {
    setPageOffset(0)
    setRowsPerPage(number)
  }

  const setSort = (sort) => {
    setPageOffset(0)
    if (sort === sortBy) {
      setSortDesc(!sortDesc)
    } else {
      setSortBy(sort)
      setSortDesc(true)
    }
  }

  const editToggle = () => {
    setEditable((prevState) => !prevState)
  }

  useEffect(() => {
    setLoading(true)
    const loadGroupDetails = async () => {
      await getGroupDetails(costCtrId)
        .then((load) => {
          setLoading(false)
          console.log('loadDetails: ', load)
          setGroupDetails(load)
          setOriginalGroupDetails(load)
        })
        .catch((err) => {
          setLoading(false)
          setError(true)
          setErrorMessage(err.message)
        })
    }
    loadGroupDetails()
  }, [getGroupDetails, costCtrId])

  useEffect(() => {
    setLoading(true)
    const loadUsers = async () => {
      await getUserList({
        costCtrId,
        pageOffset,
        rowsPerPage,
        sortBy,
        sortOrder: sortDesc ? 'asc' : 'desc',
        searchFilter: searchFilter,
        statusFilter: statusFilter
      })
        .then((load) => {
          setLoading(false)
          // console.log('load: ', load)
          setMaxResults(load.maxResults)
          setLastPage(Math.ceil(load.maxResults / rowsPerPage))
          setUserList(load.results)
        })
        .catch((err) => {
          setLoading(false)
          setError(true)
          setErrorMessage(err.message)
        })
    }
    loadUsers()
  }, [
    getUserList,
    costCtrId,
    pageOffset,
    rowsPerPage,
    sortBy,
    sortDesc,
    searchFilter,
    statusFilter
  ])

  const statusFlag = () => {
    if (groupDetails.status === 'E' && terminated === false) {
      return (
        <Button color="success" disabled="true">
          Active
        </Button>
      )
    } else {
      return (
        <Button color="dark" disabled="true">
          Terminated
        </Button>
      )
    }
  }

  const toggle = () => {
    setRecordsDropdownOpen(!recordsDropdownOpen)
  }

  const MIN_LENGTH = 4 // PSMA wants at least 4 chars before returning results

  const handleGroupDetailsChange = (key, value) => {
    // hack to deep copy object and avoid mutating state
    let updatedDetails = JSON.parse(JSON.stringify(groupDetails))
    console.log('updatedGroupDetails: ', updatedDetails)
    if (key.match(/\./)) {
      const path = key.split(/\./)
      const lastKey = path.pop()
      const nestedObj = path.reduce((a, prop) => a[prop], updatedDetails)
      nestedObj[lastKey] = value
    } else {
      updatedDetails[key] = value
    }
    setGroupDetails({
      ...updatedDetails
    })
  }

  const statusArray = [
    { status: 'active', label: 'Active' },
    { status: 'terminated', label: 'Terminated' }
  ]

  const addressToggle = () => {
    setShowAddressList((prevState) => !prevState)
  }

  const selectAddress = useCallback(
    async (item) => {
      setError(false)
      setLoading(true)

      await getAddress(item.id)
        .then((res) => {
          setLoading(false)
          setAddress(Address.ConstructFromPSMAAddress(res.address))
          handleGroupDetailsChange('groupDetails.address', {
            unitType: res.address.properties.complex_unit_type ? res.address.properties.complex_unit_type  : '' 
            ,unitNumber: res.address.properties.complex_unit_identifier ? res.address.properties.complex_unit_identifier : ''
            ,levelType: res.address.properties.complex_level_type ? res.address.properties.complex_level_type  : '' 
            ,levelNumber: res.address.properties.complex_level_number ? res.address.properties.complex_level_number : ''
            ,streetNumber: res.address.properties.street_number_1
            ,streetNumber2: res.address.properties.street_number_2 ? res.address.properties.street_number_2 : ''
            ,streetName: res.address.properties.street_name
            ,streetType: res.address.properties.street_type
            ,suburb: res.address.properties.locality_name
            ,state: res.address.properties.state_territory
            ,postcode: res.address.properties.postcode
          })
        })
        .catch((err) => {
          console.error(err)
          setLoading(false)
          setPsmaError(true)
          setPsmaErrorMessage(
            'Something went wrong with getting the address details, please try again later'
          )
        })
    },
    [handleGroupDetailsChange]
  )

  const debouncedAddressQuery = useDebounce(addressQuery, 300)

  useEffect(() => {
    const getAddressList = async () => {
      if (
        debouncedAddressQuery === null ||
        debouncedAddressQuery.length < MIN_LENGTH
      ) {
        setSuggestedAddresses(null)
        setAddress(null)
        return
      }

      if (debouncedAddressQuery === address?.formattedAddress) {
        // if the address query is the same as the selected address, don't make a request again
        return
      }

      setLoading(true)

      await getAddressSuggestions(debouncedAddressQuery)
        .then((res) => {
          setLoading(false)
          setSuggestedAddresses(res.suggest)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setPsmaError(true)
          setPsmaErrorMessage(
            'Something went wrong with getting the address suggestions list, please try again later'
          )
          setSuggestedAddresses(null)
        })
    }
    getAddressList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddressQuery])

  useEffect(() => {
    if (address) {
      console.log('selected address to mrtm:', address)
      // set the address query shown in Input field to the PSMA suggested address
      setAddressQuery(address.formattedAddress)
    }
  }, [address])

  const handleClick = (user) => {
    console.log('user/details ')
    history.push({
      pathname: '/admin/user/details',
      state: {
        authUserId: user.authUserId,
        authRole: user.authRole,
        authRoleId: user.authRoleId,
        groupName: user.costCtrDescr,
        status: user.status,
        emailVerified: user.userDetails.emailVerified
      }
    })
  }

  const EmailValid = isEmailValid(groupDetails?.groupDetails?.email)
  const PhoneValid = isPhoneNumberValid(
    groupDetails?.groupDetails?.contact?.contactMobile
  )

  // const isEmailValid = useMemo(() => {
  //   const rgx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}$/
  //   return rgx.test(groupDetails?.groupDetails?.email)
  // }, [groupDetails?.groupDetails?.email])

  // const isPhoneValid = useMemo(() => {
  //   const phoneNumberRgx = /^(04)([0-9]{8})$/
  //   return phoneNumberRgx.test(groupDetails?.groupDetails?.contact?.contactMobile)
  // }, [groupDetails?.groupDetails?.contact?.contactMobile])

  const costCtrListToggle = () => {
    setShowCostCtrList((prevState) => !prevState)
  }

  const updateDetails = async () => {
    const updatedGroupDetails = {
      email: groupDetails?.groupDetails.email,
      address: groupDetails?.groupDetails.address,
      contact: groupDetails?.groupDetails.contact
    }
    setLoading(true)
    await updateGroup({
      costCtrId: costCtrId,
      costCtrDescr: groupDetails?.costCtrDescr,
      costCtrType: costCenterType,
      costCtrDetails: updatedGroupDetails
    })
      .then((res) => {
        setLoading(false)
        setSuccess(true)
        setSuccessMessage('User details updated successfully')
        getGroupDetails(costCtrId)
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
      })
  }

  const terminateToggle = () => {
    setTerminate((prevState) => !prevState)
  }

  const handleConfirmTerminate = async () => {
    setLoading(true)
    await updateGroupStatus({
      costCtrId: costCtrId,
      status: 'Disabled'
    })
      .then((res) => {
        setLoading(false)
        // setSuccess(true)
        // setSuccessMessage('User terminated successfully')
        console.log('terminate success')
        terminateToggle()
        getGroupDetails(costCtrId)
        setTerminated(true)
        setStatusFilter('terminated')
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
      })
  }

  const handleAdjustBalance = () => {
    //setAdjustBalance((prevState) => !prevState)
    console.log('/admin/group/balances')
    history.push({
      pathname: '/admin/group/balances',
      state: {
        costCtrId: costCtrId,
        credit: groupDetails.balances.credit
      }
    })
  }

  // Define the styles for active and inactive tabs
  const tabStyle = {
    height: '45px',
    backgroundColor: '#E6E7EB', // Set your desired color for inactive tabs
    color: '#2269A1', // Set the text color for inactive tabs
    cursor: 'pointer', // Set the pointer cursor for inactive tabs
    display: 'flex', // Use flex display
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    borderRadius: '8px 8px 0px 0px', // Rounded corners for the top left and right corners
    borderBottom: 'none' // Disable the bottom border
  }

  const activeTabStyle = {
    height: '45px',
    backgroundColor: '#2269A1', // Set your desired color for the active ta
    color: '#FFFFFF', // Set the text color for the active tab
    cursor: 'default', // Disable pointer cursor for the active tab
    display: 'flex', // Use flex display
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    borderRadius: '8px 8px 0px 0px', // Rounded corners for the top left and right corners
    borderBottom: 'none' // Disable the bottom border
  }

  const switchTabs = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const captalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  return (
    <>
      {groupDetails && (
        <>
          <SimpleHeader name={`View / Manage ${groupDetails.costCtrDescr}`} />

          <Container fluid>
            <Nav
              className="nav-fill flex-column flex-sm-row letter-4"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  style={
                    activeTab === 'DealerDetails' ? activeTabStyle : tabStyle
                  }
                  aria-selected={activeTab === 'DealerDetails'}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: activeTab === 'DealerDetails'
                  })}
                  onClick={() => switchTabs('DealerDetails')}
                  role="tab"
                >
                  {captalizeFirstLetter(groupDetails?.costCtrType)} Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={
                    activeTab === 'AuthorisedUsers' ? activeTabStyle : tabStyle
                  }
                  aria-selected={activeTab === 'AuthorisedUsers'}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: activeTab === 'AuthorisedUsers'
                  })}
                  onClick={() => switchTabs('AuthorisedUsers')}
                >
                  Authorised Users
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={
                    activeTab === 'TransactionHistory'
                      ? activeTabStyle
                      : tabStyle
                  }
                  aria-selected={activeTab === 'TransactionHistory'}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: activeTab === 'TransactionHistory'
                  })}
                  onClick={() => switchTabs('TransactionHistory')}
                >
                  Transaction History
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="DealerDetails">
                <Card style={{ borderRadius: '0px 0px 8px 8px' }}>
                  <CardBody className="text-primary">
                    <Form>
                      <div className="pl-lg-4">
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>Dealer Name</h>
                          </Col>
                          {editable ? (
                            <Col md={6}>
                              <Input
                                id="input-cost-center"
                                placeholder="Dealer"
                                type="input"
                                autoComplete="off"
                                required={true}
                                value={groupDetails.costCtrDescr}
                                invalid={!groupDetails.costCtrDescr}
                                onChange={(e) => {
                                  setError(false)
                                  setErrorMessage('')
                                  handleGroupDetailsChange(
                                    'costCtrDescr',
                                    e.target.value
                                  )
                                }}
                              />
                              <div className="invalid-feedback">
                                Please enter a {costCenterType} name
                              </div>
                            </Col>
                          ) : (
                            <Col className="d-flex align-items-center" md={10}>
                              <h>{groupDetails.costCtrDescr}</h>
                              <Col className="text-right" xs="2">
                                {statusFlag()}
                              </Col>
                            </Col>
                          )}
                        </Row>
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>Credit Balance</h>
                          </Col>
                          <Col className="d-flex align-items-center" md={10}>
                            <h>$ {groupDetails.balances.credit}</h>
                          </Col>
                        </Row>
                        {editable ? (
                          <FormGroup row>
                            <Label
                              className="letter-4"
                              htmlFor="select-plan"
                              md={2}
                            >
                              Address Search
                            </Label>
                            <Col md={6}>
                              <Dropdown
                                style={{ width: '100%' }}
                                isOpen={showAddressList}
                                toggle={addressToggle}
                              >
                                <DropdownToggle tag="a">
                                  <Input
                                    id="input-address"
                                    placeholder="Address Search"
                                    type="input"
                                    required={true}
                                    autoComplete="off"
                                    value={addressQuery}
                                    invalid={
                                      !!addressQuery && !address && !psmaError
                                    }
                                    onChange={(e) => {
                                      console.log(
                                        'address change',
                                        e.target.value
                                      )
                                      setPsmaError(false)
                                      setPsmaErrorMessage('')
                                      setAddressQuery(e.target.value)
                                    }}
                                  />
                                  <div className="invalid-feedback">
                                    Please choose an address from the list
                                  </div>
                                  {psmaError && psmaErrorMessage && (
                                    <div className="error-message">
                                      {psmaErrorMessage}
                                    </div>
                                  )}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {suggestedAddresses ? (
                                    suggestedAddresses.length > 0 ? (
                                      suggestedAddresses.map((item) => (
                                        <DropdownItem
                                          key={item.id}
                                          onClick={(e) => {
                                            setError(false)
                                            setErrorMessage('')
                                            selectAddress(item)
                                          }}
                                        >
                                          {item.address}
                                        </DropdownItem>
                                      ))
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <DropdownItem>
                                      Start by typing your address
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </FormGroup>
                        ) : (
                          <></>
                        )}
                        {!editable ? (
                          <Row className="mb-4 align-items-center">
                            <Col md="2">
                              <h>Address</h>
                            </Col>
                            <Col className="d-flex align-items-center" md={10}>
                              <h>
                                {groupDetails.groupDetails.address.unitNumber}{' '}
                                {groupDetails.groupDetails.address.streetNumber}{' '}
                                {groupDetails.groupDetails.address.streetName}{' '}
                                {groupDetails.groupDetails.address.streetType}{' '}
                                {groupDetails.groupDetails.address.suburb}{' '}
                                {groupDetails.groupDetails.address.state}{' '}
                                {groupDetails.groupDetails.address.postcode}
                              </h>
                            </Col>
                          </Row>
                        ) : (
                          <>
                            <FormGroup row>
                              <Label
                                className="letter-4"
                                htmlFor="select-plan"
                                md={2}
                              >
                                Shop/Unit/Level
                              </Label>
                              <Col md="6">
                                <Input
                                  placeholder="Shop/Unit/Level"
                                  value={
                                    groupDetails.groupDetails.address.unitNumber
                                      ? groupDetails.groupDetails.address
                                          .unitNumber
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setError(false)
                                    setErrorMessage('')
                                    handleGroupDetailsChange(
                                      'groupDetails.address.unitNumber',
                                      e.target.value
                                    )
                                  }}
                                ></Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="letter-4"
                                htmlFor="select-plan"
                                md={2}
                              >
                                Street Number
                              </Label>
                              <Col md="6">
                                <Input
                                  placeholder="Street Number"
                                  value={
                                    groupDetails.groupDetails.address
                                      .streetNumber
                                      ? groupDetails.groupDetails.address
                                          .streetNumber
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setError(false)
                                    setErrorMessage('')
                                    handleGroupDetailsChange(
                                      'groupDetails.address.streetNumber',
                                      e.target.value
                                    )
                                  }}
                                ></Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="letter-4"
                                htmlFor="select-plan"
                                md={2}
                              >
                                Street Name
                              </Label>
                              <Col md="6">
                                <Input
                                  placeholder="Street Name"
                                  value={
                                    groupDetails.groupDetails.address.streetName
                                      ? groupDetails.groupDetails.address
                                          .streetName
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setError(false)
                                    setErrorMessage('')
                                    handleGroupDetailsChange(
                                      'groupDetails.address.streetName',
                                      e.target.value
                                    )
                                  }}
                                ></Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="letter-4"
                                htmlFor="select-plan"
                                md={2}
                              >
                                Street Type
                              </Label>
                              <Col md="6">
                                <Input
                                  placeholder="Street Type"
                                  value={
                                    groupDetails.groupDetails.address.streetType
                                      ? groupDetails.groupDetails.address
                                          .streetType
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setError(false)
                                    setErrorMessage('')
                                    handleGroupDetailsChange(
                                      'groupDetails.address.streetType',
                                      e.target.value
                                    )
                                  }}
                                ></Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="letter-4" md={2}>
                                Suburb
                              </Label>
                              <Col md="6">
                                <Input
                                  placeholder="Suburb"
                                  value={
                                    groupDetails.groupDetails.address.suburb
                                      ? groupDetails.groupDetails.address.suburb
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setError(false)
                                    setErrorMessage('')
                                    handleGroupDetailsChange(
                                      'groupDetails.address.suburb',
                                      e.target.value
                                    )
                                  }}
                                ></Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label className="letter-4" md={2}>
                                State/Postcode
                              </Label>
                              <Col md="3">
                                <Input
                                  placeholder="State"
                                  value={
                                    groupDetails.groupDetails.address.state
                                      ? groupDetails.groupDetails.address.state
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setError(false)
                                    setErrorMessage('')
                                    handleGroupDetailsChange(
                                      'groupDetails.address.state',
                                      e.target.value
                                    )
                                  }}
                                ></Input>
                              </Col>
                              <Col md="3">
                                <Input
                                  placeholder="Postcode"
                                  value={
                                    groupDetails.groupDetails.address.postcode
                                      ? groupDetails.groupDetails.address
                                          .postcode
                                      : ''
                                  }
                                  onChange={(e) => {
                                    setError(false)
                                    setErrorMessage('')
                                    handleGroupDetailsChange(
                                      'groupDetails.address.postcode',
                                      e.target.value
                                    )
                                  }}
                                ></Input>
                              </Col>
                            </FormGroup>
                          </>
                        )}
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>Email</h>
                          </Col>
                          {editable ? (
                            <Col md="6">
                              <Input
                                placeholder="Email"
                                value={
                                  groupDetails.groupDetails.email
                                    ? groupDetails.groupDetails.email
                                    : ''
                                }
                                onChange={(e) => {
                                  setError(false)
                                  setErrorMessage('')
                                  handleGroupDetailsChange(
                                    'groupDetails.email',
                                    e.target.value
                                  )
                                }}
                                invalid={
                                  !EmailValid &&
                                  !!groupDetails?.groupDetails?.email
                                }
                              ></Input>
                              <div className="invalid-feedback">
                                Please enter a valid email address
                              </div>
                            </Col>
                          ) : (
                            <Col className="d-flex align-items-center" md={10}>
                              <h>{groupDetails.groupDetails.email}</h>
                            </Col>
                          )}
                        </Row>
                        <Row className="mb-4 align-items-center">
                          <Col md="2">
                            <h>Phone Number</h>
                          </Col>
                          {editable ? (
                            <Col md="6">
                              <Input
                                placeholder="Phone Number"
                                value={
                                  groupDetails.groupDetails.contact
                                    .contactMobile
                                    ? groupDetails.groupDetails.contact
                                        .contactMobile
                                    : ''
                                }
                                onChange={(e) => {
                                  setError(false)
                                  setErrorMessage('')
                                  handleGroupDetailsChange(
                                    'groupDetails.contact.contactMobile',
                                    e.target.value
                                  )
                                }}
                                invalid={
                                  !PhoneValid &&
                                  !!groupDetails?.groupDetails?.contact
                                    ?.contactMobile
                                }
                                maxLength={10}
                              ></Input>
                              <div className="invalid-feedback">
                                Please enter a valid phone number
                              </div>
                            </Col>
                          ) : (
                            <Col className="d-flex align-items-center" md={10}>
                              <h>
                                {
                                  groupDetails.groupDetails.contact
                                    .contactMobile
                                }
                              </h>
                            </Col>
                          )}
                        </Row>
                        <FormGroup row>
                          {!editable ? (
                              <Col className="d-flex justify-content-start">
                                <Button
                                  className="btn-icon btn-3"
                                  type="button"
                                  disabled={(groupDetails?.status==='D' || terminated)}
                                  style={(groupDetails?.status==='D' || terminated) ? {backgroundColor: 'lightgray'} : null}
                                  onClick={editToggle}
                                >
                                  <span className="btn-inner--icon"></span>
                                  <span className="btn-inner-center-text">
                                    Edit
                                  </span>
                                </Button>
                                <Button
                                  className="btn-icon btn-3"
                                  type="button"
                                  disabled={(groupDetails?.status==='D' || terminated)}
                                  style={(groupDetails?.status==='D' || terminated) ? {backgroundColor: 'lightgray'} : null}
                                  onClick={handleAdjustBalance}
                                >
                                  <span className="btn-inner--icon"></span>
                                  <span className="btn-inner-center-text">
                                    Adjust Balance
                                  </span>
                                </Button>
                                <Button
                                  className="delete-btn btn-3"
                                  type="button"
                                  disabled={(groupDetails?.status==='D' || terminated)}
                                  style={(groupDetails?.status==='D' || terminated) ? {backgroundColor: 'lightgray'} : null}
                                  onClick={terminateToggle}
                                >
                                  <span className="btn-inner--icon"></span>
                                  <span className="btn-inner-center-text">
                                    Terminate
                                  </span>
                                </Button>
                                {terminateToggle ? (
                                  <Modal
                                    isOpen={terminate}
                                    toggle={terminateToggle}
                                    className="modal-dialog-centered"
                                  >
                                    <ModalBody>
                                      <p>
                                        Are you sure you want to terminate{' '}
                                        {groupDetails.costCtrDescr}?
                                      </p>
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button
                                        color="secondary"
                                        onClick={handleConfirmTerminate}
                                      >
                                        Confirm
                                      </Button>
                                      <Button
                                        color="primary"
                                        onClick={terminateToggle}
                                      >
                                        Cancel
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                ) : null}
                              </Col>
                            ) : (
                              <Col className="d-flex justify-content-start">
                                <Button
                                  className="btn-icon btn-3"
                                  color="light"
                                  type="button"
                                  style={{ border: '1px solid' }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setGroupDetails(originalGroupDetails)
                                    editToggle()
                                  }}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-times" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Cancel
                                  </span>
                                </Button>
                                <Button
                                  className="btn-icon btn-3"
                                  color="primary"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    updateDetails()
                                    editToggle()
                                    setAddressQuery('')
                                    setOriginalGroupDetails(groupDetails)
                                  }}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fa fa-check" />
                                  </span>
                                  <span className="btn-inner--text">Save</span>
                                </Button>
                              </Col>
                          )}
                        </FormGroup>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="AuthorisedUsers">
                <Card style={{ borderRadius: '0px 0px 8px 8px' }}>
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col md="4">
                        <RecordsDropdown
                          dropdownOpen={recordsDropdownOpen}
                          toggle={toggle}
                          setRowsPerPage={changeRowsPerPage}
                          rowsPerPage={rowsPerPage}
                        ></RecordsDropdown>
                      </Col>
                      <span className="text-primary">Filter by:</span>
                      <Dropdown
                        isOpen={statusDropdownOpen}
                        toggle={() =>
                          setStatusDropdownOpen(!statusDropdownOpen)
                        }
                        className="ml-1"
                      >
                        <DropdownToggle color="light" caret>
                          {statusFilter === 'All'
                            ? 'User Status'
                            : statusFilter}
                        </DropdownToggle>
                        <DropdownMenu className="text-primary">
                          <DropdownItem onClick={() => setStatusFilter('All')}>
                            All
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => setStatusFilter('Active')}
                          >
                            Active
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => setStatusFilter('Terminated')}
                          >
                            Terminated
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      {/* <Col lg="3 ml-auto">
                    <Input
                      className="py-1"
                      placeHolder="search by dealer name"
                      type="search"
                      onKeyPress={(event) => {
                        if (/[^a-zA-Z0-9_-\s]/gi.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        setPageOffset(0)
                        setSearchFilter(e.target.value)
                      }}
                    />
                  </Col> */}
                      {JSON.parse(groupFeatures?.canAddGroup) && (
                        <Col className="text-right ml-auto">
                          <Button
                            color="light"
                            className="text-primary"
                            onClick={(e) => {
                              e.preventDefault()
                              history.push({
                                pathname: '/admin/user/add',
                                state: {
                                  costCenterType: groupDetails?.costCtrType,
                                  groupName: groupDetails?.costCtrDescr,
                                  costCtrId: costCtrId
                                }
                              })
                            }}
                          >
                            {Strings['User'].MG_Add_Button}
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </CardHeader>
                  <div className="table-responsive">
                    <Table
                      hover
                      className="align-items-center table-flush text-primary"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            className="sort"
                            data-sort="authRole"
                            scope="col"
                            onClick={() => setSort('authRole')}
                          >
                            ROLE
                          </th>
                          <th>EMAIL</th>
                          <th
                            className="sort"
                            data-sort="lastLogin"
                            scope="col"
                            onClick={() => setSort('lastLogin')}
                          >
                            LAST-LOGIN
                          </th>
                          <th>PHONE</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {userList &&
                          userList.map((user) => {
                            return (
                              <tr
                                className="cursor-pointer"
                                key={user.authUserId}
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleClick(user)
                                }}
                              >
                                {/* <td className="costCtrDescr">{user.costCtrDescr}</td> */}
                                <td className="authRoleId">{user.authRole}</td>
                                <td className="username">{user.username}</td>
                                <td className="lastLogin">{user.lastLogin}</td>
                                <td className="contactMobile">
                                  {user.userDetails?.contact?.contactMobile}
                                </td>
                                <td className="status">
                                  {user.status
                                    ? statusArray.find(
                                        (i) => i.status === user.status
                                      )?.label
                                    : 'Unknown'}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </Table>
                    {loading && (
                      <div className="d-flex justify-content-center">
                        <Spinner>Loading...</Spinner>
                      </div>
                    )}
                    {error && (
                      <div className="d-flex justify-content-center">
                        <span className="error-message">{errorMessage}</span>
                      </div>
                    )}
                  </div>
                  {!error && !loading && (
                    <CardFooter className="py-4 flexbox-container">
                      <Row className="d-flex align-items-center">
                        <Col xs="12" md="4" xl="3" xxl="8">
                          {recordFooter()}
                        </Col>
                        <Col sm="6" md="4" xl="9" xxl="2">
                          <nav aria-label="...">
                            <PaginationService
                              setPageOffset={setPageOffset}
                              pageOffset={pageOffset}
                              lastPage={lastPage}
                            ></PaginationService>
                          </nav>
                        </Col>
                      </Row>
                    </CardFooter>
                  )}
                </Card>
              </TabPane>
              <TabPane tabId="TransactionHistory">
                <DealerTransHistory
                  costCtrId={costCtrId}
                  style={{ borderRadius: '0px 0px 8px 8px' }}
                />
              </TabPane>
            </TabContent>
          </Container>
        </>
      )}
    </>
  )
}

export default GroupDetails
