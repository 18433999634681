import { Line } from 'react-chartjs-2'
import React, { useCallback } from 'react'
import { colors } from 'variables/charts.js'

import { useState, useEffect } from 'react'

import { Card, CardHeader, CardBody } from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'

function ActivationHistoryLineChart({
  startDate,
  endDate,
  timePeriod,
  costCtrId
}) {
  const [activationHistoryList, setActivationHistoryList] = useState([])
  const { getActivationHistory } = useDashboardContext()

  const loadActivationHistory = useCallback(async () => {
    if (startDate === null || endDate === null) {
      return
    }
    await getActivationHistory({
      startDate,
      endDate,
      timePeriod,
      costCtrId
    })
      .then((response) => {
        setActivationHistoryList(response)
        //console.log('set activation history: ', response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
  }, [getActivationHistory, startDate, endDate, timePeriod, costCtrId])

  const getActivationLable = () => {
    if (timePeriod === 'Day' || timePeriod === 'Week') {
      const list = activationHistoryList.map((data) =>
        new Date(data.Date).toLocaleDateString('es-AU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      )
      return list
    }
    if (timePeriod === 'Month') {
      const list = activationHistoryList.map((data) =>
        new Date(data.Date).toLocaleDateString('en-AU', {
          year: '2-digit',
          month: 'short'
        })
      )
      return list
    }
    if (timePeriod === 'Year') {
      const list = activationHistoryList.map((data) =>
        new Date(data.Date).toLocaleDateString('en-AU', {
          year: 'numeric'
        })
      )
      return list
    }
  }

  const getActivationData = () => {
    const list = activationHistoryList.map((data) => data.Count)

    return list
  }

  useEffect(() => {
    loadActivationHistory()
  }, [loadActivationHistory])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Activation History</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Line
              data={{
                labels: getActivationLable(),
                datasets: [
                  {
                    label: 'number of activation',
                    data: getActivationData(activationHistoryList)
                  }
                ]
              }}
              options={{
                legend: {
                  display: false
                },
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        color: colors.gray[200],
                        zeroLineColor: colors.gray[200]
                      },
                      ticks: {}
                    }
                  ]
                },
                onClick: (e, element) => {
                  console.log('getevent', e)
                  console.log(
                    'getelement',
                    element,
                    activationHistoryList[element[0]._index].Count
                  )
                }
              }}
              className="chart-canvas"
              id="chart-bars"
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ActivationHistoryLineChart
