import { useEffect, useState, useCallback } from 'react'
import { useDashboardContext } from '../../contexts/DashboardContext'
import SummartCard from './SummaryCard'

function RetailSpendCard({ costCtrId }) {
  const [retailSpend, setRetailSpend] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { getTotalRetailSpend } = useDashboardContext()

  const loadRetailSpend = useCallback(async () => {
    setIsLoading(true)
    await getTotalRetailSpend(costCtrId)
      .then((response) => {
        setRetailSpend(response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [getTotalRetailSpend, costCtrId])

  useEffect(() => {
    loadRetailSpend()
  }, [loadRetailSpend])
  return (
    <SummartCard
      title="Retail Spend"
      value={'$ ' + retailSpend}
      isLoading={isLoading}
    />
  )
}

export default RetailSpendCard
