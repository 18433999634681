import { IPsmaHandler } from '../interfaces'
import { ID } from '../models/models'
import { IHttpResponse, IHttpClient } from '../interfaces'
import {
  PSMAAddressResponse,
  PSMAAddressSuggestionsResponse
} from '../models/responses'

const PsmaHandler = (client: IHttpClient): IPsmaHandler => {
  const getAddressSuggestions = async (
    query: string
  ): Promise<IHttpResponse<PSMAAddressSuggestionsResponse>> => {
    return client.GET(
      '/v1/predictive/address',
      {
        addressType: 'mailing',
        dataset: 'gnaf',
        maxNumberOfResults: 10,
        query
      },
      {
        headers: { Authorization: process.env.REACT_APP_PSMA_API_KEY }
      }
    )
  }
  const getAddress = async (
    id: ID
  ): Promise<IHttpResponse<PSMAAddressResponse>> => {
    return client.GET(
      `/v1/predictive/address/${id}`,
      {},
      {
        headers: { Authorization: process.env.REACT_APP_PSMA_API_KEY }
      }
    )
  }
  return {
    getAddressSuggestions,
    getAddress
  }
}

export default PsmaHandler
