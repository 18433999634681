// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import AuthLayout from 'layouts/Auth.js'

export const UnauthenticatedApp = () => {
  console.log('request page in Unauthenticated', window.location.href)
  if (window.location.pathname.split('/')[1] !== 'auth') {
    console.log('save request page')
    sessionStorage.setItem('request_page', window.location.pathname)
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    </BrowserRouter>
  )
}
