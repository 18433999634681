import { Line } from 'react-chartjs-2'
import React, { useCallback, useState, useEffect } from 'react'
import { colors } from 'variables/charts.js'

import { Card, CardHeader, CardBody } from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'

function ServiceHistoryLineChart({
  startDate,
  endDate,
  timePeriod,
  costCtrId
}) {
  const [serviceHistoryList, setServiceHistoryList] = useState([])

  const { getServiceHistory } = useDashboardContext()

  const loadServiceHistory = useCallback(async () => {
    if (startDate === null || endDate === null) {
      return
    }
    await getServiceHistory({
      startDate,
      endDate,
      timePeriod,
      costCtrId
    })
      .then((response) => {
        setServiceHistoryList(response)
        //console.log('set service history: ', response)
      })
      .catch((err) => {
        console.log('error at dashboard: ', err)
      })
  }, [endDate, getServiceHistory, startDate, timePeriod, costCtrId])

  const getServiceLable = () => {
    if (timePeriod === 'Day' || timePeriod === 'Week') {
      const list = serviceHistoryList.map((data) =>
        new Date(data.Date).toLocaleDateString('es-AU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      )
      return list
    }
    if (timePeriod === 'Month') {
      const list = serviceHistoryList.map((data) =>
        new Date(data.Date).toLocaleDateString('en-AU', {
          year: '2-digit',
          month: 'short'
        })
      )
      return list
    }
    if (timePeriod === 'Year') {
      const list = serviceHistoryList.map((data) =>
        new Date(data.Date).toLocaleDateString('en-AU', {
          year: 'numeric'
        })
      )
      return list
    }
  }

  const getServiceData = () => {
    const list = serviceHistoryList.map((data) => data.Count)

    return list
  }

  useEffect(() => {
    loadServiceHistory()
  }, [loadServiceHistory])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Service History</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Line
              data={{
                labels: getServiceLable(),
                datasets: [
                  {
                    label: 'number of service',
                    data: getServiceData()
                  }
                ]
              }}
              options={{
                legend: {
                  display: false
                },
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        color: colors.gray[200],
                        zeroLineColor: colors.gray[200]
                      },
                      ticks: {}
                    }
                  ]
                },
                onClick: (e, element) => {
                  console.log('getevent', e)
                  console.log(
                    'getelement',
                    element[0],
                    element[0]._index,
                    getServiceData()[element[0]._index]
                  )
                }
              }}
              id="chart-sales"
              className="chart-canvas"
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ServiceHistoryLineChart
