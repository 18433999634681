import SimpleHeader from 'components/Headers/SimpleHeader.js'
import React, { useEffect, useState } from 'react'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import { Container, Row } from 'reactstrap'
import TransactionHistoryTable from './TransactionHistoryTable'

function TransactionHistory() {
  const { authCostCtrId } = useAccessControlContext()

  return (
    <>
      <SimpleHeader
        name="Transaction History"
        description="The transaction history"
      ></SimpleHeader>
      <Container fluid>
        <Row>
          <TransactionHistoryTable costCtrId={authCostCtrId} />
        </Row>
      </Container>
    </>
  )
}

export default TransactionHistory
