import { IRouteConfig, IGraph, IFeature } from './models'
import { FeatureResponse } from './responses'

class FeatureConfig {
  features: FeatureResponse
  public static EmptyConstructorParams: FeatureResponse = {
    type: '',
    routes: []
  }
  public static ConstructEmpty(): FeatureConfig {
    return FeatureConfig.Construct(FeatureConfig.EmptyConstructorParams)
  }

  public static Construct(params: FeatureResponse): FeatureConfig {
    return new FeatureConfig(params)
  }

  constructor(params: FeatureResponse) {
    this.features = params
  }

  getFeatureByRouteName(routeName: string): IRouteConfig | undefined {
    return this.features.routes.find(
      (feature: IRouteConfig) => feature.name === routeName
    )
  }

  getDashboardSummary(): IGraph[] {
    const dashboard = this.getFeatureByRouteName('Dashboard')
    console.log('get Dashboard', dashboard)
    return dashboard?.features?.summaries || []
  }

  getServiceListColumns(): String[] {
    const serviceList = this.getFeatureByRouteName('ServiceList')
    return serviceList?.features?.columns || []
  }

  getActivationFlow(): String {
    const activationFlow = this.getFeatureByRouteName('SearchActivate')
    return activationFlow?.features?.activateFlow || ''
  }

  getDashboardCharts(): IGraph[] {
    const dashboard = this.getFeatureByRouteName('Dashboard')
    return dashboard?.features?.charts || []
  }

  getShowCostCtrPicker(): string {
    const dashboard = this.getFeatureByRouteName('Dashboard')
    return dashboard?.features?.showCostCtrPicker || 'false'
  }

  getUserListVisibleType(): String[] {
    const userList = this.getFeatureByRouteName('UsersList')
    return userList?.features?.visibleType || []
  }

  getAddUserVisibleType(): String[] {
    const addUser = this.getFeatureByRouteName('AddUser')
    return addUser?.features?.visibleType || []
  }
}

export default FeatureConfig
