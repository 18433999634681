import React, { useState, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

function SimDetails({ location }) {
  const { getDealerSimDetails, getProductList, updateDealerSimDetails } =
    useDashboardContext()
  const { getSimDetailsFeatures } = useAccessControlContext()
  const [simDetailsFeatures, setSimDetailsFeatures] = useState(
    getSimDetailsFeatures()
  )
  const history = useHistory()
  const [iccid, setIccid] = useState(null)
  const [msisdn, setMsisdn] = useState(null)
  const [costCtrName, setCostCtrName] = useState(null)
  const [costCtrType, setCostCtrType] = useState(null)

  const [curStatusId, setCurStatusId] = useState(null)
  const [curStatus, setCurStatus] = useState(null)
  const [targetStatus, setTargetStatus] = useState(null)
  const [targetStatusId, setTargetStatusId] = useState(null)
  const [showStatusList, setShowStatusList] = useState(false)

  const [curPlanCosId, setCurPlanCosId] = useState(null)
  const [curPlanName, setCurPlanName] = useState(null)
  const [targetPlanCosId, setTargetPlanCosId] = useState(null)
  const [targetPlanName, setTargetPlanName] = useState(null)
  const [planList, setPlanList] = useState([])
  const [showPlanList, setShowPlanList] = useState(false)
  const [editing, setEditing] = useState(false)
  const [editable, setEditable] = useState(true)

  // This could be buggy if there are changes statusId-to-statusDescr mapping.
  // The current design is based on the following mapping in status table:
  // {1: 'Loaded', 2: 'Linked', 3: 'Exported', 4: 'Activated', 5: 'Assigned', 6: 'Disabled', 7: 'Deactivated', 8: 'Expired'}
  // in the web view, we show 2 as Loaded, 4 as Available, 5 as Used, 6 as Hold, 7 as Terminated, others won't show
  const [allowedTargetStatusList, setAllowedTargetStatusList] = useState([])
  const [statusList, setStatusList] = useState([
    '',
    'Loaded',
    '',
    'Available',
    'Used',
    'Hold',
    'Terminated',
    ''
  ])

  // Only execute once when clicking on one record on the inventory page
  useEffect(() => {
    setIccid(location.state.sim)
    setMsisdn(location.state.msisdn)
    setCostCtrName(location.state.costCtrName)
    // if there is orderId!==null or dnaStatus==='Reserved' in location.state which means the sim is reserved,
    // we don't allow them to edit the sim details
    if (
      location.state?.orderId !== null ||
      location.state?.dnaStatus === 'Reserved'
    ) {
      setCurStatus('Reserved')
      setTargetStatus('Reserved')
      setEditable(false)
    }
  })

  // Only execute once when entering the sim details page
  useEffect(() => {
    if (iccid !== null && iccid !== undefined) {
      getDealerSimStatus()
    }
  }, [iccid])

  const captalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const getDealerSimStatus = async () => {
    // if the sim is reserved, we don't need to get the status from the backend
    if (
      location.state?.orderId !== null ||
      location.state?.dnaStatus === 'Reserved'
    ) {
      return
    }

    await getDealerSimDetails({
      // iccidFilter is the last 10 digits of the iccid
      iccidFilter: iccid.slice(-10)
    })
      .then((res) => {
        setCurStatus(res.statusDescr)
        setCurStatusId(res.statusId.toString())
        setTargetStatusId(res.statusId.toString())
        setTargetStatus(res.statusDescr)
        setCurPlanCosId(res.cosId)
        setCurPlanName(res.planName)
        setTargetPlanCosId(res.cosId)
        setTargetPlanName(res.planName)
        setCostCtrType(captalizeFirstLetter(res.costCtrType))
      })
      .catch((err) => {
        console.log('error happened during getting dealerSimDetails():', err)
      })
  }

  // when the current status is in [1,2,3,4,6], we allow them to change the status to [2,4,6,7]
  // when the status is in [5,7], we don't allow them to change the status
  // status 8 will not happen
  useEffect(() => {
    if (['1', '2', '3', '4', '6'].includes(curStatusId)) {
      setAllowedTargetStatusList(['Loaded', 'Available', 'Hold', 'Terminated'])
    } else if (['5', '7'].includes(curStatusId)) {
      setAllowedTargetStatusList([])
    }
  }, [curStatus, curStatusId])

  const getTargetStatusId = (status) => {
    return (statusList.indexOf(status) + 1).toString()
  }

  const getProducts = async () => {
    var productType = 'Plan'
    var webAvailable = 'No'
    if (
      curPlanName !== null &&
      curPlanName !== undefined &&
      curPlanName === 'PAYG Plan'
    ) {
      webAvailable = 'Yes'
    }

    await getProductList({
      productType: productType,
      webAvailable: webAvailable
    }).then((res) => {
      setPlanList(res)
    })
  }

  const updateSimDetails = async () => {
    await updateDealerSimDetails({
      serialNumber: iccid,
      cosId: targetPlanCosId,
      statusId: targetStatusId
    })
      .then((res) => {
        setCurStatusId(targetStatusId)
        setCurStatus(targetStatus)
        setCurPlanCosId(targetPlanCosId)
        setCurPlanName(targetPlanName)
        setEditing((editing) => !editing)
        alert('Sim details updated successfully')
      })
      .catch((err) => {
        alert('Failed to update sim details, err:' + err)
        handleCancel()
      })
  }

  // The trigger function behind the edit button
  const handleEdit = () => {
    console.log('edit')
    setEditing((editing) => !editing)
  }

  // The trigger function behind the activate button
  const handleActivate = () => {
    console.log('activate')
    // jump to the activation page, auto-fill the msisdn
    history.push({
      pathname: '/admin/search/activate',
      state: {
        iccid: iccid.slice(-10)
      }
    })
  }

  // trigger function behind the cancel button
  const handleCancel = () => {
    console.log('cancel')
    setEditing((editing) => !editing)
    setTargetStatusId(curStatusId)
    setTargetStatus(curStatus)
    setTargetPlanCosId(curPlanCosId)
    setTargetPlanName(curPlanName)
  }

  // trigger function behind the save button
  const handleSave = () => {
    console.log('save')
    updateSimDetails()
  }

  return (
    <>
      <SimpleHeader name="Sim Details" />
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Edit or activate this SIM
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary ml-3">
            <Form>
              <FormGroup row>
                <Label className="letter-4" htmlFor="input-nickname" md={2}>
                  ICCID
                </Label>
                <Col className="d-flex align-items-center" md={10}>
                  <h>{iccid}</h>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label className="letter-4" htmlFor="input-nickname" md={2}>
                  MSISDN
                </Label>
                <Col className="d-flex align-items-center" md={10}>
                  <h>{msisdn}</h>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label className="letter-4" htmlFor="input-nickname" md={2}>
                  Status
                </Label>

                {!editing ? (
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{curStatus ? targetStatus : 'Select a status'}</h>
                  </Col>
                ) : (
                  <Dropdown
                    isOpen={showStatusList}
                    disabled={!editing}
                    toggle={() => setShowStatusList(!showStatusList)}
                  >
                    <DropdownToggle color="light" caret>
                      {curStatus ? targetStatus : 'Select a status'}
                    </DropdownToggle>
                    <DropdownMenu>
                      {allowedTargetStatusList.map((status) => {
                        return (
                          <DropdownItem
                            key={status}
                            onClick={() => {
                              setTargetStatus(status)
                              setTargetStatusId(getTargetStatusId(status))
                            }}
                          >
                            {status}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </FormGroup>

              {editable ? (
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Plan Name
                  </Label>

                  {!editing ? (
                    <Col className="d-flex align-items-center" md={10}>
                      <h>{curPlanName ? targetPlanName : 'Select a plan'}</h>
                    </Col>
                  ) : (
                    <Dropdown
                      isOpen={showPlanList}
                      disabled={!editing}
                      toggle={() => {
                        setShowPlanList(!showPlanList)
                        getProducts()
                      }}
                    >
                      <DropdownToggle color="light" caret>
                        {curPlanName ? targetPlanName : 'Select a plan'}
                      </DropdownToggle>
                      <DropdownMenu>
                        {planList.map((plan) => {
                          return (
                            <DropdownItem
                              key={plan.cosId}
                              onClick={() => {
                                setTargetPlanCosId(plan.cosId)
                                setTargetPlanName(plan.planName)
                              }}
                            >
                              {plan.planName}
                            </DropdownItem>
                          )
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </FormGroup>
              ) : null}

              {location.state?.orderId !== null ? (
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    {'Order ID'}
                  </Label>
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{location.state?.orderId}</h>
                  </Col>
                </FormGroup>
              ) : null}

              {editable ? (
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    {costCtrType}
                  </Label>
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{costCtrName}</h>
                  </Col>
                </FormGroup>
              ) : (
                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    {'Reason'}
                  </Label>
                  <Col className="d-flex align-items-center" md={10}>
                    <h>
                      {location.state?.orderId !== null
                        ? 'On hold by an active order'
                        : 'On hold by a dealer activation'}
                    </h>
                  </Col>
                </FormGroup>
              )}

              <Row className="mt-3 ml-1">
                {editing ? (
                  <>
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        handleCancel()
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="bg-red"
                      onClick={(e) => {
                        e.preventDefault()
                        handleSave()
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="edit"
                      disabled={!editable}
                      style={editable ? {} : { backgroundColor: 'lightgray' }}
                      onClick={(e) => {
                        e.preventDefault()
                        handleEdit()
                      }}
                    >
                      Edit
                    </Button>
                    {JSON.parse(simDetailsFeatures?.showActivateSvcButton) ? (
                      <Button
                        type="activate"
                        style={
                          curStatusId === '4'
                            ? {}
                            : { backgroundColor: 'lightgray' }
                        }
                        disabled={!(curStatusId === '4')}
                        onClick={(e) => {
                          e.preventDefault()
                          handleActivate()
                        }}
                      >
                        Activate Service
                      </Button>
                    ) : null}
                  </>
                )}
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default SimDetails
