import { Bar } from 'react-chartjs-2'
import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { colors } from 'variables/charts.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'
function ActivationsByCostCtrChart({ startDate, endDate, timePeriod, costCtrId }) {
  const [activationsByCostCtrList, setActivationsByCostCtrList] = useState([])
  const { getActivationsByCostCtr } = useDashboardContext()

  const history = useHistory()

  const loadActivationsByCostCtr = useCallback(async () => {
    await getActivationsByCostCtr({
      pageOffset: 0,
      rowsPerPage: 10,
      sortOrder: 'desc',
      startDate,
      endDate,
      timePeriod
    })
      .then((response) => {
        setActivationsByCostCtrList(response.results)
        //console.log('set costCtr activation count: ', response)
      })
      .catch((err) => {
        console.log('error at loadActivationsByCostCtr: ', err)
      })
  }, [getActivationsByCostCtr, startDate, endDate, timePeriod])

  const getCostCtrLable = () => {
    const list = activationsByCostCtrList.map((data) => data.costCtrDescr)

    return list
  }

  const getActivationCountData = () => {
    const list = activationsByCostCtrList.map((data) => data.count)

    return list
  }

  useEffect(() => {
    loadActivationsByCostCtr()
  }, [loadActivationsByCostCtr])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Dealer Activation Count</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
              <Bar
                data={{
                  labels: getCostCtrLable(),
                  datasets: [
                    {
                      label: 'Activation Count',
                      data: getActivationCountData(activationsByCostCtrList)
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {
                          // beginAtZero: true
                        }
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element[0],
                      element[0]._index,
                      activationsByCostCtrList[element[0]._index],
                      'costCtrId: ' +
                      activationsByCostCtrList[element[0]._index].costCtrId
                    )
                    history.push({
                      pathname: '/admin/services',
                      state: {
                        costCtrId: activationsByCostCtrList[element[0]._index].costCtrId
                      }
                    })
                  }
                }}
                id="chart-activations-by-costCtr-count"
                className="chart-canvas"
              />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ActivationsByCostCtrChart
