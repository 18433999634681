import { useAuthContext } from './contexts/AuthContext'
import { AuthenticatedApp } from './authenticated-app'
import { UnauthenticatedApp } from './unauthenticated-app'
import { useEffect } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css'

function App() {
  const { isLogged, rememberMe } = useAuthContext()
  useEffect(() => {
    console.log('isLogged:', isLogged)
  }, [isLogged])

  window.onbeforeunload = function () {
    if (!rememberMe) {
      sessionStorage.removeItem('__auth_provider_token__')
    }
  }

  return (
    <div className="App">
      {isLogged ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </div>
    // <BrowserRouter>
    //   <Compose components={[AuthContextProvider]}>
    //     <Switch>
    //       <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
    //       <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
    //       <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
    //       <Route path="/" render={(props) => <IndexView {...props} />} />
    //       <Redirect from="*" to="/" />
    //     </Switch>
    //   </Compose>
    // </BrowserRouter>
  )
}

export default App
