import { Bar, Line } from 'react-chartjs-2'
import React, { useCallback, useState, useEffect } from 'react'
import { colors } from 'variables/charts.js'

import { Card, CardHeader, CardBody } from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useHistory } from 'react-router-dom'

function PreActiveSimsChart(props) {
  const { startDate, endDate, timePeriod, costCtrId } = props
  const [preActiveSimsList, setPreActiveSimsList] = useState([])
  const [subtenantPreActiveSimsList, setSubtenantPreActiveSimsList] = useState(
    []
  )
  const history = useHistory()
  const { getPreActiveSimsByCostCtr, getSubtenantPreActiveSimsHistory } =
    useDashboardContext()

  const loadPreActiveSimsList = useCallback(async () => {
    await getPreActiveSimsByCostCtr()
      .then((response) => {
        setPreActiveSimsList(response.results)
        //console.log('set preActive sims list: ', response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
  }, [getPreActiveSimsByCostCtr])

  const loadSubtenantPreActiveSimsList = useCallback(async () => {
    await getSubtenantPreActiveSimsHistory({
      startDate,
      endDate,
      timePeriod,
      costCtrId
    })
      .then((response) => {
        setSubtenantPreActiveSimsList(response)
        //console.log('set subtenant preActive sims list: ', response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
  }, [
    endDate,
    getSubtenantPreActiveSimsHistory,
    startDate,
    timePeriod,
    costCtrId
  ])

  const getCostCtrLable = () => {
    const list = preActiveSimsList.map((data) => data.costCtrDescr)
    return list
  }

  const getCostCtrData = () => {
    const list = preActiveSimsList.map((data) => data.count)

    return list
  }

  const getDateLable = () => {
    if (timePeriod === 'Day' || timePeriod === 'Week') {
      const list = subtenantPreActiveSimsList.map((data) =>
        new Date(data.date).toLocaleDateString('es-AU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      )
      return list
    }
    if (timePeriod === 'Month') {
      const list = subtenantPreActiveSimsList.map((data) =>
        new Date(data.date).toLocaleDateString('en-AU', {
          year: '2-digit',
          month: 'short'
        })
      )
      return list
    }
    if (timePeriod === 'Year') {
      const list = subtenantPreActiveSimsList.map((data) =>
        new Date(data.date).toLocaleDateString('en-AU', {
          year: 'numeric'
        })
      )
      return list
    }
  }

  const getCountData = () => {
    const list = subtenantPreActiveSimsList.map((data) => data.count)

    return list
  }

  useEffect(() => {
    if (costCtrId) {
      loadSubtenantPreActiveSimsList()
    } else {
      loadPreActiveSimsList()
    }
  }, [loadPreActiveSimsList, loadSubtenantPreActiveSimsList, costCtrId])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Preactive Sims</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {costCtrId ? (
              <Line
                data={{
                  labels: getDateLable(),
                  datasets: [
                    {
                      label: 'number of preactive sims',
                      data: getCountData()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {}
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element[0],
                      element[0]._index,
                      getCountData()[element[0]._index]
                    )
                  }
                }}
                id="chart-no-charge-subtenant"
                className="chart-canvas"
              />
            ) : (
              <Bar
                data={{
                  labels: getCostCtrLable(),
                  datasets: [
                    {
                      label: 'number of activation',
                      data: getCostCtrData()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {}
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element,
                      preActiveSimsList[element[0]._index].count,
                      'costCtrId',
                      preActiveSimsList[element[0]._index].costCtrId,
                      'costCtrDescr',
                      preActiveSimsList[element[0]._index].costCtrDescr
                    )
                    history.push({
                      pathname: '/admin/inventory',
                      state: {
                        costCtrId:
                          preActiveSimsList[element[0]._index].costCtrId
                      }
                    })
                  }
                }}
                className="chart-canvas"
                id="chart-bars"
              />
            )}
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default PreActiveSimsChart
