import { CostCenterEnum } from 'enums/enums'

const Strings = {
  // General Strings
  // Pages
  // Groups Page
  MG_Card_Title: 'Overview',
  MG_Tb_Header_Services: 'Services',

  // ServiceList Page
  SL_Title: 'My Services',
  SL_SubTitle: 'List of all services',

  [CostCenterEnum.Subtenant]: {
    // Sidebar
    Sidebar_Group: 'Sub-Tenants',
    // Pages
    // Groups Page
    MG_Title: 'My Sub-Tenants',
    MG_SubTitle: 'Manage my sub-Tenants',
    MG_Tb_Header_Group: 'SUB-TENANT',
    MG_Add_Button: 'Add Sub-Tenant',

    // ServiceList Page
    SL_Group_Filter: 'Sub-Tenant',
    SL_Tb_Header_Group: 'SUB-TENANT',

    //View/Manage Service Page
    MS_Group: 'Sub-Tenant',

    //Activate SIM Page
    AS_Group: 'Sub-Tenant name',

    //Inventory Page
    I_Group_Filter: 'Sub-Tenant'
  },
  [CostCenterEnum.Department]: {
    // Sidebar
    Sidebar_Group: 'Departments',
    // Pages
    // Groups Page
    MG_Title: 'Company Departments',
    MG_SubTitle: 'Manage company departments',
    MG_Tb_Header_Group: 'DEPARTMENT',
    MG_Add_Button: 'Add Department',

    // ServiceList Page
    SL_Filter: 'Filter by Department:',
    SL_Tb_Header_Group: 'DEPARTMENT',

    //View/Manage Service Page
    MS_Group: 'Department',

    //Activate SIM Page
    AS_Group: 'Department name',

    //Inventory Page
    I_Group_Filter: 'Department'
  },
  [CostCenterEnum.Dealer]: {
    // Sidebar
    Sidebar_Group: 'My Dealers',
    // Pages
    // Groups Page
    MG_Title: 'My Dealers',
    MG_SubTitle: 'Manage my dealers',
    MG_Tb_Header_Group: 'DEALER',
    MG_Add_Button: 'Add Dealer',

    // ServiceList Page
    SL_Filter: 'Filter by Dealer:',
    SL_Tb_Header_Group: 'DEALER',

    //View/Manage Service Page
    MS_Group: 'Dealer',

    //Activate SIM Page
    AS_Group: 'Dealer name',

    //Inventory Page
    I_Group_Filter: 'Dealer'
  },
  [CostCenterEnum.Iot]: {
    // Sidebar
    Sidebar_Group: 'My Groups',
    // Pages
    // Groups Page
    MG_Title: 'My Groups',
    MG_SubTitle: 'Manage my groups',
    MG_Tb_Header_Group: 'GROUP',
    MG_Add_Button: 'Add Group',

    // ServiceList Page
    SL_Filter: 'Filter by Group:',
    SL_Tb_Header_Group: 'GROUP',

    //View/Manage Service Page
    MS_Group: 'Group',

    //Activate SIM Page
    AS_Group: 'Group name',

    //Inventory Page
    I_Group_Filter: 'Group'
  },
  [CostCenterEnum.User]: {
    // Sidebar
    Sidebar_Group: 'My Users',
    // Pages
    // Groups Page
    MG_Title: 'My Users',
    MG_SubTitle: 'Manage my Users',
    MG_Tb_Header_Group: 'USER',
    MG_Add_Button: 'Add User',

    // ServiceList Page
    SL_Filter: 'Filter by User:',
    SL_Tb_Header_Group: 'USER',

    //View/Manage Service Page
    MS_Group: 'User',

    //Activate SIM Page
    AS_Group: 'User name',

    //Inventory Page
    I_Group_Filter: 'User'
  },
}

export default Strings
