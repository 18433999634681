import React, { useEffect, useState } from 'react'
// reactstrap components
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'

import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAuthContext } from '../../contexts/AuthContext'

import Strings from 'localization'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

export const nicknameMaxLength = 60

function Activate({ location }) {
  const { msisdn, sim, costCtrName } = location.state
  const history = useHistory()
  const { getCostCtrListSimple, getProductList, activateSim } =
    useDashboardContext()
  const { authCostCtrId } = useAccessControlContext()
  const { costCenterType } = useAuthContext()
  const [atu, setAtu] = useState(true)
  const [groupName, setGroupName] = useState(costCtrName)
  const [preferredName, setPreferredName] = useState('')
  const [planName, setPlanName] = useState(null)
  const [planId, setPlanId] = useState(null)
  const [costCtrId, setCostCtrId] = useState(location.state.costCtrId ?? null)

  const [costCtrList, setCostCtrList] = useState([])
  const [productList, setProductList] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [invalid, setInvalid] = useState(false)

  const handleActivate = async () => {
    // validate values
    if (groupName && preferredName && planName) {
      await activateSim({
        preferredName,
        msisdn,
        iccid: sim,
        ATUStatus: atu ? 'Enabled' : 'Disabled',
        planId,
        costCtrId
      })
        .then((res) => {
          console.log('activate sim success:', res)
          history.push({
            pathname: '/admin/summary',
            state: {
              msisdn: msisdn,
              sim: sim,
              preferredName: preferredName,
              planName: planName,
              groupName: groupName,
              activated: true,
              svcId: res.activation.svcId
            }
          })
        })
        .catch((err) => {
          setError(true)
          setErrorMessage(err.message ? err.message : 'Something went wrong')
        })
    } else {
      setInvalid(true)
    }
  }

  useEffect(() => {
    setLoading(true)
    const loadCostCtrList = async () => {
      await getCostCtrListSimple({ costCtrId: authCostCtrId }).then(
        (response) => {
          setCostCtrList(response)
          setGroupName(response[0].costCtrDescription)
          setCostCtrId(response[0].costCtrId)
        }
      )
    }
    const loadProductList = async () => {
      await getProductList().then((response) => {
        setProductList(response)
        setPlanName(response[0].planName)
        setPlanId(response[0].planId)
      })
    }
    loadCostCtrList()
    loadProductList()
    setLoading(false)
  }, [])

  useEffect(() => {
    console.log('groupname:', groupName)
    console.log('costCtrId:', costCtrId)
    console.log('preferredname:', preferredName)
    console.log('planname:', planName)
    console.log('planId:', planId)
    console.log('msisdn:', msisdn)
    console.log('sim:', sim)
  }, [preferredName, groupName, planName])
  return (
    <>
      <SimpleHeader name="Activate SIM" />
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Activate MSISDN
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form>
              <div className="pl-lg-4">
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    {Strings[costCenterType].AS_Group}
                  </Label>
                  <Col md={6}>
                    <Input
                      id="select-plan"
                      type="select"
                      onChange={(e) => {
                        setError(false)
                        const index = e.target.selectedIndex
                        setCostCtrId(costCtrList[index].costCtrId)
                        setGroupName(costCtrList[index].costCtrDescription)
                      }}
                    >
                      {costCtrList &&
                        costCtrList.map((costCtr, index) => {
                          return (
                            <option
                              key={index}
                              selected={
                                costCtr.costCtrId === location.state.costCtrId
                              }
                            >
                              {costCtr.costCtrDescription}
                            </option>
                          )
                        })}
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Nickname
                  </Label>
                  <Col md={6}>
                    <Input
                      id="input-nickname"
                      placeholder="Nickname"
                      type="text"
                      value={preferredName}
                      invalid={invalid}
                      onChange={(e) => {
                        setInvalid(false)
                        setError(false)
                        setPreferredName(
                          e.target.value.replace(/[^a-zA-Z0-9_-\s]/gi, '')
                        )
                      }}
                      maxLength={nicknameMaxLength}
                    />
                    <div className="invalid-feedback">
                      You must fill in the nickname
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Plan name
                  </Label>
                  <Col md={6}>
                    <Input
                      id="select-plan"
                      type="select"
                      onChange={(e) => {
                        setError(false)
                        const index = e.target.selectedIndex
                        setPlanName(productList[index].planName)
                        setPlanId(productList[index].planId)
                      }}
                    >
                      {productList &&
                        productList.map((product, index) => {
                          return <option key={index}>{product.planName}</option>
                        })}
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-password" md={2}>
                    Auto renew
                  </Label>
                  <Col className="d-flex align-items-center" md="5">
                    <Label className="custom-toggle custom-toggle-success mr-1">
                      <Input
                        checked={atu}
                        onChange={() => setAtu(!atu)}
                        type="checkbox"
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </Label>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label
                    className="text-primary letter-4"
                    htmlFor="re-input-password"
                    md={2}
                  >
                    MSISDN
                  </Label>

                  <Col className="d-flex align-items-center" md={10}>
                    <h>{msisdn}</h>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label
                    className="text-primary letter-4"
                    htmlFor="re-input-password"
                    md={2}
                  >
                    SIM Number
                  </Label>

                  <Col className="d-flex align-items-center" md={10}>
                    <h>{sim}</h>
                  </Col>
                </FormGroup>
                <Row className="align-items-center">
                  <Button color="secondary" onClick={handleActivate}>
                    Next
                  </Button>
                  {error && <div className="error-message">{errorMessage}</div>}
                </Row>

                {/* </Row> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Activate
