import { useAuthContext } from 'contexts/AuthContext'
import PropTypes from 'prop-types'
import React, { FunctionComponent, useRef } from 'react'
import DashboardHandler from 'services/sdk/handlers/DashboardHandler'
import HttpClient from 'services/sdk/HttpClient'
import { IDashboardHandler, IHttpClient, IToken } from 'services/sdk/interfaces'
import { HistoryData, SpendHistory, UserDetails } from 'services/sdk/models/models'
import {
  ActivateRequest,
  AddGroupRequest,
  CreateAccountRequest,
  GetHistoryDataRequest,
  ServiceDetailRequest,
  ServicesListRequest,
  SetATURequest,
  SetBarRequest,
  SimDetailsRequest,
  SIMInventoryRequest,
  UpdateServiceDetailRequest,
  SubtenantHistoryDataRequest,
  InvoiceRequest,
  costCtrSimpleRequest,
  costCtrDetailedRequest,
  VoucherDetailsRequest,
  UserListRequest,
  AddUserRequest,
  UserDetailsRequest,
  UpdateUserRequest,
  UpdateGroupRequest,
  UpdateUserStatusRequest,
  UpdateGroupStatusRequest,
  ActivationsByCostCtrRequest,
  ActivationsByChannelRequest,
  CostCtrCreditBalanceRequest,
  AdjustBalanceRequest,
  ProductListRequest,
  ApplyProductRequest,
  RoleListRequest,
  UsernameCheckRequest,
  DealerSimDetailsRequest,
  DealerUpdateSimDetailsRequest,
  GetTransactionHistoryRequest,
  ChangePasswordRequest,
  SendEmailVerificationRequest,
  CheckGroupNameRequest,
  GetTerminationDataRequest,
  CheckSimRequest,
  AuditLogListRequest
} from 'services/sdk/models/requests'
import {
  ActivationResponse,
  CostCtrSimple,
  DashboardStatsResponse,
  DashboardSummaryResponse,
  //DealerDetailResponse,
  ProductResponse,
  ServiceDetailResponse,
  ServicesListResponse,
  SimDetailsResponse,
  SIMInventoryResponse,
  CostCtrServiceCountResponse,
  SpendByCostCtrResponse,
  ServiceCountPerPlanResponse,
  SubtenantHistoryDataResponse,
  InvoiceResponse,
  PreActiveSimsCountResponse,
  CostCtrDetail,
  VoucherDetailsResponse,
  UserDetailsResponse,
  UserListResponse,
  GroupDetailsResponse,
  UpdateUserResponse,
  UserRoleResponse,
  UpdateGroupResponse,
  CostCtrBalancesResponse,
  ActivationsByCostCtrResponse,
  ActivationsByChannelResponse,
  CostCtrCreditBalanceResponse,
  ApplyProductResponse,
  UsernameCheckResponse,
  DealerSimDetailsResponse,
  AuditLogListResponse,
} from 'services/sdk/models/responses'
import { useAccessControlContext } from './AccessControlContext'
import { createCtx } from './helpers'

interface DashboardContextInterface {
  // Http Clients
  dashboardHandler: IDashboardHandler

  // Functions
  getInventory: (request: SIMInventoryRequest) => Promise<SIMInventoryResponse>
  getServices: (request: ServicesListRequest) => Promise<ServicesListResponse>
  getServiceDetail: (
    Request: ServiceDetailRequest
  ) => Promise<ServiceDetailResponse | null>
  getUserDetails: (request: UserDetailsRequest) => Promise<UserDetailsResponse | null>
  setServiceATU: (Request: SetATURequest) => Promise<number>
  setServiceBar: (Request: SetBarRequest) => Promise<number>
  updateServiceDetail: (request: UpdateServiceDetailRequest) => Promise<number>
  getCostCtrList: (request: costCtrDetailedRequest) => Promise<CostCtrDetail[] | null>
  getCostCtrListSimple: (
    request: costCtrSimpleRequest
  ) => Promise<CostCtrSimple[] | null>
  getUserList: (request: UserListRequest) => Promise<UserListResponse | null>
  getProductList: (request: ProductListRequest) => Promise<ProductResponse[] | null>
  activateSim: (request: ActivateRequest) => Promise<ActivationResponse>
  checkSimSerial: (request: CheckSimRequest) => Promise<number>
  getSimDetails: (request: SimDetailsRequest) => Promise<SimDetailsResponse>
  getGroupDetails: (costCtrId: string) => Promise<GroupDetailsResponse | null>
  updateGroup: (request: UpdateGroupRequest) => Promise<UpdateGroupResponse>
  addGroup: (request: AddGroupRequest) => Promise<number>
  updateGroupStatus: (request: UpdateGroupStatusRequest) => Promise<number>
  addUser: (request: AddUserRequest) => Promise<number>
  updateUser: (request: UpdateUserRequest) => Promise<UpdateUserResponse>
  updateUserStatus: (request: UpdateUserStatusRequest) => Promise<number>
  adjustBalance: (request: AdjustBalanceRequest) => Promise<number>
  getServiceHistory: (request: GetHistoryDataRequest) => Promise<HistoryData[]>
  getActivationHistory: (
    request: GetHistoryDataRequest
  ) => Promise<HistoryData[]>
  getTerminationHistory: (
    request: GetTerminationDataRequest
  ) => Promise<HistoryData[]>
  // getDashboardSummary: () => Promise<DashboardSummaryResponse>
  purchaseNewPlan: (Request: SetATURequest) => Promise<number>
  getDashboardPAYGStats: () => Promise<DashboardStatsResponse>
  getDashboardUsageStats: () => Promise<DashboardStatsResponse>
  getTotalActivatedServices: (costCtrId?: string) => Promise<number>
  getTotalPreActiveSims: (costCtrId?: string) => Promise<number>
  getTotalSpend: (costCtrId?: string) => Promise<number>
  getTotalRetailSpend: (costCtrId?: string) => Promise<number>
  getCostCtrServiceCount: () => Promise<CostCtrServiceCountResponse>
  getSpendByCostCtr: () => Promise<SpendByCostCtrResponse>
  getPreActiveSimsByCostCtr: () => Promise<PreActiveSimsCountResponse>
  getCostCtrBalances: () => Promise<CostCtrBalancesResponse[]>
  getNoRechargeServiceCount: () => Promise<CostCtrServiceCountResponse>
  getSubtenantNoRecharge: (
    request: SubtenantHistoryDataRequest
  ) => Promise<SubtenantHistoryDataResponse[]>
  getSubtenantServiceCountPerPlan: (
    costCtrId: string
  ) => Promise<ServiceCountPerPlanResponse>
  getSubtenantPreActiveSimsHistory: (
    request: SubtenantHistoryDataRequest
  ) => Promise<SubtenantHistoryDataResponse[]>
  getSubtenantSpendHistory: (
    request: SubtenantHistoryDataRequest
  ) => Promise<SpendHistory[]>
  getActivationsByCostCtr: (
    request: ActivationsByCostCtrRequest
  ) => Promise<ActivationsByCostCtrResponse>
  getActivationsByChannel: (
    request: ActivationsByChannelRequest
  ) => Promise<ActivationsByChannelResponse>
  getCostCtrCreditBalance: (
    request: CostCtrCreditBalanceRequest
  ) => Promise<CostCtrCreditBalanceResponse>
  getInvoice: (request: InvoiceRequest) => Promise<InvoiceResponse>
  getSpreadsheet: () => void
  getVoucherDetails: ( 
    request: VoucherDetailsRequest
  ) => Promise<VoucherDetailsResponse>
  applyVoucher: (request: VoucherDetailsRequest) => Promise<number>
  applyProduct: (request: ApplyProductRequest) => Promise<any>
  getRoleList: (request: RoleListRequest) => Promise<UserRoleResponse[]>
  usernameCheck: (request: UsernameCheckRequest) => Promise<number>
  getDealerSimDetails: (request: DealerSimDetailsRequest) => Promise<DealerSimDetailsResponse>
  updateDealerSimDetails: (request: DealerUpdateSimDetailsRequest) => Promise<any>
  getTransactionHistory: (request: GetTransactionHistoryRequest) => Promise<any>
  changePassword: (reqeust: ChangePasswordRequest) => Promise<any>
  sendEmailVerification: (request: SendEmailVerificationRequest) => Promise<any>
  checkGroupName: (request: CheckGroupNameRequest) => Promise<any>
  getAuditLogList: (request: AuditLogListRequest) => Promise<AuditLogListResponse>
}

type DashboardContextProviderProps = {
  children: React.ReactNode
}

const [ctx, useContext, Provider] = createCtx<DashboardContextInterface>()

export const DashboardContextProvider: FunctionComponent<
  DashboardContextProviderProps
> = ({ children }) => {
  const { tokenProvider } = useAuthContext()
  const { getShowCostCtrPicker } = useAccessControlContext()
  const standardHttpClient = useRef<IHttpClient>(
    HttpClient(process.env.REACT_APP_HOST_AUTH, tokenProvider)
  ).current

  const dashboardHandler = useRef(DashboardHandler(standardHttpClient)).current

  const getInventory = async (
    request: SIMInventoryRequest
  ): Promise<SIMInventoryResponse> => {
    return await dashboardHandler
      .getInventory(request)
      .then((response) => {
        // console.log('get Inventory response: ', response)
        if (response.ok && response.response) {
          console.log('res.res: ', response.response)
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log('getInventory err: ', err)
        throw err
      })
  }

  const getServices = async (
    request: ServicesListRequest
  ): Promise<ServicesListResponse> => {
    return await dashboardHandler
      .getServicesList(request)
      .then((response) => {
        // console.log('get ServicesList response: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return response.response
        }
        throw new Error(
          response.error ? response.error : 'Something went wrong'
        )
      })
  }

  const getServiceDetail = async (
    request: ServiceDetailRequest
  ): Promise<ServiceDetailResponse | null> => {
    return await dashboardHandler
      .getServiceDetail(request)
      .then((response) => {
        // console.log('get service detail response: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return response.response
        }
        return null
      })
      .catch((err) => {
        console.log('get service detail error: ', err)
        return null
      })
  }

  const getUserDetails = async (
    request: UserDetailsRequest
    ): Promise<UserDetailsResponse | null> => {
    return await dashboardHandler
      .getUserDetails(request)
      .then((response) => {
        // console.log('get user detail response: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return response.response
        }
        return null
      })
      .catch((err) => {
        console.log('get user detail error: ', err)
        return null
      })
  }

  const setServiceATU = async (request: SetATURequest) => {
    return await dashboardHandler
      .setServiceATU(request)
      .then((response) => {
        // console.log('setatu res: ', response)
        if (response.status === null) {
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('set atu error: ', err)
        return 500
      })
  }

  const purchaseNewPlan = async (request: SetATURequest) => {
    return await dashboardHandler
      .purchaseNewPlan(request)
      .then((response) => {
        // console.log('purchase plan res: ', response)
        if (response.status === null) {
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('purchase plan error: ', err)
        return 500
      })
  }

  const setServiceBar = async (request: SetBarRequest) => {
    return await dashboardHandler
      .setServiceBar(request)
      .then((response) => {
        // console.log('setatu res: ', response)
        if (response.status === null) {
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('set atu error: ', err)
        return 500
      })
  }

  const updateServiceDetail = async (request: UpdateServiceDetailRequest) => {
    return await dashboardHandler
      .updateServiceDetail(request)
      .then((response) => {
        // console.log('update service detail res: ', response)
        if (response.status === null) {
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('update service detail error: ', err)
        return 500
      })
  }

  const getCostCtrList = async (request: costCtrDetailedRequest) => {
    return await dashboardHandler
      .getCostCtrList(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        }
        return null
      })
      .catch((err) => {
        console.log('getCostCtrList error:', err)
        return null
      })
  }

  const getCostCtrListSimple = async (request: costCtrSimpleRequest) => {
    return await dashboardHandler
      .getCostCtrListSimple(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        }
        return null
      })
      .catch((err) => {
        console.log('getCostCtrListSimple error:', err)
        return null
      })
  }

  const getUserList = async (request: UserListRequest) => {
    return await dashboardHandler
      .getUserList(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        }
        return null
      })
      .catch((err) => {
        console.log('getUserList error:', err)
        return null
      })
  }

  const getProductList = async (request: ProductListRequest) => {
    return await dashboardHandler
      .getProductList(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        }
        return null
      })
      .catch((err) => {
        console.log('getProductList error:', err)
        return null
      })
  }

  const activateSim = async (request: ActivateRequest) => {
    return await dashboardHandler
      .activateSim(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log('activateSim error:', err)
        throw err
      })
  }

  const checkSimSerial = async (request: CheckSimRequest) => {
    return await dashboardHandler
      .checkSimSerial(request)
      .then((res) => {
        // console.log('checkSimSerial res: ', res)
        if (res.ok && res.response) {
          return res.response
        } else {
          console.log('checkSimSerial error: ', res)
          throw new Error(
            res.error ? res.error : 'checkSimSerial: Something went wrong'
          )
        }
      })
  }

  const getSimDetails = async (request: SimDetailsRequest) => {
    return await dashboardHandler
      .getSimDetails(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get sim details error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getGroupDetails = async (costCtrId: string) => {
    return await dashboardHandler
      .getGroupDetails(costCtrId)
      .then((response) => {
        // console.log('get dealer detail response: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return response.response
        }
        return null
      })
      .catch((err) => {
        console.log('get dealer detail error: ', err)
        return err
      })
  }

  const addGroup = async (request: AddGroupRequest) => {
    return await dashboardHandler
      .addGroup(request)
      .then((response) => {
        // console.log('add group res: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('add group error: ', err)
        return 500
      })
  }

  const updateGroup = async (request: UpdateGroupRequest) => {
    return await dashboardHandler
      .updateGroup(request)
      .then((response) => {
        // console.log('update group res: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return response.response
        }
        return response.status
      })
      .catch((err) => {
        console.log('update group error: ', err)
        return err
      })
  }

  const updateGroupStatus = async (request: UpdateGroupStatusRequest) => {
    return await dashboardHandler
      .updateGroupStatus(request)
      .then((response) => {
        // console.log('update group res: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('update group error: ', err)
        return 500
      })
  }

  const addUser = async (request: AddUserRequest) => {
    return await dashboardHandler
      .addUser(request)
      .then((response) => {
        // console.log('add group res: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('add group error: ', err)
        return 500
      })
  }

  const updateUser = async (request: UpdateUserRequest) => {
    return await dashboardHandler
      .updateUser(request)
      .then((response) => {
        // console.log('add group res: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return response.response
        }
        return response.status
      })
      .catch((err) => {
        console.log('add group error: ', err)
        return err
      })
  }

  const updateUserStatus = async (request: UpdateUserStatusRequest) => {
    return await dashboardHandler
      .updateUserStatus(request)
      .then((response) => {
        // console.log('add group res: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('add group error: ', err)
        return 500
      })
  }

  const adjustBalance = async (request: AdjustBalanceRequest) => {
    return await dashboardHandler
      .adjustBalance(request)
      .then((response) => {
        // console.log('add group res: ', response)
        if (response.ok && response.response) {
          // console.log('res.res: ', response.response)
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('add group error: ', err)
        return 500
      })
  }

  const getServiceHistory = async (request: GetHistoryDataRequest) => {
    return await dashboardHandler
      .getServiceHistory(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get service detail error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getActivationHistory = async (request: GetHistoryDataRequest) => {
    return await dashboardHandler
      .getActivationHistory(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get activation history error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getTerminationHistory = async (request: GetTerminationDataRequest) => {
    return await dashboardHandler
      .getTerminationHistory(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get termination history error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  // const getDashboardSummary = async () => {
  //   return await dashboardHandler
  //     .getDashboardSummary()
  //     .then((response) => {
  //       if (response.ok && response.response) {
  //         return response.response
  //       } else {
  //         console.log('get dashboard summary error:', response)
  //         throw new Error(
  //           response.error ? response.error : 'Something went wrong'
  //         )
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       throw err
  //     })
  // }

  const getDashboardPAYGStats = async () => {
    return await dashboardHandler
      .getDashboardPAYGStats()
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get dashboard PAYG error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getDashboardUsageStats = async () => {
    return await dashboardHandler
      .getDashboardUsageStats()
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get dashboard Usage error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getTotalActivatedServices = async (costCtrId?: string) => {
    return await dashboardHandler
      .getTotalActivatedServices(costCtrId)
      .then((response) => {
        if (response.ok && response.response) {
          // console.log(
          //   'get total activated services response.response:',
          //   response.response
          // )
          return response.response
        } else {
          console.log('get total activated services error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getCostCtrServiceCount = async () => {
    return await dashboardHandler
      .getCostCtrServiceCount()
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get cost ctr service count error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getSubtenantNoRecharge = async (
    request: SubtenantHistoryDataRequest
  ) => {
    return await dashboardHandler
      .getSubtenantNoRecharge(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get subtenant no recharge error:', response)
          throw new Error(
            response.error
              ? response.error
              : 'getSubtenantNoRecharge: Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getSubtenantServiceCountPerPlan = async (costCtrId: string) => {
    return await dashboardHandler
      .getSubtenantServiceCountPerPlan(costCtrId)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get subtenant service count per plan error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getTotalPreActiveSims = async (costCtrId?: string) => {
    return await dashboardHandler
      .getTotalPreActiveSims(costCtrId)
      .then((response) => {
        if (response.ok && response.response) {
          // console.log(
          //   'get total pre active sims response.response:',
          //   response.response
          // )
          return response.response
        } else {
          console.log('get total pre active sims error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getTotalSpend = async (costCtrId?: string) => {
    return await dashboardHandler
      .getTotalSpend(costCtrId)
      .then((response) => {
        if (response.ok && response.response) {
          // console.log('get total spend response.response:', response.response)
          return response.response
        } else {
          console.log('get total spend error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getTotalRetailSpend = async (costCtrId?: string) => {
    return await dashboardHandler
      .getTotalRetailSpend(costCtrId)
      .then((response) => {
        if (response.ok && response.response) {
          // console.log(
          //   'get total retail spend response.response:',
          //   response.response
          // )
          return response.response
        } else {
          console.log('get total retail spend error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getNoRechargeServiceCount = async () => {
    return await dashboardHandler
      .getNoRechargeServiceCount()
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get no charge service count error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getSpendByCostCtr = async () => {
    return await dashboardHandler.getSpendByCostCtr().then((response) => {
      if (response.ok && response.response) {
        // console.log(
        //   'get spend by cost ctr response.response:',
        //   response.response
        // )
        return response.response
      } else {
        console.log('get spend by cost ctr error:', response)
        throw new Error(
          response.error ? response.error : 'Something went wrong'
        )
      }
    })
  }

  const getPreActiveSimsByCostCtr = async () => {
    return await dashboardHandler
      .getPreActiveSimsByCostCtr()
      .then((response) => {
        if (response.ok && response.response) {
          // console.log(
          //   'get pre active sims by cost ctr response.response:',
          //   response.response
          // )
          return response.response
        } else {
          console.log('get pre active sims by cost ctr error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
  }

  const getCostCtrBalances = async () => {
    return await dashboardHandler
      .getCostCtrBalances()
      .then((response) => {
        if (response.ok && response.response) {
          // console.log(
          //   'get cost ctr balances response.response:',
          //   response.response
          // )
          return response.response
        } else {
          console.log('get cost ctr balances error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
  }

  const getSubtenantPreActiveSimsHistory = async (
    request: SubtenantHistoryDataRequest
  ) => {
    return await dashboardHandler
      .getSubtenantPreActiveSimsHistory(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get subtenant pre active sims history error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }
  const getSubtenantSpendHistory = async (
    request: SubtenantHistoryDataRequest
  ) => {
    return await dashboardHandler
      .getSubtenantSpendHistory(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get subtenant spend history error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getActivationsByCostCtr = async (
    request: ActivationsByCostCtrRequest
  ) => {
    return await dashboardHandler
      .getActivationsByCostCtr(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get activations by costCtr error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getActivationsByChannel = async (
    request: ActivationsByChannelRequest
  ) => {
    return await dashboardHandler
      .getActivationsByChannel(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get activations by channel error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getCostCtrCreditBalance = async (
    request: CostCtrCreditBalanceRequest
  ) => {
    return await dashboardHandler
      .getCostCtrCreditBalance(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get cost center credit balance error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getInvoice = async (request: InvoiceRequest) => {
    return await dashboardHandler
      .getInvoice(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get invoice error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getSpreadsheet = async () => {
    return await dashboardHandler
      .getSpreadsheet()
      .then((response) => {
        // console.log('get spreadsheet response: ', response)
        if (response.ok && response.response) {
          const blob = new Blob([response.response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet;charset=utf-8;'
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${Date.now()}.xlsx`
          link.click()
        } else {
          console.log('get spreadsheet error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  const getVoucherDetails = async (request: VoucherDetailsRequest) => {
    return await dashboardHandler
      .getVoucherDetails(request)
      .then((response) => {
        // console.log('get voucher details response: ', response)
        if (response.ok && response.response) {
          return response.response
        } else {
          console.log('get voucher details error:', response)
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
  }

  const getAuditLogList = async (request: AuditLogListRequest) => {
    return await dashboardHandler
    .getAuditLogList(request)
    .then((response) => {
      // console.log('get audit log list response: ', response)
      if (response.ok && response.response) {
        return response.response
      } else {
        console.log('get audit log list error:', response)
        throw new Error(
          response.error ? response.error : 'Something went wrong'
        )
      }
    })
  }

  const applyVoucher = async (request: VoucherDetailsRequest) => {
    return await dashboardHandler
      .applyVoucher(request)
      .then((response) => {
        // console.log('apply voucher res: ', response)
        if (response.status === null) {
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('apply voucher error: ', err)
        return 500
      })
  }

  const applyProduct = async (request: ApplyProductRequest) => {
    return await dashboardHandler
      .applyProduct(request)
      .then((response) => {
        if (response.ok && response.response) {
          return 200
        } else {
          return response
        }
      })
      .catch((err) => {
        console.log('applyProduct error:', err)
        throw err
      })
  }

  const getRoleList = async (request: RoleListRequest) => {
    return await dashboardHandler
      .getRoleList(request)
      .then((response) => {
        // console.log('get role list res: ', response)
        if (response.status === null) {
          return response.status
        }
        return response.response
      })
      .catch((err) => {
        console.log('get role list error: ', err)
        return err
      })
  }

  const usernameCheck = async (request: UsernameCheckRequest) => {
    return await dashboardHandler
      .usernameCheck(request)
      .then((response) => {
        // console.log('check username res: ', response)
        if (response.ok && response.response) {
          return response.status
        } else {
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log('check username error: ', err)
        return err.status
      })
  }

  const getDealerSimDetails = async (request: DealerSimDetailsRequest) => {
    return await dashboardHandler
      .getDealerSimDetails(request)
      .then((response) => {
        // console.log('get dealer sim details res: ', response)
        if (response.ok && response.response) {
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Error when getting sim details'
          )
        }
      })
  }

  const updateDealerSimDetails = async (request: DealerUpdateSimDetailsRequest) => {
    return await dashboardHandler
      .updateDealerSimDetails(request)
      .then((response) => {
        // console.log('update dealer sim details res: ', response)
        if (response.ok && response.response) {
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Error when updating sim details'
          )
        }
      })
  }

  const getTransactionHistory = async (request: GetTransactionHistoryRequest) => {
    return await dashboardHandler
      .getTransactionHistory(request)
      .then((response) => {
        // console.log('get credit history res: ', response)
        if (response.ok && response.response) {
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Error when getting credit history'
          )
        }
      })
  }

  const changePassword = async (request: ChangePasswordRequest) => {
    return await dashboardHandler
      .changePassword(request)
      .then((response) => {
        // console.log('change password res: ', response)
        if (response.ok && response.response) {
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Error when changing password'
          )
        }
      })
  }

  const sendEmailVerification = async (request: SendEmailVerificationRequest) => {
    return await dashboardHandler
      .sendEmailVerification(request)
      .then((response) => {
        // console.log('send email verification res: ', response)
        if (response.ok && response.response) {
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Error when sending email verification'
          )
        }
      })
  }

  const checkGroupName = async (request: CheckGroupNameRequest) => {
    return await dashboardHandler
      .checkGroupName(request)
      .then((response) => {
        // console.log('check group name res: ', response)
        if (response.ok && response.response) {
          return response.status
        } else {
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log('check username error: ', err)
        return err.status
      })
  }

  return (
    <Provider
      value={{
        dashboardHandler,
        getInventory,
        getServices,
        getServiceDetail,
        getUserDetails,
        setServiceATU,
        setServiceBar,
        updateServiceDetail,
        getCostCtrList,
        getCostCtrListSimple,
        getUserList,
        getAuditLogList,
        getProductList,
        activateSim,
        checkSimSerial,
        getSimDetails,
        getGroupDetails,
        updateGroup,
        addGroup,
        updateGroupStatus,
        addUser,
        updateUser,
        updateUserStatus,
        adjustBalance,
        getServiceHistory,
        getActivationHistory,
        getTerminationHistory,
        // getDashboardSummary,
        purchaseNewPlan,
        getDashboardPAYGStats,
        getDashboardUsageStats,
        getTotalActivatedServices,
        getTotalPreActiveSims,
        getTotalSpend,
        getTotalRetailSpend,
        getCostCtrServiceCount,
        getSpendByCostCtr,
        getPreActiveSimsByCostCtr,
        getCostCtrBalances,
        getNoRechargeServiceCount,
        getSubtenantNoRecharge,
        getSubtenantServiceCountPerPlan,
        getSubtenantPreActiveSimsHistory,
        getSubtenantSpendHistory,
        getActivationsByCostCtr,
        getActivationsByChannel,
        getCostCtrCreditBalance,
        getInvoice,
        getSpreadsheet,
        getVoucherDetails,
        applyVoucher,
        getRoleList,
        applyProduct,
        usernameCheck,
        getDealerSimDetails,
        updateDealerSimDetails,
        getTransactionHistory,
        changePassword,
        sendEmailVerification,
        checkGroupName
      }}
    >
      {children}
    </Provider>
  )
}

DashboardContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useDashboardContext = useContext

export default ctx
