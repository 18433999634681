import { useEffect, useState } from 'react'
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Label,
  Button
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useCallback } from 'react'
import { kBToGB } from '../../utils/Utils'
import { useHistory } from 'react-router-dom'

function VoucherRechargeReceipt({ location }) {
  const { voucherName, price, svcId } = location.state
  const { getServiceDetail } = useDashboardContext()
  const [serviceDetail, setServiceDetail] = useState(null)
  const history = useHistory()

  const loadService = useCallback(async () => {
    const serviceInfo = await getServiceDetail({ svcId })
    console.log('service info: ', serviceInfo)
    setServiceDetail(serviceInfo)
  }, [getServiceDetail, svcId])

  useEffect(() => {
    loadService()
  }, [loadService])

  // const renderAccountDetails = useCallback(() => {
  //   if (serviceDetail && serviceDetail.planDetails.balances) {
  //     return serviceDetail.planDetails.balances.map((balance, index) => {
  //       console.log('balance: ', balance)
  //       return (
  //         <FormGroup row key={index}>
  //           <Label className="letter-4" htmlFor="input-msisdn" md={5}>
  //             {balance.description}
  //           </Label>
  //           <Col className="d-flex align-items-center" md={5}>
  //             {balance.description === 'Data' && balance.unlimited === 'N' ? (
  //               <h className="neue-bold">{kBToGB(balance.remaining) + ' GB'}</h>
  //             ) : (
  //               <h className="neue-bold">{balance.remaining}</h>
  //             )}
  //           </Col>
  //         </FormGroup>
  //       )
  //     })
  //   }
  // }, [serviceDetail])

  const renderAccountDetails = useCallback(() => {
    if (serviceDetail) {
      return (
        <>
          <FormGroup row>
            <Label className="letter-4" md={5}>
              CURRENT PLAN
            </Label>
            <Col className="d-flex align-items-center" md={5}>
              <h className="neue-bold">{serviceDetail.planDetails?.planName}</h>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label className="letter-4" md={5}>
              MAIN BALANCE
            </Label>
            <Col className="d-flex align-items-center" md={5}>
              <h className="neue-bold">{'$ ' + serviceDetail.balance}</h>
            </Col>
          </FormGroup>
          {serviceDetail.planDetails.balances.map((balance, index) => {
            console.log('balance: ', balance)
            return (
              <FormGroup row key={index}>
                <Label className="letter-4" htmlFor="input-msisdn" md={5}>
                  {balance.description}
                </Label>
                <Col className="d-flex align-items-center" md={5}>
                  {balance.description === 'Data' &&
                  balance.unlimited === 'N' ? (
                    <h className="neue-bold">
                      {kBToGB(balance.remaining) + ' GB'}
                    </h>
                  ) : (
                    <h className="neue-bold">{balance.remaining}</h>
                  )}
                </Col>
              </FormGroup>
            )
          })}
        </>
      )
    }
  }, [serviceDetail])

  return (
    <>
      <SimpleHeader name="Voucher Review" />
      <Container fluid>
        <Card>
          <Row className="align-items-center bg-success">
            <h className="ml-3 text-white">Recharge successfully processed</h>
          </Row>
          <CardHeader>
            {/* <Row className="align-items-center bg-success">
              <h className="text-white">Recharge successfully processed</h>
            </Row> */}
            <Row className="align-items-center">
              <Col xs="6">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Account Details
                </h3>
              </Col>
              <Col xs="6">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Voucher Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Row className="align-items-start">
              <Col xs="6">{renderAccountDetails()}</Col>
              <Col xs="6">
                <FormGroup row>
                  <Label className="letter-4" md={3}>
                    VOUCHER NAME
                  </Label>
                  <Col className="d-flex align-items-center" md={5}>
                    <h className="neue-bold">{voucherName}</h>
                  </Col>
                </FormGroup>
                {price && (
                  <FormGroup row>
                    <Label className="letter-4" md={3}>
                      VOUCHER PRICE
                    </Label>
                    <Col className="d-flex align-items-center" md={5}>
                      <h className="neue-bold">{'$ ' + price}</h>
                    </Col>
                  </FormGroup>
                )}
                {/* <FormGroup row>
                  <Label className="letter-4" md={3}>
                    CUSTOMER REF
                  </Label>
                  <Col className="d-flex align-items-center" md={5}>
                    <h className="neue-bold">{svcId}</h>
                  </Col>
                </FormGroup> */}
              </Col>
            </Row>
            <Button
              onClick={() => {
                history.push({
                  pathname: '/admin/dashboard'
                })
              }}
            >
              Return to HomePage
            </Button>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default VoucherRechargeReceipt
