import React, { useState, useMemo, useEffect } from 'react'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // Modal,
  // ModalBody,
  // ModalFooter,
  FormGroup,
  Label,
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
// import { useAccessControlContext } from '../../contexts/AccessControlContext'
import { useHistory } from 'react-router-dom'
import { isBalanceAmountValid } from '../../utils/validation'
import { dollarAmountFormat } from '../../utils/Utils'

function Balances({ location }) {
  const { costCtrId, credit } = location.state
  // const { getGroupDetails } = useDashboardContext()
  // const { authCostCtrId } = useAccessControlContext()
  const history = useHistory()
  // const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [amountError, setAmountError] = useState(false)
  const [amountErrorMessage, setAmountErrorMessage] = useState(null)
  // const [success, setSuccess] = useState(false)
  // const [successMessage, setSuccessMessage] = useState(null)
  // const [balanceTypeList, setBalanceTypeList] = useState([])
  const [balanceAmount, setBalanceAmount] = useState('')
  const [adjustmentType, setAdjustmentType] = useState('Credit')
  const [adjustmentDetails, setAdjustmentDetails] = useState('')
  const [submitError, setSubmitError] = useState('')
  // const [adjustmentCredit, setAdjustmentCredit] = useState('')
  // const [adjustmentDebit, setAdjustmentDebit] = useState('')
  const { adjustBalance } = useDashboardContext()

  const validBalanceAmount = isBalanceAmountValid(balanceAmount)

  const handleSubmit = async () => {
    console.log(costCtrId, balanceAmount, adjustmentType, adjustmentDetails)
    if (
      balanceAmount === '' ||
      adjustmentDetails === ''
    ) {
      setError(true)
      setErrorMessage('Please fill out all fields.')
      return
    }
    if (!validBalanceAmount) {
      setAmountError(true)
      setAmountErrorMessage('Please enter a valid adjustment amount.')
      return
    }
    if (adjustmentType === 'Debit' && parseFloat(balanceAmount) > credit) {
      setAmountError(true)
      setAmountErrorMessage("Debit amount can't be greater than current balance.")
      return
    }
    await adjustBalance({
      costCtrId,
      amount: balanceAmount,
      balanceType: 'Credit',
      adjustmentType: adjustmentType,
      adjustmentDetails: adjustmentDetails,
    })
      .then((res) => {
        console.log('res: ', res)
        if (res === 200) {
          alert('Balance Adjustment Successful')
          history.push({
            pathname: '/admin/group/details',
            state: {
              costCtrId
            }
          })
        } else {
          setError(true)
          setSubmitError(
            'Something went wrong. Please try again later.'
          )
        }
      })
      .catch((err) => {
        setError(true)
        setSubmitError(
          err.message
            ? err.message
            : 'Something went wrong. Please try again later.'
        )
      })
  }

  return (
    <>
      <SimpleHeader
        name="Balances"
        description="Make Balance Adjustment"
      />
      <Container fluid>
        <Card className="text-primary">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Make Balance Adjustment
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <div className="pl-lg-4">
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Current Balance</h>
                </Col>
                <Col md="6">
                  <h>$ {credit}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Adjustment Type</h>
                </Col>
                <Col md={2}
                    // xs="12 d-flex align-items-center"
                    // md="10 d-flex align-items-center" 
                    // sm="6 d-flex align-items-center" 
                  >
                    <Input
                      id="select-adjustment-type"
                      type="select"
                      value={adjustmentType}
                      onChange={(e) => {
                        console.log('e: ', e.target.value)
                        setError(false)
                        setErrorMessage('')
                        setAdjustmentType(e.target.value)
                      }}
                    >
                      <option>Credit</option>
                      <option>Debit</option>
                    </Input>
                  </Col>
              </Row>
              <FormGroup row>
              <Label className="letter-3" htmlFor="amount" md={2}>
                  <h>Adjustment Amount</h>
              </Label>
                <Col md="2">
                  <Input
                    className="py-1"
                    placeholder="$ Amount"
                    type='number'
                    value={balanceAmount}
                    onChange={(e) => {
                      const numberRgx =  /^([0-9]{1,5})(\.\d\d)?$/
                      const number = dollarAmountFormat.format(e.target.value)
                      if (numberRgx.test(number) && e.target.value.length <= number.length) {
                        setAmountError(false)
                        setAmountErrorMessage('')
                        setBalanceAmount(e.target.value)
                      }
                    }}
                    ></Input>
                    {amountError && (
                      <div className="error-message">{amountErrorMessage}</div>
                    )}
                </Col>
              </FormGroup>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Adjustment Details</h>
                </Col>
                <Col md="6">
                  <Input
                    placeholder="Details"
                    value={balanceAmount.details}
                    invalid={adjustmentDetails === '' && !!adjustmentDetails}
                    onChange={(e) => {
                      setError(false)
                      setErrorMessage('')
                      setAdjustmentDetails(e.target.value)
                    }}
                  >
                  </Input>
                  {/* {error && (
                    <div className="error-message">{errorMessage}</div>
                  )} */}
                </Col>
              </Row>
              {error && (
                <div className="error-message">{errorMessage}</div>
              )}
              {!!submitError && (
                <div className="error-message">{submitError}</div>
              )}
              <Row className="mb-4 align-items-center">
                <Col className="d-flex justify-content-start">
                  <Button
                    className="btn-icon btn-3"
                    color="light"
                    type="button"
                    style={{border: '1px solid'}}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push({
                        pathname: '/admin/group/details',
                        state: { 
                          costCtrId: costCtrId
                        }
                      })
                    }}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-times" />
                    </span>
                    <span className="btn-inner--text">Cancel</span>
                  </Button>
                  <Button
                    className="btn-icon btn-3"
                    color="primary"
                    type="button"
                    invalid={balanceAmount === '' || adjustmentDetails === ''}
                    onClick={(e) => {
                      e.preventDefault()
                      setError(false)
                      setErrorMessage('')
                      handleSubmit()
                    }}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa fa-check" />
                    </span>
                    <span className="btn-inner--text">Submit</span>
                  </Button>
                  {/* {handleSave ? (
                    <Modal
                      isOpen={terminate}
                      toggle={terminateToggle}
                      className="modal-dialog-centered"
                    >
                      <ModalBody>
                        <p>
                          Are you sure you want to terminate {groupDetails.costCtrDescr}?
                        </p>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={handleConfirmTerminate}>
                          Confirm
                        </Button>
                        <Button color="primary" onClick={terminateToggle}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    ) : null
                  }*/}
                </Col>
              </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Balances