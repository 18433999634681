import { Bar } from 'react-chartjs-2'
import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { colors } from 'variables/charts.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'
function ActivationsByChannelChart({ startDate, endDate, timePeriod, costCtrId }) {
  const [activationsByChannelList, setActivationsByChannelList] = useState([])
  const { getActivationsByChannel } = useDashboardContext()

  const history = useHistory()

  const loadActivationsByChannel = useCallback(async () => {
    await getActivationsByChannel({
      pageOffset: 0,
      rowsPerPage: 10,
      sortOrder: 'desc',
      startDate,
      endDate,
      timePeriod
    })
      .then((response) => {
        setActivationsByChannelList(response.results)
        //console.log('set costCtr activation count: ', response)
      })
      .catch((err) => {
        console.log('error at loadActivationsByChannel: ', err)
      })
  }, [getActivationsByChannel, startDate, endDate, timePeriod])

  const getChannelLable = () => {
    const list = activationsByChannelList.map((data) => data.channel)

    return list
  }

  const getActivationCountData = () => {
    const list = activationsByChannelList.map((data) => data.count)

    return list
  }

  useEffect(() => {
    loadActivationsByChannel()
  }, [loadActivationsByChannel])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Activation Count By Channel</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
              <Bar
                data={{
                  labels: getChannelLable(),
                  datasets: [
                    {
                      label: 'Activation Count',
                      data: getActivationCountData()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {
                          // beginAtZero: true
                        }
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element[0],
                      element[0]._index,
                      activationsByChannelList[element[0]._index],
                      'costCtrId: ' +
                      activationsByChannelList[element[0]._index].costCtrId
                    )
                    history.push({
                      pathname: '/admin/services',
                      state: {
                        costCtrId: activationsByChannelList[element[0]._index].costCtrId
                      }
                    })
                  }
                }}
                id="chart-activations-by-channel-count"
                className="chart-canvas"
              />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default ActivationsByChannelChart
