import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
import { useState, useEffect, useMemo } from 'react'
import { addDays, addWeeks, addMonths, addYears, isEqual } from 'date-fns'
import { DateRange } from 'react-date-range'

function DatePicker({ handleOnChange }) {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [TPeriod, setTPeriod] = useState('Day')
  const [isActive, setIsActive] = useState({
    0: true,
    1: false,
    2: false,
    3: false
  })

  const [dateRange, setDateRange] = useState('Select Date Range')

  const defaultDate = useMemo(() => {
    return {
      Day: {
        startDate: addDays(new Date(), -6),
        endDate: new Date()
      },
      Week: {
        startDate: addWeeks(new Date(), -3),
        endDate: new Date()
      },
      Month: {
        startDate: addMonths(new Date(), -5),
        endDate: new Date()
      },
      Year: {
        startDate: addYears(new Date(), -1),
        endDate: new Date()
      }
    }
  }, [])

  const [datePicker, setDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ])

  const timePeriods = ['Day', 'Week', 'Month', 'Year']

  const toggle = () => {
    setShowDatePicker((prevState) => !prevState)
  }

  useEffect(() => {
    if (datePicker[0].startDate && datePicker[0].endDate) {
      setDateRange(
        datePicker[0].startDate.toLocaleDateString('es-CL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }) +
          ' - ' +
          datePicker[0].endDate.toLocaleDateString('es-CL', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
      )
    }
  }, [datePicker])

  useEffect(() => {
    const startDate = datePicker[0].endDate
      ? datePicker[0].startDate.toLocaleDateString('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      : defaultDate[TPeriod].startDate.toLocaleDateString('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
    const endDate = datePicker[0].endDate
      ? datePicker[0].endDate.toLocaleDateString('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      : defaultDate[TPeriod].endDate.toLocaleDateString('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
    handleOnChange({ startDate, endDate, TPeriod })
  }, [datePicker, TPeriod, handleOnChange, defaultDate])

  return (
    <>
      <Row className="d-flex align-items-center justify-content-end">
        <Col
          xs="12 d-flex align-items-center justify-content-between"
          sm="auto"
        >
          <Label className="neue-light col-form-label text-light pr-1">
            Time period:
          </Label>
          <ButtonGroup className="btn-group-sm border rounded-sm">
            <Button
              color="light"
              type="button"
              onClick={async (e) => {
                e.preventDefault()
                setTPeriod(timePeriods[0])
                setIsActive({ ...{ 0: true } })
              }}
              active={isActive[0]}
            >
              {timePeriods[0] + 's'}
            </Button>
            <Button
              color="light"
              type="button"
              onClick={(e) => {
                e.preventDefault()
                setTPeriod(timePeriods[1])
                setIsActive({ ...{ 1: true } })
              }}
              active={isActive[1]}
            >
              {timePeriods[1] + 's'}
            </Button>
            <Button
              color="light"
              type="button"
              onClick={(e) => {
                e.preventDefault()
                setTPeriod(timePeriods[2])
                setIsActive({ ...{ 2: true } })
              }}
              active={isActive[2]}
            >
              {timePeriods[2] + 's'}
            </Button>
            <Button
              color="light"
              type="button"
              onClick={(e) => {
                e.preventDefault()
                setTPeriod(timePeriods[3])
                setIsActive({ ...{ 3: true } })
              }}
              active={isActive[3]}
            >
              {timePeriods[3] + 's'}
            </Button>
          </ButtonGroup>
        </Col>

        <Col
          xs="12 d-flex align-items-center justify-content-between"
          sm="auto"
        >
          <Label className="neue-light col-form-label text-light pr-1">
            Date range:
          </Label>
          <Dropdown isOpen={showDatePicker} toggle={toggle}>
            <DropdownToggle className="p-0" tag="a">
              <InputGroup className="input-group-sm input-group-merge input-group-alternative">
                <Input
                  className="neue-light px-2"
                  type="text"
                  value={dateRange}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText
                    style={{
                      borderTopRightRadius: '0.25rem',
                      borderBottomRightRadius: '0.25rem'
                    }}
                  >
                    <i className="ni ni-calendar-grid-58 align-self-center" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </DropdownToggle>
            <DropdownMenu right={true} className="p-0 m-0">
              <DropdownItem toggle={false} className="p-0 m-0">
                {showDatePicker && (
                  <DateRange
                    onChange={(item) => {
                      setDatePicker([item.selection])
                      if (
                        !isEqual(
                          item.selection.startDate,
                          item.selection.endDate
                        )
                      ) {
                        setShowDatePicker(false)
                      }
                    }}
                    months={1}
                    minDate={addDays(new Date(), -900)}
                    maxDate={new Date()}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    ranges={datePicker}
                  />
                )}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
    </>
  )
}

export default DatePicker
