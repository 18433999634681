/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react'

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap'

function AuthFooter() {
  return (
    <>
      <footer className="pt-6" id="footer-main">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col>
              <div className="copyright text-center text-lg-center text-muted">
                ©️Copyright {new Date().getFullYear()} <span>powered by</span>
                <a
                  className="font-weight-bold ml-1 text-muted"
                  href="https://www.imzi.com/"
                  target="_blank"
                >
                  imzi
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default AuthFooter
