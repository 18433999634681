import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'

import { Pie } from 'react-chartjs-2'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { colors } from 'variables/charts.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
function UsageOverview() {
  const pieLabels = ['0-50', '50-80', '80-95', '95-99', 'AT100']
  const [UsageStats, setUsageStats] = useState({})
  const [loading, setLoading] = useState(true)
  const { getDashboardUsageStats } = useDashboardContext()
  const loadUsageStats = useCallback(async () => {
    // setLoading(true)
    await getDashboardUsageStats()
      .then((response) => {
        setUsageStats(response)
        //console.log('setUsageStats', response)
      })
      .catch((err) => {
        console.log('error PAYG Stats:', err)
      })
  }, [getDashboardUsageStats])

  useEffect(() => {
    console.log('load UsageOverview')
    setLoading(true)
    loadUsageStats()
    setLoading(false)
  }, [loadUsageStats])
  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Usage Overview (%)</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Pie
              data={{
                labels: pieLabels,
                datasets: [
                  {
                    data: [
                      UsageStats['0-50'],
                      UsageStats['50-80'],
                      UsageStats['80-95'],
                      UsageStats['95-99'],
                      UsageStats['AT100']
                    ],
                    backgroundColor: [
                      colors.theme['primary'],
                      colors.theme['secondary'],
                      colors.theme['success'],
                      colors.theme['warning'],
                      colors.theme['danger']
                    ],
                    label: 'Dataset 1'
                  }
                ]
              }}
              options={{
                responsive: true,
                legend: {
                  position: 'right'
                },
                animation: {
                  animateScale: true,
                  animateRotate: true
                },
                onClick: (e, element) => {
                  console.log('getevent', e)
                  if (element.length === 0) return
                  const { _datasetIndex, _index } = element[0]
                  console.log('Label', pieLabels[_index])
                }
              }}
              className="chart-canvas"
              id="chart-pie"
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default UsageOverview
