import { Bar } from 'react-chartjs-2'
import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { colors } from 'variables/charts.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { useHistory } from 'react-router-dom'
function CostCtrCreditBalanceChart({ costCtrId }) {
  const [costCtrCreditBalanceList, setCostCtrCreditBalanceList] = useState([])
  const { getCostCtrCreditBalance } = useDashboardContext()

  const history = useHistory()

  const loadCostCtrCreditBalance = useCallback(async () => {
    await getCostCtrCreditBalance({
      pageOffset: 0,
      rowsPerPage: 10,
      sortOrder: 'asc',
    })
      .then((response) => {
        setCostCtrCreditBalanceList(response.results)
        //console.log('set costCtr activation count: ', response)
      })
      .catch((err) => {
        console.log('error at loadCostCtrCreditBalance: ', err)
      })
  }, [getCostCtrCreditBalance])

  const getCostCtrLable = () => {
    const list = costCtrCreditBalanceList.map((data) => data.costCtrDescr)

    return list
  }

  const getCreditBalanceData = () => {
    const list = costCtrCreditBalanceList.map((data) => data.balance)

    return list
  }

  useEffect(() => {
    if (costCtrId) {
        loadCostCtrCreditBalance()
    }
  }, [loadCostCtrCreditBalance, costCtrId])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">Credit Balance</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
              <Bar
                data={{
                  labels: getCostCtrLable(),
                  datasets: [
                    {
                      label: 'Credit Balance',
                      data: getCreditBalanceData()
                    }
                  ]
                }}
                options={{
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.gray[200],
                          zeroLineColor: colors.gray[200]
                        },
                        ticks: {
                          // beginAtZero: true
                        }
                      }
                    ]
                  },
                  onClick: (e, element) => {
                    console.log('getevent', e)
                    console.log(
                      'getelement',
                      element[0],
                      element[0]._index,
                      costCtrCreditBalanceList[element[0]._index],
                      'costCtrId: ' +
                      costCtrCreditBalanceList[element[0]._index].costCtrId
                    )
                    history.push({
                      pathname: '/admin/group/details',
                      state: {
                        costCtrId: costCtrCreditBalanceList[element[0]._index].costCtrId
                      }
                    })
                  }
                }}
                id="chart-credit-balance-count"
                className="chart-canvas"
              />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default CostCtrCreditBalanceChart
