import { IDashboardHandler, IHttpClient, IHttpResponse } from '../interfaces'
import { AuditLogList, HistoryData, SpendHistory, UserList } from '../models/models'
import {
  ActivateRequest,
  AddGroupRequest,
  GetHistoryDataRequest,
  ServiceDetailRequest,
  ServicesListRequest,
  SetATURequest,
  SetBarRequest,
  SimDetailsRequest,
  SIMInventoryRequest,
  UpdateServiceDetailRequest,
  SubtenantHistoryDataRequest,
  InvoiceRequest,
  costCtrSimpleRequest,
  costCtrDetailedRequest,
  VoucherDetailsRequest,
  UserListRequest,
  CreateAccountRequest,
  AddUserRequest,
  UserDetailsRequest,
  UpdateUserRequest,
  UpdateGroupRequest,
  UpdateUserStatusRequest,
  UpdateGroupStatusRequest,
  ActivationsByCostCtrRequest,
  ActivationsByChannelRequest,
  CostCtrCreditBalanceRequest,
  AdjustBalanceRequest,
  ProductListRequest,
  ApplyProductRequest,
  RoleListRequest,
  UsernameCheckRequest,
  DealerSimDetailsRequest,
  DealerUpdateSimDetailsRequest,
  GetTransactionHistoryRequest,
  ChangePasswordRequest,
  SendEmailVerificationRequest,
  CheckGroupNameRequest,
  GetTerminationDataRequest,
  CheckSimRequest,
  AuditLogListRequest,
} from '../models/requests'
import {
  ActivationResponse,
  CostCtrServiceCountResponse,
  CostCtrSimple,
  DashboardStatsResponse,
  GroupDetailsResponse,
  ProductResponse,
  ServiceDetailResponse,
  ServicesListResponse,
  SimDetailsResponse,
  SIMInventoryResponse,
  SpendByCostCtrResponse,
  ServiceCountPerPlanResponse,
  SubtenantHistoryDataResponse,
  InvoiceResponse,
  PreActiveSimsCountResponse,
  CostCtrDetail,
  VoucherDetailsResponse,
  UserDetailsResponse,
  UserListResponse,
  CreateAccountResponse,
  UpdateUserResponse,
  UserRoleResponse,
  UpdateGroupResponse,
  CostCtrBalancesResponse,
  ActivationsByCostCtrResponse,
  ActivationsByChannelResponse,
  CostCtrCreditBalanceResponse,
  ApplyProductResponse,
  UsernameCheckResponse,
  DealerSimDetailsResponse,
  AuditLogListResponse,
} from '../models/responses'

const DashboardHandler = (client: IHttpClient): IDashboardHandler => {
  const getInventory = async (
    request: SIMInventoryRequest
  ): Promise<IHttpResponse<SIMInventoryResponse>> => {
    console.log(request)
    const url =
      '/api/v1/dealer/sims/inventory?pageOffset=' +
      request.pageOffset +
      '&rowsPerPage=' +
      request.rowsPerPage +
      '&sortBy=' +
      request.sortBy +
      '&sortOrder=' +
      request.sortOrder +
      (request.iccidFilter ? '&iccidFilter=' + request.iccidFilter : '') +
      (request.statusFilter ? '&statusFilter=' + request.statusFilter : '') +
      (request.costCtrId ? '&costCtrId=' + request.costCtrId : '')
    return client.GET<SIMInventoryResponse>(url)
  }

  const getServicesList = async (
    request: ServicesListRequest
  ): Promise<IHttpResponse<ServicesListResponse>> => {
    const url =
      '/api/v1/dealer/dashboard/services/list?pageOffset=' +
      request.pageOffset +
      '&rowsPerPage=' +
      request.rowsPerPage +
      '&sortBy=' +
      request.sortBy +
      '&sortOrder=' +
      request.sortOrder +
      (request.groupNameFilter
        ? '&costCtrIdFilter=' + request.groupNameFilter
        : '') +
      (request.serviceStatusFilter
        ? '&serviceStatusFilter=' + request.serviceStatusFilter
        : '') +
      (request.planId ? '&planId=' + request.planId : '') +
      (request?.searchFilter ? '&searchFilter=' + request.searchFilter : '')
    return client.GET<ServicesListResponse>(url)
  }

  const getServiceDetail = async (
    request: ServiceDetailRequest
  ): Promise<IHttpResponse<ServiceDetailResponse>> => {
    const url = '/api/v1/dealer/dashboard/service/details?svcId=' + request.svcId
    return client.GET<ServiceDetailResponse>(url)
  }

  const getUserDetails = async (
    request: UserDetailsRequest
  ): Promise<IHttpResponse<UserDetailsResponse>> => {
    if (!request.authUserId) {
      console.log('no authUserId')
      const url = '/api/v1/user/detailsEx'
      return client.GET<UserDetailsResponse>(url)
    }
    const url = '/api/v1/user/detailsEx?authUserId=' + request.authUserId
    console.log(url)
    return client.GET<UserDetailsResponse>(url)
  }

  const setServiceATU = async (
    request: SetATURequest
  ): Promise<IHttpResponse<number>> => {
    const url = '/api/v1/dealer/ATU'
    return client.POST<number>(url, request)
  }

  const purchaseNewPlan = async (
    request: SetATURequest
  ): Promise<IHttpResponse<number>> => {
    const url = '/api/v1/dealer/plan'
    return client.POST<number>(url, request)
  }

  const setServiceBar = async (
    request: SetBarRequest
  ): Promise<IHttpResponse<number>> => {
    const url = '/api/v1/dealer/service/bar'
    return client.POST<number>(url, request)
  }

  const updateServiceDetail = async (
    request: UpdateServiceDetailRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/dealer/service/details', request)
  }

  const getCostCtrList = async (
    request: costCtrDetailedRequest
  ): Promise<IHttpResponse<CostCtrDetail[]>> => {
    let params = new URLSearchParams()
    console.log('request:', request)
    if (request.detailed) params.append('detailed', request.detailed)
    if (request.pageOffset >= 0)
      params.append('pageOffset', request.pageOffset.toString())
    if (request.rowsPerPage)
      params.append('rowsPerPage', request.rowsPerPage.toString())
    if (request.sortBy) params.append('sortBy', request.sortBy)
    if (request.sortOrder) params.append('sortOrder', request.sortOrder)
    if (request.searchFilter) params.append('searchFilter', request.searchFilter)
    if (request.statusFilter) params.append('statusFilter', request.statusFilter)
    const url = '/api/v1/dealer/costCtr/list?' + params.toString()
    return client.GET<CostCtrDetail[]>(url)
  }

  const getCostCtrListSimple = async (
    request: costCtrSimpleRequest
  ): Promise<IHttpResponse<CostCtrSimple[]>> => {
    let params = new URLSearchParams()
    if (request.costCtrId) params.append('costCtrId', request.costCtrId.toString())
    if (request.sortOrder) params.append('sortOrder', request.sortOrder)
    if (request.costCtrDescrFilter) params.append('costCtrDescrFilter', request.costCtrDescrFilter)
    if (request.costCtrType) params.append('costCtrType', request.costCtrType)
    const url = '/api/v1/dealer/costCtr/displayList?' + params.toString()
    return client.GET<CostCtrSimple[]>(url)
  }

  const getUserList = async (
    request: UserListRequest
  ): Promise<IHttpResponse<UserListResponse>> => {
    let params = new URLSearchParams()
    console.log('request:', request);
    if (request.costCtrId) params.append('costCtrId', request.costCtrId.toString())
    if (request.pageOffset >= 0) params.append('pageOffset', request.pageOffset.toString())
    if (request.rowsPerPage) params.append('rowsPerPage', request.rowsPerPage.toString())
    if (request.sortBy) params.append('sortBy', request.sortBy)
    if (request.sortOrder) params.append('sortOrder', request.sortOrder)
    if (request.searchFilter) params.append('searchFilter', request.searchFilter)
    if (request.statusFilter) params.append('statusFilter', request.statusFilter)
    if (request.typeFilter) params.append('typeFilter', request.typeFilter)
    const url = '/api/v1/dealer/user/list?' + params.toString()
    return client.GET<UserListResponse>(url)
  }
  
  const getProductList = async (
    request: ProductListRequest
  ): Promise<IHttpResponse<ProductResponse[]>> => {
    let params = new URLSearchParams()
    console.log('request1:', request);
    if (request.productType) {
      params.append('productType', request.productType)
    }
    if (request.webAvailable) {
      params.append('webAvailable', request.webAvailable)
    }
    const url = '/api/v1/dealer/products?' + params.toString()
    return client.GET<ProductResponse[]>(url)
  }

  const getAuditLogList = async (
    request: AuditLogListRequest
  ): Promise<IHttpResponse<AuditLogListResponse>> => {
    const url = '/api/v1/dealer/auditLog/list'
    return client.POST<AuditLogListResponse>(url, request)
  }

  const activateSim = async (
    request: ActivateRequest
  ): Promise<IHttpResponse<ActivationResponse>> => {
    const url = '/api/v1/dealer/activate'
    return client.POST<ActivationResponse>(url, request)
  }

  const checkSimSerial = async (
    request: CheckSimRequest
  ): Promise<IHttpResponse<number>> => {
    const url = '/api/v1/dealer/sim/check'
    return client.POST<number>(url, request)
  }

  const getSimDetails = async (
    request: SimDetailsRequest
  ): Promise<IHttpResponse<SimDetailsResponse>> => {
    const url = '/api/v1/dealer/sim/details?iccidFilter=' + request.iccid
    return client.GET<SimDetailsResponse>(url)
  }

  const getGroupDetails = async (
    costCtrId: string
  ): Promise<IHttpResponse<GroupDetailsResponse>> => {
    const url = '/api/v1/dealer/details?costCtrId=' + costCtrId
    console.log('url: ', url)
    return client.GET<GroupDetailsResponse>(url)
  }

  const updateGroup = async (
    request: UpdateGroupRequest
  ): Promise<IHttpResponse<UpdateGroupResponse>> => {
    return client.POST<UpdateGroupResponse>('/api/v1/dealer/costCtr/update', request)
  }

  const addGroup = async (
    request: AddGroupRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/dealer/costCtr/add', request)
  }

  const updateGroupStatus = async (
    request: UpdateGroupStatusRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/dealer/costCtr/status', request)
  }

  const addUser = async (
    request: AddUserRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/user/addEx', request)
  }

  const updateUser = async (
    request: UpdateUserRequest
  ): Promise<IHttpResponse<UpdateUserResponse>> => {
    return client.POST<UpdateUserResponse>('/api/v1/user/updateEx', request)
  }

  const updateUserStatus = async (
    request: UpdateUserStatusRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/user/status/update', request)
  }

  const adjustBalance = async (
    request: AdjustBalanceRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/dealer/costCtr/balance/manage', request)
  }

  const getServiceHistory = async (
    request: GetHistoryDataRequest
  ): Promise<IHttpResponse<HistoryData[]>> => {
    console.log('service history costCtrId: ' + request.costCtrId)
    const url =
      '/api/v1/dealer/dashboard/service/history?startDate=' +
      request.startDate +
      '&endDate=' +
      request.endDate +
      '&timePeriod=' +
      request.timePeriod +
      (request.costCtrId ? '&costCtrId=' + request.costCtrId : '')
    return client.GET<HistoryData[]>(url)
  }

  const getActivationHistory = async (
    request: GetHistoryDataRequest
  ): Promise<IHttpResponse<HistoryData[]>> => {
    const url =
      '/api/v1/dealer/dashboard/service/activationHistory?startDate=' +
      request.startDate +
      '&endDate=' +
      request.endDate +
      '&timePeriod=' +
      request.timePeriod +
      (request.costCtrId ? '&costCtrId=' + request.costCtrId : '')
    return client.GET<HistoryData[]>(url)
  }

  const getTerminationHistory = async (
    request: GetTerminationDataRequest
  ): Promise<IHttpResponse<HistoryData[]>> => {
    const url =
      '/api/v1/dealer/dashboard/termination/history?startDate=' +
      request.startDate +
      '&endDate=' +
      request.endDate +
      '&timePeriod=' +
      request.timePeriod +
      (request.costCtrId ? '&costCtrId=' + request.costCtrId : '')
    return client.GET<HistoryData[]>(url)
  }

  // const getDashboardSummary = async (): Promise<
  //   IHttpResponse<DashboardSummaryResponse>
  // > => {
  //   return client.GET<DashboardSummaryResponse>('/api/v1/dealer/dashboard/summary')
  // }

  const getDashboardPAYGStats = async (): Promise<
    IHttpResponse<DashboardStatsResponse>
  > => {
    return client.GET<DashboardStatsResponse>('/api/v1/dealer/dashboard/PAYGStats')
  }

  const getDashboardUsageStats = async (): Promise<
    IHttpResponse<DashboardStatsResponse>
  > => {
    return client.GET<DashboardStatsResponse>('/api/v1/dealer/dashboard/usageStats')
  }

  const getTotalActivatedServices = async (
    costCtrId?: string
  ): Promise<IHttpResponse<number>> => {
    if (!costCtrId) {
      return client.GET<number>('/api/v1/dealer/dashboard/costCtr/activatedServices')
    } else {
      return client.GET<number>(
        '/api/v1/dealer/dashboard/costCtr/activatedServices?costCtrId=' + costCtrId
      )
    }
  }

  const getTotalPreActiveSims = async (
    costCtrId?: string
  ): Promise<IHttpResponse<number>> => {
    if (!costCtrId) {
      return client.GET<number>('/api/v1/dealer/dashboard/costCtr/preActiveSims')
    } else {
      return client.GET<number>(
        '/api/v1/dealer/dashboard/costCtr/preActiveSims?costCtrId=' + costCtrId
      )
    }
  }

  const getTotalSpend = async (
    costCtrId?: string
  ): Promise<IHttpResponse<number>> => {
    if (!costCtrId) {
      return client.GET<number>('/api/v1/dealer/dashboard/costCtr/currentSpend')
    } else {
      return client.GET<number>(
        '/api/v1/dealer/dashboard/costCtr/currentSpend?costCtrId=' + costCtrId
      )
    }
  }

  const getTotalRetailSpend = async (
    costCtrId?: string
  ): Promise<IHttpResponse<number>> => {
    if (!costCtrId) {
      return client.GET<number>('/api/v1/dealer/dashboard/costCtr/retailSpend')
    } else {
      return client.GET<number>(
        '/api/v1/dealer/dashboard/costCtr/retailSpend?costCtrId=' + costCtrId
      )
    }
  }

  const getCostCtrServiceCount = async (): Promise<
    IHttpResponse<CostCtrServiceCountResponse>
  > => {
    return client.GET<CostCtrServiceCountResponse>(
      '/api/v1/dealer/dashboard/costCtr/serviceCount'
    )
  }

  const getNoRechargeServiceCount = async (): Promise<
    IHttpResponse<CostCtrServiceCountResponse>
  > => {
    return client.GET<CostCtrServiceCountResponse>(
      '/api/v1/dealer/dashboard/costCtr/noRecharge'
    )
  }

  const getSpendByCostCtr = async (): Promise<
    IHttpResponse<SpendByCostCtrResponse>
  > => {
    return client.GET<SpendByCostCtrResponse>('/api/v1/dealer/dashboard/costCtr/spend')
  }

  const getPreActiveSimsByCostCtr = async (): Promise<
    IHttpResponse<PreActiveSimsCountResponse>
  > => {
    return client.GET<PreActiveSimsCountResponse>(
      '/api/v1/dealer/dashboard/costCtr/preActiveServices'
    )
  }

  const getCostCtrBalances = async (): Promise<
    IHttpResponse<CostCtrBalancesResponse[]>
  > => {
    return client.GET<CostCtrBalancesResponse[]>(
      '/api/v1/dealer/dashboard/costCtr/balance'
    )
  }

  const getSubtenantNoRecharge = async (
    request: SubtenantHistoryDataRequest
  ): Promise<IHttpResponse<SubtenantHistoryDataResponse[]>> => {
    return client.GET<SubtenantHistoryDataResponse[]>(
      '/api/v1/dealer/dashboard/costCtr/subtenant/noRecharge?costCtrId=' +
        request.costCtrId +
        '&startDate=' +
        request.startDate +
        '&endDate=' +
        request.endDate +
        '&timePeriod=' +
        request.timePeriod
    )
  }

  const getSubtenantServiceCountPerPlan = async (
    costCtrId: string
  ): Promise<IHttpResponse<ServiceCountPerPlanResponse>> => {
    return client.GET<ServiceCountPerPlanResponse>(
      '/api/v1/dealer/dashboard/costCtr/subtenant/planCount?costCtrId=' + costCtrId
    )
  }

  const getSubtenantPreActiveSimsHistory = async (
    request: SubtenantHistoryDataRequest
  ): Promise<IHttpResponse<SubtenantHistoryDataResponse[]>> => {
    return client.GET<SubtenantHistoryDataResponse[]>(
      '/api/v1/dealer/dashboard/costCtr/subtenant/preActiveServices?costCtrId=' +
        request.costCtrId +
        '&startDate=' +
        request.startDate +
        '&endDate=' +
        request.endDate +
        '&timePeriod=' +
        request.timePeriod
    )
  }

  const getSubtenantSpendHistory = async (
    request: SubtenantHistoryDataRequest
  ): Promise<IHttpResponse<SpendHistory[]>> => {
    return client.GET<SpendHistory[]>(
      '/api/v1/dealer/dashboard/costCtr/subtenant/spend?costCtrId=' +
        request.costCtrId +
        '&startDate=' +
        request.startDate +
        '&endDate=' +
        request.endDate +
        '&timePeriod=' +
        request.timePeriod
    )
  }

  const getActivationsByCostCtr = async (
    request: ActivationsByCostCtrRequest
  ): Promise<IHttpResponse<ActivationsByCostCtrResponse>> => {
    let params = new URLSearchParams()
    // console.log('request:', request);
    if (request.pageOffset >= 0) params.append('pageOffset', request.pageOffset.toString())
    if (request.rowsPerPage) params.append('rowsPerPage', request.rowsPerPage.toString())
    if (request.sortOrder) params.append('sortOrder', request.sortOrder)
    if (request.startDate) params.append('startDate', request.startDate)
    if (request.endDate) params.append('endDate', request.endDate)
    if (request.timePeriod) params.append('timePeriod', request.timePeriod)
    const url = '/api/v1/dealer/dashboard/costCtr/activations?' + params.toString()
    return client.GET<ActivationsByCostCtrResponse>(url)
  }
  
  const getActivationsByChannel = async (
    request: ActivationsByChannelRequest
  ): Promise<IHttpResponse<ActivationsByChannelResponse>> => {
    let params = new URLSearchParams()
    // console.log('request:', request);
    if (request.pageOffset >= 0) params.append('pageOffset', request.pageOffset.toString())
    if (request.rowsPerPage) params.append('rowsPerPage', request.rowsPerPage.toString())
    if (request.sortOrder) params.append('sortOrder', request.sortOrder)
    if (request.startDate) params.append('startDate', request.startDate)
    if (request.endDate) params.append('endDate', request.endDate)
    if (request.timePeriod) params.append('timePeriod', request.timePeriod)
    const url = '/api/v1/dealer/dashboard/channel/activations?' + params.toString()
    return client.GET<ActivationsByChannelResponse>(url)
  }
  
  const getCostCtrCreditBalance = async (
    request: CostCtrCreditBalanceRequest
  ): Promise<IHttpResponse<CostCtrCreditBalanceResponse>> => {
    let params = new URLSearchParams()
    if (request.pageOffset >= 0) params.append('pageOffset', request.pageOffset.toString())
    if (request.rowsPerPage) params.append('rowsPerPage', request.rowsPerPage.toString())
    if (request.sortOrder) params.append('sortOrder', request.sortOrder)
    const url = '/api/v1/dealer/dashboard/costCtr/credit/balance?' + params.toString()
    return client.GET<CostCtrCreditBalanceResponse>(url)
  }
    
  const getInvoice = async (
    request: InvoiceRequest
  ): Promise<IHttpResponse<InvoiceResponse>> => {
    return client.GET<InvoiceResponse>(
    '/api/v1/dealer/dashboard/costCtr/invoice?costCtrId=' +
    request.costCtrId +
    '&detailed=' +
    request.detailed
    )
  }
    
  const getSpreadsheet = async (): Promise<IHttpResponse<Blob>> => {
    return client.GET<Blob>('/api/v1/dealer/spreadsheet/costCtr')
  }
        
  const getVoucherDetails = async (
    request: VoucherDetailsRequest
  ): Promise<IHttpResponse<VoucherDetailsResponse>> => {
  return client.GET<VoucherDetailsResponse>(
    '/api/v1/dealer/voucher/details?voucherPin=' +
    request.voucherPin +
    '&msisdn=' +
    request.msisdn
    )
  }

  const applyVoucher = async (
    request: VoucherDetailsRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/dealer/voucher/apply', request)
  }

  const applyProduct = async (
    request: ApplyProductRequest
  ): Promise<IHttpResponse<any>> => {
    return client.POST<any>('/api/v1/dealer/plan', request)
  }

  const getRoleList = async (
    request: RoleListRequest
  ): Promise<IHttpResponse<UserRoleResponse[]>> => {
    const url = '/api/v1/user/roles?interface=' +
    request.interface
    return client.GET<UserRoleResponse[]>(url)
  }

  const usernameCheck = async (
    request: UsernameCheckRequest
  ): Promise<IHttpResponse<number> > => {
    return client.POST<number>('/api/v1/user/email/check', request)
  }

  const getDealerSimDetails = async (
    request: DealerSimDetailsRequest
  ): Promise<IHttpResponse<DealerSimDetailsResponse>> => {
    const url = '/api/v1/dealer/sim/details?iccidFilter=' +
    request.iccidFilter
    return client.GET<DealerSimDetailsResponse>(url)
  }

  const updateDealerSimDetails = async (
    request: DealerUpdateSimDetailsRequest
  ): Promise<IHttpResponse<any>> => {
    return client.POST<any>('/api/v1/dealer/sim/update', request)
  }

  const getTransactionHistory = async (
    request: GetTransactionHistoryRequest
  ): Promise<IHttpResponse<any>> => {
    const url = '/api/v1/dealer/costCtr/balance/history?' +
    'pageOffset=' + request.pageOffset +
    '&rowsPerPage=' + request.rowsPerPage +
    '&sortBy=' + request.sortBy +
    '&sortOrder=' + request.sortOrder +
    '&costCtrId=' + request.costCtrId +
    '&balanceType=' + request.balanceType +
    '&typeFilter=' + request.typeFilter +
    '&searchFilter=' + request.searchFilter
    return client.GET<any>(url)
  }

  const changePassword = async (
    request: ChangePasswordRequest
  ): Promise<IHttpResponse<any>> => {
    const url = '/api/v1/dealer/user/change-password'
    return client.POST<any>(url, request)
  }

  const sendEmailVerification = async (
    request: SendEmailVerificationRequest
  ): Promise<IHttpResponse<any>> => {
    const url = '/api/v1/auth/otp/generate'
    return client.POST<any>(url, request)
  }

  const checkGroupName = async (
    request: CheckGroupNameRequest
  ): Promise<IHttpResponse<number>> => {
    return client.POST<number>('/api/v1/dealer/costCtrDescr/check', request)
  }

  return {
    getInventory,
    getServicesList,
    getServiceDetail,
    getUserDetails,
    setServiceATU,
    setServiceBar,
    getCostCtrList,
    getUserList,
    getAuditLogList,
    getProductList,
    activateSim,
    checkSimSerial,
    getSimDetails,
    getGroupDetails,
    updateGroup,
    updateServiceDetail,
    addGroup,
    updateGroupStatus,
    addUser,
    updateUser,
    updateUserStatus,
    adjustBalance,
    getServiceHistory,
    getActivationHistory,
    getTerminationHistory,
    // getDashboardSummary,
    purchaseNewPlan,
    getDashboardPAYGStats,
    getDashboardUsageStats,
    getCostCtrListSimple,
    getTotalActivatedServices,
    getTotalPreActiveSims,
    getTotalSpend,
    getTotalRetailSpend,
    getCostCtrServiceCount,
    getSpendByCostCtr,
    getPreActiveSimsByCostCtr,
    getCostCtrBalances,
    getNoRechargeServiceCount,
    getSubtenantNoRecharge,
    getSubtenantServiceCountPerPlan,
    getSubtenantPreActiveSimsHistory,
    getSubtenantSpendHistory,
    getActivationsByCostCtr,
    getActivationsByChannel,
    getCostCtrCreditBalance,
    getInvoice,
    getSpreadsheet,
    getVoucherDetails,
    applyVoucher,
    applyProduct,
    getRoleList,
    usernameCheck,
    getDealerSimDetails,
    updateDealerSimDetails,
    getTransactionHistory,
    changePassword,
    sendEmailVerification,
    checkGroupName
  }
}

export default DashboardHandler
