

export const validateName = (value) =>
    value.replace(/[^a-zA-Z'\-\s]/g, '').substr(0, 60);

export const isEmailValid = (value) =>
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}$/i.test(value);

// value.replace(PHONE_NUMBER_FORMAT_REGEX, '$1$2 $3 $4 $5');

export const isBalanceAmountValid = (value) =>
    /^([0-9]{1,5})(\.\d\d)?$/.test(value);

export const isPhoneNumberValid = (value) =>
    /^(0)([0-9]{9})$/.test(value);

export const isMobileNumberValid = (value) =>
    /^(04)([0-9]{8})$/i.test(value);

const pwlowercaseRgx = /[a-z]/g;    
const pwuppercaseRgx = /[A-Z]/g;
const pwnumberRgx = /[0-9]/g;
const pwspecialRgx = /[!@#$%&*?]/g;

const countMatches = (str, rgx) => {
    return ((str || '').match(rgx) || []).length;
};

const isLengthMatches = (
    string = '',
    minLength = 0,
    maxLength = Number.MAX_VALUE
) => {
    if (string.length >= minLength && string.length <= maxLength) {
        return true;
    }
    return false;
};

const isLowerCaseMatches = (string, lowerMatches = 1) => {
    if (countMatches(string, pwlowercaseRgx) < lowerMatches) {
        return false;
    }
    return true;
};

const isUpperCaseMatches = (string, upperMatches = 1) => {
    if (countMatches(string, pwuppercaseRgx) < upperMatches) {
        return false;
    }
    return true;
};

const isNumberMatches = (string, numberMatches = 1) => {
    if (countMatches(string, pwnumberRgx) < numberMatches) {
        return false;
    }
    return true;
};

const isSpecialMatches = (string, specialMatches = 1) => {
    if (countMatches(string, pwspecialRgx) < specialMatches) {
        return false;
    }
    return true;
};

export const validatePassword = (password) => {
    // Use instead of react-hook-form to validate multiple errors at once.
    const length = password && isLengthMatches(password, 12, 30);
    const uppercase = password && isUpperCaseMatches(password);
    const number = password && isNumberMatches(password);
    const lowercase = password && isLowerCaseMatches(password);
    const special = password && isSpecialMatches(password);
    const all = length && uppercase && number && lowercase && special;

    return {length, uppercase, number, lowercase, special, all};
};

export default {validateName};
