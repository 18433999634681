import React, { useEffect, useState } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  Table
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useHistory } from 'react-router-dom'
import { dollarAmountFormat } from '../../../utils/Utils'
import { useDashboardContext } from '../../../contexts/DashboardContext'
import { useActivationContext } from '../../../contexts/ActivationContext'

function Success({ location }) {
  const {
    msisdn,
    sim,
    subscriberDetails,
    email,
    // mobileType,
    // password,
    // address,
    phoneNumber,
    // acctCostCtrId,
    portInNumber,
    activationType,
    planDetails,
    accountNumber
  } = location.state

  const history = useHistory()

  const onSubmit = async () => {
    history.push({
      pathname: '/admin/search/activate'
    })
  }

  return (
    <>
      <SimpleHeader name="Activation Success!" />
      <Container
        fluid
        className="d-flex align-items-center justify-content-start flex-column"
      >
        <Card style={{ width: '100%' }}>
          <Row className="align-items-center bg-success">
            <h className="ml-3 text-white">Activation Successfully Processed</h>
          </Row>
          <CardHeader>
            <Row className="align-items-center">
              <Col md="3">
                <h3 className="mb-4 text-primary neue-bold letter-4">
                  Subscriber Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            >
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">Mobile Number</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{msisdn}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">SIM Card Number</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{sim}</h>
                </Col>
              </Row>
              {!!portInNumber && (
                <>
                <Row className="mb-4 d-flex align-items-center">
                  <Col md="2">
                    <h className="neue-bold">PortIn Number</h>
                  </Col>
                  <Col md="9">
                    <h className="letter-4">{portInNumber}</h>
                  </Col>
                  {/* <Col className="d-flex align-items-center letter-4" md={8}>
                    <h>{portInNumber}</h>
                  </Col> */}
                </Row>
                <Row className="mb-4 d-flex align-items-center">
                  <Col md="2">
                    <h className="neue-bold">PortIn Account Type</h>
                  </Col>
                  <Col className="d-flex align-items-center" md={9}>
                    <h className="letter-4">
                      {activationType === 'portfromprepaid'
                        ? `Pre-Paid`
                        : `Post-Paid(AN: ${accountNumber})`}
                    </h>
                  </Col>
                </Row>
                </>
              )}
              <Row className="mb-4 d-flex align-items-center">
                <Col md="2">
                  <h className="neue-bold">Name</h>
                </Col>
                <Col className="d-flex align-items-center" md={9}>
                  <h className="letter-4">
                    {subscriberDetails.title}{' '}
                    {subscriberDetails.firstName}{' '}
                    {subscriberDetails.middleName}{' '}
                    {subscriberDetails.lastName}
                  </h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">Email</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{email}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">Contact Number</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{phoneNumber}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                 <Col md="2">
                   <h className="neue-bold">Plan Name</h>
                 </Col>
                 <Col md="9">
                   <h className="letter-4">{planDetails.planName}</h>
                 </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">Plan Price</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{'$ '}{dollarAmountFormat.format(planDetails.planValue)}</h>
                </Col>
              </Row>
              <Row>
                <Button color="secondary" className="" type="submit">
                  Activate Another Service
                </Button>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Success
