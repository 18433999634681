import { useEffect, useState, useCallback } from 'react'
import { useDashboardContext } from '../../contexts/DashboardContext'
import SummartCard from './SummaryCard'
import { useHistory } from 'react-router-dom'

function PreActiveSimsCard({ costCtrId }) {
  const [preActiveSims, setPreActiveSims] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { getTotalPreActiveSims } = useDashboardContext()
  const history = useHistory()

  const loadNumberOfPreActiveSims = useCallback(async () => {
    setIsLoading(true)
    await getTotalPreActiveSims(costCtrId)
      .then((response) => {
        setPreActiveSims(response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [getTotalPreActiveSims, costCtrId])

  const handleClick = useCallback(() => {
    console.log('pre active sims card clicked')
    history.push({
      pathname: '/admin/inventory',
      state: {
        costCtrId: costCtrId
      }
    })
  }, [costCtrId, history])

  useEffect(() => {
    loadNumberOfPreActiveSims()
  }, [loadNumberOfPreActiveSims])
  return (
    <SummartCard
      title="PreActive Sims"
      value={preActiveSims}
      isLoading={isLoading}
      handleClick={handleClick}
    />
  )
}

export default PreActiveSimsCard
