import {
  IAuthHandler,
  IHttpClient,
  IHttpResponse,
  IToken
} from 'services/sdk/interfaces'
import { UpdateEmailVerificationStatusRequest } from '../models/requests'

const AuthHandler = (client: IHttpClient): IAuthHandler => {
  const authenticate = async (
    username: string,
    password: string
  ): Promise<IHttpResponse<IToken>> => {
    return client.POST<IToken>('/api/v1/auth/login', {
      username,
      password
    })
    // .then((response) => {
    //     if (response.ok && response.response) {
    //         handleAuthResponse(response.response);
    //     }
    //     return response;
    // });
  }

  const sendResetPasswordLink = async (
      email: string
    ): Promise<IHttpResponse<number>> => {
    return client.POST('/api/v1/auth/otp/forgot-password', {
      email
    })
  }

  const resetPassword = async (
      otpCode: string, 
      password: string
    ): Promise<IHttpResponse<number>> => {
    return client.POST('/api/v1/auth/otp/change/password', {
      otpCode,
      password
    })
  }

  const updateEmailVerificationStatus = async (
    request: UpdateEmailVerificationStatusRequest
  ): Promise<IHttpResponse<any>> => {
    const url = '/api/v1/auth/otp/validate/email'
    return client.POST<any>(url, request)
  }

  return {
    authenticate,
    sendResetPasswordLink,
    resetPassword,
    updateEmailVerificationStatus
  }
}

export default AuthHandler
