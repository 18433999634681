import { useEffect, useState, useCallback } from 'react'
import { useDashboardContext } from '../../contexts/DashboardContext'
import SummartCard from './SummaryCard'

function CreditCard({ costCtrId }) {
   const [balances, setBalances] = useState({credit: ''})
//     deposit: [],
//     balance: []
// })
  const [isLoading, setIsLoading] = useState(false)
  const { getCostCtrBalances } = useDashboardContext()

  const loadRetailSpend = useCallback(async () => {
    setIsLoading(true)
    await getCostCtrBalances(costCtrId)
      .then((response) => {
        setBalances({
            credit: response
            .filter((item) => item.balanceType === 'CREDIT')
        })
        console.log('balances: ', balances)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [getCostCtrBalances, costCtrId])

  useEffect(() => {
    loadRetailSpend()
  }, [loadRetailSpend])
  return (
    <SummartCard
      title="Current Credit"
      value={'$ ' + balances.credit[0]?.balance ? '$ ' + balances.credit[0]?.balance : '$ 0.00'}
      isLoading={isLoading}
    />
  )
}

export default CreditCard