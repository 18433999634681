import { useCallback } from 'react'
import {
  ServiceHistoryLineChart,
  ActivationHistoryLineChart,
  ActivatedServicesCard,
  PreActiveSimsCard,
  CurrentSpendCard,
  RetailSpendCard,
  CreditCard,
  CostCtrServiceCountBarChart,
  PAYGStatus,
  UsageOverview,
  PreActiveSimsChart,
  SpendChart,
  NoRechargeChart,
  ActivationsByCostCtrChart,
  ActivationsByChannelChart,
  CostCtrCreditBalanceChart,
  TerminationHistoryLineChart
} from '../components/Dashboard'

export const FeatureComponent = {
  ServiceHistoryLineChart: 'ServiceHistoryLineChart',
  ActivationHistoryLineChart: 'ActivationHistoryLineChart',
  ActivatedServicesCard: 'ActivatedServicesCard',
  PreActiveSimsCard: 'PreActiveSimsCard',
  CurrentSpendCard: 'CurrentSpendCard',
  RetailSpendCard: 'RetailSpendCard',
  CreditCard: 'CreditCard',
  CostCtrServiceCountBarChart: 'CostCtrServiceCountBarChart',
  PAYGStatus: 'PAYG Stats',
  UsageOverview: 'Usage Stats',
  PreActiveSimsChart: 'PreActiveSimsChart',
  SpendChart: 'SpendChart',
  NoRechargeChart: 'NoChargeChart',
  ActivationsByCostCtrChart: 'ActivationsByCostCtrChart',
  ActivationsByChannelChart: 'ActivationsByChannelChart',
  CostCtrCreditBalanceChart: 'CostCtrCreditBalanceChart',
  TerminationHistoryLineChart: 'TerminationHistoryLineChart'
}

export const renderFeatureComponent = (name, props) => {
  switch (name) {
    case FeatureComponent.ServiceHistoryLineChart:
      return <ServiceHistoryLineChart {...props} />
    case FeatureComponent.ActivationHistoryLineChart:
      return <ActivationHistoryLineChart {...props} />
    case FeatureComponent.ActivatedServicesCard:
      return <ActivatedServicesCard {...props} />
    case FeatureComponent.PreActiveSimsCard:
      return <PreActiveSimsCard {...props} />
    case FeatureComponent.CurrentSpendCard:
      return <CurrentSpendCard {...props} />
    case FeatureComponent.RetailSpendCard:
      return <RetailSpendCard {...props} />
    case FeatureComponent.CreditCard:
      return <CreditCard {...props} />
    case FeatureComponent.CostCtrServiceCountBarChart:
      return <CostCtrServiceCountBarChart {...props} />
    case FeatureComponent.PAYGStatus:
      return <PAYGStatus {...props} />
    case FeatureComponent.UsageOverview:
      return <UsageOverview {...props} />
    case FeatureComponent.PreActiveSimsChart:
      return <PreActiveSimsChart {...props} />
    case FeatureComponent.SpendChart:
      return <SpendChart {...props} />
    case FeatureComponent.NoRechargeChart:
      return <NoRechargeChart {...props} />
    case FeatureComponent.ActivationsByCostCtrChart:
      return <ActivationsByCostCtrChart {...props} />
    case FeatureComponent.ActivationsByChannelChart:
      return <ActivationsByChannelChart {...props} />
    case FeatureComponent.CostCtrCreditBalanceChart:
      return <CostCtrCreditBalanceChart {...props} />
    case FeatureComponent.TerminationHistoryLineChart:
      return <TerminationHistoryLineChart {...props} />
    default:
      return null
  }
}
