import SimpleHeader from 'components/Headers/SimpleHeader.js'
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Col,
  Input,
  Row,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import PaginationService from '../../components/Table/PaginationService'
import RecordsDropdown from '../../components/Table/RecordsDropdown'

function TransactionHistoryTable({ costCtrId }) {
  const { getTransactionHistory } = useDashboardContext()
  const { getTransactionHistoryFeatures } = useAccessControlContext()

  const [transactionHistoryList, setTransactionHistoryList] = useState([])
  const [pageOffset, setPageOffset] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [maxResults, setMaxResults] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [rowsPerPageDropdownOpen, setRowsPerPageDropdownOpen] = useState(false)
  const [sortBy, setSortBy] = useState('tstampCreated')
  const [sortDesc, setSortDesc] = useState(true)
  const [typeFilter, setTypeFilter] = useState('All')
  const [typeFilterDropdownOpen, setTypeFilterDropdownOpen] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [transHistoryFeatures, setTransHistoryFeatures] = useState(
    getTransactionHistoryFeatures()
  )
  const [columns, setColumns] = useState(transHistoryFeatures?.columns)

  const columnNames = {
    tstampCreated: 'DATE',
    mvnoSvcId: 'ACCOUNT',
    subAdjustmentType: 'TYPE',
    planName: 'PRODUCT',
    adjustmentAmount: 'AMOUNT',
    username: 'USER',
    description: 'DESCRIPTION',
    openingBalance: 'OPEN',
    closingBalance: 'CLOSE'
  }

  const sortKeys = {
    tstampCreated: 'tstampCreated',
    mvnoSvcId: 'mvnoSvcId',
    subAdjustmentType: 'subAdjustmentType',
    planName: 'planName',
    adjustmentAmount: 'adjustmentAmount',
    username: 'username',
    description: 'description',
    openingBalance: 'openingBalance',
    closingBalance: 'closingBalance'
  }

  const sortable = {
    tstampCreated: true,
    mvnoSvcId: false,
    subAdjustmentType: false,
    planName: false,
    adjustmentAmount: false,
    username: true,
    description: false,
    openingBalance: false,
    closingBalance: false
  }

  const setSort = (sort) => {
    setPageOffset(0)
    if (sort === sortBy) {
      setSortDesc(!sortDesc)
    } else {
      setSortBy(sort)
      setSortDesc(true)
    }
  }

  useEffect(() => {
    const showTransactionHistory = async () => {
      await getTransactionHistory({
        pageOffset: pageOffset,
        rowsPerPage: rowsPerPage,
        sortBy: sortBy,
        sortOrder: sortDesc ? 'desc' : 'asc',
        costCtrId: costCtrId,
        balanceType: 'CREDIT',
        typeFilter: typeFilter,
        searchFilter: searchFilter
      })
        .then((res) => {
          setTransactionHistoryList(res.results)
          setMaxResults(res.maxResults)
          setTotalPages(Math.ceil(res.maxResults / rowsPerPage))
        })
        .catch((err) => {
          console.log('get transaction history failed', err)
          alert(err)
        })
    }
    showTransactionHistory()
  }, [
    getTransactionHistory,
    pageOffset,
    rowsPerPage,
    sortBy,
    sortDesc,
    costCtrId,
    typeFilter,
    searchFilter
  ])

  const changeRowsPerPage = (number) => {
    setPageOffset(0)
    setRowsPerPage(number)
  }

  const handleClickCurrentTransaction = (transactionHistory) => {
    console.log('current transactionHistory:', transactionHistory)
  }

  const recordFooter = () => {
    const first = maxResults === 0 ? 0 : pageOffset * rowsPerPage + 1
    const last = Math.min(pageOffset * rowsPerPage + rowsPerPage, maxResults)
    return (
      <span>
        showing {first} to {last} of {maxResults} entries
      </span>
    )
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col md="4">
              <RecordsDropdown
                dropdownOpen={rowsPerPageDropdownOpen}
                toggle={() =>
                  setRowsPerPageDropdownOpen(!rowsPerPageDropdownOpen)
                }
                setRowsPerPage={changeRowsPerPage}
                rowsPerPage={rowsPerPage}
              ></RecordsDropdown>
            </Col>
            <span className="text-primary">{'Filtered by: '}</span>
            <Dropdown
              isOpen={typeFilterDropdownOpen}
              toggle={() => setTypeFilterDropdownOpen(!typeFilterDropdownOpen)}
              className="ml-1"
            >
              <DropdownToggle color="light" caret>
                {typeFilter === 'All' ? 'Transaction type' : typeFilter}
              </DropdownToggle>
              <DropdownMenu className="text-primary">
                {/* 'Activation', 'Recharge', 'Reversal', 'Adjustment' */}
                <DropdownItem onClick={() => setTypeFilter('All')}>
                  All
                </DropdownItem>
                <DropdownItem onClick={() => setTypeFilter('Activation')}>
                  Activation
                </DropdownItem>
                <DropdownItem onClick={() => setTypeFilter('Recharge')}>
                  Recharge
                </DropdownItem>
                <DropdownItem onClick={() => setTypeFilter('Reversal')}>
                  Reversal
                </DropdownItem>
                <DropdownItem onClick={() => setTypeFilter('Adjustment')}>
                  Adjustment
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Col lg="3 ml-auto">
              <Input
                className="py-1"
                placeHolder="search by user name"
                type="search"
                onKeyPress={(event) => {
                  if (/[^a-zA-Z0-9_-\s]/gi.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                onChange={(e) => {
                  setPageOffset(0)
                  setSearchFilter(e.target.value)
                }}
              />
            </Col>
          </Row>
        </CardHeader>
        <div className="table-responsive">
          <Table hover className="align-items-center table-flush text-primary">
            <thead className="thead-light">
              <tr>
                {columns.map((column, index) => (
                  <th
                    scope="col"
                    className="sort"
                    data-sort={sortable[column] ? sortKeys[column] : null}
                    onClick={() =>
                      sortable[column] && setSort(sortKeys[column])
                    }
                    key={index}
                  >
                    {columnNames[column]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="list">
              {transactionHistoryList &&
                transactionHistoryList.map((ch) => {
                  return (
                    <tr
                      className="cursor-pointer"
                      key={ch.balanceHistoryId}
                      onClick={(e) => {
                        e.preventDefault()
                        handleClickCurrentTransaction(ch)
                      }}
                    >
                      {columns.includes('tstampCreated') && (
                        <td className="tstampCreated">{ch.tstampCreated}</td>
                      )}
                      {columns.includes('mvnoSvcId') && (
                        <td className="mvnoSvcId">{ch?.mvnoSvcId}</td>
                      )}
                      {columns.includes('subAdjustmentType') && (
                        <td className="subAdjustmentType">
                          {ch.subAdjustmentType}
                        </td>
                      )}
                      {columns.includes('planName') && (
                        <td className="planName">{ch.planName}</td>
                      )}
                      {columns.includes('adjustmentAmount') && (
                        <td className="adjustmentAmount">
                          {ch.adjustmentType === 'CREDIT'
                            ? '$' + ch.adjustmentAmount
                            : '-$' + ch.adjustmentAmount}
                        </td>
                      )}
                      {columns.includes('username') && (
                        <td className="username">{ch.username}</td>
                      )}
                      {columns.includes('description') && (
                        <td className="description">{ch.description}</td>
                      )}
                      {columns.includes('openingBalance') && (
                        <td className="openingBalance">
                          {'$' + ch.openingBalance}
                        </td>
                      )}
                      {columns.includes('closingBalance') && (
                        <td className="closingBalance">
                          {'$' + ch.closingBalance}
                        </td>
                      )}
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </div>
        <CardFooter className="py-4 flexbox-container">
          <Row className="d-flex align-items-center">
            <Col xs="12" md="4" xl="3" xxl="8">
              {recordFooter()}
            </Col>
            <Col sm="6" md="4" xl="9" xxl="2">
              <nav aria-label="...">
                <PaginationService
                  setPageOffset={setPageOffset}
                  pageOffset={pageOffset}
                  lastPage={totalPages}
                ></PaginationService>
              </nav>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  )
}

export default TransactionHistoryTable
