/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useCallback, useMemo } from 'react'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import { usePsmaContext } from 'contexts/PsmaContext'
import Address from 'services/sdk/models/address'
import useDebounce from 'hooks/useDebounce'
import { dateTimeFormatter } from '../../utils/Utils'


function Profile() {
  const { getGroupDetails, getUserDetails, updateUser } = useDashboardContext()
  const { authCostCtrId } = useAccessControlContext()
  const [costCenterType, setCostCenterType] = useState('User')
  const [groupDetails, setGroupDetails] = useState(null)
  const [userDetails, setUserDetails] = useState(null)
  const [editable, setEditable] = useState(false)
  const [originalUserDetails, setOriginalUserDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const { getAddressSuggestions, getAddress } = usePsmaContext()
  const [psmaError, setPsmaError] = useState(false)
  const [psmaErrorMessage, setPsmaErrorMessage] = useState('')
  const [suggestedAddresses, setSuggestedAddresses] = useState(null)
  const [addressQuery, setAddressQuery] = useState('')
  const [showAddressList, setShowAddressList] = useState(false)
  const [address, setAddress] = useState(null)
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [authUserId, setAuthUserId] = useState('')
  // const [showRoleList, setShowRoleList] = useState(false)
  const [roleId, setRole] = useState('')

  const MIN_LENGTH = 4 // PSMA wants at least 4 chars before returning results

  const loadProfile = useCallback(async () => {
    setLoading(true)
    const res = await getGroupDetails(authCostCtrId)
    const user = await getUserDetails({})
    console.log('company Details: ', res)
    console.log('user Details: ', user) 
    setGroupDetails(res)
    setUserDetails(user)
    setAuthUserId(user.authUserId)
    setRole(user.authRoleId)
    setOriginalUserDetails(user)
    setLoading(false)
  }, [getUserDetails, authCostCtrId, getGroupDetails])

  useEffect(() => {
    loadProfile()
  }, [getGroupDetails, authCostCtrId, getUserDetails])

  const editToggle = () => {
    setEditable((prevState) => !prevState)
  }

  const addressToggle = () => {
    setShowAddressList((prevState) => !prevState)
  }

  const handleUserDetailsChange = (key, value) => {
    // hack to deep copy object and avoid mutating state
    let updatedDetails = JSON.parse(JSON.stringify(userDetails));
    if (key.match(/\./)) {
      const path = key.split(/\./);
      const lastKey = path.pop();
      const nestedObj = path.reduce((a, prop) => a[prop], updatedDetails);
      nestedObj[lastKey] = value;
    } else {
      updatedDetails[key] = value;
    }
  
    setUserDetails({
      ...updatedDetails,
    });
  }

  const selectAddress = useCallback(async (item) => {
    setError(false)
    setLoading(true)

    await getAddress(item.id)
      .then((res) => {
        setLoading(false)
        setAddress(Address.ConstructFromPSMAAddress(res.address))
        handleUserDetailsChange('userDetails.address', {
          unitType: res.address.properties.complex_unit_type ? res.address.properties.complex_unit_type  : '' 
          ,unitNumber: res.address.properties.complex_unit_identifier ? res.address.properties.complex_unit_identifier : ''
          ,levelType: res.address.properties.complex_level_type ? res.address.properties.complex_level_type  : '' 
          ,levelNumber: res.address.properties.complex_level_number ? res.address.properties.complex_level_number : ''
          ,streetNumber: res.address.properties.street_number_1
          ,streetNumber2: res.address.properties.street_number_2 ? res.address.properties.street_number_2 : ''
          ,streetName: res.address.properties.street_name
          ,streetType: res.address.properties.street_type
          ,suburb: res.address.properties.locality_name
          ,state: res.address.properties.state_territory
          ,postcode: res.address.properties.postcode
      })
     }) 
      .catch((err) => {
        console.log(err)
        setLoading(false)
        setPsmaError(true)
        setPsmaErrorMessage(
          'Something went wrong with getting the address details, please try again later'
        )
      })
  }, [handleUserDetailsChange])

  const debouncedAddressQuery = useDebounce(addressQuery, 300)

  useEffect(() => {
    const getAddressList = async () => {
      if (
        debouncedAddressQuery === null ||
        debouncedAddressQuery.length < MIN_LENGTH
      ) {
        setSuggestedAddresses(null)
        setAddress(null)
        return
      }
  
      if (debouncedAddressQuery === address?.formattedAddress) {
        // if the address query is the same as the selected address, don't make a request again
        return
      }
  
      setLoading(true)
  
      await getAddressSuggestions(debouncedAddressQuery)
        .then((res) => {
          setLoading(false)
          setSuggestedAddresses(res.suggest)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          setPsmaError(true)
          setPsmaErrorMessage(
            'Something went wrong with getting the address suggestions list, please try again later'
          )
          setSuggestedAddresses(null)
        })
    }
  
    getAddressList()
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddressQuery])

  useEffect(() => {
    if (address) {
      console.log('selected address to mrtm:', address)
      // set the address query shown in Input field to the PSMA suggested address
      setAddressQuery(address.formattedAddress)
    }
  }, [address])
  
  // useEffect(() => {
  //   setLoading(true)
  //   const getRoles = async () => {
  //     await getRoleList()
  //       .then((role) => {
  //         setLoading(false)
  //         setRoleList(role)
  //         console.log('role: ', role)
  //       })
  //       .catch((err) => {
  //         setLoading(false)
  //         setError(true)
  //         setErrorMessage(err.message)
  //         setRoleList([])
  //       })
  //   }
  //   getRoles()
  // }, [getRoleList])

  const updateDetails = async () => {
    const updatedUserDetails = {
      firstName: userDetails?.userDetails.firstName,
      lastName: userDetails?.userDetails.lastName,
      address: userDetails?.userDetails.address,
      contact: userDetails?.userDetails.contact
    }
    setLoading(true)
    await updateUser({
      authUserId: authUserId,
      authRoleId: roleId,
      username: userDetails.username,
      userDetails: updatedUserDetails
    })
      .then((res) => {
        setLoading(false)
        setSuccess(true)
        setSuccessMessage('User details updated successfully')
        console.log('updated user details: ', res)
        getUserDetails({ authUserId })
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
      })
  }

  const isUsernameValid = useMemo(() => {
    const rgx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}$/
    return rgx.test(userDetails?.username)
  }, [userDetails?.username])

  // TODO: add some regex validation on first name and last name
  const isFirstNameValid = useMemo(() => {
    return true
  }, [userDetails?.userDetails?.firstName])

  const isLastNameValid = useMemo(() => {
    return true
  }, [userDetails?.userDetails?.lastName])

  const isPhoneValid = useMemo(() => {
    const rgx = /^(?:\d{8}|\d{10})$/
    return rgx.test(userDetails?.userDetails?.contact?.contactMobile)
  }, [userDetails?.userDetails?.contact?.contactMobile])

  return (
    <>
      <SimpleHeader
        name="Profile"
        description="Contextual information about profile page"
      />
      <Container fluid>
        <Card className="text-primary">
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  User Profile
                </h3>
              </Col>
              <Col className="text-right" xs="4">
                <Button color="success" disabled="true">
                  Change Password
                </Button>
              </Col>
            </Row>
          </CardHeader>
          {userDetails && (
            <CardBody>
            <Form>
              <div className="pl-lg-4">
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Username</h>
                </Col>
                <Col className="d-flex align-items-center" md={10}>
                  <h>{userDetails.username}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>First Name</h>
                </Col>
                {editable ? (
                  <Col md="6">
                    <Input
                      placeholder="First Name"
                      value={userDetails?.userDetails?.firstName}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.firstName', e.target.value)
                      }}
                      invalid={!!userDetails?.userDetails?.firstName && !isFirstNameValid}
                    >
                    </Input>
                    <div className="invalid-feedback">
                      Please enter a valid first name
                    </div>
                    {error && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                  </Col>
                ) : (
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{userDetails?.userDetails?.firstName}</h>
                  </Col>
                )}
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Last Name</h>
                </Col>
                {editable ? (
                  <Col md="6">
                    <Input
                      placeholder="Last Name"
                      value={userDetails?.userDetails.lastName}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.lastName', e.target.value)
                      }}
                      invalid={!!userDetails?.userDetails.lastName && !isLastNameValid}
                    >
                    </Input>
                    <div className="invalid-feedback">
                      Please enter a valid last name
                    </div>
                    {error && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                  </Col>
                ) : (
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{userDetails?.userDetails?.lastName}</h>
                  </Col>
                )}
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Last Login</h>
                </Col>
                <Col md="9">
                  <h>{userDetails.lastLogin === null ? '' : dateTimeFormatter(userDetails.lastLogin)}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Role</h>
                </Col>
                <Col className="d-flex align-items-center" md={10}>
                  <h>{userDetails.authRole ? userDetails.authRole: ''}</h>
                </Col>
              </Row>
              {editable ? (
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Address Search
                  </Label>
                  <Col md={6}>
                    <Dropdown
                      style={{ width: '100%' }}
                      isOpen={showAddressList}
                      toggle={addressToggle}
                    >
                      <DropdownToggle tag="a">
                        <Input
                          id="input-address"
                          placeholder="Address Search"
                          type="input"
                          required={true}
                          autoComplete='off'
                          value={addressQuery}
                          invalid={!!addressQuery && !address && !psmaError}
                          onChange={(e) => {
                            console.log('address change', e.target.value)
                            setPsmaError(false)
                            setPsmaErrorMessage('')
                            setAddressQuery(e.target.value)
                          }}
                        />
                        <div className="invalid-feedback">
                          Please choose an address from the list
                        </div>
                        {psmaError && psmaErrorMessage && (
                          <div className="error-message">
                            {psmaErrorMessage}
                          </div>
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        {suggestedAddresses ? (
                          suggestedAddresses.length > 0 ? (
                            suggestedAddresses.map((item) => (
                              <DropdownItem
                                key={item.id}
                                onClick={(e) => {
                                  selectAddress(item)
                                }}
                              >
                                {item.address}
                              </DropdownItem>
                            ))
                          ) : (
                            <></>
                          )
                        ) : (
                          <DropdownItem>
                            Start by typing your address
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </FormGroup>
              ) : (
                <>
                </>
              )}
              {!editable ? (
                <Row className="mb-4 align-items-center">
                  <Col md="2">
                    <h>Address</h>
                  </Col>
                  <Col className="d-flex align-items-center" md={10}>
                    <h>
                      {userDetails.userDetails.address.unitNumber}{' '}
                      {userDetails.userDetails.address.streetNumber}{' '}
                      {userDetails.userDetails.address.streetName}{' '}
                      {userDetails.userDetails.address.streetType}{' '}
                      {userDetails.userDetails.address.suburb}{' '}
                      {userDetails.userDetails.address.state}{' '}
                      {userDetails.userDetails.address.postcode}
                    </h>
                  </Col>
                </Row>
              ) : (
                <>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Shop/Unit/Level
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Shop/Unit/Level"
                      value={userDetails.userDetails.address.unitNumber ? userDetails.userDetails.address.unitNumber : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.unitNumber', e.target.value)
                      } }
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Number
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Number"
                      value={userDetails.userDetails.address.streetNumber ? userDetails.userDetails.address.streetNumber : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.streetNumber', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Name
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Name"
                      value={userDetails.userDetails.address.streetName ? userDetails.userDetails.address.streetName : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.streetName', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Street Type
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Street Type"
                      value={userDetails.userDetails.address.streetType ? userDetails.userDetails.address.streetType : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.streetType', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    Suburb
                  </Label>
                  <Col md="6">
                    <Input
                      placeholder="Suburb"
                      value={userDetails.userDetails.address.suburb ? userDetails.userDetails.address.suburb : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.suburb', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label className="letter-4" md={2}>
                    State/Postcode
                  </Label>
                  <Col md="3">
                    <Input
                      placeholder="State"
                      value={userDetails.userDetails.address.state ? userDetails.userDetails.address.state : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.state', e.target.value)
                      }}
                    ></Input>
                  </Col>
                  <Col md="3">
                    <Input
                      placeholder="Postcode"
                      value={userDetails.userDetails.address.postcode ? userDetails.userDetails.address.postcode : ''}
                      onChange={(e) => {
                        setError(false)
                        setErrorMessage('')
                        handleUserDetailsChange('userDetails.address.postcode', e.target.value)
                      }}
                    ></Input>
                  </Col>
                </FormGroup>
                </>
              )}
                  <Row className="mb-4 align-items-center">
                  <Col md="2">
                    <h>Phone Number</h>
                  </Col>
                  {editable ? (
                    <Col md="6">
                      <Input
                        placeholder="Phone Number"
                        value={userDetails.userDetails.contact.contactMobile}
                        onChange={(e) => {
                          setError(false)
                          setErrorMessage('')
                          handleUserDetailsChange('userDetails.contact.contactMobile', e.target.value)
                        }}
                        invalid={!isPhoneValid && !!userDetails?.userDetails?.contact?.contactMobile}
                        maxLength={10}
                      ></Input>
                      <div className="invalid-feedback">
                        Please enter a valid phone number
                      </div>
                    </Col>
                  ) : (
                    <Col className="d-flex align-items-center" md={10}>
                      <h>{userDetails.userDetails.contact.contactMobile}</h>
                    </Col>
                  )}
                </Row>
                <FormGroup row>
                  {!editable ? (
                    <Col className="d-flex justify-content-start">
                      <Button 
                        className="btn-icon btn-3"
                        color="primary"
                        type="button"
                        onClick={editToggle}
                        >
                        <span className="btn-inner--icon"></span>
                        <span className="btn-inner-center-text">Edit</span>
                      </Button>
                    </Col>
                  ) : (
                    <Col className="d-flex justify-content-start">
                      <Button
                        className="btn-icon btn-3"
                        color="light"
                        type="button"
                        style={{border: '1px solid'}}
                        onClick={(e) => {
                          e.preventDefault()
                          setUserDetails(originalUserDetails)
                          editToggle()
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-times" />
                        </span>
                        <span className="btn-inner--text">Cancel</span>
                      </Button>
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="button"
                        onClick={(e) => {
                          updateDetails()
                          e.preventDefault()
                          editToggle()
                          setAddressQuery('')
                        }}
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-check" />
                        </span>
                        <span className="btn-inner--text">Save</span>
                      </Button>
                      </Col>
                  )}
                </FormGroup>
              </div>
            </Form>
            </CardBody>
          )}

          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Company profile
                </h3>
              </Col>
            </Row>
          </CardHeader>
          {groupDetails && (
            <CardBody>
            <Form className="text-primary">
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Company</h>
                </Col>
                <Col md="9">
                  <h>{groupDetails.costCtrDescr ? groupDetails.costCtrDescr : ''}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Email</h>
                </Col>
                <Col md="9">
                  <h>{groupDetails.groupDetails.email ? groupDetails.groupDetails.email : ''}</h>
                </Col>
              </Row>
              {/* <FormGroup row> */}
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Address</h>
                </Col>
                <Col md="9">
                  <h>
                    {groupDetails.groupDetails.address.unitNumber}{' '}
                    {groupDetails.groupDetails.address.streetNumber}{' '}
                    {groupDetails.groupDetails.address.streetName}{' '}
                    {groupDetails.groupDetails.address.streetType}{' '}
                    {groupDetails.groupDetails.address.suburb}{' '}
                    {groupDetails.groupDetails.address.state}{' '}
                    {groupDetails.groupDetails.address.postcode}
                  </h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h>Phone</h>
                </Col>
                <Col md="9">
                  <h>{groupDetails.groupDetails.contact.contactMobile ? groupDetails.groupDetails.contact.contactMobile : ''}</h>
                </Col>
              </Row>
              {/* </FormGroup> */}
              {/* </Col> */}
            </Form>
          </CardBody>
          )}
        </Card>
      </Container>
    </>
  )
}

export default Profile
