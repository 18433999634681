import { IToken, ITokenProvider } from 'services/sdk/interfaces'

const sessionStorageKey = '__auth_provider_token__'

const createTokenProvider = (): ITokenProvider => {
  const tmpToken = sessionStorage.getItem(sessionStorageKey)
  let token: IToken | null = tmpToken ? JSON.parse(tmpToken) : null
  let observers: Array<(isLogged: boolean) => void> = []

  const isLoggedIn = () => {
    return !!token
  }

  const notify = () => {
    const isLogged = isLoggedIn()

    observers.forEach((observer) => observer(isLogged))
  }

  const getExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) {
      return null
    }

    const jwt = JSON.parse(atob(jwtToken.split('.')[1]))

    if (jwt && jwt.exp) {
      return jwt.exp * 1000
    }
    return null
  }

  const isExpired = (exp: number | null) => {
    if (!exp) {
      return false
    }

    return Date.now() > exp
  }

  const setToken = (userToken: IToken | null) => {
    if (userToken) {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(userToken))
    } else {
      sessionStorage.removeItem(sessionStorageKey)
    }

    token = userToken

    notify()
  }

  const getToken = () => {
    if (!token) {
      return null
    }

    if (isExpired(getExpirationDate(token.data.token))) {
      setToken(null)
      return null
    }
    return token && token.data.token
  }

  const subscribe = (observer: (isLogged: boolean) => void) =>
    observers.push(observer)

  const unsubscribe = (observer: (isLogged: boolean) => void) => {
    observers = observers.filter((obvs) => obvs !== observer)
  }

  return {
    getToken,
    setToken,
    isLoggedIn,
    subscribe,
    unsubscribe
  }
}

export default createTokenProvider
