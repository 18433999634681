import React, { useCallback } from 'react'
import { useState, useEffect } from 'react'

import { Doughnut } from 'react-chartjs-2'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { colors } from 'variables/charts.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
function PAYGStatus() {
  const doughnutLabels = ['0-50', '50-80', '80-95', '95-99', 'AT100']
  const [PAYGStats, setPAYGStats] = useState({})
  const [loading, setLoading] = useState(true)
  const { getDashboardPAYGStats } = useDashboardContext()
  const loadPAYGStats = useCallback(async () => {
    // setLoading(true)
    await getDashboardPAYGStats()
      .then((response) => {
        setPAYGStats(response)
        //console.log('setPAYGStats', response)
      })
      .catch((err) => {
        console.log('error PAYG Stats:', err)
      })
  }, [getDashboardPAYGStats])

  useEffect(() => {
    console.log('load PAYG Stats')
    setLoading(true)
    loadPAYGStats()
    setLoading(false)
  }, [loadPAYGStats])

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="text-uppercase text-muted mb-0">PAYG Status (%)</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Doughnut
              data={{
                labels: doughnutLabels,
                datasets: [
                  {
                    data: [
                      PAYGStats['0-50'],
                      PAYGStats['50-80'],
                      PAYGStats['80-95'],
                      PAYGStats['95-99'],
                      PAYGStats['AT100']
                    ],
                    backgroundColor: [
                      colors.theme['primary'],
                      colors.theme['secondary'],
                      colors.theme['success'],
                      colors.theme['warning'],
                      colors.theme['danger']
                    ],
                    label: 'Dataset 1'
                  }
                ]
              }}
              options={{
                responsive: true,
                legend: {
                  position: 'right'
                },
                animation: {
                  animateScale: true,
                  animateRotate: true
                },
                onClick: (e, element) => {
                  console.log('getevent', e)
                  console.log('getelement', element[0]._index)
                  console.log('getlabel', doughnutLabels[element[0]._index])
                }
              }}
              className="chart-canvas"
              id="chart-doughnut"
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default PAYGStatus
