import { IHttpClient, IRoutesHandler, IHttpResponse } from '../interfaces'
import { PermissionResponse, FeatureResponse } from '../models/responses'
const features = require('features.js')

const RoutesHandler = (client: IHttpClient): IRoutesHandler => {
  const getPermissions = async (): Promise<
    IHttpResponse<PermissionResponse>
  > => {
    return client.GET<PermissionResponse>('/api/v1/user/permissions')
  }

  return {
    getPermissions
  }
}

export default RoutesHandler
