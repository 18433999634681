/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const Chart = require('chart.js')
// Only for demo purposes - return a random number to generate datasets
var randomScalingFactor = function () {
  return Math.round(Math.random() * 100)
}

//
// Chart extension for making the bars rounded
// Code from: https://codepen.io/jedtrow/full/ygRYgo
//

Chart.elements.Rectangle.prototype.draw = function () {
  var ctx = this._chart.ctx
  var vm = this._view
  var left, right, top, bottom, signX, signY, borderSkipped, radius
  var borderWidth = vm.borderWidth
  // Set Radius Here
  // If radius is large enough to cause drawing errors a max radius is imposed
  var cornerRadius = 6

  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2
    right = vm.x + vm.width / 2
    top = vm.y
    bottom = vm.base
    signX = 1
    signY = bottom > top ? 1 : -1
    borderSkipped = vm.borderSkipped || 'bottom'
  } else {
    // horizontal bar
    left = vm.base
    right = vm.x
    top = vm.y - vm.height / 2
    bottom = vm.y + vm.height / 2
    signX = right > left ? 1 : -1
    signY = 1
    borderSkipped = vm.borderSkipped || 'left'
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth shold be less than bar width and bar height.
    var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom))
    borderWidth = borderWidth > barSize ? barSize : borderWidth
    var halfStroke = borderWidth / 2
    // Adjust borderWidth when bar top position is near vm.base(zero).
    var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
    var borderRight =
      right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
    var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
    var borderBottom =
      bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop
      bottom = borderBottom
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft
      right = borderRight
    }
  }

  ctx.beginPath()
  ctx.fillStyle = vm.backgroundColor
  ctx.strokeStyle = vm.borderColor
  ctx.lineWidth = borderWidth

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  var corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom]
  ]

  // Find first (starting) corner with fallback to 'bottom'
  var borders = ['bottom', 'left', 'top', 'right']
  var startCorner = borders.indexOf(borderSkipped, 0)
  if (startCorner === -1) {
    startCorner = 0
  }

  function cornerAt(index) {
    return corners[(startCorner + index) % 4]
  }

  // Draw rectangle from 'startCorner'
  var corner = cornerAt(0)
  ctx.moveTo(corner[0], corner[1])

  for (var i = 1; i < 4; i++) {
    corner = cornerAt(i)
    let nextCornerId = i + 1
    if (nextCornerId === 4) {
      nextCornerId = 0
    }

    // let nextCorner = cornerAt(nextCornerId);

    let width = corners[2][0] - corners[1][0]
    let height = corners[0][1] - corners[1][1]
    let x = corners[1][0]
    let y = corners[1][1]
    // eslint-disable-next-line
    var radius: any = cornerRadius

    // Fix radius being too large
    if (radius > height / 2) {
      radius = height / 2
    }
    if (radius > width / 2) {
      radius = width / 2
    }

    ctx.moveTo(x + radius, y)
    ctx.lineTo(x + width - radius, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
    ctx.lineTo(x + width, y + height - radius)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
    ctx.lineTo(x + radius, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
    ctx.lineTo(x, y + radius)
    ctx.quadraticCurveTo(x, y, x + radius, y)
  }

  ctx.fill()
  if (borderWidth) {
    ctx.stroke()
  }
}

var mode = 'light' //(themeMode) ? themeMode : 'light';
var fonts = {
  base: 'Open Sans'
}

// Colors
var colors = {
  gray: {
    100: '#f6f9fc',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#8898aa',
    700: '#525f7f',
    800: '#32325d',
    900: '#212529'
  },
  theme: {
    default: '#001f60',
    primary: '#2169A2',
    secondary: '#66B1E1',
    info: '#4479bd',
    success: '#c1d5ee',
    danger: '#f5821f',
    warning: '#f1af21'
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent'
}

// Methods

// Chart.js global options
function chartOptions() {
  // Options
  var options = {
    defaults: {
      global: {
        responsive: true,
        maintainAspectRatio: false,
        defaultColor: mode === 'dark' ? colors.gray[700] : colors.gray[600],
        defaultFontColor: mode === 'dark' ? colors.gray[700] : colors.gray[600],
        defaultFontFamily: fonts.base,
        defaultFontSize: 13,
        layout: {
          padding: 0
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 16
          }
        },
        elements: {
          point: {
            radius: 0,
            backgroundColor: colors.theme['primary']
          },
          line: {
            tension: 0.4,
            borderWidth: 4,
            borderColor: colors.theme['primary'],
            backgroundColor: colors.transparent,
            borderCapStyle: 'rounded'
          },
          rectangle: {
            backgroundColor: colors.theme['secondary']
          },
          arc: {
            backgroundColor: colors.theme['primary'],
            borderColor: mode === 'dark' ? colors.gray[800] : colors.white,
            borderWidth: 4
          }
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false
        }
      },
      doughnut: {
        cutoutPercentage: 83,
        legendCallback: function (chart) {
          var data = chart.data
          var content = ''

          data.labels.forEach(function (label, index) {
            var bgColor = data.datasets[0].backgroundColor[index]

            content += '<span class="chart-legend-item">'
            content +=
              '<i class="chart-legend-indicator" style="background-color: ' +
              bgColor +
              '"></i>'
            content += label
            content += '</span>'
          })

          return content
        }
      }
    }
  }

  // yAxes
  Chart.scaleService.updateScaleDefaults('linear', {
    gridLines: {
      borderDash: [2],
      borderDashOffset: [2],
      color: mode === 'dark' ? colors.gray[900] : colors.gray[300],
      drawBorder: false,
      drawTicks: false,
      lineWidth: 1,
      zeroLineWidth: 1,
      zeroLineColor: mode === 'dark' ? colors.gray[900] : colors.gray[300],
      zeroLineBorderDash: [2],
      zeroLineBorderDashOffset: [2]
    },
    ticks: {
      beginAtZero: true,
      padding: 10,
      callback: function (value) {
        if (!(value % 1)) {
          return value
        }
      }
    }
  })

  // xAxes
  Chart.scaleService.updateScaleDefaults('category', {
    gridLines: {
      drawBorder: false,
      drawOnChartArea: false,
      drawTicks: false
    },
    ticks: {
      padding: 20
    }
  })

  return options
}

// Parse global options
function parseOptions(parent, options) {
  for (var item in options) {
    if (typeof options[item] !== 'object') {
      parent[item] = options[item]
    } else {
      parseOptions(parent[item], options[item])
    }
  }
}

// Example 1 of Chart inside src/views/dashboards/Dashboard.js
let chartExample1 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[700],
            zeroLineColor: colors.gray[700]
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                return '$' + value + 'k'
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || ''
          var yLabel = item.yLabel
          var content = ''

          if (data.datasets.length > 1) {
            content += label
          }

          content += '$' + yLabel + 'k'
          return content
        }
      }
    }
  },
  data1: (canvas) => {
    return {
      labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Performance',
          data: [0, 20, 10, 30, 15, 40, 20, 60, 60]
        }
      ]
    }
  },
  data2: (canvas) => {
    return {
      labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
        {
          label: 'Performance',
          data: [0, 20, 5, 25, 10, 30, 15, 40, 40]
        }
      ]
    }
  }
}

// Example 2 of Chart inside src/views/dashboards/Dashboard.js and src/views/dashboards/Alternative.js and src/views/pages/Charts.js
let chartExample2 = {
  options: {
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200]
          },
          ticks: {
            // callback: function (value) {
            //   if (!(value % 10)) {
            //     //return '$' + value + 'k'
            //     return value
            //   }
            // }
          }
        }
      ]
    },
    // tooltips: {
    //   callbacks: {
    //     label: function (item, data) {
    //       var label = data.datasets[item.datasetIndex].label || ''
    //       var yLabel = item.yLabel
    //       var content = ''
    //       if (data.datasets.length > 1) {
    //         content += label
    //       }
    //       content += yLabel
    //       return content
    //     }
    //   }
    // }
    onClick: (e, element) => {
      console.log('getevent', e)
      console.log('getelement', element)
    }
  },
  data: {
    labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Sales',
        data: [25, 20, 30, 22, 17, 29],
        maxBarThickness: 10
      }
    ]
  }
}

// Example 3 of Chart inside src/views/dashboards/Alternative.js and src/views/pages/Charts.js
let chartExample3 = {
  options: {
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200]
          },
          ticks: {}
        }
      ]
    },
    onClick: (e, element) => {
      console.log('getevent', e)
      console.log('getelement', element)
    }
  },
  data: {
    labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Performance',
        data: [0, 20, 10, 30, 15, 40, 20, 60, 60]
      }
    ]
  }
}

// Example 4 of Chart inside src/views/pages/Charts.js
const chartExample4 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200]
          },
          ticks: {}
        }
      ]
    }
  },
  data: {
    labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Performance',
        data: [10, 18, 28, 23, 28, 40, 36, 46, 52],
        pointRadius: 10,
        pointHoverRadius: 15,
        showLine: false
      }
    ]
  }
}

// Example 5 of Chart inside src/views/pages/Charts.js
const chartExample5 = {
  data: {
    labels: ['Danger', 'Warning', 'Success', 'Primary', 'Info'],
    datasets: [
      {
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ],
        backgroundColor: [
          colors.theme['danger'],
          colors.theme['warning'],
          colors.theme['success'],
          colors.theme['primary'],
          colors.theme['info']
        ],
        label: 'Dataset 1'
      }
    ]
  },
  options: {
    responsive: true,
    legend: {
      position: 'right'
    },
    animation: {
      animateScale: true,
      animateRotate: true
    },
    onClick: (e, element) => {
      console.log('getevent', e)
      console.log('getelement', element)
    }
  }
}

// Example 6 of Chart inside src/views/pages/Charts.js
const chartExample6 = {
  data: {
    labels: ['Danger', 'Warning', 'Success', 'Primary', 'Info'],
    datasets: [
      {
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ],
        backgroundColor: [
          colors.theme['danger'],
          colors.theme['warning'],
          colors.theme['success'],
          colors.theme['primary'],
          colors.theme['info']
        ],
        label: 'Dataset 1'
      }
    ]
  },
  options: {
    responsive: true,
    legend: {
      position: 'right'
    },
    animation: {
      animateScale: true,
      animateRotate: true
    },
    onClick: (e, element) => {
      console.log('getevent', e)
      if (element.length === 0) return
      const { _datasetIndex, _index } = element[0]
      console.log(_datasetIndex, _index)
    }
  }
}

// Example 7 of Chart inside src/views/pages/Charts.js
const chartExample7 = {
  data: {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Dataset 1',
        backgroundColor: colors.theme['danger'],
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ],
        maxBarThickness: 10
      },
      {
        label: 'Dataset 2',
        backgroundColor: colors.theme['primary'],
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ],
        maxBarThickness: 10
      },
      {
        label: 'Dataset 3',
        backgroundColor: colors.theme['success'],
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor()
        ],
        maxBarThickness: 10
      }
    ]
  },
  options: {
    tooltips: {
      mode: 'index',
      intersect: false
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          stacked: true
        }
      ]
    }
  }
}

module.exports = {
  chartOptions, // used alonside with the chartExamples variables
  parseOptions, // used alonside with the chartExamples variables
  chartExample1, // used inside src/views/dashboards/Dashboard.js
  chartExample2, // used inside src/views/dashboards/Dashboard.js and src/views/dashboards/Alternative.js and src/views/pages/Charts.js
  chartExample3, // used inside src/views/dashboards/Alternative.js and src/views/pages/Charts.js
  chartExample4, // used inside src/views/pages/Charts.js
  chartExample5, // used inside src/views/pages/Charts.js
  chartExample6, // used inside src/views/pages/Charts.js
  chartExample7, // used inside src/views/pages/Charts.js
  colors
}
