import React, { FunctionComponent, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  IPsmaHandler,
  IHttpClient,
  IHttpResponse
} from 'services/sdk/interfaces'

import PsmaHandler from 'services/sdk/handlers/PsmaHandler'

import HttpClient from 'services/sdk/HttpClient'
import { ID } from 'services/sdk/models/models'
import { createCtx } from './helpers'

import {
  PSMAAddressSuggestionsResponse,
  PSMAAddressResponse
} from 'services/sdk/models/responses'
interface PsmaContextInterface {
  // Http Clients
  psmaHandler: IPsmaHandler

  // Functions
  getAddressSuggestions: (
    query: string
  ) => Promise<PSMAAddressSuggestionsResponse>
  getAddress: (id: ID) => Promise<PSMAAddressResponse>
}
type PsmaContextProviderProps = {
  children: React.ReactNode
}

const [ctx, useContext, Provider] = createCtx<PsmaContextInterface>()

export const PsmaContextProvider: FunctionComponent<
  PsmaContextProviderProps
> = ({ children }) => {
  const psmaHttpClient = useRef<IHttpClient>(
    HttpClient(process.env.REACT_APP_PSMA_HOST)
  ).current

  const psmaHandler = useRef(PsmaHandler(psmaHttpClient)).current

  const getAddressSuggestions = async (
    query: string
  ): Promise<PSMAAddressSuggestionsResponse> => {
    return await psmaHandler
      .getAddressSuggestions(query)
      .then((response) => {
        if (response.ok && response.response) {
          console.log('AddressSuggestionsResponse', response.response)
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log('getInventory err: ', err)
        throw err
      })
  }

  const getAddress = async (id: ID): Promise<PSMAAddressResponse> => {
    return await psmaHandler
      .getAddress(id)
      .then((response) => {
        if (response.ok && response.response) {
          console.log('AddressResponse', response.response)
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Something went wrong'
          )
        }
      })
      .catch((err) => {
        console.log('getInventory err: ', err)
        throw err
      })
  }

  return (
    <Provider
      value={{
        psmaHandler,
        getAddressSuggestions,
        getAddress
      }}
    >
      {children}
    </Provider>
  )
}

PsmaContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const usePsmaContext = useContext

export default ctx
