import React, { useEffect, useState } from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  CardFooter,
  Col,
  Navbar,
  NavbarBrand,
  Row
} from 'reactstrap'
// core components
import { useAuthContext } from '../../contexts/AuthContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import styled from 'styled-components'
import AuthFooter from '../../components/Footers/AuthFooter'
import 'assets/css/argon-dashboard-pro-react.css'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 95% center;
  background-size: auto 90%;
  background-origin: content-box;
`

function EmailVerification({ location }) {
  const { updateEmailVerificationStatus } = useAuthContext()

  const [focusedEmail, setfocusedEmail] = React.useState(false)
  const [focusedPassword, setfocusedPassword] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [verificationSuccess, setVerificationSuccess] = useState(false)

  const [mvnoName, setMvnoName] = useState(process.env.REACT_APP_MVNO_NAME)
  const [portalType, setPortalType] = useState(
    process.env.REACT_APP_PORTAL_TYPE
  )
  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    const updateVerificationStatus = async () => {
      await updateEmailVerificationStatus({
        otpCode: queryParams.get('token')
      })
        .then((response) => {
          console.log('UpdateEmailVerificationStatus response', response)
          setIsLoading(false)
          setVerificationSuccess(true)
        })
        .catch((error) => {
          console.log('UpdateEmailVerificationStatus error', error)
          setIsLoading(false)
          setVerificationSuccess(false)
        })
    }
    updateVerificationStatus()
  }, [])

  return (
    <>
      <Background className="bg-imzi-dark" />
      <Navbar light expand="md">
        <NavbarBrand href="/" style={{ paddingLeft: '2vh', paddingTop: '2vh' }}>
          <img
            src={process.env.REACT_APP_HOST_AUTH + `/assets/mvno.png`}
            alt="Logo"
            height="30"
          />
        </NavbarBrand>
      </Navbar>
      <Container style={{ paddingTop: '8vh' }}>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <h1
              className="text-center text-white my-0 neue-bold letter-2"
              style={{ fontSize: '26px' }}
            >
              {mvnoName + ' ' + portalType + ' '}Portal
            </h1>{' '}
            <p
              className="text-center text-white mb-4 neue-thin letter-4"
              style={{ fontSize: '16px' }}
            >
              Manage your SIM services and inventory.
            </p>
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <CardTitle
                  className="text-center text-primary neue-bold letter-2"
                  style={{
                    fontSize: '20px',
                    lineHeight: '24px'
                  }}
                >
                  {'Email Verification'}
                </CardTitle>
                {!isLoading ? (
                  verificationSuccess ? (
                    <div className="text-center">
                      <Form className="mt-5" role="form">
                        {'Your email has been successfully verified! '}
                        <i className="ni ni-check-bold text-primary" />
                      </Form>
                      <Button
                        className="mt-5"
                        onClick={() => {
                          window.location.href = '/auth/login'
                        }}
                      >
                        Click here to login
                      </Button>
                    </div>
                  ) : (
                    <div className="text-center">
                      <Form className="mt-5 mb-4" role="form">
                        {
                          'An error occurred while we are verifying your email, please try again.'
                        }
                      </Form>
                    </div>
                  )
                ) : (
                  <div className="text-center">
                    <Form className="mt-5 mb-4" role="form">
                      {'Verifying your email...'}
                    </Form>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <AuthFooter />
    </>
  )
}

export default EmailVerification
