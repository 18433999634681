import React, { useState, useMemo, useEffect } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardText,
  Spinner,
} from 'reactstrap'
import PropTypes from 'prop-types';

// core components
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useActivationContext } from '../../contexts/ActivationContext'
import { isEmailValid } from '../../utils/validation'

function AccountLookup({ confirmedAccount }) {
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [account, setAccount] = useState({costCtrId: '', email: '', createAccount: false})

  const [disableEmailInput, setDisableEmailInput] = useState(false)
  const [showSearchButton, setShowSearchButton] = useState(true)

  const [confirmedAccountSelection, setConfirmedAccountSelection] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isCreateAccountLoading, setIsCreateAccountLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [existingUsernameLookupError, setExistingUsernameLookupError] = useState(false)
  const [existingUsernameLookupErrorMessage, setExistingUsernameLookupErrorMessage] = useState('')
  const [checkBox, setCheckBox] = useState(false)

  // const { usernameCheck } = useDashboardContext()
  const { accountLookup, createAccount } = useActivationContext()
  
  useEffect(() => {
    if (isEmailValid(email)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [email])

  // check if email belongs to an existing account
  const handleEmailLookup = async () => {
    setIsLoading(true)
    if (isValid) {
      await accountLookup({
        email: email,
       })
      .then((res) => {
        console.log('res', res)
        if (res.status === 200) {
          setExistingUsernameLookupError(false)
          setAccount(res.response.data)
          setDisableEmailInput(true)
          setIsLoading(false)
        } else {
         if (email !== '') {
            setExistingUsernameLookupError(true)
            setExistingUsernameLookupErrorMessage(
              'Something went wrong. Please try again later.'
            )
            setIsLoading(false)
          }
        }
      })
      .catch((err) => {
        console.log('err', err)
        if (email !== '') {
          setExistingUsernameLookupError(true)
          setExistingUsernameLookupErrorMessage(
            'Something went wrong. Please try again later.'
          )
          setIsLoading(false)
        }
      })
    }
  }

  const handleCreateAccount = async () => {
    setIsCreateAccountLoading(true)
    if (isValid) {
      await createAccount({
        username: email,
       })
      .then((res) => {
        console.log('res', res)
        if (res.code === 0) {
          setExistingUsernameLookupError(false)
          setAccount(res.data)
          setConfirmedAccountSelection(true)
          confirmedAccount({
            costCtrId: res.data.costCtrId,
            email: res.data.email,
            createAccount: true,
          })
          setIsCreateAccountLoading(false)
        } else {
          setError(true)
          setErrorMessage('Something went wrong. Please try again later.')
          setIsCreateAccountLoading(false)
        }
      })
      .catch((err) => {
        console.log('err', err)
        setIsCreateAccountLoading(false)
      })
    }
  }

  const handleSelectAccount = async () => {
    setConfirmedAccountSelection(true)
    confirmedAccount({
      costCtrId: account.costCtrId,
      email: account.email,
      createAccount: false,
    })
  }

  const clearConfirmedAccount = async () => {
    setConfirmedAccountSelection(false)
    setDisableEmailInput(false)
    setShowSearchButton(true)
    setAccount({costCtrId: '', email: '', createAccount: false})
    confirmedAccount({costCtrId: '', email: '', createAccount: false})
    setEmail('')
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0 text-primary neue-bold letter-2">
                Account
              </h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <div className="pl-lg-4">
              {confirmedAccountSelection ? (
                <CardText className="text-primary">
                  This account will be used to activate the service under.
                </CardText>
              ) : (
                <>
                  <CardText className="text-primary" style={{marginBottom: "0px"}}>
                    For sim activation, enter an email address to:
                  </CardText>
                  <table>
                    <li className="text-primary">
                      <span className="text-primary"></span>Register a new account for a service.
                    </li>
                    <li className="text-primary" style={{paddingBottom: "20px"}}>
                      <span className="text-primary"></span> Search for an existing account to add a service to.
                    </li>
                  </table>
                </>
              )}
                <>
                  <FormGroup row>
                    <Label className="letter-4 text-primary" htmlFor="email" md={2}>
                      Email
                    </Label>
                    <Col md={6}>
                      <Input
                        id="email"
                        placeholder="Email"
                        type="text"
                        value={email}
                        invalid={existingUsernameLookupError}
                        onChange={(e) => {
                          setExistingUsernameLookupError(false)
                          setExistingUsernameLookupErrorMessage('')
                          setEmail(e.target.value)
                        }}
                        disabled={disableEmailInput}
                      />
                    <div className="invalid-feedback">
                        {existingUsernameLookupErrorMessage}
                    </div>
                    </Col>
                  </FormGroup>
                  {!confirmedAccountSelection ? (
                    <>
                      {isLoading && (
                        <>
                          <div className="d-flex justify-content-start">
                            <Spinner>Loading...</Spinner>
                          </div>
                        </>
                      )}
                      {showSearchButton && (
                        <>
                        <Button
                          className='mt-3'
                          // marginTop="20px"
                          type="button"
                          onClick={() => {
                            handleEmailLookup()
                            setShowSearchButton(false)
                          }}
                          style={isValid ? {} : { backgroundColor: 'lightgray', marginTop: '10px'}}
                          disabled={!isValid}
                        >
                          Search
                        </Button>
                        </>
                      )}
                      {disableEmailInput && (
                        <>
                          <FormGroup row style={{marginBottom: '20px', marginTop: '20px'}}>
                            <Label
                              className="letter-4 text-primary neue-bold"
                              htmlFor="email"
                              md={4}
                              style={{
                                paddingBottom: '0px',
                                paddingTop: '0px'
                              }}
                            >
                              {account.createAccount ? 'Create New Account' : 'Found Existing Account'}
                            </Label>
                          </FormGroup>
                          {!account.createAccount && (
                            <>
                                <Col style={{paddingLeft: '20px', paddingBottom: '10px'}}>
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => {
                                      setCheckBox(e.target.checked)
                                      console.log('confirm checkbox', e.target.checked)
                                    }}
                                  />
                                  <Label check className="text-primary">
                                    By proceeding with the activation, I confirm that the transfer is authorised and I have verified the account holder.
                                  </Label>
                                </Col>
                            </>
                          )}
                          <CardText className="text-primary" style={{paddingBottom: '5px'}}>
                            {
                              account.createAccount
                              ? 'Do you wish to create a new account for ' + email + '?'
                              : 'Are you sure that you want to add a service to ' + email + '\'s account.'
                            }
                          </CardText>
                          {isCreateAccountLoading ? (
                            <>
                              <div className="d-flex justify-content-start">
                                <Spinner>Loading...</Spinner>
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                color="light"
                                onClick={() => {
                                  setDisableEmailInput(false)
                                  setShowSearchButton(true)
                                  setAccount({costCtrId: '', email: '', createAccount: false})
                                  setCheckBox(false)
                                }}
                                style={{width: '86px'}}
                              >
                                No
                              </Button>
                              <Button
                                color="secondary"
                                onClick={() => {
                                  account.createAccount ? handleCreateAccount() : handleSelectAccount()
                                }}
                                style={{width: '86px'}}
                                disabled={!account.createAccount && !checkBox}
                              >
                                Yes
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      color="secondary"
                      onClick={() => {
                        clearConfirmedAccount()
                      }}
                      style={{width: '88px'}}
                    >
                      Cancel
                    </Button>
                  )}
                </>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  )
}

AccountLookup.propTypes = {
  // Data
  confirmedAccount: PropTypes.arrayOf(PropTypes.shape({
    costCtrId: PropTypes.string,
    email: PropTypes.string,
    createAccount: PropTypes.bool,
  })),
  // Flags
  // Styles
  // Callbacks
};

AccountLookup.defaultProps = {
  // Data
  // Flags
  confirmedAccount: {},
  // Styles
  // Callbacks
};

export default AccountLookup