import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  Spinner,
  // Badge,
  Button
  // CardBody
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useHistory } from 'react-router-dom'
import Strings from 'localization'
// import { useAuthContext } from '../../contexts/AuthContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import PaginationService from '../../components/Table/PaginationService'
import RecordsDropdown from '../../components/Table/RecordsDropdown'
import useDebounce from 'hooks/useDebounce'

const Group = () => {
  const [groupList, setGroupList] = useState([])
  const { getCostCtrList } = useDashboardContext()
  const { getGroupFeatures } = useAccessControlContext()
  const [costCenterType, setCostCenterType] = useState('Dealer')
  const [pageOffset, setPageOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState('costCtrDescr')
  const [sortDesc, setSortDesc] = useState(true)
  const [detailed, setDetailed] = useState('True')
  const [lastPage, setLastPage] = useState(0)
  const [maxResults, setMaxResults] = useState(0)
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false)
  const [recordsDropdownOpen, setRecordsDropdownOpen] = useState(false)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('All')

  const history = useHistory()
  const groupFeatures = getGroupFeatures()
  const columns = groupFeatures?.columns

  useEffect(() => {
    console.log('groupFeatures: ', groupFeatures)
  }, [groupFeatures])

  const columnNames = {
    costCtrDescription: 'DEALER NAME',
    state: 'STATE',
    suburb: 'SUBURB',
    email: 'EMAIL',
    contactMobile: 'PHONE',
    credit: 'CREDIT',
    status: 'STATUS'
  }

  const sortKeys = {
    costCtrDescription: 'costCtrDescr',
    state: 'state',
    suburb: 'suburb',
    email: 'email',
    contactMobile: 'contactMobile',
    credit: 'credit',
    status: 'status'
  }

  const sortable = {
    costCtrDescription: true,
    state: true,
    suburb: true,
    email: false,
    contactMobile: false,
    credit: true,
    status: false
  }

  const recordFooter = () => {
    const first = maxResults === 0 ? 0 : pageOffset * rowsPerPage + 1
    const last = Math.min(pageOffset * rowsPerPage + rowsPerPage, maxResults)
    return (
      <span>
        showing {first} to {last} of {maxResults} entries
      </span>
    )
  }

  const changeRowsPerPage = (number) => {
    setPageOffset(0)
    setRowsPerPage(number)
  }

  const setSort = (sort) => {
    setPageOffset(0)
    if (sort === sortBy) {
      setSortDesc(!sortDesc)
    } else {
      setSortBy(sort)
      setSortDesc(true)
    }
  }

  const debouncedSearchFilter = useDebounce(searchFilter, 300)
  useEffect(() => {
    setIsLoading(true)
    const loadGroups = async () => {
      await getCostCtrList({
        detailed,
        pageOffset,
        rowsPerPage,
        sortBy,
        sortOrder: sortDesc ? 'asc' : 'desc',
        searchFilter: debouncedSearchFilter,
        statusFilter: statusFilter
      })
        .then((load) => {
          setIsLoading(false)
          // console.log('load: ', load)
          // console.log('load.results: ', load.results)
          setMaxResults(load.maxResults)
          setLastPage(Math.ceil(load.maxResults / rowsPerPage))
          setGroupList(load.results)
        })
        .catch((err) => {
          setIsLoading(false)
          setError(true)
          setErrorMessage(err.message)
        })
    }
    loadGroups()
  }, [
    getCostCtrList,
    detailed,
    pageOffset,
    rowsPerPage,
    sortBy,
    sortDesc,
    debouncedSearchFilter,
    statusFilter
  ])

  const statusArray = [
    { status: 'E', label: 'Active' },
    { status: 'D', label: 'Terminated' }
  ]

  const toggle = () => {
    setRecordsDropdownOpen(!recordsDropdownOpen)
  }

  const handleClick = (group) => {
    history.push({
      pathname: '/admin/group/details',
      state: {
        costCtrId: group.costCtrId,
        groupName: group.costCtrDescription,
        status: group.status,
        costCenterType: costCenterType
      }
    })
  }

  return (
    <>
      <SimpleHeader
        name={Strings[costCenterType].MG_Title}
        description={Strings[costCenterType].MG_SubTitle}
      ></SimpleHeader>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col md="4">
                    <RecordsDropdown
                      dropdownOpen={recordsDropdownOpen}
                      toggle={toggle}
                      setRowsPerPage={changeRowsPerPage}
                      rowsPerPage={rowsPerPage}
                    ></RecordsDropdown>
                  </Col>
                  <span className="text-primary">Filter by:</span>
                  <Dropdown
                    isOpen={statusDropdownOpen}
                    toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                    className="ml-1"
                  >
                    <DropdownToggle color="light" caret>
                      {statusFilter === 'All' ? 'Dealer Status' : statusFilter}
                    </DropdownToggle>
                    <DropdownMenu className="text-primary">
                      <DropdownItem onClick={() => setStatusFilter('All')}>
                        All
                      </DropdownItem>
                      <DropdownItem onClick={() => setStatusFilter('Active')}>
                        Active
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setStatusFilter('Terminated')}
                      >
                        Terminated
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <Col lg="3 ml-auto">
                    <Input
                      className="py-1"
                      placeHolder="search by dealer name"
                      type="search"
                      onKeyPress={(event) => {
                        if (/[^a-zA-Z0-9_-\s]/gi.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        setPageOffset(0)
                        setSearchFilter(e.target.value)
                      }}
                    />
                  </Col>
                  {JSON.parse(groupFeatures?.canAddGroup) && (
                    <Col className="text-right ml-auto">
                      <Button
                        color="light"
                        className="text-primary"
                        onClick={(e) => {
                          e.preventDefault()
                          history.push({
                            pathname: '/admin/group/add',
                            state: {
                              costCenterType: costCenterType
                            }
                          })
                        }}
                      >
                        {Strings[costCenterType].MG_Add_Button}
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table
                  hover
                  className="align-items-center table-flush text-primary"
                >
                  <thead className="thead-light">
                    <tr>
                      {columns.map((column, index) => (
                        <th
                          scope="col"
                          className="sort"
                          data-sort={sortable[column] ? sortKeys[column] : null}
                          onClick={() =>
                            sortable[column] && setSort(sortKeys[column])
                          }
                          key={index}
                        >
                          {columnNames[column]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {groupList &&
                      groupList.map((group) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault()
                              handleClick(group)
                            }}
                          >
                            {columns.includes('costCtrDescription') && (
                              <td className="costCtrDescr">
                                {group.costCtrDescription}
                              </td>
                            )}
                            {columns.includes('state') && (
                              <td className="state">
                                {group.costCtrDetails?.address?.state}
                              </td>
                            )}
                            {columns.includes('suburb') && (
                              <td className="suburb">
                                {group.costCtrDetails?.address?.suburb}
                              </td>
                            )}
                            {columns.includes('email') && (
                              <td className="email">
                                {group.costCtrDetails?.email}
                              </td>
                            )}
                            {columns.includes('contactMobile') && (
                              <td className="contactMobile">
                                {group.costCtrDetails?.contact?.contactMobile}
                              </td>
                            )}
                            {columns.includes('credit') && (
                              <td className="credit">
                                {'$ ' + group.balances.credit}
                              </td>
                            )}
                            {columns.includes('status') && (
                              <td className="status">
                                {group.status
                                  ? statusArray.find(
                                      (i) => i.status === group.status
                                    )?.label
                                  : 'Unknown'}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <Spinner>Loading...</Spinner>
                  </div>
                )}
                {error && (
                  <div className="d-flex justify-content-center">
                    <span className="error-message">{errorMessage}</span>
                  </div>
                )}
              </div>
              {!error && !isLoading && (
                <CardFooter className="py-4">
                  <Row className="d-flex align-items-center">
                    <Col xs="12" md="4" xl="3" xxl="8">
                      {recordFooter()}
                    </Col>
                    <Col sm="6" md="4" xl="9" xxl="2">
                      <nav aria-label="...">
                        <PaginationService
                          setPageOffset={setPageOffset}
                          pageOffset={pageOffset}
                          lastPage={lastPage}
                        ></PaginationService>
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Group
