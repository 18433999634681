/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useMemo } from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  CardFooter,
  Col,
  Navbar,
  NavbarBrand,
  Row
} from 'reactstrap'
// core components
import { useAuthContext } from '../../contexts/AuthContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import styled from 'styled-components'
import AuthFooter from '../../components/Footers/AuthFooter';
import { useHistory } from 'react-router-dom'
import 'assets/css/argon-dashboard-pro-react.css'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 95% center;
  background-size: auto 90%;
  background-origin: content-box;
`

function ForgotPassword() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [focusedEmail, setfocusedEmail] = React.useState(false)
  const [focusedPassword, setfocusedPassword] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { sendResetPasswordLink } = useAuthContext()
  //const { getRoutes } = useAccessControlContext()
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [mvnoName, setMvnoName] = useState(process.env.REACT_APP_MVNO_NAME)
  const [portalType, setPortalType] = useState(process.env.REACT_APP_PORTAL_TYPE)
  const [success, setSuccess] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const history = useHistory()

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    
    await sendResetPasswordLink(username)
    .then((res) => {
      console.log('res: ', res)
      if (res === 200) {
        setSuccess(true)
        //alert('Reset password link has been sent to your email.')
        setIsLoading(false)
      } else if (res === 401 || res === 400) {
        setIsLoading(false)
        setError(true)
        setSuccess(false)
        setSubmitError(
          'Something went wrong. Please try again later.'
        )
      } else {
        setIsLoading(false)
        setError(true)
        setErrorMessage('Something went wrong. Please try again later.')
      }
    })
  }

  const updateEmail = (val) => {
    setError(null)
    setUsername(val)
  }

  const isEmailValid = useMemo(() => {
    const rgx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,}$/
    return rgx.test(username)
  }, [username])

  return (
    <>
      <Background className="bg-imzi-dark" />
      <Navbar light expand="md">
        <NavbarBrand href="/" style={{paddingLeft: '2vh', paddingTop: '2vh'}}>
          <img src={process.env.REACT_APP_HOST_AUTH + `/assets/mvno.png`} alt="Logo" height="30"/>
        </NavbarBrand>
      </Navbar>
      <Container style={{ paddingTop: '8vh' }}>
        <Row className="justify-content-center">
        <Col lg="5" md="7">
          <h1
            className="text-center text-white my-0 neue-bold letter-2"
            style={{ fontSize: '26px' }}
          >
            {mvnoName + ' ' + portalType + ' '}Portal
          </h1>
          <p
            className="text-center text-white mb-4 neue-thin letter-4"
            style={{ fontSize: '16px' }}
          >
            Reset Password
          </p>
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <CardTitle
                className="text-center text-primary neue-bold letter-2"
                style={{
                  fontSize: '20px',
                  lineHeight: '24px'
                }}
              >
                Send a password reset link
              </CardTitle>
              {!success ? (
                <Form className="mt-4" role="form" onSubmit={handleSubmit}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83 text-primary" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="neue-roman letter-4"
                        style={{ fontSize: '14px' }}
                        placeholder="Email"
                        type="text"
                        id="email"
                        autoComplete="email"
                        value={username}
                        onChange={(e) => {
                          setError(false)
                          updateEmail(e.target.value)
                        }}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                      />
                      <div className="invalid-feedback">
                        Please enter a valid email address.
                      </div>
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                  {!!submitError && (
                    <div className="error-message">{submitError}</div>
                  )}
                    <Button
                      className="my-3 letter-4 neue-thin"
                      color="primary"
                      type="submit"
                      style={{ fontSize: '16px' }}
                    >
                      Send Reset Link
                    </Button>
                  </div>
                </Form>
              ) : (
                <div className="text-center">
                <p
                  className="text-center text-primary letter-2"
                  style={{
                    fontSize: '15px',
                    lineHeight: '24px'
                  }}
                >
                  Successfully sent a reset password link to your email.
                </p>
                <div className="text-center">
                  <Button
                    className="my-3 letter-4 neue-thin"
                    color="primary"
                    type="submit"
                    style={{ fontSize: '16px' }}
                    onClick={() => history.push('/auth/login')}
                  >
                    Back to Login
                  </Button>
                </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        </Row>
      </Container>
      <div style={{
            // height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            position: 'absolute',
            bottom: 0,
      }}>
        <AuthFooter />
      </div>
    </>
  )
}

export default ForgotPassword
