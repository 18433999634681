/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  CardFooter,
  Col,
  Navbar,
  NavbarBrand,
  Row
} from 'reactstrap'
// core components
import { useAuthContext } from '../../contexts/AuthContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import AuthFooter from '../../components/Footers/AuthFooter';
import 'assets/css/argon-dashboard-pro-react.css'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 95% center;
  background-size: auto 90%;
  background-origin: content-box;
`

function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [focusedEmail, setfocusedEmail] = React.useState(false)
  const [focusedPassword, setfocusedPassword] = React.useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const { login, rememberMe } = useAuthContext()
  const { getRoutes } = useAccessControlContext()
  const [rem, setRem] = useState(rememberMe)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [mvnoName, setMvnoName] = useState(process.env.REACT_APP_MVNO_NAME)
  const [portalType, setPortalType] = useState(process.env.REACT_APP_PORTAL_TYPE)

  const handleLogin = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const result = await login({
      username,
      password,
      rememberMe: rem
    })
    if (result === 200) {
      getRoutes()
      setIsLoading(false)
    } else if (result === 401 || result === 400) {
      setIsLoading(false)
      setError(true)
      setErrorMessage('Incorrect email or password.')
    } else {
      setIsLoading(false)
      setError(true)
      setErrorMessage('Internal Server Error. Please try again.')
    }
  }

  const updateEmail = (val) => {
    setError(null)
    setUsername(val)
  }

  const updatePassword = (val) => {
    setError(null)
    setPassword(val)
  }

  const toggle = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    setRem(rememberMe)
  }, [rememberMe])
  return (
    <>
      <Background className="bg-imzi-dark" />
      <Navbar light expand="md">
        <NavbarBrand href="/" style={{paddingLeft: '2vh', paddingTop: '2vh'}}>
          {process.env.REACT_APP_MVNO_NAME !== 'Xtreme' ? (
            <img src={process.env.REACT_APP_HOST_AUTH + `/assets/mvno.png`} alt="Logo" height="30"/>
          ) : (
            <img src={process.env.REACT_APP_HOST_AUTH + `/assets/mvno.png`} alt="Logo" height="40"/>
          )}
        </NavbarBrand>
      </Navbar>
      <Container style={{ paddingTop: '8vh' }}>
        <Row className="justify-content-center">
        <Col lg="5" md="7">
          <h1
            className="text-center text-white my-0 neue-bold letter-2"
            style={{ fontSize: '26px' }}
          >
            {mvnoName + ' ' + portalType + ' '}Portal
          </h1>
          <p
            className="text-center text-white mb-4 neue-thin letter-4"
            style={{ fontSize: '16px' }}
          >
            Manage your SIM services and inventory.
          </p>
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <CardTitle
                className="text-center text-primary neue-bold letter-2"
                style={{
                  fontSize: '20px',
                  lineHeight: '24px'
                }}
              >
                Sign in with credentials
              </CardTitle>
              <Form className="mt-4" role="form" onSubmit={handleLogin}>
                <FormGroup
                  className={classnames('mb-3', {
                    focused: focusedEmail
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83 text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="neue-roman letter-4"
                      style={{ fontSize: '14px' }}
                      placeholder="Email"
                      type="text"
                      id="email"
                      autoComplete="email"
                      value={username}
                      onChange={(e) => {
                        setError(false)
                        updateEmail(e.target.value)
                      }}
                      onFocus={() => setfocusedEmail(true)}
                      onBlur={() => setfocusedEmail(true)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: focusedPassword
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open text-primary" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="neue-roman letter-4"
                      style={{ fontSize: '14px' }}
                      placeholder="Password"
                      type="password"
                      id="password"
                      autoComplete="password"
                      value={password}
                      onChange={(e) => {
                        setError(false)
                        updatePassword(e.target.value)
                      }}
                      onFocus={() => {
                        if (error) {
                          updatePassword('')
                        }
                        setfocusedPassword(true)
                      }}
                      onBlur={() => setfocusedPassword(true)}
                    />
                  </InputGroup>
                  {error && <div className="error-message">{errorMessage}</div>}
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="customCheckLogin"
                    type="checkbox"
                    // value={rem}
                    onChange={() => {
                      setRem(!rem)
                    }}
                    checked={rem}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckLogin"
                  >
                    <span className="text-secondary neue-roman letter-4">
                      Remember me
                    </span>
                  </label>
                </div>
                <div className="text-center">
                  <Button
                    className="my-3 letter-4 neue-thin"
                    color="primary"
                    type="submit"
                    style={{ fontSize: '16px' }}
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <a
            className="letter-4 text-white neue-thin"
            href="/auth/forgot-password"
            onClick={(e) => {
              //e.preventDefault()
              //setShowModal(!showModal)
              //history.push('/auth/forgot-password')
            }}
          >
            <p
              style={{
                fontSize: '16px',
                lineHeight: '19px',
                marginTop: '19px'
              }}
            >
              Forgot password?
            </p>
          </a>
        </Col>
        </Row>
      </Container>
      <AuthFooter />
    </>
  )
}

export default Login
