/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// javascript plugin that creates a sortable object from a dom object

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Strings from 'localization'

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  Spinner,
  Button
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAuthContext } from '../../contexts/AuthContext'
import PaginationService from '../../components/Table/PaginationService'
import RecordsDropdown from '../../components/Table/RecordsDropdown'
import { dateFormatter } from '../../utils/Utils'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

function Inventory({ location }) {
  const [simList, setSimList] = useState([])
  const { getInventory, getCostCtrListSimple, getSpreadsheet } =
    useDashboardContext()
  const { costCenterType } = useAuthContext()
  const {
    getInventoryFeatures,
    getShowCostCtrPicker,
    authCostCtrId,
    authRole
  } = useAccessControlContext()
  const [pageOffset, setPageOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState('msisdn')
  const [sortDesc, setSortDesc] = useState(true)
  const [lastPage, setLastPage] = useState(0)
  const [maxResults, setMaxResults] = useState(0)
  const [recordsDropdownOpen, setRecordsDropdownOpen] = useState(false)
  const [groupDropdownOpen, setGroupDropdownOpen] = useState(false)
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false)
  const [statusFilter, setStatusFilter] = useState('Available')
  const [groupName, setGroupName] = useState('All')
  const [costCtrId, setCostCtrId] = useState(
    location.state?.costCtrId ? location.state.costCtrId : null
  )
  const [iccidFilter, setIccidFilter] = useState()
  const [costCtrList, setCostCtrList] = useState([])
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(null)
  const history = useHistory()
  const [inventoryFeatures, setInventoryFeatures] = useState(
    getInventoryFeatures()
  )
  const [columns, setColumns] = useState(inventoryFeatures?.columns)

  const columnNames = {
    costCtrType: 'TYPE',
    costCtrName: 'NAME',
    msisdn: 'MSISDN',
    serialNumber: 'SIM NUMBER',
    tstampCreated: 'CREATE DATE',
    expiryDate: 'EXPIRY',
    simType: 'SIM TYPE',
    status: 'STATUS'
  }

  const sortKeys = {
    costCtrType: 'costCtrType',
    costCtrName: 'costCtrName',
    msisdn: 'msisdn',
    serialNumber: 'serialNumber',
    tstampCreated: 'tstampCreated',
    expiryDate: 'expiryDate',
    simType: 'simType',
    status: 'status'
  }

  const sortable = {
    costCtrType: false,
    costCtrName: false,
    msisdn: true,
    serialNumber: true,
    tstampCreated: true,
    expiryDate: true,
    simType: true,
    status: true
  }

  const recordFooter = () => {
    const first = maxResults === 0 ? 0 : pageOffset * rowsPerPage + 1
    const last = Math.min(pageOffset * rowsPerPage + rowsPerPage, maxResults)
    return (
      <span>
        showing {first} to {last} of {maxResults} entries
      </span>
    )
  }

  const changeRowsPerPage = (number) => {
    setPageOffset(0)
    setRowsPerPage(number)
  }

  const setSort = (sort) => {
    setPageOffset(0)
    if (sort === sortBy) {
      setSortDesc(!sortDesc)
    } else {
      setSortBy(sort)
      setSortDesc(true)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const loadSims = async () => {
      console.log('iccidFilter: ', iccidFilter)
      await getInventory({
        pageOffset,
        rowsPerPage,
        sortBy,
        sortOrder: sortDesc ? 'desc' : 'asc',
        iccidFilter,
        statusFilter,
        costCtrId
      })
        .then((load) => {
          setIsLoading(false)
          // console.log('load: ', load)
          // console.log('load.results: ', load.results)
          setSimList(load.results)
          setMaxResults(load.maxResults)
          setLastPage(Math.ceil(load.maxResults / rowsPerPage))
        })
        .catch((err) => {
          setIsLoading(false)
          setError(true)
          setErrorMessage(err.message)
        })
    }
    if (getShowCostCtrPicker()) {
      loadSims()
    } else if (costCtrId) {
      loadSims()
    }

    // console.log("load sims: ", res);
  }, [
    getInventory,
    pageOffset,
    rowsPerPage,
    sortBy,
    sortDesc,
    iccidFilter,
    statusFilter,
    costCtrId
  ])

  useEffect(() => {
    const loadCostCtrList = async () => {
      await getCostCtrListSimple({ costCtrId: authCostCtrId }).then(
        (response) => {
          setCostCtrList(response)
          if (location.state?.costCtrId) {
            const costCtr = response.find(
              (costCtr) => costCtr.costCtrId === location.state.costCtrId
            )
            setGroupName(costCtr.costCtrDescription)
          }
        }
      )
    }
    loadCostCtrList()
  }, [getCostCtrListSimple])

  useEffect(() => {
    if (!getShowCostCtrPicker()) {
      setCostCtrId(authCostCtrId)
    }
  }, [authCostCtrId, getShowCostCtrPicker])

  const toggle = () => {
    setRecordsDropdownOpen(!recordsDropdownOpen)
  }

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleClick = (record) => {
    history.push({
      pathname: '/admin/simDetails',
      state: {
        costCtrId: record.costCtrId ?? null,
        costCtrName: record.costCtrName ?? null,
        msisdn: record.msisdn,
        sim: record.serialNumber,
        svcId: record.svcId,
        orderId: record.orderId ?? null,
        dnaStatus: record.dnaStatus ?? null,
      }
    })
  }

  return (
    <>
      <SimpleHeader
        name="Inventory"
        description="List of all SIM inventory for activation"
      />
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="d-flex align-items-center">
                  <Col md="4">
                    <RecordsDropdown
                      dropdownOpen={recordsDropdownOpen}
                      toggle={toggle}
                      setRowsPerPage={changeRowsPerPage}
                      rowsPerPage={rowsPerPage}
                    ></RecordsDropdown>
                  </Col>
                  <Col sm="auto">
                    <span className="text-primary">Filter by:</span>
                    {getShowCostCtrPicker() && (
                      <Dropdown
                        isOpen={groupDropdownOpen}
                        toggle={() => setGroupDropdownOpen(!groupDropdownOpen)}
                        className="ml-1 py-1"
                      >
                        <DropdownToggle color="light" caret>
                          {groupName === 'All' ? 'Name' : groupName}
                        </DropdownToggle>
                        <DropdownMenu className="text-primary">
                          <DropdownItem
                            onClick={() => {
                              setPageOffset(0)
                              setCostCtrId(null)
                              setGroupName('All')
                            }}
                          >
                            All
                          </DropdownItem>
                          {costCtrList.map((costCtr) => {
                            return (
                              <DropdownItem
                                key={costCtr.costCtrId}
                                onClick={() => {
                                  setPageOffset(0)
                                  setCostCtrId(costCtr.costCtrId)
                                  setGroupName(costCtr.costCtrDescription)
                                }}
                              >
                                {costCtr.costCtrDescription}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </Col>
                  <Col lg="3 ml-auto">
                    <Input
                      className="py-1"
                      placeHolder="search by iccid"
                      //   id="example-search-input"
                      type="search"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        setPageOffset(0)
                        setIccidFilter(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table
                  hover
                  className="align-items-center table-flush text-primary"
                >
                  <thead className="thead-light">
                    <tr>
                      {columns.map((column, index) => (
                        <th
                          scope="col"
                          className="sort"
                          data-sort={sortable[column] ? sortKeys[column] : null}
                          onClick={() =>
                            sortable[column] && setSort(sortKeys[column])
                          }
                          key={index}
                        >
                          {columnNames[column]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {simList &&
                      simList.map((record) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault()
                              handleClick(record)
                            }}
                          >
                            {columns.includes('costCtrType') && (
                              <td className="type">
                                
                              {Capitalize(record.costCtrType.toLowerCase())}
                              
                            </td>
                            )}
                            {columns.includes('costCtrName') && (
                              <td className="group">{record.costCtrName}</td>
                            )}
                            {columns.includes('msisdn') && (
                              <td className="msisdn">{record.msisdn}</td>
                            )}
                            {columns.includes('serialNumber') && (
                              <td className="sim">{record.serialNumber}</td>
                            )}
                            {columns.includes('tstampCreated') && (
                              <td className="createDate">
                                {dateFormatter(record.tstampCreated)}
                              </td>
                            )}
                            {columns.includes('expiryDate') && (
                              <td className="expiry">
                                {dateFormatter(record.expiryDate)}
                              </td>
                            )}
                            {columns.includes('simType') && (
                              <td className="simType">{record.simType}</td>
                            )}
                            {columns.includes('status') && (
                              <td
                                className={
                                  record.status === 'Used'
                                    ? 'text-warning'
                                    : record.status === 'Terminated'
                                    ? 'text-muted'
                                    : 'text-success'
                                }
                              >
                                {record.orderId !== null ||
                              record.dnaStatus === 'Reserved'
                                ? 'Reserved'
                                : record.status}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <Spinner>Loading...</Spinner>
                  </div>
                )}
                {error && (
                  <div className="d-flex justify-content-center">
                    <span className="error-message">{errorMessage}</span>
                  </div>
                )}
              </div>

              {!error && !isLoading && (
                <CardFooter className="py-4 flexbox-container">
                  <Row className="d-flex align-items-center">
                    <Col xs="12" md="4" xl="6" xxl="8">
                      {recordFooter()}
                    </Col>
                    <Col sm="6" md="4" xl="3" xxl="2" className="text-right">
                      <Button onClick={getSpreadsheet}>
                        download spreadsheet
                      </Button>
                    </Col>
                    <Col sm="6" md="4" xl="3" xxl="2">
                      <nav aria-label="...">
                        <PaginationService
                          setPageOffset={setPageOffset}
                          pageOffset={pageOffset}
                          lastPage={lastPage}
                        ></PaginationService>
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Inventory
