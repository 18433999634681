import React, { useEffect, useState } from 'react'
// javascript plugin that creates a sortable object from a dom object
// reactstrap components
import {
  Card,
  CardFooter,
  CardHeader,
  Container,
  Row,
  Input,
  Col,
  Table,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Spinner,
  Badge
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useHistory } from 'react-router-dom'
import PaginationService from '../../components/Table/PaginationService'
import RecordsDropdown from '../../components/Table/RecordsDropdown'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAuthContext } from '../../contexts/AuthContext'
import { dateFormatter, kBToGB, roundToOneDecimal } from '../../utils/Utils'
import Strings from 'localization'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

function ServiceList({ location }) {
  const { costCenterType } = useAuthContext()
  const columnNames = {
    costCtrType: 'Grp Type',
    costCtrName: 'Grp Name',
    preferredName: 'Nickname',
    data: 'Data',
    PAYG: 'PAYG',
    planStartDate: 'Plan Start',
    msisdn: 'MSISDN',
    plan: 'Plan',
    balance: 'Balance',
    daysLeft: 'Days Left',
    status: 'Status',
    ATUStatus: 'Auto Renew'
  }
  const sortKeys = {
    costCtrName: 'costCtrDescr',
    preferredName: 'preferredName',
    data: 'dataUsed',
    PAYG: 'PAYGUsed',
    planStartDate: 'planStartDate',
    msisdn: 'msisdn',
    plan: 'plan',
    balance: 'balance',
    daysLeft: 'planExpiry',
    status: 'state',
    ATUStatus: null
  }
  const sortable = {
    costCtrName: true,
    preferredName: true,
    data: true,
    PAYG: true,
    planStartDate: true,
    balance: true,
    msisdn: true,
    plan: true,
    daysLeft: true,
    status: true,
    ATUStatus: false
  }
  const [servicesList, setServicesList] = useState([])
  const { getServices, getCostCtrListSimple, getProductList } =
    useDashboardContext()
  const {
    getServiceListFeatures,
    getServiceListColumns,
    getShowCostCtrPicker,
    authCostCtrId,
    authRole
  } = useAccessControlContext()
  const [columns, setColumns] = useState(getServiceListColumns())
  const [serviceListFeatures, setServiceListFeatures] = useState(
    getServiceListFeatures()
  )
  const [pageOffset, setPageOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(0)
  const [maxResults, setMaxResults] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [sortBy, setSortBy] = useState('msisdn')
  const [sortDesc, setSortDesc] = useState(true)
  const [costCtrDropdownOpen, setCostCtrDropdownOpen] = useState(false)
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(null)

  const [groupName, setGroupName] = useState('All')
  const [costCtrId, setCostCtrId] = useState(
    location.state?.costCtrId ? location.state.costCtrId : null
  )

  const [planId, setPlanId] = useState(
    location.state?.planId ? location.state.planId : null
  )

  const [statusFilter, setStatusFilter] = useState(
    location.state?.statusFilter ? location.state.statusFilter : 'off'
  )
  const [planNameFilter, setPlanNameFilter] = useState(null)
  const [planList, setPlanList] = useState([])
  const [planNameFilterOpen, setPlanNameFilterOpen] = useState(false)
  const [costCtrList, setCostCtrList] = useState([])

  const [searchFilter, setSearchFilter] = useState('')

  const history = useHistory()

  // const costCenterType = 'Subtenant'

  const getDaysLeft = (expiryDate) => {
    const expDate = new Date(expiryDate)
    const currentDate = new Date()
    const diffTime = Math.abs(expDate - currentDate)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  }

  const getProducts = async () => {
    await getProductList({
      productType: 'Plan',
      webAvailable: 'No'
    }).then((res) => {
      setPlanList(res)
    })
  }

  const recordFooter = () => {
    const first = maxResults === 0 ? 0 : pageOffset * rowsPerPage + 1
    const last = Math.min(pageOffset * rowsPerPage + rowsPerPage, maxResults)
    return (
      <span>
        showing {first} to {last} of {maxResults} entries
      </span>
    )
  }

  const stateTransformer = (state) => {
    const activeList = ['Registered', 'Validated']
    const suspendedList = ['Grace', 'Dormant', 'Suspended']
    if (activeList.includes(state)) {
      return 'Active'
    } else if (suspendedList.includes(state)) {
      return 'Suspended'
    } else {
      console.log('Unexpected sim status: ', state)
      return state
    }
  }

  const setSort = (sort) => {
    setPageOffset(0)
    if (sort === sortBy) {
      setSortDesc(!sortDesc)
    } else {
      setSortBy(sort)
      setSortDesc(true)
    }
  }

  const changeRowsPerPage = (number) => {
    setPageOffset(0)
    setRowsPerPage(number)
  }

  useEffect(() => {
    setIsLoading(true)
    const loadServices = async () => {
      await getServices({
        pageOffset,
        rowsPerPage,
        sortBy,
        sortOrder: sortDesc ? 'desc' : 'asc',
        groupNameFilter: costCtrId,
        serviceStatusFilter: statusFilter,
        planId,
        searchFilter
      })
        .then((load) => {
          // console.log('load: ', load)
          setIsLoading(false)
          setServicesList(load.results)
          setMaxResults(load.maxResults)
          setLastPage(Math.ceil(load.maxResults / rowsPerPage))
        })
        .catch((err) => {
          setIsLoading(false)
          setError(true)
          setErrorMessage(err.message)
        })
    }
    if (getShowCostCtrPicker()) {
      loadServices()
    } else if (costCtrId) {
      loadServices()
    }
  }, [
    costCtrId,
    getServices,
    pageOffset,
    rowsPerPage,
    sortBy,
    sortDesc,
    statusFilter,
    planId,
    searchFilter
  ])

  useEffect(() => {
    if (!getShowCostCtrPicker()) {
      setCostCtrId(authCostCtrId)
    }
  }, [authCostCtrId, getShowCostCtrPicker])

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleClick = (record) => {
    history.push({
      pathname: '/admin/list/service',
      state: {
        msisdn: record.msisdn,
        svcId: record.svcId,
        groupName: record.costCtrName,
        preferredName: record.preferredName
      }
    })
  }

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  useEffect(() => {
    const loadCostCtrList = async () => {
      await getCostCtrListSimple({ costCtrId: authCostCtrId }).then(
        (response) => {
          setCostCtrList(response)
          if (location.state?.costCtrId) {
            const costCtr = response.find(
              (costCtr) => costCtr.costCtrId === location.state.costCtrId
            )
            setGroupName(costCtr.costCtrDescription)
          }
        }
      )
    }
    loadCostCtrList()
  }, [getCostCtrListSimple])

  useEffect(() => {
    console.log('planNameFilter:', planNameFilter)
    console.log('planId:', planId)
  }, [planNameFilter])

  useEffect(() => {
    console.log('searchFilter:', searchFilter)
  }, [searchFilter])

  return (
    <>
      <SimpleHeader name={Strings.SL_Title} description={Strings.SL_SubTitle} />
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="flex align-items-center">
                  <Col md="4">
                    <RecordsDropdown
                      dropdownOpen={dropdownOpen}
                      toggle={toggle}
                      setRowsPerPage={changeRowsPerPage}
                      rowsPerPage={rowsPerPage}
                    ></RecordsDropdown>
                  </Col>
                  <Col xs="auto">
                    <span className="text-primary">Filter by:</span>
                    {getShowCostCtrPicker() && (
                      <Dropdown
                        isOpen={costCtrDropdownOpen}
                        toggle={() =>
                          setCostCtrDropdownOpen(!costCtrDropdownOpen)
                        }
                        className="ml-1"
                      >
                        <DropdownToggle color="light" caret>
                          {groupName === 'All' ? 'Name' : groupName}
                        </DropdownToggle>
                        <DropdownMenu className="text-primary">
                          <DropdownItem
                            onClick={() => {
                              setPageOffset(0)
                              setCostCtrId(null)
                              setGroupName('All')
                            }}
                          >
                            All
                          </DropdownItem>
                          {costCtrList.map((costCtr) => {
                            return (
                              <DropdownItem
                                key={costCtr.costCtrId}
                                onClick={() => {
                                  setPageOffset(0)
                                  setCostCtrId(costCtr.costCtrId)
                                  setGroupName(costCtr.costCtrDescription)
                                }}
                              >
                                {costCtr.costCtrDescription}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                    {serviceListFeatures.showWithPlanFilter === 'true' && (
                      <Dropdown
                        isOpen={statusDropdownOpen}
                        toggle={() =>
                          setStatusDropdownOpen(!statusDropdownOpen)
                        }
                        className="ml-1"
                      >
                        <DropdownToggle color="light" caret>
                          {statusFilter === 'off'
                            ? 'Service Status'
                            : statusFilter}
                        </DropdownToggle>
                        <DropdownMenu className="text-primary">
                          <DropdownItem onClick={() => setStatusFilter('off')}>
                            All
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => setStatusFilter('withPlan')}
                          >
                            With Plan
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => setStatusFilter('withoutPlan')}
                          >
                            Without Plan
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                    {serviceListFeatures.showPlanNameFilter === 'true' && (
                      <Dropdown
                        isOpen={planNameFilterOpen}
                        toggle={() => {
                          setPlanNameFilterOpen(!planNameFilterOpen)
                          getProducts()
                        }}
                      >
                        <DropdownToggle color="light" caret>
                          {planNameFilter ? planNameFilter : 'Plan Name'}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageOffset(0)
                              setPlanId(null)
                              setPlanNameFilter('All')
                            }}
                          >
                            All
                          </DropdownItem>
                          {planList.map((plan) => {
                            return (
                              <DropdownItem
                                key={plan.planId}
                                onClick={() => {
                                  setPlanNameFilter(plan.planName)
                                  setPlanId(plan.planId)
                                }}
                              >
                                {plan.planName}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </Col>
                  <Col lg="3 ml-auto">
                    <Input
                      className="py-1"
                      placeHolder="search by msisdn"
                      type="search"
                      onKeyPress={(event) => {
                        if (/[^0-9\s]/gi.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        setPageOffset(0)
                        setSearchFilter(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table
                  hover
                  className="align-items-center table-flush text-primary"
                >
                  <thead className="thead-light">
                    <tr>
                      {columns.map((column, index) => (
                        <th
                          scope="col"
                          className="sort"
                          data-sort={sortKeys[column]}
                          onClick={() =>
                            sortable[column] && setSort(sortKeys[column])
                          }
                          key={index}
                        >
                          {columnNames[column]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {servicesList &&
                      servicesList.map((record) => {
                        const dataUsage = roundToOneDecimal(
                          (Number(record.dataUsed) / Number(record.dataLimit)) *
                            100
                        )

                        const PAYGUsage = roundToOneDecimal(
                          Number(record.PAYGUsed) / Number(record.PAYGLimit)
                        )
                        // console.log('dataLimit', record.dataLimit)
                        // console.log(
                        //   'dataLimit !== 0 : ',
                        //   record.dataLimit !== 0
                        // )
                        return (
                          <tr
                            className={
                              authRole === 'ADMIN' ? '' : 'cursor-pointer'
                            }
                            key={record.msisdn}
                            onClick={(e) => {
                              e.preventDefault()
                              if (serviceListFeatures.canClickIntoServiceListItem === 'true') {
                                handleClick(record)
                              }
                            }}
                          >
                            {columns.includes('costCtrType') && (
                              <td key="grouptype" className="grouptype">
                                {Capitalize(record.costCtrType.toLowerCase())}
                              </td>
                            )}
                            {columns.includes('costCtrName') && (
                              <td key="groupname" className="groupname">
                                {record.costCtrName}
                              </td>
                            )}
                            {columns.includes('preferredName') && (
                              <td key="nickname" className="nickname">
                                {record.preferredName}
                              </td>
                            )}
                            {columns.includes('msisdn') && (
                              <td className="msisdn">{record.msisdn}</td>
                            )}
                            {columns.includes('plan') && (
                              <td className="plan">{record.plan}</td>
                            )}
                            {columns.includes('planStartDate') && (
                              <td className="date">
                                {record.planStartDate &&
                                  dateFormatter(record.planStartDate)}
                              </td>
                            )}
                            {columns.includes('daysLeft') && (
                              <td className="expiry">
                                {record.planExpiry &&
                                  getDaysLeft(record.planExpiry)}
                              </td>
                            )}
                            {columns.includes('data') && (
                              <td key="data">
                                {record.dataUsed &&
                                record.dataLimit &&
                                record.dataLimit !== 0 ? (
                                  <>
                                    {dataUsage >= 90 ? (
                                      <Badge
                                        className="badge-dot mr-4"
                                        color="text-primary"
                                      >
                                        {/* <i className="bg-warning" /> */}
                                        <span className="status">
                                          {dataUsage +
                                            '% of ' +
                                            kBToGB(record.dataLimit) +
                                            ' GB'}
                                        </span>
                                        <i className="ml-2 bg-warning" />
                                      </Badge>
                                    ) : (
                                      <h>
                                        {dataUsage +
                                          '% of ' +
                                          kBToGB(record.dataLimit) +
                                          ' GB'}
                                      </h>
                                    )}
                                  </>
                                ) : null}
                              </td>
                            )}
                            {columns.includes('PAYG') && (
                              <td key="payg" className="payg">
                                {/* <h>
                                {'$' +
                                  record.PAYGUsed +
                                  ' of $' +
                                  record.PAYGLimit}
                              </h> */}
                                {record.PAYGUsed >= 80 ? (
                                  <Badge
                                    className="badge-dot mr-4"
                                    color="text-primary"
                                  >
                                    {/* <i className="bg-warning" /> */}
                                    <span className="status">
                                      {'$' +
                                        record.PAYGUsed +
                                        ' of $' +
                                        record.PAYGLimit}
                                    </span>
                                    <i className="ml-2 bg-warning" />
                                  </Badge>
                                ) : (
                                  <div>
                                    {'$' +
                                      record.PAYGUsed +
                                      ' of $' +
                                      record.PAYGLimit}
                                  </div>
                                )}
                                {/* <Progress
                                color={PAYGUsage < 0.5 ? 'success' : 'warning'}
                                className="progress-xs mb-0"
                                max={record.PAYGLimit}
                                value={record.PAYGUsed}
                              /> */}
                              </td>
                            )}
                            {columns.includes('balance') && (
                              <td className="balance">
                                {'$' + record.balance}
                              </td>
                            )}
                            {columns.includes('status') && (
                              <td className="status">
                                {record.billingBars.data !== 'E'
                                  ? 'Barred'
                                  : stateTransformer(record.state)}
                              </td>
                            )}
                            {columns.includes('ATUStatus') && (
                              <td className="atu">
                                {record.ATUStatus &&
                                  (record.ATUStatus === 'E' ? 'On' : 'Off')}
                              </td>
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <Spinner>Loading...</Spinner>
                  </div>
                )}
                {error && (
                  <div className="d-flex justify-content-center">
                    <span className="error-message">{errorMessage}</span>
                  </div>
                )}
              </div>
              {!error && !isLoading && (
                <CardFooter className="py-4">
                  <Row className="d-flex align-items-center">
                    <Col xs="12" md="4" xl="3" xxl="8">
                      {recordFooter()}
                    </Col>
                    <Col sm="6" md="4" xl="9" xxl="2">
                      <nav aria-label="...">
                        <PaginationService
                          setPageOffset={setPageOffset}
                          pageOffset={pageOffset}
                          lastPage={lastPage}
                        ></PaginationService>
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ServiceList
