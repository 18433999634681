import React, { useEffect, useState } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  Table
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useHistory } from 'react-router-dom'
import { useDashboardContext } from '../../../contexts/DashboardContext'
import { useActivationContext } from '../../../contexts/ActivationContext'

function IDVerification({ location }) {
  const {
    msisdn,
    sim,
    email,
    password,
    subscriberDetails,
    mobileType,
    phoneNumber,
    address,
    portInNumber,
    activationType,
    accountNumber,
    acctCostCtrId,
    acctEmail,
    createAccount
  } = location.state

  const history = useHistory()

  const [noOfServices, setNoOfServices] = useState(undefined)
  const { getIDList, isSimKitted } = useActivationContext()
  const { getDealerSimDetails, getProductList } = useDashboardContext()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [IDList, setIDList] = useState({
    A: [],
    B: []
  })
  const [confirmID, setConfirmID] = useState(false)
  const [selectedIDs, setSelectedIDs] = useState([])
  const [numOfSelectedA, setNumOfSelectedA] = useState(0)
  const [numOfSelectedB, setNumOfSelectedB] = useState(0)
  const [pointsRequired, setPointsRequired] = useState(0)
  const [pointsAttained, setPointsAttained] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [planId, setPlanId] = useState(null)
  const [planName, setPlanName] = useState(null)
  const [planDescr, setPlanDescr] = useState(null)
  const [planValue, setPlanValue] = useState(null)

  useEffect(() => {
    const getCurrentPlan = async () => {
      // Get all the plan details
      await getProductList({
        productType: 'Plan',
        webAvaiable: 'No'
      })
        .then(async (planList) => {
          console.log('planList:', planList)
          // Get the current plan Id
          await getDealerSimDetails({
            iccidFilter: sim.slice(-10)
          }).then((simDetailRes) => {
            console.log('simDetailRes:', simDetailRes)
            planList.map((plan) => {
              // Map the plan details to the current plan
              if (plan.planId === simDetailRes.planId) {
                console.log('plan found:', plan)
                setPlanId(plan.planId)
                setPlanName(plan.planName)
                setPlanDescr(plan.planDescr)
                setPlanValue(plan.value)
              }
            })
          })
        })
        .catch((err) => {
          console.log('err:', err)
        })
    }
    getCurrentPlan()
  }, [getProductList, getDealerSimDetails])

  const onSubmit = async () => {
    if (!confirmID) {
      alert("Please confirm the customer's ID")
      return
    }
    let points = pointsRequired
    if (noOfServices && noOfServices < 4) {
      setPointsRequired(pointsRequired + 100)
      points = points + 100
      if (numOfSelectedA < 1 && numOfSelectedB < 2) {
        alert('Please select at least 1 x category A OR 2 x category B')
        return
      }
    } else if (noOfServices && noOfServices >= 4) {
      setPointsRequired(pointsRequired + 200)
      points = points + 200
      if (numOfSelectedA < 2 && (numOfSelectedA < 1 || numOfSelectedB < 2)) {
        alert(
          'Please select at least 2 x category A OR 1 x category A and 2 x category B'
        )
        return
      }
    }
    let idType = selectedIDs.join(',')

    // if a sim is kitted, go to review page directly with plan details
    // else go to plan selection page
    if (isSimKitted) {
      history.push({
        pathname: '/admin/review',
        state: {
          msisdn,
          sim,
          email,
          password,
          subscriberDetails,
          mobileType,
          phoneNumber,
          address,
          portInNumber,
          activationType,
          accountNumber,
          acctCostCtrId,
          acctEmail,
          idDetails: {
            idType: idType,
            idRequest: {
              numServices: noOfServices,
              totalServices: +noOfServices + 1,
              pointsRequired: points,
              pointsAttained: pointsAttained,
              selectedIDs: selectedIDs
            }
          },
          planDetails: {
            planId: planId,
            planName: planName,
            planDescr: planDescr,
            planValue: planValue
          }
        }
      })
    } else {
      history.push({
        pathname: '/admin/plan/selection',
        state: {
          msisdn,
          sim,
          email,
          password,
          subscriberDetails,
          mobileType,
          phoneNumber,
          address,
          portInNumber,
          activationType,
          accountNumber,
          acctCostCtrId,
          acctEmail,
          createAccount,
          idDetails: {
            idType: idType,
            idRequest: {
              numServices: noOfServices,
              totalServices: +noOfServices + 1,
              pointsRequired: points,
              pointsAttained: pointsAttained,
              selectedIDs: selectedIDs
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    const getVerificationIdList = async () => {
      setLoading(true)
      await getIDList()
        .then((res) => {
          console.log('res:', res)
          setLoading(false)
          setIDList({
            A: res
              .filter((item) => item.category === 'A')
              .sort(function (a, b) {
                return a.displayOrder - b.displayOrder
              }),
            B: res
              .filter((item) => item.category === 'B')
              .sort(function (a, b) {
                return a.displayOrder - b.displayOrder
              })
          })
        })
        .catch((err) => {
          console.log('err:', err)
          setLoading(false)
          setIDList([])
        })
    }

    getVerificationIdList()
  }, [])

  useEffect(() => {
    if (!confirmID) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [confirmID])

  return (
    <>
      <SimpleHeader name="ID Verification Required" />
      <Container
        fluid
        className="d-flex align-items-center justify-content-start flex-column"
      >
        <Card style={{ width: '100%' }}>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  ID Verification
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            >
              <div className="pl-lg-4">
                <CardText className="text-primary letter-4">
                  Prior to this activation, the customer has activated this many
                  prepaid services:
                </CardText>
                <CardText>
                  <Input
                    id="NoOfServices"
                    type="number"
                    className="form-control-alternative"
                    style={{ width: '20%' }}
                    placeholder="No. of Services"
                    value={noOfServices}
                    invalid={noOfServices < 0}
                    required={true}
                    onChange={(e) =>
                      setNoOfServices(e.target.value >= 0 ? e.target.value : 0)
                    }
                  />
                  <div className="invalid-feedback">
                    Please enter a valid non-negative number
                  </div>
                </CardText>
                <CardText>
                  If the above number is less than 4, you will need to sight 1 x
                  category A OR 2 x category B
                </CardText>
                <CardText>
                  If the above number is 4 or more, you will need to sight 2 x
                  category A OR 1 x category A and 2 x category B
                </CardText>
                <Row>
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          <th>Category A</th>
                        </tr>
                      </thead>
                      <tbody>
                        {IDList?.A?.map((item) => {
                          return (
                            <tr>
                              <td>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => {
                                      console.log(
                                        'checkbox',
                                        item.displayLabel,
                                        e.target.checked
                                      )
                                      if (e.target.checked) {
                                        setSelectedIDs([
                                          ...selectedIDs,
                                          item.name
                                        ])
                                        setNumOfSelectedA(numOfSelectedA + 1)
                                        setPointsAttained(pointsAttained + 100)
                                      } else {
                                        setSelectedIDs(
                                          selectedIDs.filter(
                                            (id) => id !== item.name
                                          )
                                        )
                                        setNumOfSelectedA(numOfSelectedA - 1)
                                        setPointsAttained(pointsAttained - 100)
                                      }
                                    }}
                                  />
                                  <Label check>{item.displayLabel}</Label>
                                </FormGroup>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          <th>Category B</th>
                        </tr>
                      </thead>
                      <tbody>
                        {IDList?.B?.map((item) => {
                          return (
                            <tr>
                              <td>
                                <FormGroup check inline>
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => {
                                      console.log(
                                        'checkbox',
                                        item.displayLabel,
                                        e.target.checked
                                      )
                                      if (e.target.checked) {
                                        setSelectedIDs([
                                          ...selectedIDs,
                                          item.name
                                        ])
                                        setNumOfSelectedB(numOfSelectedB + 1)
                                        setPointsAttained(pointsAttained + 50)
                                      } else {
                                        setSelectedIDs(
                                          selectedIDs.filter(
                                            (id) => id !== item.name
                                          )
                                        )
                                        setNumOfSelectedB(numOfSelectedB - 1)
                                        setPointsAttained(pointsAttained - 50)
                                      }
                                    }}
                                  />
                                  <Label check>{item.displayLabel}</Label>
                                </FormGroup>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <CardText>
                  <FormGroup check inline className="mt-3">
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        console.log('confirm checkbox', e.target.checked)
                        setConfirmID(e.target.checked)
                      }}
                    />
                    <Label check>
                      I confirm that I have sighted valid and unexpired proof of
                      identity for {subscriberDetails.firstName}{' '}
                      {subscriberDetails.lastName}
                    </Label>
                  </FormGroup>
                </CardText>
                <Row>
                  <Button
                    color="secondary"
                    className=""
                    type="submit"
                    disabled={!isValid}
                  >
                    Submit
                  </Button>
                </Row>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default IDVerification
