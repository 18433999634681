/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback } from 'react'

import { useState, useEffect } from 'react'
// react plugin for creating vector maps
// javascipt plugin for creating charts
import Chart from 'chart.js'
// react plugin used to create charts
import { Doughnut, Pie } from 'react-chartjs-2'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  DropdownMenu,
  Dropdown,
  DropdownToggle
} from 'reactstrap'

// core components

import { useDashboardContext } from '../../contexts/DashboardContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'

import { chartOptions, parseOptions, colors } from 'variables/charts.js'
import SummaryCards from '../../components/Dashboard/SummaryCards'
import DatePicker from '../../components/Dashboard/DatePicker'
import { renderFeatureComponent } from '../../utils/ComponentHelper'
import CostCtrPicker from '../../components/Dashboard/CostCtrPicker'

function Dashboard() {
  const [chartsList, setChartsList] = useState([])

  const {
    getDashboardSummary,
    getDashboardCharts,
    getShowCostCtrPicker,
    authCostCtrId
  } = useAccessControlContext()

  const [cardList, setCardList] = useState([])
  const [costCtrId, setCostCtrId] = useState(authCostCtrId)

  const [datePicker, setDatePicker] = useState({
    startDate: new Date(),
    endDate: null,
    TPeriod: 'Day'
  })

  const handleDatePickerChange = useCallback((picker) => {
    setDatePicker(picker)
  }, [])

  const handleCostCtrChange = useCallback((costCtrId) => {
    setCostCtrId(costCtrId)
    console.log('handle change costCtrId: ', costCtrId)
  }, [])

  const renderCharts = useCallback(() => {
    console.log('render charts chartsList: ', chartsList)
    return chartsList.map((chart) => (
      <Col lg="6">
        {renderFeatureComponent(chart.name, {
          startDate: datePicker.startDate,
          endDate: datePicker.endDate,
          timePeriod: datePicker.TPeriod,
          costCtrId: costCtrId
        })}
      </Col>
    ))
  }, [chartsList, datePicker, costCtrId])

  useEffect(() => {
    console.log('load once')
    setCardList(getDashboardSummary())
    setChartsList(getDashboardCharts())
  }, [getDashboardCharts, getDashboardSummary])

  useEffect(() => {
    if (!getShowCostCtrPicker()) {
      setCostCtrId(authCostCtrId)
    }
  }, [authCostCtrId, getShowCostCtrPicker])

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions())
    }
  }, [])

  useEffect(() => {
    console.log('chartsList change', chartsList)
  }, [chartsList])

  return (
    <>
      {
        <>
          <div className="header bg-primary pb-8">
            <Container fluid>
              <div className="header-body">
                <Row className="d-flex align-items-center pt-4">
                  <Col md="auto" xs="">
                    <h6 className="h2 text-white d-inline-block mb-0">
                      Dashboard
                    </h6>{' '}
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col
                    xs="12 d-flex align-items-center justify-content-between"
                    sm="6 justify-content-sm-start"
                    md="4"
                  >
                    {getShowCostCtrPicker() && (
                      <CostCtrPicker handleOnChange={handleCostCtrChange} />
                    )}
                  </Col>
                  <Col xs="12" sm="6" md="8">
                    <DatePicker handleOnChange={handleDatePickerChange} />
                  </Col>
                </Row>

                <SummaryCards cardList={cardList} costCtrId={costCtrId} />
              </div>
            </Container>
          </div>
          <Container className="mt--8" fluid>
             {/*<Row className="ml-atuo">
              <CostCtrPicker handleOnChange={handleCostCtrChange} />
              <DatePicker handleOnChange={handleDatePickerChange} />
            </Row> */}
            <Row>{renderCharts()}</Row>
          </Container>
        </>
      }
    </>
  )
}

export default Dashboard
