import { useEffect, useState, useCallback } from 'react'
import { useDashboardContext } from '../../contexts/DashboardContext'
import SummartCard from './SummaryCard'

function CurrentSpendCard({ costCtrId }) {
  const [currentSpend, setRetailSpend] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { getTotalSpend } = useDashboardContext()

  const loadRetailSpend = useCallback(async () => {
    setIsLoading(true)
    await getTotalSpend(costCtrId)
      .then((response) => {
        setRetailSpend(response)
      })
      .catch((err) => {
        console.log('error at dashboard')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [getTotalSpend, costCtrId])

  useEffect(() => {
    loadRetailSpend()
  }, [loadRetailSpend])
  return (
    <SummartCard
      title="Current Spend"
      value={'$ ' + currentSpend}
      isLoading={isLoading}
    />
  )
}

export default CurrentSpendCard
