import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'

import { useDashboardContext } from '../../contexts/DashboardContext'

function PurchasePlan({ location }) {
  const { groupName = 'default', nickName, msisdn, svcId } = location.state
  const history = useHistory()

  const { getProductList, purchaseNewPlan } = useDashboardContext()

  const [atu, setAtu] = useState(true)
  const [planName, setPlanName] = useState(null)
  const [planId, setPlanId] = useState(null)

  const [productList, setProductList] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    const loadProductList = async () => {
      await getProductList().then((response) => {
        setProductList(response)
        setPlanName(response[0].planName)
        setPlanId(response[0].planId)
      })
    }
    loadProductList()
    setLoading(false)
  }, [])

  useEffect(() => {
    console.log('groupname:', groupName)
    // console.log('costCtrId:', costCtrId)
    // console.log('preferredname:', preferredName)
    // console.log('planname:', planName)
    // console.log('planId:', planId)
    // console.log('msisdn:', msisdn)
    // console.log('nickname:', nickName)
    // console.log('sim:', sim)
  }, [groupName, planName, nickName, planId, msisdn])

  const purchasePlan = async () => {
    const res = await purchaseNewPlan({
      svcId,
      planId,
      ATUStatus: atu ? 'Enabled' : 'Disabled'
    })
    console.log('change nect plan res: ', res)
    if (res === 200) {
      console.log('change next plan success')
      history.goBack()
    } else {
      console.log('error change plan')
    }
  }

  return (
    <>
      <SimpleHeader name="Purchase New Plan" />
      <Container fluid>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Plan details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form>
              <div className="pl-lg-4">
                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Group name
                  </Label>
                  <Col className="d-flex align-items-center" md={6}>
                    <h>{groupName}</h>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-nickname" md={2}>
                    Nickname
                  </Label>
                  <Col className="d-flex align-items-center" md={10}>
                    <h>{nickName}</h>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="select-plan" md={2}>
                    Plan name
                  </Label>
                  <Col md={6}>
                    <Input
                      id="select-plan"
                      type="select"
                      onChange={(e) => {
                        const index = e.target.selectedIndex
                        setPlanName(productList[index].planName)
                        setPlanId(productList[index].planId)
                      }}
                    >
                      {productList &&
                        productList.map((product, index) => {
                          return <option key={index}>{product.planName}</option>
                        })}
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label className="letter-4" htmlFor="input-password" md={2}>
                    Auto renew
                  </Label>
                  <Col className="d-flex align-items-center" md="5">
                    <Label className="custom-toggle custom-toggle-success mr-1">
                      <Input
                        checked={atu}
                        onChange={() => setAtu(!atu)}
                        type="checkbox"
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </Label>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label
                    className="text-primary letter-4"
                    htmlFor="re-input-password"
                    md={2}
                  >
                    MSISDN
                  </Label>

                  <Col className="d-flex align-items-center" md={10}>
                    <h>{msisdn}</h>
                  </Col>
                </FormGroup>

                <Row>
                  <Button color="secondary" onClick={() => purchasePlan()}>
                    Save
                  </Button>

                  <Button
                    color="light"
                    onClick={(e) => {
                      history.goBack()
                    }}
                  >
                    Cancel
                  </Button>
                </Row>

                {/* </Row> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default PurchasePlan
