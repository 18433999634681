import { Card, CardBody, CardTitle, Row, Spinner } from 'reactstrap'
import { PropTypes } from 'prop-types'
import { useEffect } from 'react'

function SummaryCard({ title, value, isLoading, handleClick = () => {} }) {
  useEffect(() => {
    // console.log('title: ', title)
    // console.log('value: ', value)
  }, [title, value])
  return (
    <Card
      className="card-stats"
      onClick={() => {
        handleClick()
      }}
    >
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {title}
            </CardTitle>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner>Loading...</Spinner>
              </div>
            ) : (
              <span className="h2 font-weight-bold mb-0">{value}</span>
            )}
          </div>
        </Row>
      </CardBody>
    </Card>
  )
}

SummaryCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string
}

export default SummaryCard
