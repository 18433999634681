import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
  Col,
  Spinner,
  // Badge,
  Button
  // CardBody
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useHistory } from 'react-router-dom'
import Strings from 'localization'
// import { useAuthContext } from '../../contexts/AuthContext'
import { useAccessControlContext } from '../../contexts/AccessControlContext'
import PaginationService from '../../components/Table/PaginationService'
import RecordsDropdown from '../../components/Table/RecordsDropdown'
import useDebounce from 'hooks/useDebounce'

const User = ({ location }) => {
  const types = {
    All: 'All',
    Company: 'Company',
    Dealer: 'Dealer',
    Subtenant: 'Subtenant',
    Department: 'Department'
  }

  const [userList, setUserList] = useState([])
  const { getUserList } = useDashboardContext()
  const { getGroupFeatures, getUserListFeatures } = useAccessControlContext()
  const [costCenterType, setCostCenterType] = useState('User')
  //const { costCenterType } = useAuthContext()
  const { authCostCtrId } = useAccessControlContext()
  const [pageOffset, setPageOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState('costCtrDescr')
  const [sortDesc, setSortDesc] = useState(true)
  const [statusFilter, setStatusFilter] = useState('All')
  const [lastPage, setLastPage] = useState(0)
  const [maxResults, setMaxResults] = useState(0)
  const [recordsDropdownOpen, setRecordsDropdownOpen] = useState(false)
  //const [groupDropdownOpen, setGroupDropdownOpen] = useState(false)
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false)
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(null)
  const [searchFilter, setSearchFilter] = useState('')
  const [typeFilter, setTypeFilter] = useState('All')

  const history = useHistory()
  const groupFeatures = getGroupFeatures()
  const userListFeatures = getUserListFeatures()
  const visibleTypes = userListFeatures?.visibleType
  const columns = userListFeatures?.columns

  const columnNames = {
    costCtrType: 'TYPE',
    costCtrDescr: 'NAME',
    authRole: 'ROLE',
    username: 'EMAIL',
    name: 'NAME',
    emailVerified: 'EMAIL VERIFIED',
    lastLogin: 'LAST LOGIN',
    contactMobile: 'PHONE',
    status: 'STATUS'
  }

  const sortKeys = {
    costCtrType: 'costCtrType',
    costCtrDescr: 'costCtrDescr',
    authRole: 'authRole',
    username: 'username',
    name: 'name',
    emailVerified: 'emailVerified',
    lastLogin: 'lastLogin',
    contactMobile: 'contactMobile',
    status: 'status'
  }

  const sortable = {
    costCtrType: true,
    costCtrDescr: true,
    authRole: true,
    username: false,
    name: false,
    emailVerified: false,
    lastLogin: true,
    contactMobile: false,
    status: false
  }

  useEffect(() => {
    console.log('userListFeatures: ', userListFeatures)
  }, [groupFeatures, userListFeatures])

  const recordFooter = () => {
    const first = maxResults === 0 ? 0 : pageOffset * rowsPerPage + 1
    const last = Math.min(pageOffset * rowsPerPage + rowsPerPage, maxResults)
    return (
      <span>
        showing {first} to {last} of {maxResults} entries
      </span>
    )
  }

  const changeRowsPerPage = (number) => {
    setPageOffset(0)
    setRowsPerPage(number)
  }

  const setSort = (sort) => {
    setPageOffset(0)
    if (sort === sortBy) {
      setSortDesc(!sortDesc)
    } else {
      setSortBy(sort)
      setSortDesc(true)
    }
  }

  const debouncedSearchFilter = useDebounce(searchFilter, 300)
  useEffect(() => {
    setIsLoading(true)
    const loadUsers = async () => {
      await getUserList({
        pageOffset,
        rowsPerPage,
        sortBy,
        sortOrder: sortDesc ? 'asc' : 'desc',
        searchFilter: debouncedSearchFilter,
        statusFilter: statusFilter,
        typeFilter: typeFilter
      })
        .then((load) => {
          setIsLoading(false)
          // console.log('load: ', load)
          // console.log('load.results: ', load.results)
          setMaxResults(load.maxResults)
          setLastPage(Math.ceil(load.maxResults / rowsPerPage))
          setUserList(load.results)
        })
        .catch((err) => {
          setIsLoading(false)
          setError(true)
          setErrorMessage(err.message)
        })
    }
    loadUsers()
  }, [
    getUserList,
    pageOffset,
    rowsPerPage,
    sortBy,
    sortDesc,
    debouncedSearchFilter,
    statusFilter,
    typeFilter
  ])

  const statusArray = [
    { status: 'active', label: 'Active' },
    { status: 'terminated', label: 'Terminated' },
    { status: 'suspended', label: 'Suspended' }
  ]

  const toggle = () => {
    setRecordsDropdownOpen(!recordsDropdownOpen)
  }

  const handleClick = (user) => {
    console.log('user/details ')
    history.push({
      pathname: '/admin/user/details',
      state: {
        authUserId: user.authUserId,
        authRole: user.authRole,
        authRoleId: user.authRoleId,
        groupName: user.costCtrDescr,
        status: user.status,
        emailVerified: user.userDetails.emailVerified
      }
    })
  }

  useEffect(() => {
    const loadUsers = async () => {
      const res = await getUserList()
      console.log('userList res: ', res)
      setUserList(res)
    }
    loadUsers()
  }, [getUserList])

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  // avoid showing null when string is empty
  const showNonNullName = (firstName, lastName) => {
    const nonNullFirstName =
      firstName === null || firstName === undefined ? '' : firstName
    const nonNullLastName =
      lastName === null || lastName === undefined ? '' : lastName
    return nonNullFirstName + ' ' + nonNullLastName
  }

  return (
    <>
      <SimpleHeader
        name={Strings[costCenterType].MG_Title}
        description={Strings[costCenterType].MG_SubTitle}
      ></SimpleHeader>
      <Container fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col md="3">
                    {/* <span */}
                    <RecordsDropdown
                      dropdownOpen={recordsDropdownOpen}
                      toggle={toggle}
                      setRowsPerPage={changeRowsPerPage}
                      rowsPerPage={rowsPerPage}
                    ></RecordsDropdown>
                  </Col>
                  {/* <Col md="3"> */}
                  <span className="text-primary">Filter by:</span>
                  <Dropdown
                    isOpen={statusDropdownOpen}
                    toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                    className="ml-1"
                  >
                    <DropdownToggle color="light" caret>
                      {statusFilter === 'All' ? 'User Status' : statusFilter}
                    </DropdownToggle>
                    <DropdownMenu className="text-primary">
                      <DropdownItem onClick={() => setStatusFilter('All')}>
                        All
                      </DropdownItem>
                      <DropdownItem onClick={() => setStatusFilter('Active')}>
                        Active
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setStatusFilter('Terminated')}
                      >
                        Terminated
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {/* </Col> */}
                  {/* <Col md="3"> */}
                  <Dropdown
                    isOpen={typeDropdownOpen}
                    toggle={() => setTypeDropdownOpen(!typeDropdownOpen)}
                    className="ml-1"
                  >
                    <DropdownToggle color="light" caret>
                      {typeFilter === 'All' ? 'Type' : typeFilter}
                    </DropdownToggle>
                    <DropdownMenu className="text-primary">
                      {visibleTypes.map((type, index) => {
                        return (
                          <DropdownItem
                            key={index}
                            onClick={() => {
                              setTypeFilter(types[type])
                            }}
                          >
                            {types[type]}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  {/* </Col> */}
                  {/* <Col lg="3 ml-auto"> */}
                  <Col md="3" className="text-right">
                    <Input
                      className="py-1"
                      placeHolder="search by dealer name"
                      type="search"
                      onKeyPress={(event) => {
                        if (/[^a-zA-Z0-9_-\s]/gi.test(event.key)) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => {
                        setPageOffset(0)
                        setSearchFilter(e.target.value)
                      }}
                    />
                  </Col>
                  {JSON.parse(userListFeatures?.canAddUsers) && (
                    <Col className="text-right">
                      <Button
                        color="light"
                        className="text-primary"
                        onClick={(e) => {
                          e.preventDefault()
                          history.push({
                            pathname: '/admin/user/add',
                            state: {
                              costCenterType: costCenterType,
                              costCtrId: authCostCtrId
                            }
                          })
                        }}
                      >
                        {Strings[costCenterType].MG_Add_Button}
                      </Button>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <div className="table-responsive">
                <Table
                  hover
                  className="align-items-center table-flush text-primary"
                >
                  <thead className="thead-light">
                    <tr>
                      {columns.map((column, index) => (
                        <th
                          scope="col"
                          className="sort"
                          data-sort={sortable[column] ? sortKeys[column] : null}
                          onClick={() =>
                            sortable[column] && setSort(sortKeys[column])
                          }
                          key={index}
                        >
                          {columnNames[column]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="list">
                    {userList &&
                      userList.map((user) => {
                        return (
                          <tr
                            className="cursor-pointer"
                            key={user.authUserId}
                            onClick={(e) => {
                              e.preventDefault()
                              handleClick(user)
                            }}
                          >
                            {columns.includes('costCtrType') && (
                              <td className="costCtrType">
                                {Capitalize(user.costCtrType.toLowerCase())}
                              </td>
                            )}
                            {columns.includes('costCtrDescr') && (
                              <td className="costCtrDescr">
                                {user.costCtrDescr}
                              </td>
                            )}
                            {columns.includes('authRole') && (
                              <td className="authRole">{user.authRole}</td>
                            )}
                            {columns.includes('username') && (
                              <td className="username">{user.username}</td>
                            )}
                            {columns.includes('name') && (
                              <td className="name">
                                {showNonNullName(
                                  user.userDetails?.firstName,
                                  user.userDetails?.lastName
                                )}
                              </td>
                            )}
                            {columns.includes('emailVerified') && (
                              <td
                                className="emailVerified"
                                style={{
                                  color:
                                    user.userDetails?.emailVerified === 'Y'
                                      ? 'green'
                                      : 'red'
                                }}
                              >
                                {user.userDetails?.emailVerified === 'Y'
                                  ? 'Verified'
                                  : 'Not Verified'}
                              </td>
                            )}
                            {columns.includes('lastLogin') && (
                              <td className="lastLogin">{user.lastLogin}</td>
                            )}
                            {columns.includes('contactMobile') && (
                              <td className="contactMobile">
                                {user.userDetails?.contact?.contactMobile}
                              </td>
                            )}
                            {columns.includes('status') && (
                              <td className="status">
                                {user.status
                                  ? statusArray.find(
                                      (i) => i.status === user.status
                                    )?.label
                                  : 'Unknown'}
                              </td>
                            )}
                            {/*<td className="credit">{user.credit}</td>
                            <td className="totalServices">{user.totalServices}</td>
                            <td className="preActiveSIMs">{group.preActiveSIMs}*/}
                            {/*{group.preActiveSIMs < 2 ? (
                              <Badge
                                className="badge-dot mr-4"
                                color="text-primary"
                              >
                              <span className="status">
                              </span>
                              <i className="ml-2 bg-warning" />
                              </Badge>
                            ) : ( null )}
                            </td>*/}
                            {/*<td className="vouchers">{group.vouchers}</td>*/}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    <Spinner>Loading...</Spinner>
                  </div>
                )}
                {error && (
                  <div className="d-flex justify-content-center">
                    <span className="error-message">{errorMessage}</span>
                  </div>
                )}
              </div>
              {!error && !isLoading && (
                <CardFooter className="py-4">
                  <Row className="d-flex align-items-center">
                    <Col xs="12" md="4" xl="3" xxl="8">
                      {recordFooter()}
                    </Col>
                    <Col sm="6" md="4" xl="9" xxl="2">
                      <nav aria-label="...">
                        <PaginationService
                          setPageOffset={setPageOffset}
                          pageOffset={pageOffset}
                          lastPage={lastPage}
                        ></PaginationService>
                      </nav>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default User
