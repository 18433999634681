/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react'
// react library for routing
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'
// core components
import AdminFooter from 'components/Footers/AdminFooter.js'
import AdminNavbar from 'components/Navbar/Navbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'

// import { routes } from 'routes.js'
import { useAccessControlContext } from '../contexts/AccessControlContext'

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true)
  const location = useLocation()
  const history = useHistory()
  const mainContentRef = React.useRef(null)
  React.useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainContentRef.current.scrollTop = 0

    // console.log('Admin layout location', location)
  }, [location])
  const { accessRoutes } = useAccessControlContext()
  const requestPage = sessionStorage.getItem('request_page')
  const mvnoName = process.env.REACT_APP_MVNO_NAME.toLowerCase();

  useEffect(() => {
    if (requestPage) {
      history.push({ pathname: requestPage })
      sessionStorage.removeItem('request_page')
    }
  }, [])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        if (prop.redirect === true && location.state === undefined) {
          return (
            <Redirect
              from={prop.layout + prop.path}
              to="/admin/dashboard"
              key={key}
            />
          )
        } else {
          return (
            <Route
              exact
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          )
        }
      } else {
        return null
      }
    })
  }

  const serviceRoutes = getRoutes(accessRoutes.serviceRoutes)

  const accountRoutes = getRoutes(accessRoutes.accountRoutes)

  // const getBrandText = (path) => {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
  //       return routes[i].name;
  //     }
  //   }
  //   return "Brand";
  // };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned')
      document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned')
      document.body.classList.remove('g-sidenav-hidden')
    }
    setSidenavOpen(!sidenavOpen)
  }
  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/dashboard') === -1
      ? 'light'
      : 'dark'
  }

  return (
    <>
      <Sidebar
        // routes={routes.serviceRoutes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: '/',
          imgSrc: process.env.REACT_APP_HOST_AUTH + `/assets/mvno-dashboard.png`,
          imgAlt: '...'
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
        />
        {serviceRoutes.length > 0 || accountRoutes.length > 0 ? (
          <Switch>
            {serviceRoutes}
            {accountRoutes}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
        ) : null}
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  )
}

export default Admin
