import React, { useEffect, useState } from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
// core components
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import { useHistory } from 'react-router-dom'
import { useDashboardContext } from '../../../contexts/DashboardContext'
import { useActivationContext } from '../../../contexts/ActivationContext'

function PlanSelection({ location }) {
  const {
    msisdn,
    sim,
    email,
    password,
    subscriberDetails,
    mobileType,
    phoneNumber,
    address,
    portInNumber,
    activationType,
    accountNumber,
    acctCostCtrId,
    acctEmail,
    createAccount,
    idDetails
  } = location.state
  const { getProductList } = useDashboardContext() 
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [planDetails, setPlanDetails] = useState([])
  const [productType, setProductType] = useState(null)
  const [planId, setPlanId] = useState(null)
  const [planName, setPlanName] = useState(null)
  const [planDescr, setPlanDescr] = useState(null)
  const [planValue, setPlanValue] = useState(null)
  const [productList, setProductList] = useState([])
  const [showProductList, setShowProductList] = useState(false)
  const [isValid, setIsValid] = useState(false)


  const history = useHistory()

  const onSubmit = () => {
    console.log('planId: ', planId)
    const planDetails = {
      planId: planId,
      planName: planName,
      planDescr: planDescr,
      planValue: planValue
    }

    history.push({
      pathname: '/admin/review',
      state: {
        msisdn,
        sim,
        email,
        password,
        subscriberDetails,
        mobileType,
        phoneNumber,
        address,
        portInNumber,
        activationType,
        accountNumber,
        acctCostCtrId,
        acctEmail,
        createAccount,
        idDetails,
        planDetails: planDetails
      }
    })
  }

  useEffect(() => {
    const loadProductList = async (type) => {
      console.log('type: ', type)
      setLoading(true)
      await getProductList({
        productType: 'Plan',
      })
      .then((res) => {
        setLoading(false)
        console.log('loadProductList: ', res)
        setProductList(res)
      })
      .catch((err) => {
        setLoading(false)
        setError(true)
        setErrorMessage(err.message)
      })
    }
    loadProductList()
  }, [getProductList])

  const productListToggle = () => {
    setShowProductList((prevState) => !prevState)
  }

  useEffect(() => {
    if (planId !== null) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [productType, planId])

  return (
    <>
      <SimpleHeader name="Select A Plan" />
      <Container
        fluid
        className="d-flex align-items-center justify-content-start flex-column"
      >
         <Card style={{ width: '100%' }}>
           <CardHeader>
             <Row className="align-items-center">
               <Col xs="8">
                 <h3 className="mb-0 text-primary neue-bold letter-2">
                   Plan Selection
                 </h3>
               </Col>
             </Row>
           </CardHeader>
           <CardBody className="text-primary">
             <Form
              onSubmit={(e) => {
                e.preventDefault()
                onSubmit()
              }}
            >
              <div className="pl-lg-4">
                  <FormGroup row>
                    <Label className="letter-4" htmlFor="select-plan" md={2}>
                      Select Plan
                    </Label>
                      <Col md={6}>
                      <Dropdown
                        style={{ width: '100%' }}
                        isOpen={showProductList}
                        toggle={productListToggle}
                      >
                        <DropdownToggle color="light" caret>
                          {planId ? productList.find(i => i.planId === planId)?.planName : "Select a Plan"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {productList.map((list) => {
                            return (
                              <DropdownItem
                                key={list.planId}
                                onClick={() => {
                                  setPlanId(list.planId)
                                  setPlanName(list.planName)
                                  setPlanDescr(list.planDescr)
                                  setPlanValue(list.value)
                                }}
                              >
                                {list.planName}
                              </DropdownItem>
                            )
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </FormGroup>
                {/* )} */}
                {planId && (
                  <FormGroup row>
                    <Label className="letter-4 text-primary" htmlFor="select-plan" md={2}>
                      Plan Details
                    </Label>
                    <Col md={10}>
                      <Table className='text-primary letter-4'>
                        <thead>
                          <tr>
                            <th>Plan Name</th>
                            <th>Plan Descr</th>
                            <th>Plan Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{productList.find(i => i.planId === planId)?.planName}</td>
                            <td>{productList.find(i => i.planId === planId)?.planDescr}</td>
                            <td>$ {productList.find(i => i.planId === planId)?.value}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </FormGroup>
                )}
                  <Button color="secondary" className="" type="submit" disabled={!isValid}>
                    Submit
                  </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}  

export default PlanSelection