import PropTypes from 'prop-types'
import createTokenProvider from 'providers/token-provider'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import AuthHandler from 'services/sdk/handlers/AuthHandler'
import HttpClient from 'services/sdk/HttpClient'
import {
  IAuthHandler,
  IHttpClient,
  ITokenProvider
} from 'services/sdk/interfaces'
import { createCtx } from './helpers'
import { UpdateEmailVerificationStatusRequest } from 'services/sdk/models/requests'

type AuthContextProviderProps = {
  children: React.ReactNode
}

interface AuthContextInterface {
  // Providers
  tokenProvider: ITokenProvider
  // Http Clients
  authHandler: IAuthHandler

  // Functions
  login: (form: authForm) => Promise<number>
  sendResetPasswordLink: (username: string) => Promise<number>
  resetPassword: (form: resetPasswordForm) => Promise<number>
  logout(): void
  updateEmailVerificationStatus: (request: UpdateEmailVerificationStatusRequest) => Promise<any>

  // State
  isLogged: boolean
  rememberMe: boolean
  costCenterType: string
}

interface authForm {
  username: string
  password: string
  rememberMe: boolean
}

interface resetPasswordForm {
  otpCode: string
  password: string
}

const [ctx, useContext, Provider, Consumer] = createCtx<AuthContextInterface>()

export const AuthContextProvider: FunctionComponent<
  AuthContextProviderProps
> = ({ children }) => {
  const authHttpClient = useRef<IHttpClient>(
    HttpClient(process.env.REACT_APP_HOST_AUTH)
  ).current

  const authHandler = useRef(AuthHandler(authHttpClient)).current
  const tokenProvider = useRef<ITokenProvider>(createTokenProvider()).current

  // const bootstrapToken = async () => {
  //   let token = await authHandler.getToken();

  //   console.log("In bootstrapToken", token);
  //   if (token) {
  //     return true;
  //   }
  //   return false;
  // };

  // useEffect(() => {
  //   bootstrapToken().then(setIsLogged);
  // }, []);

  const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn())
  const [rememberMe, setRememberMe] = useState(false)
  const [costCenterType, setCostCenterType] = useState(
    tokenProvider.getToken() ? 'Subtenant' : ''
  )

  const login = async (form: authForm) => {
    localStorage.setItem('rememberMe', form.rememberMe.toString())
    setRememberMe(localStorage.getItem('rememberMe') === 'true')

    return await authHandler
      .authenticate(form.username, form.password)
      .then((response) => {
        if (response.ok && response.response) {
          // get cost center type from decoded token
          setCostCenterType('Subtenant')
          tokenProvider.setToken(response.response)
        }
        return response.status
      })
      .catch((err) => {
        return 500
      })
  }

  const sendResetPasswordLink = async (username: string) => {
    return await authHandler
      .sendResetPasswordLink(username)
      .then((response) => {
        console.log('reset password link res: ', response)
        if (response.status === null) {
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('reset password link error: ', err)
        return 500
      })
  }  

  const resetPassword = async (form: resetPasswordForm) => {
    return await authHandler
      .resetPassword(form.otpCode, form.password)
      .then((response) => {
        console.log('reset password res: ', response)
        if (response.status === null) {
          return 200
        }
        return response.status
      })
      .catch((err) => {
        console.log('reset password error: ', err)
        return 500
      })
  }

  const logout = (): void => {
    tokenProvider.setToken(null)
  }

  useEffect(() => {
    const token = tokenProvider.getToken()
    if (token) {
      console.log('token', token)
      setCostCenterType('Subtenant')
    }
  }, [isLogged])

  useEffect(() => {
    const listener = (newIsLogged: boolean): void => {
      setIsLogged(newIsLogged)
    }

    tokenProvider.subscribe(listener)

    setRememberMe(localStorage.getItem('rememberMe') === 'true')

    return (): void => {
      tokenProvider.unsubscribe(listener)
    }
  }, [tokenProvider])

  const updateEmailVerificationStatus = async (request: UpdateEmailVerificationStatusRequest) => {
    return await authHandler
      .updateEmailVerificationStatus(request)
      .then((response) => {
        if (response.ok && response.response) {
          return response.response
        } else {
          throw new Error(
            response.error ? response.error : 'Error when verifying email'
          )
        }
      })
  }

  return (
    <Provider
      value={{
        tokenProvider,
        authHandler,
        isLogged,
        rememberMe,
        costCenterType,
        login,
        logout,
        sendResetPasswordLink,
        resetPassword,
        updateEmailVerificationStatus
      }}
    >
      {children}
    </Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useAuthContext = useContext
export const AuthContextConsumer = Consumer

export default ctx
