import SimpleHeader from 'components/Headers/SimpleHeader.js'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  // ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap'
import PasswordRequirements from '../../components/PasswordRequirements'
import { useDashboardContext } from '../../contexts/DashboardContext'

function ChangePassword({ location }) {
  const [username, setUsername] = useState(location?.state?.username)
  const [authUserId, setAuthUserId] = useState(location?.state?.authUserId)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordIcon, setPasswordIcon] = useState(
    <i className="text-primary fa fa-eye-slash"></i>
  )
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmError, setConfirmError] = useState(false)
  const [confirmErrorMessage, setConfirmErrorMessage] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [updateFailed, setUpdateFailed] = useState(false)
  const [updateFailedMessage, setUpdateFailedMessage] = useState('')
  const { changePassword } = useDashboardContext()
  const history = useHistory()

  useEffect(() => {
    if (showPassword) {
      setPasswordIcon(<i className="text-primary fa fa-eye"></i>)
    } else {
      setPasswordIcon(<i className="text-primary fa fa-eye-slash"></i>)
    }
  }, [showPassword])

  // password requirements are as follows password requirements:
  // Minimum of 12 characters including at least 1 upper letter, 1 lower letter, 1 digit, and 1 special character in "[ @ $ # ! % * ? & ]".
  // Acceptable special characters include the following: [@ $ # ! % * ? &]
  const checkPasswordRules = (password) => {
    // reset error and error message
    setError(false)
    setErrorMessage('')

    // if use removes all the characters, reset the error
    if (password.length === 0) {
      setError(false)
      setErrorMessage('')
      return false
    }

    // check if the password contains at least one uppercase letter, one lowercase letter, one number, and one special character
    // (?=.*[A-Z]): Positive lookahead assertion to ensure there is at least one uppercase letter.
    // (?=.*[a-z]): Positive lookahead assertion to ensure there is at least one lowercase letter.
    // (?=.*\d): Positive lookahead assertion to ensure there is at least one number.
    // (?=.*[@$#!%*?&]): Positive lookahead assertion to ensure there is at least one special character.
    const regex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{12,30}$/

    if (!regex.test(password)) {
      setError(true)
      setErrorMessage(
        'Password does not meet requirement'
      )
      return false
    }
    return true
  }

  useEffect(() => {
    checkPasswordRules(newPassword)
  }, [newPassword])

  const checkConfirmPassword = () => {
    // reset confirmError and error message
    setConfirmError(false)
    setConfirmErrorMessage('')

    if (newPassword !== confirmPassword) {
      setConfirmError(true)
      setConfirmErrorMessage('The passwords do not match')
      return false
    }
    return true
  }

  useEffect(() => {
    checkConfirmPassword()
  }, [confirmPassword])

  const handleChange = () => {
    if (newPassword.length === 0) {
      setError(true)
      setErrorMessage('Password is required')
      return
    }

    // check the confirm password again before you click reset
    // only send ChangePasswordRequest if the password is valid
    if (checkPasswordRules(newPassword)) {
      if (checkConfirmPassword()) {
        sendChangePasswordRequest()
      } else {
        setConfirmError(true)
        setConfirmErrorMessage('The passwords do not match')
      }
    } else {
      setError(true)
      setErrorMessage('Password does not meet requirement')
    }
  }

  const sendChangePasswordRequest = async () => {
    await changePassword({
      authUserId: authUserId,
      password: newPassword
    })
      .then((response) => {
        console.log('do the actions after changing the password')
        setUpdateSuccess(true)
      })
      .catch((error) => {
        console.log('error', error)
        setUpdateFailed(true)
        setUpdateFailedMessage(error)
      })
  }

  const handleBack = () => {
    // clear the password and confirm password
    setNewPassword('')
    setConfirmPassword('')
    history.goBack()
  }

  return (
    <>
      <SimpleHeader name="Change Password" />
      <Container fluid>
        <Card>
          <CardHeader>
            <h3 className="mb-0 text-primary neue-bold letter-2">Account</h3>
          </CardHeader>
          <CardBody>
            <Form>
              <div className="pl-lg-4">
                <Row className="mb-4 align-items-center">
                  <Col md="2">Username</Col>
                  <Col md="9">
                    <h>{username}</h>
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md="2">New password</Col>
                  <Col md="5">
                    <InputGroup
                      className="input-group-merge input-group-alternative"
                      style={{
                        border: `1px solid ${!error ? '#dee2e6' : 'red'}`,
                        boxShadow: 'none'
                      }}
                    >
                      <Input
                        placeholder="Password"
                        autoComplete="password"
                        type={showPassword ? 'text' : 'password'}
                        value={newPassword}
                        invalid={error}
                        onChange={(e) => {
                          setNewPassword(e.target.value)
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {passwordIcon}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {error && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md="2">Confirm new password</Col>
                  <Col md="5">
                    <InputGroup
                      className="input-group-merge input-group-alternative"
                      style={{
                        border: `1px solid ${
                          !confirmError ? '#dee2e6' : 'red'
                        }`,
                        boxShadow: 'none'
                      }}
                    >
                      <Input
                        placeholder="Confirm password"
                        autoComplete="password"
                        type={showPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        invalid={confirmError}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {passwordIcon}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {confirmError && (
                      <div className="error-message">{confirmErrorMessage}</div>
                    )}
                  </Col>
                </Row>
                <Row className="mb-4 align-items-center">
                  <Col md="2"></Col>
                  <Col>
                    <PasswordRequirements password={newPassword} visible />
                  </Col>
                </Row>
                <Button onClick={() => handleBack()}>Back</Button>
                <Button onClick={() => handleChange()} disabled={error || confirmError}>Change</Button>
              </div>
            </Form>
            {updateSuccess ? (
              <Modal isOpen={updateSuccess} className="modal-dialog-centered">
                <ModalBody>
                  <p>successfully update password!</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      console.log('click confirm')
                      setUpdateSuccess(false)
                      history.goBack()
                    }}
                  >
                    Confirm
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
            {updateFailed ? (
              <Modal isOpen={updateFailed} className="modal-dialog-centered">
                <ModalBody>
                  <p>update password failed! {updateFailedMessage}</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => {
                      console.log('click OK')
                      setUpdateFailed(false)
                    }}
                  >
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            ) : null}
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default ChangePassword
