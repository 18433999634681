import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// import IconText from '@components/IconText';

// import {Colors, SVG} from '@styles';

import {validatePassword} from '../utils/validation';

const RequirementContainer = styled.span`
    display: flex;
    align-items: center;
    font-size: 14px;
`;

const Icon = styled.svg`
    width: ${({width}) => width}px;
    // height: ${({height}) => height}px;
    min-width: ${({width}) => width}px;
    max-width: ${({width}) => width}px;
    fill: ${({fillColor}) => fillColor};
    stroke: ${({strokeColor}) => strokeColor};
    stroke-width: ${({strokeWidth}) => strokeWidth}px;
    margin-right: ${({marginRight = '10'}) => marginRight}px;

    align-self: flex-start;
`;
const RequiredItem = ({isValid, children}) => (
    <RequirementContainer>
        {children}
        {isValid && (
            <Icon
                width={12}
                height={null}
                strokeWidth={0}
                viewBox='0 0 20 20'
            >
            <circle cx="10" cy="10" r="10" fill="#012F64" />
                <g clipPath="url(#clip0_0_1)">
                    <path
                        d="M13.541 5.58594L8.95898 11.627L6.25 8.91992L5 10.1699L9.16602 14.3359L15 6.83594L13.541 5.58594Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_0_1">
                        <rect
                            width="10"
                            height="10"
                            fill="white"
                            transform="translate(5 5)"
                        />
                    </clipPath>
                </defs>
            </Icon>
        )}
    </RequirementContainer>
);

const PasswordRequirements = (props) => {
    const {password, visible} = props;

    const pwRequirements = validatePassword(password);

    return (
        <>
            <RequiredItem isValid={pwRequirements.length}>
                12-30 characters&nbsp;&nbsp;
            </RequiredItem>
            <RequiredItem isValid={pwRequirements.uppercase}>
                an uppercase letter&nbsp;&nbsp;
            </RequiredItem>
            <RequiredItem isValid={pwRequirements.number}>
                a number&nbsp;&nbsp;
            </RequiredItem>
            <RequiredItem isValid={pwRequirements.lowercase}>
                a lowercase letter&nbsp;&nbsp;
            </RequiredItem>
            <RequiredItem isValid={pwRequirements.special}>
                a special character (!@#$%&*?)&nbsp;&nbsp;
            </RequiredItem>
        </>
    );
};

PasswordRequirements.propTypes = {
    // Data
    password: PropTypes.string,
    visible: PropTypes.bool,
};

PasswordRequirements.defaultProps = {
    // Data
    password: '',
    visible: true,
};

export default PasswordRequirements;
