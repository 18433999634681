import { useEffect, useState } from 'react'
import SimpleHeader from 'components/Headers/SimpleHeader.js'
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Label,
  Button
} from 'reactstrap'
import { useDashboardContext } from '../../contexts/DashboardContext'
import { useCallback } from 'react'
import { kBToGB } from '../../utils/Utils'
import { useHistory } from 'react-router-dom'

function RechargeReceipt({ location }) {
  const { msisdn, planId, rechargeType, price, name, descr } = location.state
  const { getServiceDetail } = useDashboardContext()
  const [serviceDetail, setServiceDetail] = useState(null)
  const history = useHistory()

  // const loadService = useCallback(async () => {
  //   const serviceInfo = await getServiceDetail()
  //   console.log('service info: ', serviceInfo)
  //   setServiceDetail(serviceInfo)
  // }, [getServiceDetail])

  // useEffect(() => {
  //   loadService()
  // }, [loadService])

  // const renderAccountDetails = useCallback(() => {
  //   if (serviceDetail && serviceDetail.planDetails.balances) {
  //     return serviceDetail.planDetails.balances.map((balance, index) => {
  //       console.log('balance: ', balance)
  //       return (
  //         <FormGroup row key={index}>
  //           <Label className="letter-4" htmlFor="input-msisdn" md={5}>
  //             {balance.description}
  //           </Label>
  //           <Col className="d-flex align-items-center" md={5}>
  //             {balance.description === 'Data' && balance.unlimited === 'N' ? (
  //               <h className="neue-bold">{kBToGB(balance.remaining) + ' GB'}</h>
  //             ) : (
  //               <h className="neue-bold">{balance.remaining}</h>
  //             )}
  //           </Col>
  //         </FormGroup>
  //       )
  //     })
  //   }
  // }, [serviceDetail])

  return (
    <>
      <SimpleHeader name="Recharge Success" />
      <Container fluid>
        <Card>
          <Row className="align-items-center bg-success">
            <h className="ml-3 text-white">Recharge Successfully Processed</h>
          </Row>
          <CardHeader>
            {/* <Row className="align-items-center bg-success">
              <h className="text-white">Recharge successfully processed</h>
            </Row> */}
            <Row className="align-items-center">
              {/* <Col xs="6">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Account Details
                </h3>
              </Col> */}
              <Col md="3">
                <h3 className="mb-0 text-primary neue-bold letter-2">
                  Recharge Details
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="text-primary">
            <Form>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">MSISDN</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{msisdn}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">{rechargeType} Name</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{name}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">{rechargeType} Description</h>
                </Col>
                <Col md="9">
                  <h className="letter-4">{descr}</h>
                </Col>
              </Row>
              <Row className="mb-4 align-items-center">
                <Col md="2">
                  <h className="neue-bold">{rechargeType} Price</h>
                </Col>
                <Col className="d-flex align-items-center" md={9}>
                  <h className="letter-4">{'$ ' + price}</h>
                </Col>
              </Row>
            </Form>
            <Button
              onClick={() => {
                history.push({
                  pathname: '/admin/dashboard'
                })
              }}
            >
              Return to Dashboard
            </Button>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default RechargeReceipt